define("admin/controllers/admin-customize-themes-edit", ["exports", "@ember/controller", "@ember/object", "@ember/service", "discourse/lib/computed", "discourse-common/utils/decorators", "discourse-i18n"], function (_exports, _controller, _object, _service, _computed, _decorators, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminCustomizeThemesEditController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    section = null;
    currentTarget = 0;
    maximized = false;
    static #_2 = (() => dt7948.g(this.prototype, "previewUrl", [(0, _computed.url)("model.id", "/admin/themes/%@/preview")]))();
    #previewUrl = (() => (dt7948.i(this, "previewUrl"), void 0))();
    showAdvanced = false;
    editRouteName = "adminCustomizeThemes.edit";
    showRouteName = "adminCustomizeThemes.show";
    setTargetName(name) {
      const target = this.get("model.targets").find(t => t.name === name);
      this.set("currentTarget", target && target.id);
    }
    currentTargetName(id) {
      const target = this.get("model.targets").find(t => t.id === parseInt(id, 10));
      return target && target.name;
    }
    static #_3 = (() => dt7948.n(this.prototype, "currentTargetName", [(0, _decorators.default)("currentTarget")]))();
    saveButtonText(isSaving) {
      return isSaving ? _discourseI18n.default.t("saving") : _discourseI18n.default.t("admin.customize.save");
    }
    static #_4 = (() => dt7948.n(this.prototype, "saveButtonText", [(0, _decorators.default)("model.isSaving")]))();
    saveDisabled(changed, isSaving) {
      return !changed || isSaving;
    }
    static #_5 = (() => dt7948.n(this.prototype, "saveDisabled", [(0, _decorators.default)("model.changed", "model.isSaving")]))();
    save() {
      this.set("saving", true);
      this.model.saveChanges("theme_fields").finally(() => {
        this.set("saving", false);
      });
    }
    static #_6 = (() => dt7948.n(this.prototype, "save", [_object.action]))();
    fieldAdded(target, name) {
      this.router.replaceWith(this.editRouteName, this.get("model.id"), target, name);
    }
    static #_7 = (() => dt7948.n(this.prototype, "fieldAdded", [_object.action]))();
    onlyOverriddenChanged(onlyShowOverridden) {
      if (onlyShowOverridden) {
        if (!this.model.hasEdited(this.currentTargetName, this.fieldName)) {
          let firstTarget = this.get("model.targets").find(t => t.edited);
          let firstField = this.get(`model.fields.${firstTarget.name}`).find(f => f.edited);
          this.router.replaceWith(this.editRouteName, this.get("model.id"), firstTarget.name, firstField.name);
        }
      }
    }
    static #_8 = (() => dt7948.n(this.prototype, "onlyOverriddenChanged", [_object.action]))();
    goBack() {
      this.router.replaceWith(this.showRouteName, this.model.id);
    }
    static #_9 = (() => dt7948.n(this.prototype, "goBack", [_object.action]))();
  }
  _exports.default = AdminCustomizeThemesEditController;
});