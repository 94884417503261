define("admin/controllers/admin-badges", ["exports", "@glimmer/tracking", "@ember/controller", "@ember/service"], function (_exports, _tracking, _controller, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminBadgesController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "badgeGroupings", [_tracking.tracked]))();
    #badgeGroupings = (() => (dt7948.i(this, "badgeGroupings"), void 0))(); // Set by the route
    static #_3 = (() => dt7948.g(this.prototype, "badgeTypes", [_tracking.tracked]))();
    #badgeTypes = (() => (dt7948.i(this, "badgeTypes"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "protectedSystemFields", [_tracking.tracked]))();
    #protectedSystemFields = (() => (dt7948.i(this, "protectedSystemFields"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "badgeTriggers", [_tracking.tracked]))();
    #badgeTriggers = (() => (dt7948.i(this, "badgeTriggers"), void 0))();
    get selectedRoute() {
      const currentRoute = this.router.currentRouteName;
      const indexRoute = "adminBadges.index";
      if (currentRoute === indexRoute) {
        return "adminBadges.show";
      } else {
        return currentRoute;
      }
    }
  }
  _exports.default = AdminBadgesController;
});