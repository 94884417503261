define("admin/routes/admin-customize-robots-txt", ["exports", "@ember/routing/route", "discourse/lib/ajax"], function (_exports, _route, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminCustomizeRobotsTxtRoute extends _route.default {
    model() {
      return (0, _ajax.ajax)("/admin/customize/robots");
    }
  }
  _exports.default = AdminCustomizeRobotsTxtRoute;
});