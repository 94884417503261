define("admin/models/site-text", ["exports", "@ember/object", "discourse/lib/ajax", "discourse/models/rest"], function (_exports, _object, _ajax, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SiteText extends _rest.default {
    revert(locale) {
      return (0, _ajax.ajax)(`/admin/customize/site_texts/${this.id}?locale=${locale}`, {
        type: "DELETE"
      }).then(result => (0, _object.getProperties)(result.site_text, "value", "can_revert"));
    }
    dismissOutdated(locale) {
      return (0, _ajax.ajax)(`/admin/customize/site_texts/${this.id}/dismiss_outdated?locale=${locale}`, {
        type: "PUT"
      });
    }
  }
  _exports.default = SiteText;
});