define("admin/controllers/admin-badges/index", ["exports", "@glimmer/tracking", "@ember/controller"], function (_exports, _tracking, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminBadgesIndexController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "badgeIntroLinks", [_tracking.tracked]))();
    #badgeIntroLinks = (() => (dt7948.i(this, "badgeIntroLinks"), void 0))(); // Set by the route
    static #_2 = (() => dt7948.g(this.prototype, "badgeIntroEmoji", [_tracking.tracked]))();
    #badgeIntroEmoji = (() => (dt7948.i(this, "badgeIntroEmoji"), void 0))();
  }
  _exports.default = AdminBadgesIndexController;
});