define("admin/components/modal/custom-date-range", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    class="custom-date-range-modal"
    @title={{i18n "admin.dashboard.reports.dates"}}
    @closeModal={{@closeModal}}
  >
    <:body>
      <DateTimeInputRange
        @from={{this.startDate}}
        @to={{this.endDate}}
        @onChange={{this.onChangeDateRange}}
        @showFromTime={{false}}
        @showToTime={{false}}
      />
    </:body>
    <:footer>
      <DButton
        @action={{this.updateDateRange}}
        @label="admin.dashboard.reports.refresh_report"
        @icon="arrows-rotate"
      />
    </:footer>
  </DModal>
  */
  {
    "id": "5UgtVO2l",
    "block": "[[[8,[39,0],[[24,0,\"custom-date-range-modal\"]],[[\"@title\",\"@closeModal\"],[[28,[37,1],[\"admin.dashboard.reports.dates\"],null],[30,1]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@from\",\"@to\",\"@onChange\",\"@showFromTime\",\"@showToTime\"],[[30,0,[\"startDate\"]],[30,0,[\"endDate\"]],[30,0,[\"onChangeDateRange\"]],false,false]],null],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,3],null,[[\"@action\",\"@label\",\"@icon\"],[[30,0,[\"updateDateRange\"]],\"admin.dashboard.reports.refresh_report\",\"arrows-rotate\"]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\"i18n\",\"date-time-input-range\",\"d-button\"]]",
    "moduleName": "admin/components/modal/custom-date-range.hbs",
    "isStrictMode": false
  });
  class CustomDateRange extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "startDate", [_tracking.tracked], function () {
      return this.args.model.startDate;
    }))();
    #startDate = (() => (dt7948.i(this, "startDate"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "endDate", [_tracking.tracked], function () {
      return this.args.model.endDate;
    }))();
    #endDate = (() => (dt7948.i(this, "endDate"), void 0))();
    onChangeDateRange(range) {
      this.startDate = range.from;
      this.endDate = range.to;
    }
    static #_3 = (() => dt7948.n(this.prototype, "onChangeDateRange", [_object.action]))();
    updateDateRange() {
      this.args.model.setCustomDateRange(this.startDate, this.endDate);
      this.args.closeModal();
    }
    static #_4 = (() => dt7948.n(this.prototype, "updateDateRange", [_object.action]))();
  }
  _exports.default = CustomDateRange;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, CustomDateRange);
});