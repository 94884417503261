define("admin/routes/admin-customize-colors-show", ["exports", "@ember/routing/route", "@ember/service"], function (_exports, _route, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminCustomizeColorsShowRoute extends _route.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    model(params) {
      const all = this.modelFor("adminCustomize.colors");
      const model = all.findBy("id", parseInt(params.scheme_id, 10));
      if (model) {
        return model;
      } else {
        this.router.replaceWith("adminCustomize.colors.index");
      }
    }
    serialize(model) {
      return {
        scheme_id: model.get("id")
      };
    }
    setupController(controller, model) {
      controller.set("model", model);
      controller.set("allColors", this.modelFor("adminCustomize.colors"));
    }
  }
  _exports.default = AdminCustomizeColorsShowRoute;
});