define("admin/controllers/admin-dashboard-tab", ["exports", "@ember/controller", "@ember/object", "@ember/service", "admin/components/modal/custom-date-range"], function (_exports, _controller, _object, _service, _customDateRange) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminDashboardTabController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    queryParams = ["period"];
    period = "monthly";
    endDate = (() => moment().locale("en").utc().endOf("day"))();
    _startDate;
    get startDate() {
      if (this._startDate) {
        return this._startDate;
      }
      const fullDay = moment().locale("en").utc().endOf("day");
      switch (this.period) {
        case "yearly":
          return fullDay.subtract(1, "year").startOf("day");
        case "quarterly":
          return fullDay.subtract(3, "month").startOf("day");
        case "weekly":
          return fullDay.subtract(6, "days").startOf("day");
        case "monthly":
          return fullDay.subtract(1, "month").startOf("day");
        default:
          return fullDay.subtract(1, "month").startOf("day");
      }
    }
    static #_2 = (() => dt7948.n(this.prototype, "startDate", [(0, _object.computed)("_startDate", "period")]))();
    setCustomDateRange(_startDate, endDate) {
      this.setProperties({
        _startDate,
        endDate
      });
    }
    static #_3 = (() => dt7948.n(this.prototype, "setCustomDateRange", [_object.action]))();
    setPeriod(period) {
      this.setProperties({
        period,
        _startDate: null
      });
    }
    static #_4 = (() => dt7948.n(this.prototype, "setPeriod", [_object.action]))();
    openCustomDateRangeModal() {
      this.modal.show(_customDateRange.default, {
        model: {
          startDate: this.startDate,
          endDate: this.endDate,
          setCustomDateRange: this.setCustomDateRange
        }
      });
    }
    static #_5 = (() => dt7948.n(this.prototype, "openCustomDateRangeModal", [_object.action]))();
  }
  _exports.default = AdminDashboardTabController;
});