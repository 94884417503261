define("admin/controllers/admin-dashboard-moderation", ["exports", "@ember/object", "discourse-common/utils/decorators", "admin/controllers/admin-dashboard-tab"], function (_exports, _object, _decorators, _adminDashboardTab) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminDashboardModerationController extends _adminDashboardTab.default {
    flagsStatusOptions() {
      return {
        table: {
          total: false,
          perPage: 10
        }
      };
    }
    static #_ = (() => dt7948.n(this.prototype, "flagsStatusOptions", [_decorators.default]))();
    get isModeratorsActivityVisible() {
      return !(this.siteSettings.dashboard_hidden_reports || "").split("|").filter(Boolean).includes("moderators_activity");
    }
    static #_2 = (() => dt7948.n(this.prototype, "isModeratorsActivityVisible", [(0, _object.computed)("siteSettings.dashboard_hidden_reports")]))();
    userFlaggingRatioOptions() {
      return {
        table: {
          total: false,
          perPage: 10
        }
      };
    }
    static #_3 = (() => dt7948.n(this.prototype, "userFlaggingRatioOptions", [_decorators.default]))();
    get filters() {
      return {
        startDate: this.startDate,
        endDate: this.endDate
      };
    }
    static #_4 = (() => dt7948.n(this.prototype, "filters", [(0, _object.computed)("startDate", "endDate")]))();
    lastWeekFilters(endDate) {
      const lastWeek = moment().locale("en").utc().endOf("day").subtract(1, "week");
      return {
        lastWeek,
        endDate
      };
    }
    static #_5 = (() => dt7948.n(this.prototype, "lastWeekFilters", [(0, _decorators.default)("endDate")]))();
  }
  _exports.default = AdminDashboardModerationController;
});