define("admin/components/admin-penalty-history", ["exports", "@ember/component", "@ember-decorators/component", "discourse-common/utils/decorators", "@ember/template-factory"], function (_exports, _component, _component2, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="suspended-count {{this.suspendedCountClass}}"
    title={{i18n "admin.user.last_six_months"}}
  >
    <label>{{i18n "admin.user.suspended_count"}}</label>
    <span>{{this.user.penalty_counts.suspended}}</span>
  </div>
  <div
    class="silenced-count {{this.silencedCountClass}}"
    title={{i18n "admin.user.last_six_months"}}
  >
    <label>{{i18n "admin.user.silenced_count"}}</label>
    <span>{{this.user.penalty_counts.silenced}}</span>
  </div>
  */
  {
    "id": "qFRTZvX9",
    "block": "[[[10,0],[15,0,[29,[\"suspended-count \",[30,0,[\"suspendedCountClass\"]]]]],[15,\"title\",[28,[37,0],[\"admin.user.last_six_months\"],null]],[12],[1,\"\\n  \"],[10,\"label\"],[12],[1,[28,[35,0],[\"admin.user.suspended_count\"],null]],[13],[1,\"\\n  \"],[10,1],[12],[1,[30,0,[\"user\",\"penalty_counts\",\"suspended\"]]],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[15,0,[29,[\"silenced-count \",[30,0,[\"silencedCountClass\"]]]]],[15,\"title\",[28,[37,0],[\"admin.user.last_six_months\"],null]],[12],[1,\"\\n  \"],[10,\"label\"],[12],[1,[28,[35,0],[\"admin.user.silenced_count\"],null]],[13],[1,\"\\n  \"],[10,1],[12],[1,[30,0,[\"user\",\"penalty_counts\",\"silenced\"]]],[13],[1,\"\\n\"],[13]],[],false,[\"i18n\"]]",
    "moduleName": "admin/components/admin-penalty-history.hbs",
    "isStrictMode": false
  });
  const AdminPenaltyHistory = dt7948.c(class AdminPenaltyHistory extends _component.default {
    suspendedCountClass(count) {
      if (count > 0) {
        return "danger";
      }
      return "";
    }
    static #_ = (() => dt7948.n(this.prototype, "suspendedCountClass", [(0, _decorators.default)("user.penalty_counts.suspended")]))();
    silencedCountClass(count) {
      if (count > 0) {
        return "danger";
      }
      return "";
    }
    static #_2 = (() => dt7948.n(this.prototype, "silencedCountClass", [(0, _decorators.default)("user.penalty_counts.silenced")]))();
  }, [(0, _component2.classNames)("penalty-history")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AdminPenaltyHistory);
});