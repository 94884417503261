define("admin/components/modal/merge-users-progress", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/service", "discourse/lib/url", "discourse-common/utils/decorators", "discourse-i18n", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _service, _url, _decorators, _discourseI18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    @title={{i18n "admin.user.merge.progress.title"}}
    @dismissable={{false}}
    @closeModal={{@closeModal}}
  >
    <:body>
      {{this.message}}
    </:body>
  </DModal>
  */
  {
    "id": "rUREJA82",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@dismissable\",\"@closeModal\"],[[28,[37,1],[\"admin.user.merge.progress.title\"],null],false,[30,1]]],[[\"body\"],[[[[1,\"\\n    \"],[1,[30,0,[\"message\"]]],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\"i18n\"]]",
    "moduleName": "admin/components/modal/merge-users-progress.hbs",
    "isStrictMode": false
  });
  class MergeUsersProgress extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "messageBus", [_service.service]))();
    #messageBus = (() => (dt7948.i(this, "messageBus"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "message", [_tracking.tracked], function () {
      return _discourseI18n.default.t("admin.user.merging_user");
    }))();
    #message = (() => (dt7948.i(this, "message"), void 0))();
    constructor() {
      super(...arguments);
      this.messageBus.subscribe("/merge_user", this.onMessage);
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this.messageBus.unsubscribe("/merge_user", this.onMessage);
    }
    onMessage(data) {
      if (data.merged) {
        if (/^\/admin\/users\/list\//.test(location.href)) {
          _url.default.redirectTo(location.href);
        } else {
          _url.default.redirectTo(`/admin/users/${data.user.id}/${data.user.username}`);
        }
      } else if (data.message) {
        this.message = data.message;
      } else if (data.failed) {
        this.message = _discourseI18n.default.t("admin.user.merge_failed");
      }
    }
    static #_3 = (() => dt7948.n(this.prototype, "onMessage", [_decorators.bind]))();
  }
  _exports.default = MergeUsersProgress;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, MergeUsersProgress);
});