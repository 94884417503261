define("admin/routes/admin-email-index", ["exports", "discourse/routes/discourse", "admin/models/email-settings"], function (_exports, _discourse, _emailSettings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminEmailIndexRoute extends _discourse.default {
    model() {
      return _emailSettings.default.find();
    }
  }
  _exports.default = AdminEmailIndexRoute;
});