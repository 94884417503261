define("admin/models/screened-email", ["exports", "@ember/object", "discourse/lib/ajax", "discourse-common/utils/decorators", "discourse-i18n"], function (_exports, _object, _ajax, _decorators, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ScreenedEmail extends _object.default {
    static findAll() {
      return (0, _ajax.ajax)("/admin/logs/screened_emails.json").then(function (screened_emails) {
        return screened_emails.map(function (b) {
          return ScreenedEmail.create(b);
        });
      });
    }
    actionName(action) {
      return _discourseI18n.default.t("admin.logs.screened_actions." + action);
    }
    static #_ = (() => dt7948.n(this.prototype, "actionName", [(0, _decorators.default)("action")]))();
    clearBlock() {
      return (0, _ajax.ajax)("/admin/logs/screened_emails/" + this.id, {
        type: "DELETE"
      });
    }
  }
  _exports.default = ScreenedEmail;
});