define("admin/routes/admin-customize-form-templates-index", ["exports", "@ember/object", "discourse/routes/discourse", "admin/models/form-template"], function (_exports, _object, _discourse, _formTemplate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminCustomizeFormTemplatesIndex extends _discourse.default {
    model() {
      return _formTemplate.default.findAll();
    }
    setupController(controller, model) {
      controller.set("model", model);
    }
    reloadModel() {
      this.refresh();
    }
    static #_ = (() => dt7948.n(this.prototype, "reloadModel", [_object.action]))();
  }
  _exports.default = AdminCustomizeFormTemplatesIndex;
});