define("admin/controllers/admin-logs-screened-ip-addresses", ["exports", "@ember/controller", "@ember/object", "@ember/service", "@ember-decorators/object", "discourse/lib/export-csv", "discourse/lib/export-result", "discourse-common/config/environment", "discourse-common/lib/debounce", "discourse-i18n", "admin/models/screened-ip-address"], function (_exports, _controller, _object, _service, _object2, _exportCsv, _exportResult, _environment, _debounce, _discourseI18n, _screenedIpAddress) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminLogsScreenedIpAddressesController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    loading = false;
    filter = null;
    savedIpAddress = null;
    _debouncedShow() {
      this.set("loading", true);
      _screenedIpAddress.default.findAll(this.filter).then(result => {
        this.setProperties({
          model: result,
          loading: false
        });
      });
    }
    show() {
      (0, _debounce.default)(this, this._debouncedShow, _environment.INPUT_DELAY);
    }
    static #_2 = (() => dt7948.n(this.prototype, "show", [(0, _object2.observes)("filter")]))();
    edit(record, event) {
      event?.preventDefault();
      if (!record.get("editing")) {
        this.set("savedIpAddress", record.get("ip_address"));
      }
      record.set("editing", true);
    }
    static #_3 = (() => dt7948.n(this.prototype, "edit", [_object.action]))();
    allow(record) {
      record.set("action_name", "do_nothing");
      record.save();
    }
    static #_4 = (() => dt7948.n(this.prototype, "allow", [_object.action]))();
    block(record) {
      record.set("action_name", "block");
      record.save();
    }
    static #_5 = (() => dt7948.n(this.prototype, "block", [_object.action]))();
    cancel(record) {
      const savedIpAddress = this.savedIpAddress;
      if (savedIpAddress && record.get("editing")) {
        record.set("ip_address", savedIpAddress);
      }
      record.set("editing", false);
    }
    static #_6 = (() => dt7948.n(this.prototype, "cancel", [_object.action]))();
    save(record) {
      const wasEditing = record.get("editing");
      record.set("editing", false);
      record.save().then(() => this.set("savedIpAddress", null)).catch(e => {
        if (e.jqXHR.responseJSON && e.jqXHR.responseJSON.errors) {
          this.dialog.alert(_discourseI18n.default.t("generic_error_with_reason", {
            error: e.jqXHR.responseJSON.errors.join(". ")
          }));
        } else {
          this.dialog.alert(_discourseI18n.default.t("generic_error"));
        }
        if (wasEditing) {
          record.set("editing", true);
        }
      });
    }
    static #_7 = (() => dt7948.n(this.prototype, "save", [_object.action]))();
    destroyRecord(record) {
      return this.dialog.yesNoConfirm({
        message: _discourseI18n.default.t("admin.logs.screened_ips.delete_confirm", {
          ip_address: record.get("ip_address")
        }),
        didConfirm: () => {
          return record.destroy().then(deleted => {
            if (deleted) {
              this.model.removeObject(record);
            } else {
              this.dialog.alert(_discourseI18n.default.t("generic_error"));
            }
          }).catch(e => {
            this.dialog.alert(_discourseI18n.default.t("generic_error_with_reason", {
              error: `http: ${e.status} - ${e.body}`
            }));
          });
        }
      });
    }
    static #_8 = (() => dt7948.n(this.prototype, "destroyRecord", [_object.action]))();
    recordAdded(arg) {
      this.model.unshiftObject(arg);
    }
    static #_9 = (() => dt7948.n(this.prototype, "recordAdded", [_object.action]))();
    exportScreenedIpList() {
      (0, _exportCsv.exportEntity)("screened_ip").then(_exportResult.outputExportResult);
    }
    static #_10 = (() => dt7948.n(this.prototype, "exportScreenedIpList", [_object.action]))();
  }
  _exports.default = AdminLogsScreenedIpAddressesController;
});