define("admin/components/theme-translation", ["exports", "@ember/object/computed", "discourse/lib/ajax", "discourse/lib/computed", "admin/components/site-setting"], function (_exports, _computed, _ajax, _computed2, _siteSetting) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ThemeTranslation extends _siteSetting.default {
    static #_ = (() => dt7948.g(this.prototype, "setting", [(0, _computed.alias)("translation")]))();
    #setting = (() => (dt7948.i(this, "setting"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "settingName", [(0, _computed.alias)("translation.key")]))();
    #settingName = (() => (dt7948.i(this, "settingName"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "updateUrl", [(0, _computed2.url)("model.id", "/admin/themes/%@")]))();
    #updateUrl = (() => (dt7948.i(this, "updateUrl"), void 0))();
    type = "string";
    _save() {
      const translations = {
        [this.get("translation.key")]: this.get("buffered.value")
      };
      return (0, _ajax.ajax)(this.updateUrl, {
        type: "PUT",
        data: {
          theme: {
            translations,
            locale: this.get("model.locale")
          }
        }
      });
    }
  }
  _exports.default = ThemeTranslation;
});