define("admin/components/site-settings/string", ["exports", "@ember/component", "@ember/template-factory"], function (_exports, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.setting.textarea}}
    <Textarea @value={{this.value}} class="input-setting-textarea" />
  {{else if this.isSecret}}
    <Input
      @type="password"
      @value={{this.value}}
      class="input-setting-string"
      autocomplete="new-password"
    />
  {{else}}
    <TextField @value={{this.value}} @classNames="input-setting-string" />
  {{/if}}
  */
  {
    "id": "jO3uf9Ym",
    "block": "[[[41,[30,0,[\"setting\",\"textarea\"]],[[[1,\"  \"],[8,[39,1],[[24,0,\"input-setting-textarea\"]],[[\"@value\"],[[30,0,[\"value\"]]]],null],[1,\"\"]],[]],[[[41,[30,0,[\"isSecret\"]],[[[1,\"  \"],[8,[39,2],[[24,0,\"input-setting-string\"],[24,\"autocomplete\",\"new-password\"]],[[\"@type\",\"@value\"],[\"password\",[30,0,[\"value\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,3],null,[[\"@value\",\"@classNames\"],[[30,0,[\"value\"]],\"input-setting-string\"]],null],[1,\"\\n\"]],[]]]],[]]]],[],false,[\"if\",\"textarea\",\"input\",\"text-field\"]]",
    "moduleName": "admin/components/site-settings/string.hbs",
    "isStrictMode": false
  });
  class String extends _component.default {}
  _exports.default = String;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, String);
});