define("admin/models/user-field", ["exports", "@ember/object", "discourse/lib/computed", "discourse/models/rest"], function (_exports, _object, _computed, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UserField extends _rest.default {
    static fieldTypes() {
      if (!this._fieldTypes) {
        this._fieldTypes = [UserFieldType.create({
          id: "text"
        }), UserFieldType.create({
          id: "confirm"
        }), UserFieldType.create({
          id: "dropdown",
          hasOptions: true
        }), UserFieldType.create({
          id: "multiselect",
          hasOptions: true
        })];
      }
      return this._fieldTypes;
    }
    static fieldTypeById(id) {
      return this.fieldTypes().findBy("id", id);
    }
  }
  _exports.default = UserField;
  class UserFieldType extends _object.default {
    static #_ = (() => dt7948.g(this.prototype, "name", [(0, _computed.i18n)("id", "admin.user_fields.field_types.%@")]))();
    #name = (() => (dt7948.i(this, "name"), void 0))();
  }
});