define("admin/components/admin-report-radar", ["exports", "@glimmer/component", "discourse-common/lib/helpers", "admin/lib/hex-to-rgba", "admin/models/report", "admin/components/chart", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helpers, _hexToRgba, _report, _chart, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminReportRadar extends _component.default {
    get chartConfig() {
      const {
        model: model1
      } = this.args;
      const chartData1 = (0, _helpers.makeArray)(model1.chartData || model1.data).map(cd1 => ({
        label: cd1.label,
        color: cd1.color,
        data: _report.default.collapse(model1, cd1.data)
      }));
      const data1 = {
        labels: chartData1[0].data.mapBy("x"),
        datasets: chartData1.map(cd1 => ({
          label: cd1.label,
          data: cd1.data.mapBy("y"),
          fill: true,
          backgroundColor: (0, _hexToRgba.default)(cd1.color, 0.3),
          borderColor: cd1.color,
          pointBackgroundColor: cd1.color,
          pointBorderColor: "#fff",
          pointHoverBackgroundColor: "#fff",
          pointHoverBorderColor: cd1.color
        }))
      };
      return {
        type: "radar",
        data: data1,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 0,
          hover: {
            mode: "index"
          },
          animation: {
            duration: 0
          },
          plugins: {
            tooltip: {
              mode: "index",
              intersect: false,
              callbacks: {
                beforeFooter: tooltipItem1 => {
                  const total1 = tooltipItem1.reduce((sum1, item1) => sum1 + parseInt(item1.parsed.r || 0, 10));
                  return `= ${total1}`;
                }
              }
            }
          }
        }
      };
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <Chart
          @chartConfig={{this.chartConfig}}
          class="admin-report-chart admin-report-radar"
        />
      
    */
    {
      "id": "9BNvFWiT",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"admin-report-chart admin-report-radar\"]],[[\"@chartConfig\"],[[30,0,[\"chartConfig\"]]]],null],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/admin/components/admin-report-radar.js",
      "scope": () => [_chart.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AdminReportRadar;
});