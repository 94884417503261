define("admin/controllers/admin-customize-themes", ["exports", "@ember/controller", "discourse-common/utils/decorators", "admin/models/theme"], function (_exports, _controller, _decorators, _theme) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminCustomizeThemesController extends _controller.default {
    currentTab = (() => _theme.THEMES)();
    fullThemes(themes) {
      return themes.filter(t => !t.get("component"));
    }
    static #_ = (() => dt7948.n(this.prototype, "fullThemes", [(0, _decorators.default)("model", "model.@each.component")]))();
    childThemes(themes) {
      return themes.filter(t => t.get("component"));
    }
    static #_2 = (() => dt7948.n(this.prototype, "childThemes", [(0, _decorators.default)("model", "model.@each.component")]))();
    installedThemes(content) {
      return content || [];
    }
    static #_3 = (() => dt7948.n(this.prototype, "installedThemes", [(0, _decorators.default)("model.content")]))();
  }
  _exports.default = AdminCustomizeThemesController;
});