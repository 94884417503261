define("admin/components/report-filters/list", ["exports", "@ember/component", "admin/components/report-filters/filter", "@ember/template-factory"], function (_exports, _component, _filter, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <ComboBox
    @content={{this.filter.choices}}
    @value={{this.filter.default}}
    @onChange={{this.onChange}}
    @options={{hash
      allowAny=this.filter.allow_any
      autoInsertNoneItem=this.filter.auto_insert_none_item
      filterable=true
      none="admin.dashboard.report_filter_any"
    }}
  />
  */
  {
    "id": "fry5lVoK",
    "block": "[[[8,[39,0],null,[[\"@content\",\"@value\",\"@onChange\",\"@options\"],[[30,0,[\"filter\",\"choices\"]],[30,0,[\"filter\",\"default\"]],[30,0,[\"onChange\"]],[28,[37,1],null,[[\"allowAny\",\"autoInsertNoneItem\",\"filterable\",\"none\"],[[30,0,[\"filter\",\"allow_any\"]],[30,0,[\"filter\",\"auto_insert_none_item\"]],true,\"admin.dashboard.report_filter_any\"]]]]],null]],[],false,[\"combo-box\",\"hash\"]]",
    "moduleName": "admin/components/report-filters/list.hbs",
    "isStrictMode": false
  });
  class List extends _filter.default {}
  _exports.default = List;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, List);
});