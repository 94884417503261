define("admin/controllers/admin-plugins", ["exports", "@ember/controller", "@ember/service"], function (_exports, _controller, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "adminPluginNavManager", [_service.service]))();
    #adminPluginNavManager = (() => (dt7948.i(this, "adminPluginNavManager"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    get adminRoutes() {
      return this.allAdminRoutes.filter(route => this.routeExists(route));
    }
    get brokenAdminRoutes() {
      return this.allAdminRoutes.filter(route => !this.routeExists(route));
    }

    // NOTE: See also AdminPluginsIndexController, there is some duplication here
    // while we convert plugins to use_new_show_route
    get allAdminRoutes() {
      return this.model.filter(plugin => plugin?.enabled && plugin?.adminRoute).map(plugin => {
        return Object.assign(plugin.adminRoute, {
          plugin_id: plugin.id
        });
      });
    }
    get showTopNav() {
      return !this.adminPluginNavManager.viewingPluginsList && (!this.adminPluginNavManager.currentPlugin || this.adminPluginNavManager.isSidebarMode);
    }
    routeExists(route) {
      try {
        if (route.use_new_show_route) {
          this.router.urlFor(route.full_location, route.location);
        } else {
          this.router.urlFor(route.full_location);
        }
        return true;
      } catch (e) {
        return false;
      }
    }
  }
  _exports.default = AdminPluginsController;
});