define("admin/services/admin-tools", ["exports", "@ember/object", "@ember/service", "@ember/template", "rsvp", "discourse/lib/ajax", "discourse-i18n", "admin/components/modal/penalize-user", "admin/models/admin-user"], function (_exports, _object, _service, _template, _rsvp, _ajax, _discourseI18n, _penalizeUser, _adminUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // A service that can act as a bridge between the front end Discourse application
  // and the admin application. Use this if you need front end code to access admin
  // modules. Inject it optionally, and if it exists go to town!
  class AdminToolsService extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    showActionLogs(target, filters) {
      this.router.transitionTo("adminLogs.staffActionLogs", {
        queryParams: {
          filters
        }
      });
    }
    checkSpammer(userId) {
      return _adminUser.default.find(userId).then(au => this.spammerDetails(au));
    }
    deleteUser(id, formData) {
      return _adminUser.default.find(id).then(user => user.destroy(formData));
    }
    spammerDetails(adminUser) {
      return {
        deleteUser: () => this._deleteSpammer(adminUser),
        canDelete: adminUser.get("can_be_deleted") && adminUser.get("can_delete_all_posts")
      };
    }
    async showControlModal(type, user, opts) {
      opts = opts || {};
      const loadedUser = user.adminUserView ? user : await _adminUser.default.find(user.get("id"));
      this.modal.show(_penalizeUser.default, {
        model: {
          penaltyType: type,
          postId: opts.postId,
          postEdit: opts.postEdit,
          user: loadedUser,
          before: opts.before,
          successCallback: opts.successCallback
        }
      });
    }
    static #_4 = (() => dt7948.n(this.prototype, "showControlModal", [_object.action]))();
    showSilenceModal(user, opts) {
      this.showControlModal("silence", user, opts);
    }
    showSuspendModal(user, opts) {
      this.showControlModal("suspend", user, opts);
    }
    _deleteSpammer(adminUser) {
      // Try loading the email if the site supports it
      let tryEmail = this.siteSettings.moderators_view_emails ? adminUser.checkEmail() : _rsvp.Promise.resolve();
      return tryEmail.then(() => {
        let message = (0, _template.htmlSafe)(_discourseI18n.default.messageFormat("flagging.delete_confirm_MF", {
          POSTS: adminUser.get("post_count"),
          TOPICS: adminUser.get("topic_count"),
          email: adminUser.get("email") || _discourseI18n.default.t("flagging.hidden_email_address"),
          ip_address: adminUser.get("ip_address") || _discourseI18n.default.t("flagging.ip_address_missing")
        }));
        let userId = adminUser.get("id");
        return new _rsvp.Promise((resolve, reject) => {
          this.dialog.deleteConfirm({
            message,
            class: "flagging-delete-spammer",
            confirmButtonLabel: "flagging.yes_delete_spammer",
            confirmButtonIcon: "triangle-exclamation",
            didConfirm: () => {
              return (0, _ajax.ajax)(`/admin/users/${userId}.json`, {
                type: "DELETE",
                data: {
                  delete_posts: true,
                  block_email: true,
                  block_urls: true,
                  block_ip: true,
                  delete_as_spammer: true,
                  context: window.location.pathname
                }
              }).then(result => {
                if (result.deleted) {
                  resolve();
                } else {
                  throw new Error("failed to delete");
                }
              }).catch(() => {
                this.dialog.alert(_discourseI18n.default.t("admin.user.delete_failed"));
                reject();
              });
            }
          });
        });
      });
    }
  }
  _exports.default = AdminToolsService;
});