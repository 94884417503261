define("admin/controllers/admin-site-settings-category", ["exports", "@ember/controller", "discourse-common/utils/decorators"], function (_exports, _controller, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminSiteSettingsCategoryController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "adminSiteSettings", [_controller.inject]))();
    #adminSiteSettings = (() => (dt7948.i(this, "adminSiteSettings"), void 0))();
    categoryNameKey = null;
    category(categories, nameKey) {
      return (categories || []).findBy("nameKey", nameKey);
    }
    static #_2 = (() => dt7948.n(this.prototype, "category", [(0, _decorators.default)("adminSiteSettings.visibleSiteSettings", "categoryNameKey")]))();
    filteredContent(category) {
      return category ? category.siteSettings : [];
    }
    static #_3 = (() => dt7948.n(this.prototype, "filteredContent", [(0, _decorators.default)("category")]))();
  }
  _exports.default = AdminSiteSettingsCategoryController;
});