define("admin/controllers/admin-web-hooks-edit", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/service", "@ember/utils", "discourse/lib/ajax-error", "discourse-common/utils/decorators", "discourse-i18n"], function (_exports, _controller, _object, _computed, _service, _utils, _ajaxError, _decorators, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminWebHooksEditController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "adminWebHooks", [_controller.inject]))();
    #adminWebHooks = (() => (dt7948.i(this, "adminWebHooks"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "groupedEventTypes", [(0, _computed.alias)("adminWebHooks.groupedEventTypes")]))();
    #groupedEventTypes = (() => (dt7948.i(this, "groupedEventTypes"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "defaultEventTypes", [(0, _computed.alias)("adminWebHooks.defaultEventTypes")]))();
    #defaultEventTypes = (() => (dt7948.i(this, "defaultEventTypes"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "contentTypes", [(0, _computed.alias)("adminWebHooks.contentTypes")]))();
    #contentTypes = (() => (dt7948.i(this, "contentTypes"), void 0))();
    showTagsFilter() {
      return this.siteSettings.tagging_enabled;
    }
    static #_8 = (() => dt7948.n(this.prototype, "showTagsFilter", [_decorators.default]))();
    savingStatus(isSaving, saved, saveButtonDisabled) {
      if (isSaving) {
        return _discourseI18n.default.t("saving");
      } else if (!saveButtonDisabled && saved) {
        return _discourseI18n.default.t("saved");
      }
      // Use side effect of validation to clear saved text
      this.set("saved", false);
      return "";
    }
    static #_9 = (() => dt7948.n(this.prototype, "savingStatus", [(0, _decorators.default)("model.isSaving", "saved", "saveButtonDisabled")]))();
    saveButtonText(isNew) {
      return isNew ? _discourseI18n.default.t("admin.web_hooks.create") : _discourseI18n.default.t("admin.web_hooks.save");
    }
    static #_10 = (() => dt7948.n(this.prototype, "saveButtonText", [(0, _decorators.default)("model.isNew")]))();
    secretValidation(secret) {
      if (!(0, _utils.isEmpty)(secret)) {
        if (secret.includes(" ")) {
          return _object.default.create({
            failed: true,
            reason: _discourseI18n.default.t("admin.web_hooks.secret_invalid")
          });
        }
        if (secret.length < 12) {
          return _object.default.create({
            failed: true,
            reason: _discourseI18n.default.t("admin.web_hooks.secret_too_short")
          });
        }
      }
    }
    static #_11 = (() => dt7948.n(this.prototype, "secretValidation", [(0, _decorators.default)("model.secret")]))();
    eventTypeValidation(isWildcard, eventTypes) {
      if (!isWildcard && (0, _utils.isEmpty)(eventTypes)) {
        return _object.default.create({
          failed: true,
          reason: _discourseI18n.default.t("admin.web_hooks.event_type_missing")
        });
      }
    }
    static #_12 = (() => dt7948.n(this.prototype, "eventTypeValidation", [(0, _decorators.default)("model.wildcard_web_hook", "model.web_hook_event_types.[]")]))();
    saveButtonDisabled(isSaving, secretValidation, eventTypeValidation, payloadUrl) {
      return isSaving ? false : secretValidation || eventTypeValidation || (0, _utils.isEmpty)(payloadUrl);
    }
    static #_13 = (() => dt7948.n(this.prototype, "saveButtonDisabled", [(0, _decorators.default)("model.isSaving", "secretValidation", "eventTypeValidation", "model.payload_url")]))();
    async save() {
      this.set("saved", false);
      try {
        await this.model.save();
        this.set("saved", true);
        this.adminWebHooks.model.addObject(this.model);
        this.router.transitionTo("adminWebHooks.show", this.model);
      } catch (e) {
        (0, _ajaxError.popupAjaxError)(e);
      }
    }
    static #_14 = (() => dt7948.n(this.prototype, "save", [_object.action]))();
  }
  _exports.default = AdminWebHooksEditController;
});