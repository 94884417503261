define("admin/controllers/admin-watched-words", ["exports", "@ember/controller", "@ember/object", "@ember/utils", "@ember-decorators/object", "discourse-common/config/environment", "discourse-common/lib/debounce"], function (_exports, _controller, _object, _utils, _object2, _environment, _debounce) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminWatchedWordsController extends _controller.default {
    filter = null;
    showWords = false;
    _filterContent() {
      if ((0, _utils.isEmpty)(this.allWatchedWords)) {
        return;
      }
      if (!this.filter) {
        this.set("model", this.allWatchedWords);
        return;
      }
      const filter = this.filter.toLowerCase();
      const model = [];
      this.allWatchedWords.forEach(wordsForAction => {
        const wordRecords = wordsForAction.words.filter(wordRecord => {
          return wordRecord.word.includes(filter);
        });
        model.pushObject(_object.default.create({
          nameKey: wordsForAction.nameKey,
          name: wordsForAction.name,
          words: wordRecords
        }));
      });
      this.set("model", model);
    }
    filterContent() {
      (0, _debounce.default)(this, this._filterContent, _environment.INPUT_DELAY);
    }
    static #_ = (() => dt7948.n(this.prototype, "filterContent", [(0, _object2.observes)("filter")]))();
    clearFilter() {
      this.set("filter", "");
    }
    static #_2 = (() => dt7948.n(this.prototype, "clearFilter", [_object.action]))();
    toggleMenu() {
      const adminDetail = document.querySelector(".admin-detail");
      ["mobile-closed", "mobile-open"].forEach(state => {
        adminDetail.classList.toggle(state);
      });
    }
    static #_3 = (() => dt7948.n(this.prototype, "toggleMenu", [_object.action]))();
  }
  _exports.default = AdminWatchedWordsController;
});