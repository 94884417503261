define("admin/templates/backups-settings", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <DBreadcrumbsItem @path="/admin/backups/settings" @label={{i18n "settings"}} />
  
  <div class="content-body admin-config-area__settings admin-detail pull-left">
    <AdminFilteredSiteSettings
      @initialFilter={{@model.initialFilter}}
      @settings={{@model.settings}}
      @onFilterChanged={{this.filterChanged}}
    />
  </div>
  */
  {
    "id": "3MA2lYtE",
    "block": "[[[8,[39,0],null,[[\"@path\",\"@label\"],[\"/admin/backups/settings\",[28,[37,1],[\"settings\"],null]]],null],[1,\"\\n\\n\"],[10,0],[14,0,\"content-body admin-config-area__settings admin-detail pull-left\"],[12],[1,\"\\n  \"],[8,[39,2],null,[[\"@initialFilter\",\"@settings\",\"@onFilterChanged\"],[[30,1,[\"initialFilter\"]],[30,1,[\"settings\"]],[30,0,[\"filterChanged\"]]]],null],[1,\"\\n\"],[13]],[\"@model\"],false,[\"d-breadcrumbs-item\",\"i18n\",\"admin-filtered-site-settings\"]]",
    "moduleName": "admin/templates/backups-settings.hbs",
    "isStrictMode": false
  });
});