define("admin/components/admin-page-action-button", ["exports", "discourse/components/d-button", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _dButton, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.PrimaryButton = _exports.DefaultButton = _exports.DangerButton = _exports.AdminPageActionButton = void 0;
  const AdminPageActionButton = _exports.AdminPageActionButton = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <DButton
      class="admin-page-action-button btn-small"
      ...attributes
      @action={{@action}}
      @route={{@route}}
      @routeModels={{@routeModels}}
      @label={{@label}}
      @title={{@title}}
      @icon={{@icon}}
      @isLoading={{@isLoading}}
    />
  
  */
  {
    "id": "I8DfAstA",
    "block": "[[[1,\"\\n  \"],[8,[32,0],[[24,0,\"admin-page-action-button btn-small\"],[17,1]],[[\"@action\",\"@route\",\"@routeModels\",\"@label\",\"@title\",\"@icon\",\"@isLoading\"],[[30,2],[30,3],[30,4],[30,5],[30,6],[30,7],[30,8]]],null],[1,\"\\n\"]],[\"&attrs\",\"@action\",\"@route\",\"@routeModels\",\"@label\",\"@title\",\"@icon\",\"@isLoading\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/admin/components/admin-page-action-button.js",
    "scope": () => [_dButton.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)());
  const PrimaryButton = _exports.PrimaryButton = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <AdminPageActionButton
      class="btn-primary"
      ...attributes
      @action={{@action}}
      @route={{@route}}
      @routeModels={{@routeModels}}
      @label={{@label}}
      @title={{@title}}
      @icon={{@icon}}
      @isLoading={{@isLoading}}
    />
  
  */
  {
    "id": "Al/RGnSM",
    "block": "[[[1,\"\\n  \"],[8,[32,0],[[24,0,\"btn-primary\"],[17,1]],[[\"@action\",\"@route\",\"@routeModels\",\"@label\",\"@title\",\"@icon\",\"@isLoading\"],[[30,2],[30,3],[30,4],[30,5],[30,6],[30,7],[30,8]]],null],[1,\"\\n\"]],[\"&attrs\",\"@action\",\"@route\",\"@routeModels\",\"@label\",\"@title\",\"@icon\",\"@isLoading\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/admin/components/admin-page-action-button.js",
    "scope": () => [AdminPageActionButton],
    "isStrictMode": true
  }), (0, _templateOnly.default)());
  const DangerButton = _exports.DangerButton = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <AdminPageActionButton
      class="btn-danger"
      ...attributes
      @action={{@action}}
      @route={{@route}}
      @routeModels={{@routeModels}}
      @label={{@label}}
      @title={{@title}}
      @icon={{@icon}}
      @isLoading={{@isLoading}}
    />
  
  */
  {
    "id": "4vR0zPfL",
    "block": "[[[1,\"\\n  \"],[8,[32,0],[[24,0,\"btn-danger\"],[17,1]],[[\"@action\",\"@route\",\"@routeModels\",\"@label\",\"@title\",\"@icon\",\"@isLoading\"],[[30,2],[30,3],[30,4],[30,5],[30,6],[30,7],[30,8]]],null],[1,\"\\n\"]],[\"&attrs\",\"@action\",\"@route\",\"@routeModels\",\"@label\",\"@title\",\"@icon\",\"@isLoading\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/admin/components/admin-page-action-button.js",
    "scope": () => [AdminPageActionButton],
    "isStrictMode": true
  }), (0, _templateOnly.default)());
  const DefaultButton = _exports.DefaultButton = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <AdminPageActionButton
      class="btn-default"
      ...attributes
      @action={{@action}}
      @route={{@route}}
      @routeModels={{@routeModels}}
      @label={{@label}}
      @title={{@title}}
      @icon={{@icon}}
      @isLoading={{@isLoading}}
    />
  
  */
  {
    "id": "qFuWqUhv",
    "block": "[[[1,\"\\n  \"],[8,[32,0],[[24,0,\"btn-default\"],[17,1]],[[\"@action\",\"@route\",\"@routeModels\",\"@label\",\"@title\",\"@icon\",\"@isLoading\"],[[30,2],[30,3],[30,4],[30,5],[30,6],[30,7],[30,8]]],null],[1,\"\\n\"]],[\"&attrs\",\"@action\",\"@route\",\"@routeModels\",\"@label\",\"@title\",\"@icon\",\"@isLoading\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/admin/components/admin-page-action-button.js",
    "scope": () => [AdminPageActionButton],
    "isStrictMode": true
  }), (0, _templateOnly.default)());
});