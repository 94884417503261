define("admin/templates/config-about", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <AdminPageHeader
    @titleLabel="admin.config_areas.about.header"
    @descriptionLabel="admin.config_areas.about.description"
    @hideTabs={{true}}
  >
    <:breadcrumbs>
      <DBreadcrumbsItem
        @path="/admin/config/about"
        @label={{i18n "admin.config_areas.about.header"}}
      />
    </:breadcrumbs>
  </AdminPageHeader>
  
  <div class="admin-container admin-config-page__main-area">
    <AdminConfigAreas::About @data={{this.model.site_settings}} />
  </div>
  */
  {
    "id": "0DkxpLj8",
    "block": "[[[8,[39,0],null,[[\"@titleLabel\",\"@descriptionLabel\",\"@hideTabs\"],[\"admin.config_areas.about.header\",\"admin.config_areas.about.description\",true]],[[\"breadcrumbs\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@path\",\"@label\"],[\"/admin/config/about\",[28,[37,2],[\"admin.config_areas.about.header\"],null]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n\"],[10,0],[14,0,\"admin-container admin-config-page__main-area\"],[12],[1,\"\\n  \"],[8,[39,3],null,[[\"@data\"],[[30,0,[\"model\",\"site_settings\"]]]],null],[1,\"\\n\"],[13]],[],false,[\"admin-page-header\",\"d-breadcrumbs-item\",\"i18n\",\"admin-config-areas/about\"]]",
    "moduleName": "admin/templates/config-about.hbs",
    "isStrictMode": false
  });
});