define("admin/controllers/admin-watched-words-action", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/runloop", "@ember/service", "discourse/lib/ajax", "discourse/lib/computed", "discourse-common/utils/decorators", "discourse-i18n", "admin/components/modal/watched-word-test", "admin/models/watched-word"], function (_exports, _controller, _object, _computed, _runloop, _service, _ajax, _computed2, _decorators, _discourseI18n, _watchedWordTest, _watchedWord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminWatchedWordsActionController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "adminWatchedWords", [_controller.inject]))();
    #adminWatchedWords = (() => (dt7948.i(this, "adminWatchedWords"), void 0))();
    actionNameKey = null;
    static #_4 = (() => dt7948.g(this.prototype, "downloadLink", [(0, _computed2.fmt)("actionNameKey", "/admin/customize/watched_words/action/%@/download")]))();
    #downloadLink = (() => (dt7948.i(this, "downloadLink"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "showWordsList", [(0, _computed.or)("adminWatchedWords.showWords", "adminWatchedWords.filter")]))();
    #showWordsList = (() => (dt7948.i(this, "showWordsList"), void 0))();
    findAction(actionName) {
      return (this.adminWatchedWords.model || []).findBy("nameKey", actionName);
    }
    currentAction(actionName) {
      return this.findAction(actionName);
    }
    static #_6 = (() => dt7948.n(this.prototype, "currentAction", [(0, _decorators.default)("actionNameKey", "adminWatchedWords.model")]))();
    regexpError(words) {
      for (const {
        regexp,
        word
      } of words) {
        try {
          RegExp(regexp);
        } catch {
          return _discourseI18n.default.t("admin.watched_words.invalid_regex", {
            word
          });
        }
      }
    }
    static #_7 = (() => dt7948.n(this.prototype, "regexpError", [(0, _decorators.default)("currentAction.words.[]")]))();
    actionDescription(actionNameKey) {
      return _discourseI18n.default.t("admin.watched_words.action_descriptions." + actionNameKey);
    }
    static #_8 = (() => dt7948.n(this.prototype, "actionDescription", [(0, _decorators.default)("actionNameKey")]))();
    recordAdded(arg) {
      const foundAction = this.findAction(this.actionNameKey);
      if (!foundAction) {
        return;
      }
      foundAction.words.unshiftObject(arg);
      (0, _runloop.schedule)("afterRender", () => {
        // remove from other actions lists
        let match = null;
        this.adminWatchedWords.model.forEach(otherAction => {
          if (match) {
            return;
          }
          if (otherAction.nameKey !== this.actionNameKey) {
            match = otherAction.words.findBy("id", arg.id);
            if (match) {
              otherAction.words.removeObject(match);
            }
          }
        });
      });
    }
    static #_9 = (() => dt7948.n(this.prototype, "recordAdded", [_object.action]))();
    recordRemoved(arg) {
      if (this.currentAction) {
        this.currentAction.words.removeObject(arg);
      }
    }
    static #_10 = (() => dt7948.n(this.prototype, "recordRemoved", [_object.action]))();
    uploadComplete() {
      _watchedWord.default.findAll().then(data => {
        this.adminWatchedWords.set("model", data);
      });
    }
    static #_11 = (() => dt7948.n(this.prototype, "uploadComplete", [_object.action]))();
    test() {
      _watchedWord.default.findAll().then(data => {
        this.adminWatchedWords.set("model", data);
        this.modal.show(_watchedWordTest.default, {
          model: {
            watchedWord: this.currentAction
          }
        });
      });
    }
    static #_12 = (() => dt7948.n(this.prototype, "test", [_object.action]))();
    clearAll() {
      const actionKey = this.actionNameKey;
      this.dialog.yesNoConfirm({
        message: _discourseI18n.default.t("admin.watched_words.clear_all_confirm", {
          action: _discourseI18n.default.t("admin.watched_words.actions." + actionKey)
        }),
        didConfirm: () => {
          (0, _ajax.ajax)(`/admin/customize/watched_words/action/${actionKey}.json`, {
            type: "DELETE"
          }).then(() => {
            const foundAction = this.findAction(actionKey);
            if (foundAction) {
              foundAction.set("words", []);
            }
          });
        }
      });
    }
    static #_13 = (() => dt7948.n(this.prototype, "clearAll", [_object.action]))();
  }
  _exports.default = AdminWatchedWordsActionController;
});