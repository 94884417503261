define("admin/models/site-setting", ["exports", "@ember/object", "discourse/lib/ajax", "discourse-common/utils/decorators", "discourse-i18n", "admin/mixins/setting-object"], function (_exports, _object, _ajax, _decorators, _discourseI18n, _settingObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SiteSetting extends _object.default.extend(_settingObject.default) {
    static findAll() {
      let params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return (0, _ajax.ajax)("/admin/site_settings", {
        data: params
      }).then(function (settings) {
        // Group the results by category
        const categories = {};
        settings.site_settings.forEach(function (s) {
          if (!categories[s.category]) {
            categories[s.category] = [];
          }
          categories[s.category].pushObject(SiteSetting.create(s));
        });
        return Object.keys(categories).map(function (n) {
          return {
            nameKey: n,
            name: _discourseI18n.default.t("admin.site_settings.categories." + n),
            siteSettings: categories[n]
          };
        });
      });
    }
    static update(key, value) {
      let opts = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      const data = {};
      data[key] = value;
      if (opts["updateExistingUsers"] === true) {
        data["update_existing_user"] = true;
      }
      return (0, _ajax.ajax)(`/admin/site_settings/${key}`, {
        type: "PUT",
        data
      });
    }
    staffLogFilter(setting) {
      if (!setting) {
        return;
      }
      return {
        subject: setting,
        action_name: "change_site_setting"
      };
    }
    static #_ = (() => dt7948.n(this.prototype, "staffLogFilter", [(0, _decorators.default)("setting")]))();
  }
  _exports.default = SiteSetting;
});