define("admin/templates/email", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <AdminNav>
    <NavItem @route="adminEmail.index" @label="admin.email.settings" />
    <NavItem
      @route="adminEmail.previewDigest"
      @label="admin.email.preview_digest"
    />
    <NavItem
      @route="adminEmail.advancedTest"
      @label="admin.email.advanced_test.title"
    />
    <NavItem
      @route="adminCustomizeEmailTemplates"
      @label="admin.email.templates"
    />
    <NavItem @route="adminEmail.sent" @label="admin.email.sent" />
    <NavItem @route="adminEmail.skipped" @label="admin.email.skipped" />
    <NavItem @route="adminEmail.bounced" @label="admin.email.bounced" />
    <NavItem @route="adminEmail.received" @label="admin.email.received" />
    <NavItem @route="adminEmail.rejected" @label="admin.email.rejected" />
  </AdminNav>
  
  <div class="admin-container">
    {{outlet}}
  </div>
  */
  {
    "id": "t9cOARrI",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,[[\"@route\",\"@label\"],[\"adminEmail.index\",\"admin.email.settings\"]],null],[1,\"\\n  \"],[8,[39,1],null,[[\"@route\",\"@label\"],[\"adminEmail.previewDigest\",\"admin.email.preview_digest\"]],null],[1,\"\\n  \"],[8,[39,1],null,[[\"@route\",\"@label\"],[\"adminEmail.advancedTest\",\"admin.email.advanced_test.title\"]],null],[1,\"\\n  \"],[8,[39,1],null,[[\"@route\",\"@label\"],[\"adminCustomizeEmailTemplates\",\"admin.email.templates\"]],null],[1,\"\\n  \"],[8,[39,1],null,[[\"@route\",\"@label\"],[\"adminEmail.sent\",\"admin.email.sent\"]],null],[1,\"\\n  \"],[8,[39,1],null,[[\"@route\",\"@label\"],[\"adminEmail.skipped\",\"admin.email.skipped\"]],null],[1,\"\\n  \"],[8,[39,1],null,[[\"@route\",\"@label\"],[\"adminEmail.bounced\",\"admin.email.bounced\"]],null],[1,\"\\n  \"],[8,[39,1],null,[[\"@route\",\"@label\"],[\"adminEmail.received\",\"admin.email.received\"]],null],[1,\"\\n  \"],[8,[39,1],null,[[\"@route\",\"@label\"],[\"adminEmail.rejected\",\"admin.email.rejected\"]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[10,0],[14,0,\"admin-container\"],[12],[1,\"\\n  \"],[46,[28,[37,3],null,null],null,null,null],[1,\"\\n\"],[13]],[],false,[\"admin-nav\",\"nav-item\",\"component\",\"-outlet\"]]",
    "moduleName": "admin/templates/email.hbs",
    "isStrictMode": false
  });
});