define("admin/components/admin-report-table-header", ["exports", "@ember/component", "@ember-decorators/component", "discourse-common/utils/decorators", "@ember/template-factory"], function (_exports, _component, _component2, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.showSortingUI}}
    <DButton
      @action={{this.sortByLabel}}
      @icon={{this.sortIcon}}
      class="sort-btn"
    />
  {{/if}}
  
  {{#if this.label.htmlTitle}}
    <span class="title">{{html-safe this.label.htmlTitle}}</span>
  {{else}}
    <span class="title">{{this.label.title}}</span>
  {{/if}}
  */
  {
    "id": "iqd/ZDkK",
    "block": "[[[41,[30,0,[\"showSortingUI\"]],[[[1,\"  \"],[8,[39,1],[[24,0,\"sort-btn\"]],[[\"@action\",\"@icon\"],[[30,0,[\"sortByLabel\"]],[30,0,[\"sortIcon\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"label\",\"htmlTitle\"]],[[[1,\"  \"],[10,1],[14,0,\"title\"],[12],[1,[28,[35,2],[[30,0,[\"label\",\"htmlTitle\"]]],null]],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,1],[14,0,\"title\"],[12],[1,[30,0,[\"label\",\"title\"]]],[13],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"d-button\",\"html-safe\"]]",
    "moduleName": "admin/components/admin-report-table-header.hbs",
    "isStrictMode": false
  });
  const AdminReportTableHeader = dt7948.c(class AdminReportTableHeader extends _component.default {
    isCurrentSort(currentSortField, labelSortField) {
      return currentSortField === labelSortField;
    }
    static #_ = (() => dt7948.n(this.prototype, "isCurrentSort", [(0, _decorators.default)("currentSortLabel.sortProperty", "label.sortProperty")]))();
    sortIcon(currentSortDirection) {
      return currentSortDirection === 1 ? "caret-up" : "caret-down";
    }
    static #_2 = (() => dt7948.n(this.prototype, "sortIcon", [(0, _decorators.default)("currentSortDirection")]))();
  }, [(0, _component2.attributeBindings)("label.title:title"), (0, _component2.classNameBindings)("label.mainProperty", "label.type", "isCurrentSort"), (0, _component2.classNames)("admin-report-table-header"), (0, _component2.tagName)("th")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AdminReportTableHeader);
});