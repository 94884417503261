define("admin/routes/admin-email-logs", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminEmailLogsRoute extends _discourse.default {
    setupController(controller) {
      controller.setProperties({
        loading: true,
        filter: {
          status: this.status
        }
      });
    }
  }
  _exports.default = AdminEmailLogsRoute;
});