define("admin/models/report", ["exports", "@ember/object", "@ember/utils", "discourse/helpers/user-avatar", "discourse/lib/ajax", "discourse/lib/formatter", "discourse/lib/round", "discourse/lib/utilities", "discourse-common/lib/get-url", "discourse-common/lib/helpers", "discourse-common/utils/decorators", "discourse-i18n"], function (_exports, _object, _utils, _userAvatar, _ajax, _formatter, _round, _utilities, _getUrl, _helpers, _decorators, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.WEEKLY_LIMIT_DAYS = _exports.SCHEMA_VERSION = _exports.DAILY_LIMIT_DAYS = void 0;
  // Change this line each time report format change
  // and you want to ensure cache is reset
  const SCHEMA_VERSION = _exports.SCHEMA_VERSION = 4;
  class Report extends _object.default {
    static groupingForDatapoints(count) {
      if (count < DAILY_LIMIT_DAYS) {
        return "daily";
      }
      if (count >= DAILY_LIMIT_DAYS && count < WEEKLY_LIMIT_DAYS) {
        return "weekly";
      }
      if (count >= WEEKLY_LIMIT_DAYS) {
        return "monthly";
      }
    }
    static unitForDatapoints(count) {
      if (count >= DAILY_LIMIT_DAYS && count < WEEKLY_LIMIT_DAYS) {
        return "week";
      } else if (count >= WEEKLY_LIMIT_DAYS) {
        return "month";
      } else {
        return "day";
      }
    }
    static unitForGrouping(grouping) {
      switch (grouping) {
        case "monthly":
          return "month";
        case "weekly":
          return "week";
        default:
          return "day";
      }
    }
    static collapse(model, data, grouping) {
      grouping = grouping || Report.groupingForDatapoints(data.length);
      if (grouping === "daily") {
        return data;
      } else if (grouping === "weekly" || grouping === "monthly") {
        const isoKind = grouping === "weekly" ? "isoWeek" : "month";
        const kind = grouping === "weekly" ? "week" : "month";
        const startMoment = moment(model.start_date, "YYYY-MM-DD");
        let currentIndex = 0;
        let currentStart = startMoment.clone().startOf(isoKind);
        let currentEnd = startMoment.clone().endOf(isoKind);
        const transformedData = [{
          x: currentStart.format("YYYY-MM-DD"),
          y: 0
        }];
        let appliedAverage = false;
        data.forEach(d => {
          const date = moment(d.x, "YYYY-MM-DD");
          if (!date.isSame(currentStart) && !date.isBetween(currentStart, currentEnd)) {
            if (model.average) {
              transformedData[currentIndex].y = applyAverage(transformedData[currentIndex].y, currentStart, currentEnd);
              appliedAverage = true;
            }
            currentIndex += 1;
            currentStart = currentStart.add(1, kind).startOf(isoKind);
            currentEnd = currentEnd.add(1, kind).endOf(isoKind);
          } else {
            appliedAverage = false;
          }
          if (transformedData[currentIndex]) {
            transformedData[currentIndex].y += d.y;
          } else {
            transformedData[currentIndex] = {
              x: d.x,
              y: d.y
            };
          }
        });
        if (model.average && !appliedAverage) {
          transformedData[currentIndex].y = applyAverage(transformedData[currentIndex].y, currentStart, moment(model.end_date).subtract(1, "day") // remove 1 day as model end date is at 00:00 of next day
          );
        }
        return transformedData;
      }

      // ensure we return something if grouping is unknown
      return data;
    }
    static fillMissingDates(report) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const dataField = options.dataField || "data";
      const filledField = options.filledField || "data";
      const startDate = options.startDate || "start_date";
      const endDate = options.endDate || "end_date";
      if (Array.isArray(report[dataField])) {
        const startDateFormatted = moment.utc(report[startDate]).locale("en").format("YYYY-MM-DD");
        const endDateFormatted = moment.utc(report[endDate]).locale("en").format("YYYY-MM-DD");
        if (report.modes[0] === "stacked_chart" || report.modes[0] === "stacked_line_chart") {
          report[filledField] = report[dataField].map(rep => {
            return {
              req: rep.req,
              label: rep.label,
              color: rep.color,
              data: (0, _utilities.fillMissingDates)(JSON.parse(JSON.stringify(rep.data)), startDateFormatted, endDateFormatted)
            };
          });
        } else if (report.modes[0] !== "radar") {
          report[filledField] = (0, _utilities.fillMissingDates)(JSON.parse(JSON.stringify(report[dataField])), startDateFormatted, endDateFormatted);
        }
      }
    }
    static find(type, startDate, endDate, categoryId, groupId) {
      return (0, _ajax.ajax)("/admin/reports/" + type, {
        data: {
          start_date: startDate,
          end_date: endDate,
          category_id: categoryId,
          group_id: groupId
        }
      }).then(json => {
        // don’t fill for large multi column tables
        // which are not date based
        const modes = json.report.modes;
        if (modes.length !== 1 && modes[0] !== "table") {
          Report.fillMissingDates(json.report);
        }
        const model = Report.create({
          type
        });
        model.setProperties(json.report);
        if (json.report.related_report) {
          // TODO: fillMissingDates if xaxis is date
          const related = Report.create({
            type: json.report.related_report.type
          });
          related.setProperties(json.report.related_report);
          model.set("relatedReport", related);
        }
        return model;
      });
    }
    average = false;
    percent = false;
    higher_is_better = true;
    description_link = null;
    description = null;
    reportUrl(type, start_date, end_date) {
      start_date = moment.utc(start_date).locale("en").format("YYYY-MM-DD");
      end_date = moment.utc(end_date).locale("en").format("YYYY-MM-DD");
      return (0, _getUrl.default)(`/admin/reports/${type}?start_date=${start_date}&end_date=${end_date}`);
    }
    static #_ = (() => dt7948.n(this.prototype, "reportUrl", [(0, _decorators.default)("type", "start_date", "end_date")]))();
    valueAt(numDaysAgo) {
      if (this.data) {
        const wantedDate = moment().subtract(numDaysAgo, "days").locale("en").format("YYYY-MM-DD");
        const item = this.data.find(d => d.x === wantedDate);
        if (item) {
          return item.y;
        }
      }
      return 0;
    }
    valueFor(startDaysAgo, endDaysAgo) {
      if (this.data) {
        const earliestDate = moment().subtract(endDaysAgo, "days").startOf("day");
        const latestDate = moment().subtract(startDaysAgo, "days").startOf("day");
        let d,
          sum = 0,
          count = 0;
        this.data.forEach(datum => {
          d = moment(datum.x);
          if (d >= earliestDate && d <= latestDate) {
            sum += datum.y;
            count++;
          }
        });
        if (this.method === "average" && count > 0) {
          sum /= count;
        }
        return (0, _round.default)(sum, -2);
      }
    }
    todayCount() {
      return this.valueAt(0);
    }
    static #_2 = (() => dt7948.n(this.prototype, "todayCount", [(0, _decorators.default)("data", "average")]))();
    yesterdayCount() {
      return this.valueAt(1);
    }
    static #_3 = (() => dt7948.n(this.prototype, "yesterdayCount", [(0, _decorators.default)("data", "average")]))();
    sevenDaysAgoCount() {
      return this.valueAt(7);
    }
    static #_4 = (() => dt7948.n(this.prototype, "sevenDaysAgoCount", [(0, _decorators.default)("data", "average")]))();
    thirtyDaysAgoCount() {
      return this.valueAt(30);
    }
    static #_5 = (() => dt7948.n(this.prototype, "thirtyDaysAgoCount", [(0, _decorators.default)("data", "average")]))();
    lastSevenDaysCount() {
      return this.averageCount(7, this.valueFor(1, 7));
    }
    static #_6 = (() => dt7948.n(this.prototype, "lastSevenDaysCount", [(0, _decorators.default)("data", "average")]))();
    lastThirtyDaysCount() {
      return this.averageCount(30, this.valueFor(1, 30));
    }
    static #_7 = (() => dt7948.n(this.prototype, "lastThirtyDaysCount", [(0, _decorators.default)("data", "average")]))();
    averageCount(count, value) {
      return this.average ? value / count : value;
    }
    yesterdayTrend(yesterdayCount, higherIsBetter) {
      return this._computeTrend(this.valueAt(2), yesterdayCount, higherIsBetter);
    }
    static #_8 = (() => dt7948.n(this.prototype, "yesterdayTrend", [(0, _decorators.default)("yesterdayCount", "higher_is_better")]))();
    sevenDaysTrend(lastSevenDaysCount, higherIsBetter) {
      return this._computeTrend(this.valueFor(8, 14), lastSevenDaysCount, higherIsBetter);
    }
    static #_9 = (() => dt7948.n(this.prototype, "sevenDaysTrend", [(0, _decorators.default)("lastSevenDaysCount", "higher_is_better")]))();
    currentTotal(data) {
      return data.reduce((cur, pair) => cur + pair.y, 0);
    }
    static #_10 = (() => dt7948.n(this.prototype, "currentTotal", [(0, _decorators.default)("data")]))();
    currentAverage(data, total) {
      return (0, _helpers.makeArray)(data).length === 0 ? 0 : parseFloat((total / parseFloat(data.length)).toFixed(1));
    }
    static #_11 = (() => dt7948.n(this.prototype, "currentAverage", [(0, _decorators.default)("data", "currentTotal")]))();
    trendIcon(trend, higherIsBetter) {
      return this._iconForTrend(trend, higherIsBetter);
    }
    static #_12 = (() => dt7948.n(this.prototype, "trendIcon", [(0, _decorators.default)("trend", "higher_is_better")]))();
    sevenDaysTrendIcon(sevenDaysTrend, higherIsBetter) {
      return this._iconForTrend(sevenDaysTrend, higherIsBetter);
    }
    static #_13 = (() => dt7948.n(this.prototype, "sevenDaysTrendIcon", [(0, _decorators.default)("sevenDaysTrend", "higher_is_better")]))();
    thirtyDaysTrendIcon(thirtyDaysTrend, higherIsBetter) {
      return this._iconForTrend(thirtyDaysTrend, higherIsBetter);
    }
    static #_14 = (() => dt7948.n(this.prototype, "thirtyDaysTrendIcon", [(0, _decorators.default)("thirtyDaysTrend", "higher_is_better")]))();
    yesterdayTrendIcon(yesterdayTrend, higherIsBetter) {
      return this._iconForTrend(yesterdayTrend, higherIsBetter);
    }
    static #_15 = (() => dt7948.n(this.prototype, "yesterdayTrendIcon", [(0, _decorators.default)("yesterdayTrend", "higher_is_better")]))();
    trend(prev, currentTotal, currentAverage, higherIsBetter) {
      const total = this.average ? currentAverage : currentTotal;
      return this._computeTrend(prev, total, higherIsBetter);
    }
    static #_16 = (() => dt7948.n(this.prototype, "trend", [(0, _decorators.default)("prev_period", "currentTotal", "currentAverage", "higher_is_better")]))();
    thirtyDaysTrend(prev30Days, prev_period, lastThirtyDaysCount, higherIsBetter) {
      return this._computeTrend(prev30Days ?? prev_period, lastThirtyDaysCount, higherIsBetter);
    }
    static #_17 = (() => dt7948.n(this.prototype, "thirtyDaysTrend", [(0, _decorators.default)("prev30Days", "prev_period", "lastThirtyDaysCount", "higher_is_better")]))();
    method(type) {
      if (type === "time_to_first_response") {
        return "average";
      } else {
        return "sum";
      }
    }
    static #_18 = (() => dt7948.n(this.prototype, "method", [(0, _decorators.default)("type")]))();
    percentChangeString(val1, val2) {
      const change = this._computeChange(val1, val2);
      if (isNaN(change) || !isFinite(change)) {
        return null;
      } else if (change > 0) {
        return "+" + change.toFixed(0) + "%";
      } else {
        return change.toFixed(0) + "%";
      }
    }
    trendTitle(prev, currentTotal, currentAverage) {
      let current = this.average ? currentAverage : currentTotal;
      let percent = this.percentChangeString(prev, current);
      if (this.average) {
        prev = prev ? prev.toFixed(1) : "0";
        if (this.percent) {
          current += "%";
          prev += "%";
        }
      } else {
        prev = (0, _formatter.number)(prev);
        current = (0, _formatter.number)(current);
      }
      return _discourseI18n.default.t("admin.dashboard.reports.trend_title", {
        percent,
        prev,
        current
      });
    }
    static #_19 = (() => dt7948.n(this.prototype, "trendTitle", [(0, _decorators.default)("prev_period", "currentTotal", "currentAverage")]))();
    changeTitle(valAtT1, valAtT2, prevPeriodString) {
      const change = this.percentChangeString(valAtT1, valAtT2);
      let title = "";
      if (change) {
        title += `${change} change. `;
      }
      title += `Was ${(0, _formatter.number)(valAtT1)} ${prevPeriodString}.`;
      return title;
    }
    yesterdayCountTitle(yesterdayCount) {
      return this.changeTitle(this.valueAt(2), yesterdayCount, "two days ago");
    }
    static #_20 = (() => dt7948.n(this.prototype, "yesterdayCountTitle", [(0, _decorators.default)("yesterdayCount")]))();
    sevenDaysCountTitle(lastSevenDaysCount) {
      return this.changeTitle(this.valueFor(8, 14), lastSevenDaysCount, "two weeks ago");
    }
    static #_21 = (() => dt7948.n(this.prototype, "sevenDaysCountTitle", [(0, _decorators.default)("lastSevenDaysCount")]))();
    canDisplayTrendIcon(prev30Days, prev_period) {
      return prev30Days ?? prev_period;
    }
    static #_22 = (() => dt7948.n(this.prototype, "canDisplayTrendIcon", [(0, _decorators.default)("prev30Days", "prev_period")]))();
    thirtyDaysCountTitle(prev30Days, prev_period, lastThirtyDaysCount) {
      return this.changeTitle(prev30Days ?? prev_period, lastThirtyDaysCount, "in the previous 30 day period");
    }
    static #_23 = (() => dt7948.n(this.prototype, "thirtyDaysCountTitle", [(0, _decorators.default)("prev30Days", "prev_period", "lastThirtyDaysCount")]))();
    sortedData(data) {
      return this.xAxisIsDate ? data.toArray().reverse() : data.toArray();
    }
    static #_24 = (() => dt7948.n(this.prototype, "sortedData", [(0, _decorators.default)("data")]))();
    xAxisIsDate() {
      if (!this.data[0]) {
        return false;
      }
      return this.data && this.data[0].x.match(/\d{4}-\d{1,2}-\d{1,2}/);
    }
    static #_25 = (() => dt7948.n(this.prototype, "xAxisIsDate", [(0, _decorators.default)("data")]))();
    computedLabels(labels) {
      var _this = this;
      return labels.map(label => {
        const type = label.type || "string";
        let mainProperty;
        if (label.property) {
          mainProperty = label.property;
        } else if (type === "user") {
          mainProperty = label.properties["username"];
        } else if (type === "topic") {
          mainProperty = label.properties["title"];
        } else if (type === "post") {
          mainProperty = label.properties["truncated_raw"];
        } else {
          mainProperty = label.properties[0];
        }
        return {
          title: label.title,
          htmlTitle: label.html_title,
          sortProperty: label.sort_property || mainProperty,
          mainProperty,
          type,
          compute: function (row) {
            let opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
            let value = null;
            if (opts.useSortProperty) {
              value = row[label.sort_property || mainProperty];
            } else {
              value = row[mainProperty];
            }
            if (type === "user") {
              return _this._userLabel(label.properties, row);
            }
            if (type === "post") {
              return _this._postLabel(label.properties, row);
            }
            if (type === "topic") {
              return _this._topicLabel(label.properties, row);
            }
            if (type === "seconds") {
              return _this._secondsLabel(value);
            }
            if (type === "link") {
              return _this._linkLabel(label.properties, row);
            }
            if (type === "percent") {
              return _this._percentLabel(value);
            }
            if (type === "bytes") {
              return _this._bytesLabel(value);
            }
            if (type === "number") {
              return _this._numberLabel(value, opts);
            }
            if (type === "date") {
              const date = moment(value);
              if (date.isValid()) {
                return _this._dateLabel(value, date);
              }
            }
            if (type === "precise_date") {
              const date = moment(value);
              if (date.isValid()) {
                return _this._dateLabel(value, date, "LLL");
              }
            }
            if (type === "text") {
              return _this._textLabel(value);
            }
            return {
              value,
              type,
              property: mainProperty,
              formattedValue: value ? (0, _utilities.escapeExpression)(value) : "—"
            };
          }
        };
      });
    }
    static #_26 = (() => dt7948.n(this.prototype, "computedLabels", [(0, _decorators.default)("labels")]))();
    _userLabel(properties, row) {
      const username = row[properties.username];
      const formattedValue = () => {
        const userId = row[properties.id];
        const user = _object.default.create({
          username,
          name: (0, _utilities.formatUsername)(username),
          avatar_template: row[properties.avatar]
        });
        const href = (0, _getUrl.default)(`/admin/users/${userId}/${username}`);
        const avatarImg = (0, _userAvatar.renderAvatar)(user, {
          imageSize: "tiny",
          ignoreTitle: true,
          siteSettings: this.siteSettings
        });
        return `<a href='${href}'>${avatarImg}<span class='username'>${user.name}</span></a>`;
      };
      return {
        value: username,
        formattedValue: username ? formattedValue() : "—"
      };
    }
    _topicLabel(properties, row) {
      const topicTitle = row[properties.title];
      const formattedValue = () => {
        const topicId = row[properties.id];
        const href = (0, _getUrl.default)(`/t/-/${topicId}`);
        return `<a href='${href}'>${(0, _utilities.escapeExpression)(topicTitle)}</a>`;
      };
      return {
        value: topicTitle,
        formattedValue: topicTitle ? formattedValue() : "—"
      };
    }
    _postLabel(properties, row) {
      const postTitle = row[properties.truncated_raw];
      const postNumber = row[properties.number];
      const topicId = row[properties.topic_id];
      const href = (0, _getUrl.default)(`/t/-/${topicId}/${postNumber}`);
      return {
        property: properties.title,
        value: postTitle,
        formattedValue: postTitle && href ? `<a href='${href}'>${(0, _utilities.escapeExpression)(postTitle)}</a>` : "—"
      };
    }
    _secondsLabel(value) {
      return {
        value: (0, _utilities.toNumber)(value),
        formattedValue: (0, _formatter.durationTiny)(value)
      };
    }
    _percentLabel(value) {
      return {
        value: (0, _utilities.toNumber)(value),
        formattedValue: value ? `${value}%` : "—"
      };
    }
    _numberLabel(value) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const formatNumbers = (0, _utils.isEmpty)(options.formatNumbers) ? true : options.formatNumbers;
      const formattedValue = () => formatNumbers ? (0, _formatter.number)(value) : value;
      return {
        value: (0, _utilities.toNumber)(value),
        formattedValue: value ? formattedValue() : "—"
      };
    }
    _bytesLabel(value) {
      return {
        value: (0, _utilities.toNumber)(value),
        formattedValue: _discourseI18n.default.toHumanSize(value)
      };
    }
    _dateLabel(value, date) {
      let format = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "LL";
      return {
        value,
        formattedValue: value ? date.format(format) : "—"
      };
    }
    _textLabel(value) {
      const escaped = (0, _utilities.escapeExpression)(value);
      return {
        value,
        formattedValue: value ? escaped : "—"
      };
    }
    _linkLabel(properties, row) {
      const property = properties[0];
      const value = (0, _getUrl.default)(row[property]);
      const formattedValue = (href, anchor) => {
        return `<a href="${(0, _utilities.escapeExpression)(href)}">${(0, _utilities.escapeExpression)(anchor)}</a>`;
      };
      return {
        value,
        formattedValue: value ? formattedValue(value, row[properties[1]]) : "—"
      };
    }
    _computeChange(valAtT1, valAtT2) {
      return (valAtT2 - valAtT1) / valAtT1 * 100;
    }
    _computeTrend(valAtT1, valAtT2, higherIsBetter) {
      const change = this._computeChange(valAtT1, valAtT2);
      if (change > 50) {
        return higherIsBetter ? "high-trending-up" : "high-trending-down";
      } else if (change > 2) {
        return higherIsBetter ? "trending-up" : "trending-down";
      } else if (change <= 2 && change >= -2) {
        return "no-change";
      } else if (change < -50) {
        return higherIsBetter ? "high-trending-down" : "high-trending-up";
      } else if (change < -2) {
        return higherIsBetter ? "trending-down" : "trending-up";
      }
    }
    _iconForTrend(trend, higherIsBetter) {
      switch (trend) {
        case "trending-up":
          return higherIsBetter ? "angle-up" : "angle-down";
        case "trending-down":
          return higherIsBetter ? "angle-down" : "angle-up";
        case "high-trending-up":
          return higherIsBetter ? "angles-up" : "angles-down";
        case "high-trending-down":
          return higherIsBetter ? "angles-down" : "angles-up";
        default:
          return "minus";
      }
    }
  }
  _exports.default = Report;
  const WEEKLY_LIMIT_DAYS = _exports.WEEKLY_LIMIT_DAYS = 365;
  const DAILY_LIMIT_DAYS = _exports.DAILY_LIMIT_DAYS = 34;
  function applyAverage(value, start, end) {
    const count = end.diff(start, "day") + 1; // 1 to include start
    return parseFloat((value / count).toFixed(2));
  }
});