define("admin/routes/admin-user-index", ["exports", "discourse/models/group", "discourse/routes/discourse"], function (_exports, _group, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminUserIndexRoute extends _discourse.default {
    model() {
      return this.modelFor("adminUser");
    }
    afterModel(model) {
      if (this.currentUser.admin) {
        return _group.default.findAll().then(groups => {
          this._availableGroups = groups.filterBy("automatic", false);
          return model;
        });
      }
    }
    setupController(controller, model) {
      controller.setProperties({
        originalPrimaryGroupId: model.primary_group_id,
        availableGroups: this._availableGroups,
        customGroupIdsBuffer: model.customGroups.mapBy("id"),
        ssoExternalEmail: null,
        ssoLastPayload: null,
        model
      });
    }
  }
  _exports.default = AdminUserIndexRoute;
});