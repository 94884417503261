define("admin/adapters/embedding", ["exports", "discourse/adapters/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Embedding extends _rest.default {
    pathFor() {
      return "/admin/customize/embedding";
    }
  }
  _exports.default = Embedding;
});