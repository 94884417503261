define("admin/components/site-settings/enum", ["exports", "@ember/component", "@ember/template-factory"], function (_exports, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <ComboBox
    @content={{this.setting.validValues}}
    @value={{this.value}}
    @onChange={{fn (mut this.value)}}
    @valueProperty={{this.setting.computedValueProperty}}
    @nameProperty={{this.setting.computedNameProperty}}
    @options={{hash castInteger=true allowAny=this.setting.allowsNone}}
  />
  
  {{this.preview}}
  */
  {
    "id": "QZ7YxLyf",
    "block": "[[[8,[39,0],null,[[\"@content\",\"@value\",\"@onChange\",\"@valueProperty\",\"@nameProperty\",\"@options\"],[[30,0,[\"setting\",\"validValues\"]],[30,0,[\"value\"]],[28,[37,1],[[28,[37,2],[[30,0,[\"value\"]]],null]],null],[30,0,[\"setting\",\"computedValueProperty\"]],[30,0,[\"setting\",\"computedNameProperty\"]],[28,[37,3],null,[[\"castInteger\",\"allowAny\"],[true,[30,0,[\"setting\",\"allowsNone\"]]]]]]],null],[1,\"\\n\\n\"],[1,[30,0,[\"preview\"]]]],[],false,[\"combo-box\",\"fn\",\"mut\",\"hash\"]]",
    "moduleName": "admin/components/site-settings/enum.hbs",
    "isStrictMode": false
  });
  class Enum extends _component.default {}
  _exports.default = Enum;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Enum);
});