define("admin/templates/site-settings", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <AdminSiteSettingsFilterControls
    @initialFilter={{this.filter}}
    @onChangeFilter={{this.filterChanged}}
    @showMenu={{true}}
    @onToggleMenu={{this.toggleMenu}}
  />
  
  <div class="admin-nav admin-site-settings-category-nav pull-left">
    <ul class="nav nav-stacked">
      {{#each this.visibleSiteSettings as |category|}}
        <li
          class={{concat-class
            "admin-site-settings-category-nav__item"
            category.nameKey
          }}
        >
          <LinkTo
            @route="adminSiteSettingsCategory"
            @model={{category.nameKey}}
            class={{category.nameKey}}
            title={{category.name}}
          >
            {{category.name}}
            {{#if category.count}}
              <span class="count">({{category.count}})</span>
            {{/if}}
          </LinkTo>
        </li>
      {{/each}}
    </ul>
  </div>
  
  <div class="admin-detail pull-left mobile-closed">
    {{outlet}}
  </div>
  
  <div class="clearfix"></div>
  */
  {
    "id": "wXGD1Kyw",
    "block": "[[[8,[39,0],null,[[\"@initialFilter\",\"@onChangeFilter\",\"@showMenu\",\"@onToggleMenu\"],[[30,0,[\"filter\"]],[30,0,[\"filterChanged\"]],true,[30,0,[\"toggleMenu\"]]]],null],[1,\"\\n\\n\"],[10,0],[14,0,\"admin-nav admin-site-settings-category-nav pull-left\"],[12],[1,\"\\n  \"],[10,\"ul\"],[14,0,\"nav nav-stacked\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"visibleSiteSettings\"]]],null]],null],null,[[[1,\"      \"],[10,\"li\"],[15,0,[28,[37,3],[\"admin-site-settings-category-nav__item\",[30,1,[\"nameKey\"]]],null]],[12],[1,\"\\n        \"],[8,[39,4],[[16,0,[30,1,[\"nameKey\"]]],[16,\"title\",[30,1,[\"name\"]]]],[[\"@route\",\"@model\"],[\"adminSiteSettingsCategory\",[30,1,[\"nameKey\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[1,[30,1,[\"name\"]]],[1,\"\\n\"],[41,[30,1,[\"count\"]],[[[1,\"            \"],[10,1],[14,0,\"count\"],[12],[1,\"(\"],[1,[30,1,[\"count\"]]],[1,\")\"],[13],[1,\"\\n\"]],[]],null],[1,\"        \"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"admin-detail pull-left mobile-closed\"],[12],[1,\"\\n  \"],[46,[28,[37,7],null,null],null,null,null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"clearfix\"],[12],[13]],[\"category\"],false,[\"admin-site-settings-filter-controls\",\"each\",\"-track-array\",\"concat-class\",\"link-to\",\"if\",\"component\",\"-outlet\"]]",
    "moduleName": "admin/templates/site-settings.hbs",
    "isStrictMode": false
  });
});