define("admin/routes/admin-customize-themes", ["exports", "@ember/object", "@ember/routing/route", "@ember/runloop", "@ember/service", "discourse-i18n", "admin/components/modal/install-theme"], function (_exports, _object, _route, _runloop, _service, _discourseI18n, _installTheme) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminCustomizeThemesRoute extends _route.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    queryParams = {
      repoUrl: null,
      repoName: null
    };
    model(params) {
      this.currentTab = params.type;
      return this.store.findAll("theme");
    }
    setupController(controller, model) {
      super.setupController(controller, model);
      if (this.currentTab) {
        controller.setProperties({
          editingTheme: false,
          currentTab: this.currentTab
        });
      }
      if (controller.repoUrl) {
        (0, _runloop.next)(() => {
          this.modal.show(_installTheme.default, {
            model: {
              uploadUrl: controller.repoUrl,
              uploadName: controller.repoName,
              selection: "directRepoInstall",
              clearParams: this.clearParams,
              ...this.installThemeOptions(model)
            }
          });
        });
      }
    }
    installThemeOptions(model) {
      return {
        selectedType: this.controller.currentTab,
        userId: model.userId,
        content: model.content,
        installedThemes: this.controller.installedThemes,
        addTheme: this.addTheme,
        updateSelectedType: this.updateSelectedType
      };
    }
    routeRefreshModel() {
      this.refresh();
    }
    static #_4 = (() => dt7948.n(this.prototype, "routeRefreshModel", [_object.action]))();
    installModal() {
      const currentTheme = this.modelFor("adminCustomizeThemes");
      if (this.currentModel?.warnUnassignedComponent) {
        this.dialog.yesNoConfirm({
          message: _discourseI18n.default.t("admin.customize.theme.unsaved_parent_themes"),
          didConfirm: () => {
            currentTheme.set("recentlyInstalled", false);
            this.modal.show(_installTheme.default, {
              model: {
                ...this.installThemeOptions(currentTheme)
              }
            });
          }
        });
      } else {
        this.modal.show(_installTheme.default, {
          model: {
            ...this.installThemeOptions(currentTheme)
          }
        });
      }
    }
    static #_5 = (() => dt7948.n(this.prototype, "installModal", [_object.action]))();
    updateSelectedType(type) {
      this.controller.set("currentTab", type);
    }
    static #_6 = (() => dt7948.n(this.prototype, "updateSelectedType", [_object.action]))();
    clearParams() {
      this.controller.setProperties({
        repoUrl: null,
        repoName: null
      });
    }
    static #_7 = (() => dt7948.n(this.prototype, "clearParams", [_object.action]))();
    addTheme(theme) {
      this.refresh();
      theme.setProperties({
        recentlyInstalled: true
      });
      this.router.transitionTo("adminCustomizeThemes.show", theme.get("id"), {
        queryParams: {
          repoName: null,
          repoUrl: null
        }
      });
    }
    static #_8 = (() => dt7948.n(this.prototype, "addTheme", [_object.action]))();
  }
  _exports.default = AdminCustomizeThemesRoute;
});