define("admin/components/webhook-event-chooser", ["exports", "@ember/component", "@glimmer/component", "discourse-i18n", "@ember/template-factory"], function (_exports, _component, _component2, _discourseI18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <label class="hook-event">
    <Input @type="checkbox" @checked={{this.enabled}} name="event-choice" />
    {{this.details}}
  </label>
  */
  {
    "id": "dArMa2cV",
    "block": "[[[10,\"label\"],[14,0,\"hook-event\"],[12],[1,\"\\n  \"],[8,[39,0],[[24,3,\"event-choice\"]],[[\"@type\",\"@checked\"],[\"checkbox\",[30,0,[\"enabled\"]]]],null],[1,\"\\n  \"],[1,[30,0,[\"details\"]]],[1,\"\\n\"],[13]],[],false,[\"input\"]]",
    "moduleName": "admin/components/webhook-event-chooser.hbs",
    "isStrictMode": false
  });
  class WebhookEventChooser extends _component2.default {
    get details() {
      return _discourseI18n.default.t(`admin.web_hooks.${this.args.group}_event.${this.args.type.name}`);
    }
    get eventTypeExists() {
      return this.args.eventTypes.any(event => event.name === this.args.type.name);
    }
    get enabled() {
      return this.eventTypeExists;
    }
    set enabled(value) {
      const eventTypes = this.args.eventTypes;

      // add an association when not exists
      if (value === this.eventTypeExists) {
        return;
      }
      if (value) {
        eventTypes.addObject(this.args.type);
      } else {
        eventTypes.removeObjects(eventTypes.filter(eventType => eventType.name === this.args.type.name));
      }
    }
  }
  _exports.default = WebhookEventChooser;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, WebhookEventChooser);
});