define("admin/adapters/build-plugin", ["exports", "discourse/adapters/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = buildPluginAdapter;
  function buildPluginAdapter(pluginName) {
    return class extends _rest.default {
      pathFor(store, type, findArgs) {
        return "/admin/plugins/" + pluginName + super.pathFor(store, type, findArgs);
      }
    };
  }
});