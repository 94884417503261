define("admin/routes/admin-plugins-show-settings", ["exports", "@ember/service", "discourse/routes/discourse", "discourse-i18n", "admin/models/site-setting"], function (_exports, _service, _discourse, _discourseI18n, _siteSetting) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsShowSettingsRoute extends _discourse.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    queryParams = {
      filter: {
        replace: true
      }
    };
    async model(params) {
      const plugin = this.modelFor("adminPlugins.show");
      return {
        plugin,
        settings: await _siteSetting.default.findAll({
          plugin: plugin.name
        }),
        initialFilter: params.filter
      };
    }
    titleToken() {
      return _discourseI18n.default.t("admin.plugins.change_settings_short");
    }
  }
  _exports.default = AdminPluginsShowSettingsRoute;
});