define("admin/controllers/admin-email-advanced-test", ["exports", "@ember/controller", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _controller, _object, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminEmailAdvancedTestController extends _controller.default {
    email = null;
    text = null;
    elided = null;
    format = null;
    loading = null;
    run() {
      this.set("loading", true);
      (0, _ajax.ajax)("/admin/email/advanced-test", {
        type: "POST",
        data: {
          email: this.email
        }
      }).then(data => {
        this.setProperties({
          text: data.text,
          elided: data.elided,
          format: data.format
        });
      }).catch(_ajaxError.popupAjaxError).finally(() => this.set("loading", false));
    }
    static #_ = (() => dt7948.n(this.prototype, "run", [_object.action]))();
  }
  _exports.default = AdminEmailAdvancedTestController;
});