define("admin/templates/plugins-show-settings", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <DBreadcrumbsItem
    @path="/admin/plugins/{{@model.plugin.name}}/settings"
    @label={{i18n "admin.plugins.change_settings_short"}}
  />
  
  <div
    class="content-body admin-plugin-config-area__settings admin-detail pull-left"
  >
    <AdminFilteredSiteSettings
      @initialFilter={{@model.initialFilter}}
      @plugin={{@model.plugin}}
      @settings={{@model.settings}}
      @onFilterChanged={{this.filterChanged}}
    />
  </div>
  */
  {
    "id": "C9mLdPcE",
    "block": "[[[8,[39,0],null,[[\"@path\",\"@label\"],[[29,[\"/admin/plugins/\",[30,1,[\"plugin\",\"name\"]],\"/settings\"]],[28,[37,1],[\"admin.plugins.change_settings_short\"],null]]],null],[1,\"\\n\\n\"],[10,0],[14,0,\"content-body admin-plugin-config-area__settings admin-detail pull-left\"],[12],[1,\"\\n  \"],[8,[39,2],null,[[\"@initialFilter\",\"@plugin\",\"@settings\",\"@onFilterChanged\"],[[30,1,[\"initialFilter\"]],[30,1,[\"plugin\"]],[30,1,[\"settings\"]],[30,0,[\"filterChanged\"]]]],null],[1,\"\\n\"],[13]],[\"@model\"],false,[\"d-breadcrumbs-item\",\"i18n\",\"admin-filtered-site-settings\"]]",
    "moduleName": "admin/templates/plugins-show-settings.hbs",
    "isStrictMode": false
  });
});