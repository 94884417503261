define("admin/components/site-settings/upload", ["exports", "@ember/component", "@ember/template-factory"], function (_exports, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <UppyImageUploader
    @imageUrl={{this.value}}
    @placeholderUrl={{this.setting.placeholder}}
    @additionalParams={{hash for_site_setting=true}}
    @type="site_setting"
    @id={{concat "site-setting-image-uploader-" this.setting.setting}}
  />
  */
  {
    "id": "HIksCk4T",
    "block": "[[[8,[39,0],null,[[\"@imageUrl\",\"@placeholderUrl\",\"@additionalParams\",\"@type\",\"@id\"],[[30,0,[\"value\"]],[30,0,[\"setting\",\"placeholder\"]],[28,[37,1],null,[[\"for_site_setting\"],[true]]],\"site_setting\",[28,[37,2],[\"site-setting-image-uploader-\",[30,0,[\"setting\",\"setting\"]]],null]]],null]],[],false,[\"uppy-image-uploader\",\"hash\",\"concat\"]]",
    "moduleName": "admin/components/site-settings/upload.hbs",
    "isStrictMode": false
  });
  class Upload extends _component.default {}
  _exports.default = Upload;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Upload);
});