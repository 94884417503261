define("admin/helpers/disposition-icon", ["exports", "@ember/component/helper", "@ember/template", "discourse-common/lib/icon-library"], function (_exports, _helper, _template, _iconLibrary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DispositionIcon extends _helper.default {
    compute(_ref) {
      let [disposition] = _ref;
      if (!disposition) {
        return null;
      }
      let icon;
      let title = "admin.flags.dispositions." + disposition;
      switch (disposition) {
        case "deferred":
          {
            icon = "up-right-from-square";
            break;
          }
        case "agreed":
          {
            icon = "thumbs-o-up";
            break;
          }
        case "disagreed":
          {
            icon = "thumbs-o-down";
            break;
          }
      }
      return (0, _template.htmlSafe)((0, _iconLibrary.iconHTML)(icon, {
        title
      }));
    }
  }
  _exports.default = DispositionIcon;
});