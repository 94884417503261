define("admin/routes/admin-search-logs-index", ["exports", "@ember/object", "discourse/lib/ajax", "discourse/routes/discourse"], function (_exports, _object, _ajax, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminSearchLogsIndexRoute extends _discourse.default {
    queryParams = {
      period: {
        refreshModel: true
      },
      searchType: {
        refreshModel: true
      }
    };
    async model(params) {
      this._params = params;
      const searchLogs = await (0, _ajax.ajax)("/admin/logs/search_logs.json", {
        data: {
          period: params.period,
          search_type: params.searchType
        }
      });
      return searchLogs.map(log => _object.default.create(log));
    }
    setupController(controller, model) {
      const params = this._params;
      controller.setProperties({
        model,
        period: params.period,
        searchType: params.searchType
      });
    }
  }
  _exports.default = AdminSearchLogsIndexRoute;
});