define("admin/routes/admin-web-hooks", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminWebHooksRoute extends _route.default {
    model() {
      return this.store.findAll("web-hook");
    }
    setupController(controller, model) {
      controller.setProperties({
        model,
        groupedEventTypes: model.extras.grouped_event_types,
        defaultEventTypes: model.extras.default_event_types,
        contentTypes: model.extras.content_types,
        deliveryStatuses: model.extras.delivery_statuses
      });
    }
  }
  _exports.default = AdminWebHooksRoute;
});