define("admin/components/site-settings/file-types-list", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/helper", "@ember/object", "@ember/service", "@ember/utils", "discourse/components/d-button", "discourse-common/helpers/i18n", "discourse-common/lib/helpers", "discourse-i18n", "select-kit/components/list-setting", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _helper, _object, _service, _utils, _dButton, _i18n, _helpers, _discourseI18n, _listSetting, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const IMAGE_TYPES = ["gif", "png", "jpeg", "jpg", "heic", "heif", "webp", "avif", "svg"];
  const VIDEO_TYPES = ["mov", "mp4", "webm", "m4v", "3gp", "ogv", "avi", "mpeg"];
  const AUDIO_TYPES = ["mp3", "ogg", "m4a", "wav", "aac", "flac"];
  const DOCUMENT_TYPES = ["txt", "pdf", "doc", "docx", "csv"];
  const TOKEN_SEPARATOR = "|";
  const IMAGE_TYPES_STRING = IMAGE_TYPES.join(", ");
  const VIDEO_TYPES_STRING = VIDEO_TYPES.join(", ");
  const AUDIO_TYPES_STRING = AUDIO_TYPES.join(", ");
  const DOCUMENT_TYPES_STRING = DOCUMENT_TYPES.join(", ");
  class FileTypesList extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "toasts", [_service.service]))();
    #toasts = (() => (dt7948.i(this, "toasts"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "createdChoices", [_tracking.tracked], function () {
      return null;
    }))();
    #createdChoices = (() => (dt7948.i(this, "createdChoices"), void 0))();
    get settingValue() {
      return this.args.value.toString().split(TOKEN_SEPARATOR).filter(Boolean);
    }
    get settingChoices() {
      return [...new Set([...(0, _helpers.makeArray)(this.settingValue), ...(0, _helpers.makeArray)(this.args.setting.choices), ...(0, _helpers.makeArray)(this.createdChoices)])];
    }
    onChangeListSetting(value1) {
      this.args.changeValueCallback(value1.join(TOKEN_SEPARATOR));
    }
    static #_3 = (() => dt7948.n(this.prototype, "onChangeListSetting", [_object.action]))();
    onChangeChoices(choices1) {
      this.createdChoices = [...new Set([...(0, _helpers.makeArray)(this.createdChoices), ...(0, _helpers.makeArray)(choices1)])];
    }
    static #_4 = (() => dt7948.n(this.prototype, "onChangeChoices", [_object.action]))();
    insertDefaultTypes(category1) {
      let types1;
      switch (category1) {
        case "image":
          types1 = IMAGE_TYPES;
          break;
        case "video":
          types1 = VIDEO_TYPES;
          break;
        case "audio":
          types1 = AUDIO_TYPES;
          break;
        case "document":
          types1 = DOCUMENT_TYPES;
          break;
      }
      const oldTypes1 = this.args.value.split(TOKEN_SEPARATOR);
      const newTypes1 = [...new Set([...oldTypes1, ...types1])];
      const diffTypes1 = newTypes1.filter(type1 => !oldTypes1.includes(type1));
      if ((0, _utils.isEmpty)(diffTypes1)) {
        return;
      }
      this.toasts.success({
        data: {
          message: _discourseI18n.default.t("admin.site_settings.file_types_list.add_types_toast", {
            types: diffTypes1.join(", ")
          })
        }
      });
      this.args.changeValueCallback(newTypes1.join(TOKEN_SEPARATOR));
    }
    static #_5 = (() => dt7948.n(this.prototype, "insertDefaultTypes", [_object.action]))();
    static #_6 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <ListSetting
          @value={{this.settingValue}}
          @settingName={{@setting.setting}}
          @choices={{this.settingChoices}}
          @onChange={{this.onChangeListSetting}}
          @onChangeChoices={{this.onChangeChoices}}
          @options={{hash allowAny=@allowAny}}
        />
    
        <DButton
          @action={{fn this.insertDefaultTypes "image"}}
          @label="admin.site_settings.file_types_list.add_image_types"
          @translatedTitle={{i18n
            "admin.site_settings.file_types_list.add_types_title"
            types=IMAGE_TYPES_STRING
          }}
          class="btn file-types-list__button image"
        />
        <DButton
          @action={{fn this.insertDefaultTypes "video"}}
          @label="admin.site_settings.file_types_list.add_video_types"
          @translatedTitle={{i18n
            "admin.site_settings.file_types_list.add_types_title"
            types=VIDEO_TYPES_STRING
          }}
          class="btn file-types-list__button video"
        />
        <DButton
          @action={{fn this.insertDefaultTypes "audio"}}
          @label="admin.site_settings.file_types_list.add_audio_types"
          @translatedTitle={{i18n
            "admin.site_settings.file_types_list.add_types_title audio"
            types=AUDIO_TYPES_STRING
          }}
          class="btn file-types-list__button"
        />
        <DButton
          @action={{fn this.insertDefaultTypes "document"}}
          @label="admin.site_settings.file_types_list.add_document_types"
          @translatedTitle={{i18n
            "admin.site_settings.file_types_list.add_types_title"
            types=DOCUMENT_TYPES_STRING
          }}
          class="btn file-types-list__button document"
        />
      
    */
    {
      "id": "guehX8Xh",
      "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@value\",\"@settingName\",\"@choices\",\"@onChange\",\"@onChangeChoices\",\"@options\"],[[30,0,[\"settingValue\"]],[30,1,[\"setting\"]],[30,0,[\"settingChoices\"]],[30,0,[\"onChangeListSetting\"]],[30,0,[\"onChangeChoices\"]],[28,[32,1],null,[[\"allowAny\"],[[30,2]]]]]],null],[1,\"\\n\\n    \"],[8,[32,2],[[24,0,\"btn file-types-list__button image\"]],[[\"@action\",\"@label\",\"@translatedTitle\"],[[28,[32,3],[[30,0,[\"insertDefaultTypes\"]],\"image\"],null],\"admin.site_settings.file_types_list.add_image_types\",[28,[32,4],[\"admin.site_settings.file_types_list.add_types_title\"],[[\"types\"],[[32,5]]]]]],null],[1,\"\\n    \"],[8,[32,2],[[24,0,\"btn file-types-list__button video\"]],[[\"@action\",\"@label\",\"@translatedTitle\"],[[28,[32,3],[[30,0,[\"insertDefaultTypes\"]],\"video\"],null],\"admin.site_settings.file_types_list.add_video_types\",[28,[32,4],[\"admin.site_settings.file_types_list.add_types_title\"],[[\"types\"],[[32,6]]]]]],null],[1,\"\\n    \"],[8,[32,2],[[24,0,\"btn file-types-list__button\"]],[[\"@action\",\"@label\",\"@translatedTitle\"],[[28,[32,3],[[30,0,[\"insertDefaultTypes\"]],\"audio\"],null],\"admin.site_settings.file_types_list.add_audio_types\",[28,[32,4],[\"admin.site_settings.file_types_list.add_types_title audio\"],[[\"types\"],[[32,7]]]]]],null],[1,\"\\n    \"],[8,[32,2],[[24,0,\"btn file-types-list__button document\"]],[[\"@action\",\"@label\",\"@translatedTitle\"],[[28,[32,3],[[30,0,[\"insertDefaultTypes\"]],\"document\"],null],\"admin.site_settings.file_types_list.add_document_types\",[28,[32,4],[\"admin.site_settings.file_types_list.add_types_title\"],[[\"types\"],[[32,8]]]]]],null],[1,\"\\n  \"]],[\"@setting\",\"@allowAny\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/admin/components/site-settings/file-types-list.js",
      "scope": () => [_listSetting.default, _helper.hash, _dButton.default, _helper.fn, _i18n.default, IMAGE_TYPES_STRING, VIDEO_TYPES_STRING, AUDIO_TYPES_STRING, DOCUMENT_TYPES_STRING],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = FileTypesList;
});