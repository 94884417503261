define("admin/components/schema-theme-setting/number-field", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/component", "@ember/modifier", "@ember/object", "truth-helpers", "discourse-i18n", "admin/components/schema-theme-setting/field-input-description", "@ember/template-factory"], function (_exports, _component, _tracking, _component2, _modifier, _object, _truthHelpers, _discourseI18n, _fieldInputDescription, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SchemaThemeSettingNumberField extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "touched", [_tracking.tracked], function () {
      return false;
    }))();
    #touched = (() => (dt7948.i(this, "touched"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "value", [_tracking.tracked], function () {
      return this.args.value;
    }))();
    #value = (() => (dt7948.i(this, "value"), void 0))();
    min = this.args.spec.validations?.min;
    max = this.args.spec.validations?.max;
    required = this.args.spec.required;
    onInput(event1) {
      this.touched = true;
      let inputValue1 = event1.currentTarget.value;
      if (isNaN(inputValue1)) {
        this.value = null;
      } else {
        this.value = this.parseValue(inputValue1);
      }
      this.args.onChange(this.value);
    }
    /**
    * @param {string} value - The value of the input field to parse into a number
    * @returns {number}
    */
    static #_3 = (() => dt7948.n(this.prototype, "onInput", [_object.action]))();
    parseFunc() {
      throw "Not implemented";
    }
    get validationErrorMessage() {
      if (!this.touched) {
        return;
      }
      if (!this.value) {
        if (this.required) {
          return _discourseI18n.default.t("admin.customize.theme.schema.fields.required");
        } else {
          return;
        }
      }
      if (this.min && this.value < this.min) {
        return _discourseI18n.default.t("admin.customize.theme.schema.fields.number.too_small", {
          count: this.min
        });
      }
      if (this.max && this.value > this.max) {
        return _discourseI18n.default.t("admin.customize.theme.schema.fields.number.too_large", {
          count: this.max
        });
      }
    }
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <Input
          @value={{this.value}}
          {{on "input" this.onInput}}
          @type="number"
          inputmode={{this.inputmode}}
          pattern={{this.pattern}}
          step={{this.step}}
          max={{this.max}}
          min={{this.min}}
          required={{this.required}}
        />
    
        <div class="schema-field__input-supporting-text">
          {{#if (and @description (not this.validationErrorMessage))}}
            <FieldInputDescription @description={{@description}} />
          {{/if}}
    
          {{#if this.validationErrorMessage}}
            <div class="schema-field__input-error">
              {{this.validationErrorMessage}}
            </div>
          {{/if}}
        </div>
      
    */
    {
      "id": "PEIGmEK5",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[16,\"inputmode\",[30,0,[\"inputmode\"]]],[16,\"pattern\",[30,0,[\"pattern\"]]],[16,\"step\",[30,0,[\"step\"]]],[16,\"max\",[30,0,[\"max\"]]],[16,\"min\",[30,0,[\"min\"]]],[16,\"required\",[30,0,[\"required\"]]],[4,[32,1],[\"input\",[30,0,[\"onInput\"]]],null]],[[\"@value\",\"@type\"],[[30,0,[\"value\"]],\"number\"]],null],[1,\"\\n\\n    \"],[10,0],[14,0,\"schema-field__input-supporting-text\"],[12],[1,\"\\n\"],[41,[28,[32,2],[[30,1],[28,[32,3],[[30,0,[\"validationErrorMessage\"]]],null]],null],[[[1,\"        \"],[8,[32,4],null,[[\"@description\"],[[30,1]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"validationErrorMessage\"]],[[[1,\"        \"],[10,0],[14,0,\"schema-field__input-error\"],[12],[1,\"\\n          \"],[1,[30,0,[\"validationErrorMessage\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@description\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/admin/components/schema-theme-setting/number-field.js",
      "scope": () => [_component2.Input, _modifier.on, _truthHelpers.and, _truthHelpers.not, _fieldInputDescription.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = SchemaThemeSettingNumberField;
});