define("admin/templates/email-index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <table class="table">
    <tbody>
      <tr>
        <th>{{i18n "admin.email.delivery_method"}}</th>
        <td>{{this.delivery_method}}</td>
      </tr>
  
      {{#each this.model.settings as |s|}}
        <tr>
          <th style="width: 25%">{{s.name}}</th>
          <td>{{s.value}}</td>
        </tr>
      {{/each}}
    </tbody>
  </table>
  
  <form>
    <div class="admin-controls">
      <div class="controls">
        <div class="inline-form">
          {{#if this.sendingEmail}}
            {{i18n "admin.email.sending_test"}}
          {{else}}
            <TextField
              @value={{this.testEmailAddress}}
              @placeholderKey="admin.email.test_email_address"
            />
            <DButton
              @action={{this.sendTestEmail}}
              @disabled={{this.sendTestEmailDisabled}}
              @label="admin.email.send_test"
              type="submit"
              class="btn-primary"
            />
            {{#if this.sentTestEmailMessage}}
              <span class="result-message">{{this.sentTestEmailMessage}}</span>
            {{/if}}
          {{/if}}
        </div>
      </div>
    </div>
  </form>
  */
  {
    "id": "qtAYrOPH",
    "block": "[[[10,\"table\"],[14,0,\"table\"],[12],[1,\"\\n  \"],[10,\"tbody\"],[12],[1,\"\\n    \"],[10,\"tr\"],[12],[1,\"\\n      \"],[10,\"th\"],[12],[1,[28,[35,0],[\"admin.email.delivery_method\"],null]],[13],[1,\"\\n      \"],[10,\"td\"],[12],[1,[30,0,[\"delivery_method\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"model\",\"settings\"]]],null]],null],null,[[[1,\"      \"],[10,\"tr\"],[12],[1,\"\\n        \"],[10,\"th\"],[14,5,\"width: 25%\"],[12],[1,[30,1,[\"name\"]]],[13],[1,\"\\n        \"],[10,\"td\"],[12],[1,[30,1,[\"value\"]]],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"form\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"admin-controls\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"inline-form\"],[12],[1,\"\\n\"],[41,[30,0,[\"sendingEmail\"]],[[[1,\"          \"],[1,[28,[35,0],[\"admin.email.sending_test\"],null]],[1,\"\\n\"]],[]],[[[1,\"          \"],[8,[39,4],null,[[\"@value\",\"@placeholderKey\"],[[30,0,[\"testEmailAddress\"]],\"admin.email.test_email_address\"]],null],[1,\"\\n          \"],[8,[39,5],[[24,0,\"btn-primary\"],[24,4,\"submit\"]],[[\"@action\",\"@disabled\",\"@label\"],[[30,0,[\"sendTestEmail\"]],[30,0,[\"sendTestEmailDisabled\"]],\"admin.email.send_test\"]],null],[1,\"\\n\"],[41,[30,0,[\"sentTestEmailMessage\"]],[[[1,\"            \"],[10,1],[14,0,\"result-message\"],[12],[1,[30,0,[\"sentTestEmailMessage\"]]],[13],[1,\"\\n\"]],[]],null]],[]]],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"s\"],false,[\"i18n\",\"each\",\"-track-array\",\"if\",\"text-field\",\"d-button\"]]",
    "moduleName": "admin/templates/email-index.hbs",
    "isStrictMode": false
  });
});