define("admin/models/backup-status", ["exports", "@ember/object", "@ember/object/computed", "discourse-common/utils/decorators"], function (_exports, _object, _computed, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class BackupStatus extends _object.default {
    static #_ = (() => dt7948.g(this.prototype, "restoreDisabled", [(0, _computed.not)("restoreEnabled")]))();
    #restoreDisabled = (() => (dt7948.i(this, "restoreDisabled"), void 0))();
    restoreEnabled(allowRestore, isOperationRunning) {
      return allowRestore && !isOperationRunning;
    }
    static #_2 = (() => dt7948.n(this.prototype, "restoreEnabled", [(0, _decorators.default)("allowRestore", "isOperationRunning")]))();
  }
  _exports.default = BackupStatus;
});