define("admin/routes/admin-site-settings-index", ["exports", "@ember/service", "discourse/routes/discourse"], function (_exports, _service, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
    Handles when you click the Site Settings tab in admin, but haven't
    chosen a category. It will redirect to the first category.
  **/

  class AdminSiteSettingsIndexRoute extends _discourse.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    beforeModel() {
      this.router.replaceWith("adminSiteSettingsCategory", this.controllerFor("adminSiteSettings").get("visibleSiteSettings")[0].nameKey);
    }
  }
  _exports.default = AdminSiteSettingsIndexRoute;
});