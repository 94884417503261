define("admin/controllers/admin-users-list-show", ["exports", "@ember/controller", "@ember/object", "@ember-decorators/object", "discourse/lib/computed", "discourse/mixins/can-check-emails", "discourse-common/config/environment", "discourse-common/lib/debounce", "discourse-common/utils/decorators", "discourse-i18n", "admin/models/admin-user"], function (_exports, _controller, _object, _object2, _computed, _canCheckEmails, _environment, _debounce, _decorators, _discourseI18n, _adminUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminUsersListShowController extends _controller.default.extend(_canCheckEmails.default) {
    model = null;
    query = null;
    order = null;
    asc = null;
    showEmails = false;
    refreshing = false;
    listFilter = null;
    selectAll = false;
    static #_ = (() => dt7948.g(this.prototype, "searchHint", [(0, _computed.i18n)("search_hint")]))();
    #searchHint = (() => (dt7948.i(this, "searchHint"), void 0))();
    _page = 1;
    _results = [];
    _canLoadMore = true;
    title(query) {
      return _discourseI18n.default.t("admin.users.titles." + query);
    }
    static #_2 = (() => dt7948.n(this.prototype, "title", [(0, _decorators.default)("query")]))();
    columnCount(showEmails) {
      let colCount = 7; // note that the first column is hardcoded in the template

      if (showEmails) {
        colCount += 1;
      }
      if (this.siteSettings.must_approve_users) {
        colCount += 1;
      }
      return colCount;
    }
    static #_3 = (() => dt7948.n(this.prototype, "columnCount", [(0, _decorators.default)("showEmails")]))();
    _filterUsers() {
      (0, _debounce.default)(this, this.resetFilters, _environment.INPUT_DELAY);
    }
    static #_4 = (() => dt7948.n(this.prototype, "_filterUsers", [(0, _object2.observes)("listFilter")]))();
    resetFilters() {
      this._page = 1;
      this._results = [];
      this._canLoadMore = true;
      this._refreshUsers();
    }
    _refreshUsers() {
      if (!this._canLoadMore) {
        return;
      }
      const page = this._page;
      this.set("refreshing", true);
      _adminUser.default.findAll(this.query, {
        filter: this.listFilter,
        show_emails: this.showEmails,
        order: this.order,
        asc: this.asc,
        page
      }).then(result => {
        this._results[page] = result;
        this.set("model", this._results.flat());
        if (result.length === 0) {
          this._canLoadMore = false;
        }
      }).finally(() => {
        this.set("refreshing", false);
      });
    }
    loadMore() {
      this._page += 1;
      this._refreshUsers();
    }
    static #_5 = (() => dt7948.n(this.prototype, "loadMore", [_object.action]))();
    toggleEmailVisibility() {
      this.toggleProperty("showEmails");
      this.resetFilters();
    }
    static #_6 = (() => dt7948.n(this.prototype, "toggleEmailVisibility", [_object.action]))();
  }
  _exports.default = AdminUsersListShowController;
});