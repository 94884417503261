define("admin/components/report-filters/group", ["exports", "@ember/component", "@ember/object", "@ember-decorators/component", "admin/components/report-filters/filter", "@ember/template-factory"], function (_exports, _component, _object, _component2, _filter, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <ComboBox
    @valueProperty="value"
    @content={{this.groupOptions}}
    @value={{this.groupId}}
    @onChange={{this.onChange}}
    @options={{hash
      allowAny=this.filter.allow_any
      autoInsertNoneItem=this.filter.auto_insert_none_item
      filterable=true
      none="admin.dashboard.reports.groups"
    }}
  />
  */
  {
    "id": "WeqY51NG",
    "block": "[[[8,[39,0],null,[[\"@valueProperty\",\"@content\",\"@value\",\"@onChange\",\"@options\"],[\"value\",[30,0,[\"groupOptions\"]],[30,0,[\"groupId\"]],[30,0,[\"onChange\"]],[28,[37,1],null,[[\"allowAny\",\"autoInsertNoneItem\",\"filterable\",\"none\"],[[30,0,[\"filter\",\"allow_any\"]],[30,0,[\"filter\",\"auto_insert_none_item\"]],true,\"admin.dashboard.reports.groups\"]]]]],null]],[],false,[\"combo-box\",\"hash\"]]",
    "moduleName": "admin/components/report-filters/group.hbs",
    "isStrictMode": false
  });
  const Group = dt7948.c(class Group extends _filter.default {
    get groupOptions() {
      return (this.site.groups || []).map(group => {
        return {
          name: group["name"],
          value: group["id"]
        };
      });
    }
    static #_ = (() => dt7948.n(this.prototype, "groupOptions", [_object.computed]))();
    get groupId() {
      return this.filter.default ? parseInt(this.filter.default, 10) : null;
    }
    static #_2 = (() => dt7948.n(this.prototype, "groupId", [(0, _object.computed)("filter.default")]))();
  }, [(0, _component2.classNames)("group-filter")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Group);
});