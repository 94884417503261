define("admin/components/admin-report-counts", ["exports", "@ember/component", "@ember/object/computed", "@ember-decorators/component", "@ember/template-factory"], function (_exports, _component, _computed, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <td class="title">
    {{#if this.report.icon}}
      {{d-icon this.report.icon}}
    {{/if}}
    <a href={{this.report.reportUrl}}>{{this.report.title}}</a>
  </td>
  
  <td class="value">{{number this.report.todayCount}}</td>
  
  <td
    class="value {{this.report.yesterdayTrend}}"
    title={{this.report.yesterdayCountTitle}}
  >
    {{number this.report.yesterdayCount}}
    {{d-icon this.report.yesterdayTrendIcon}}
  </td>
  
  <td
    class="value {{this.report.sevenDaysTrend}}"
    title={{this.report.sevenDaysCountTitle}}
  >
    {{number this.report.lastSevenDaysCount}}
    {{d-icon this.report.sevenDaysTrendIcon}}
  </td>
  
  <td
    class="value {{this.report.thirtyDaysTrend}}"
    title={{this.report.thirtyDaysCountTitle}}
  >
    {{number this.report.lastThirtyDaysCount}}
    {{d-icon this.report.thirtyDaysTrendIcon}}
  </td>
  
  {{#if this.allTime}}
    <td class="value">{{number this.report.total}}</td>
  {{/if}}
  */
  {
    "id": "odsxNh6I",
    "block": "[[[10,\"td\"],[14,0,\"title\"],[12],[1,\"\\n\"],[41,[30,0,[\"report\",\"icon\"]],[[[1,\"    \"],[1,[28,[35,1],[[30,0,[\"report\",\"icon\"]]],null]],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,3],[15,6,[30,0,[\"report\",\"reportUrl\"]]],[12],[1,[30,0,[\"report\",\"title\"]]],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"td\"],[14,0,\"value\"],[12],[1,[28,[35,2],[[30,0,[\"report\",\"todayCount\"]]],null]],[13],[1,\"\\n\\n\"],[10,\"td\"],[15,0,[29,[\"value \",[30,0,[\"report\",\"yesterdayTrend\"]]]]],[15,\"title\",[30,0,[\"report\",\"yesterdayCountTitle\"]]],[12],[1,\"\\n  \"],[1,[28,[35,2],[[30,0,[\"report\",\"yesterdayCount\"]]],null]],[1,\"\\n  \"],[1,[28,[35,1],[[30,0,[\"report\",\"yesterdayTrendIcon\"]]],null]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"td\"],[15,0,[29,[\"value \",[30,0,[\"report\",\"sevenDaysTrend\"]]]]],[15,\"title\",[30,0,[\"report\",\"sevenDaysCountTitle\"]]],[12],[1,\"\\n  \"],[1,[28,[35,2],[[30,0,[\"report\",\"lastSevenDaysCount\"]]],null]],[1,\"\\n  \"],[1,[28,[35,1],[[30,0,[\"report\",\"sevenDaysTrendIcon\"]]],null]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"td\"],[15,0,[29,[\"value \",[30,0,[\"report\",\"thirtyDaysTrend\"]]]]],[15,\"title\",[30,0,[\"report\",\"thirtyDaysCountTitle\"]]],[12],[1,\"\\n  \"],[1,[28,[35,2],[[30,0,[\"report\",\"lastThirtyDaysCount\"]]],null]],[1,\"\\n  \"],[1,[28,[35,1],[[30,0,[\"report\",\"thirtyDaysTrendIcon\"]]],null]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"allTime\"]],[[[1,\"  \"],[10,\"td\"],[14,0,\"value\"],[12],[1,[28,[35,2],[[30,0,[\"report\",\"total\"]]],null]],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"d-icon\",\"number\"]]",
    "moduleName": "admin/components/admin-report-counts.hbs",
    "isStrictMode": false
  });
  const AdminReportCounts = dt7948.c(class AdminReportCounts extends _component.default {
    allTime = true;
    static #_ = (() => dt7948.g(this.prototype, "reverseColors", [(0, _computed.match)("report.type", /^(time_to_first_response|topics_with_no_response)$/)]))();
    #reverseColors = (() => (dt7948.i(this, "reverseColors"), void 0))();
  }, [(0, _component2.classNameBindings)("reverseColors"), (0, _component2.tagName)("tr")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AdminReportCounts);
});