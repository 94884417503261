define("admin/routes/admin-plugins-show-index", ["exports", "@ember/routing/route", "@ember/service"], function (_exports, _route, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsShowIndexRoute extends _route.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "adminPluginNavManager", [_service.service]))();
    #adminPluginNavManager = (() => (dt7948.i(this, "adminPluginNavManager"), void 0))();
    model() {
      return this.modelFor("adminPlugins.show");
    }
    afterModel(model) {
      if (this.adminPluginNavManager.currentPluginDefaultRoute) {
        this.router.replaceWith(this.adminPluginNavManager.currentPluginDefaultRoute, model.id);
      }
    }
  }
  _exports.default = AdminPluginsShowIndexRoute;
});