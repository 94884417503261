define("admin/routes/admin-whats-new", ["exports", "@ember/service", "discourse/routes/discourse", "discourse-i18n"], function (_exports, _service, _discourse, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminWhatsNew extends _discourse.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    titleToken() {
      return _discourseI18n.default.t("admin.dashboard.new_features.title");
    }
    activate() {
      this.currentUser.set("has_unseen_features", false);
    }
  }
  _exports.default = AdminWhatsNew;
});