define("admin/components/theme-setting-editor", ["exports", "admin/components/site-setting"], function (_exports, _siteSetting) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class _default extends _siteSetting.default {
    _save() {
      return this.setting.updateSetting(this.model.id, this.get("buffered.value"));
    }
  }
  _exports.default = _default;
});