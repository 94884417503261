define("admin/components/admin-plugins-list", ["exports", "discourse-common/helpers/i18n", "admin/components/admin-plugins-list-item", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _i18n, _adminPluginsListItem, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const AdminPluginsList = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <table class="admin-plugins-list grid">
      <thead>
        <tr>
          <th>{{i18n "admin.plugins.name"}}</th>
          <th>{{i18n "admin.plugins.version"}}</th>
          <th>{{i18n "admin.plugins.enabled"}}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {{#each @plugins as |plugin|}}
          <AdminPluginsListItem @plugin={{plugin}} />
        {{/each}}
      </tbody>
    </table>
  
  */
  {
    "id": "PGPK0sNj",
    "block": "[[[1,\"\\n  \"],[10,\"table\"],[14,0,\"admin-plugins-list grid\"],[12],[1,\"\\n    \"],[10,\"thead\"],[12],[1,\"\\n      \"],[10,\"tr\"],[12],[1,\"\\n        \"],[10,\"th\"],[12],[1,[28,[32,0],[\"admin.plugins.name\"],null]],[13],[1,\"\\n        \"],[10,\"th\"],[12],[1,[28,[32,0],[\"admin.plugins.version\"],null]],[13],[1,\"\\n        \"],[10,\"th\"],[12],[1,[28,[32,0],[\"admin.plugins.enabled\"],null]],[13],[1,\"\\n        \"],[10,\"th\"],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,1]],null]],null],null,[[[1,\"        \"],[8,[32,1],null,[[\"@plugin\"],[[30,2]]],null],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@plugins\",\"plugin\"],false,[\"each\",\"-track-array\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/admin/components/admin-plugins-list.js",
    "scope": () => [_i18n.default, _adminPluginsListItem.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)());
  var _default = _exports.default = AdminPluginsList;
});