define("admin/components/site-settings/host-list", ["exports", "@ember/component", "@ember/object", "@ember/template-factory"], function (_exports, _component, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <ListSetting
    @value={{this.settingValue}}
    @settingName={{this.setting.setting}}
    @choices={{this.settingValue}}
    @onChange={{this.onChange}}
    @options={{hash allowAny=this.allowAny}}
  />
  */
  {
    "id": "DxFpwuZS",
    "block": "[[[8,[39,0],null,[[\"@value\",\"@settingName\",\"@choices\",\"@onChange\",\"@options\"],[[30,0,[\"settingValue\"]],[30,0,[\"setting\",\"setting\"]],[30,0,[\"settingValue\"]],[30,0,[\"onChange\"]],[28,[37,1],null,[[\"allowAny\"],[[30,0,[\"allowAny\"]]]]]]],null]],[],false,[\"list-setting\",\"hash\"]]",
    "moduleName": "admin/components/site-settings/host-list.hbs",
    "isStrictMode": false
  });
  class HostList extends _component.default {
    tokenSeparator = "|";
    choices = null;
    get settingValue() {
      return this.value.toString().split(this.tokenSeparator).filter(Boolean);
    }
    static #_ = (() => dt7948.n(this.prototype, "settingValue", [(0, _object.computed)("value")]))();
    onChange(value) {
      if (value.some(v => v.includes("?") || v.includes("*"))) {
        return;
      }
      this.set("value", value.join(this.tokenSeparator));
    }
    static #_2 = (() => dt7948.n(this.prototype, "onChange", [_object.action]))();
  }
  _exports.default = HostList;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, HostList);
});