define("admin/routes/admin-badges/show", ["exports", "@ember/object", "@ember/routing/route", "@ember/service", "discourse/models/badge", "discourse-i18n"], function (_exports, _object, _route, _service, _badge, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminBadgesShowRoute extends _route.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    serialize(m) {
      return {
        badge_id: (0, _object.get)(m, "id") || "new"
      };
    }
    model(params) {
      if (params.badge_id === "new") {
        return _badge.default.create({
          name: _discourseI18n.default.t("admin.badges.new_badge")
        });
      }
      return this.modelFor("adminBadges").findBy("id", parseInt(params.badge_id, 10));
    }
    setupController(controller) {
      super.setupController(...arguments);
      controller.setup();
    }
  }
  _exports.default = AdminBadgesShowRoute;
});