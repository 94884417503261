define("admin/routes/admin-customize-email-templates-edit", ["exports", "@ember/routing/route", "discourse/mixins/scroll-top"], function (_exports, _route, _scrollTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminCustomizeEmailTemplatesEditRoute extends _route.default {
    model(params) {
      const all = this.modelFor("adminCustomizeEmailTemplates");
      return all.findBy("id", params.id);
    }
    setupController(controller, emailTemplate) {
      controller.setProperties({
        emailTemplate,
        saved: false
      });
      (0, _scrollTop.scrollTop)();
    }
    deactivate() {
      this.controller.set("emailTemplate", null);
    }
  }
  _exports.default = AdminCustomizeEmailTemplatesEditRoute;
});