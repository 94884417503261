define("admin/routes/admin-dashboard-reports", ["exports", "discourse/lib/ajax", "discourse/routes/discourse"], function (_exports, _ajax, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminDashboardReportsRoute extends _discourse.default {
    model() {
      return (0, _ajax.ajax)("/admin/reports");
    }
    setupController(controller, model) {
      controller.setProperties({
        model: model.reports,
        filter: null
      });
    }
  }
  _exports.default = AdminDashboardReportsRoute;
});