define("admin/components/webhook-event", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/formatter", "discourse-i18n", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _ajax, _ajaxError, _formatter, _discourseI18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <li>
    <div class="col first status">
      {{#if @event.redelivering}}
        {{d-icon "arrows-rotate"}}
      {{else}}
        <span class={{this.statusColorClasses}}>{{@event.status}}</span>
      {{/if}}
    </div>
  
    <div class="col event-id">{{@event.id}}</div>
  
    <div class="col timestamp">{{this.createdAt}}</div>
  
    <div class="col completion">{{this.completion}}</div>
  
    <div class="col actions">
      <DButton
        @icon={{this.expandRequestIcon}}
        @action={{this.toggleRequest}}
        @label="admin.web_hooks.events.request"
      />
      <DButton
        @icon={{this.expandResponseIcon}}
        @action={{this.toggleResponse}}
        @label="admin.web_hooks.events.response"
      />
      <DButton
        @icon="arrows-rotate"
        @action={{this.redeliver}}
        @label="admin.web_hooks.events.redeliver"
      />
    </div>
  
    {{#if this.expandDetails}}
      <div class="details">
        <h3>{{i18n "admin.web_hooks.events.headers"}}</h3>
        <pre><code>{{this.headers}}</code></pre>
  
        <h3>{{this.bodyLabel}}</h3>
        <pre><code>{{this.body}}</code></pre>
      </div>
    {{/if}}
  </li>
  */
  {
    "id": "hpBIcvVp",
    "block": "[[[10,\"li\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"col first status\"],[12],[1,\"\\n\"],[41,[30,1,[\"redelivering\"]],[[[1,\"      \"],[1,[28,[35,1],[\"arrows-rotate\"],null]],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,1],[15,0,[30,0,[\"statusColorClasses\"]]],[12],[1,[30,1,[\"status\"]]],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"col event-id\"],[12],[1,[30,1,[\"id\"]]],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"col timestamp\"],[12],[1,[30,0,[\"createdAt\"]]],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"col completion\"],[12],[1,[30,0,[\"completion\"]]],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"col actions\"],[12],[1,\"\\n    \"],[8,[39,2],null,[[\"@icon\",\"@action\",\"@label\"],[[30,0,[\"expandRequestIcon\"]],[30,0,[\"toggleRequest\"]],\"admin.web_hooks.events.request\"]],null],[1,\"\\n    \"],[8,[39,2],null,[[\"@icon\",\"@action\",\"@label\"],[[30,0,[\"expandResponseIcon\"]],[30,0,[\"toggleResponse\"]],\"admin.web_hooks.events.response\"]],null],[1,\"\\n    \"],[8,[39,2],null,[[\"@icon\",\"@action\",\"@label\"],[\"arrows-rotate\",[30,0,[\"redeliver\"]],\"admin.web_hooks.events.redeliver\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"expandDetails\"]],[[[1,\"    \"],[10,0],[14,0,\"details\"],[12],[1,\"\\n      \"],[10,\"h3\"],[12],[1,[28,[35,3],[\"admin.web_hooks.events.headers\"],null]],[13],[1,\"\\n      \"],[10,\"pre\"],[12],[10,\"code\"],[12],[1,[30,0,[\"headers\"]]],[13],[13],[1,\"\\n\\n      \"],[10,\"h3\"],[12],[1,[30,0,[\"bodyLabel\"]]],[13],[1,\"\\n      \"],[10,\"pre\"],[12],[10,\"code\"],[12],[1,[30,0,[\"body\"]]],[13],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"@event\"],false,[\"if\",\"d-icon\",\"d-button\",\"i18n\"]]",
    "moduleName": "admin/components/webhook-event.hbs",
    "isStrictMode": false
  });
  class WebhookEvent extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "body", [_tracking.tracked], function () {
      return "";
    }))();
    #body = (() => (dt7948.i(this, "body"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "bodyLabel", [_tracking.tracked], function () {
      return "";
    }))();
    #bodyLabel = (() => (dt7948.i(this, "bodyLabel"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "expandDetails", [_tracking.tracked], function () {
      return null;
    }))();
    #expandDetails = (() => (dt7948.i(this, "expandDetails"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "headers", [_tracking.tracked], function () {
      return "";
    }))();
    #headers = (() => (dt7948.i(this, "headers"), void 0))();
    expandDetailsRequestKey = "request";
    expandDetailsResponseKey = "response";
    get statusColorClasses() {
      const {
        status
      } = this.args.event;
      if (!status) {
        return "";
      }
      if (status >= 200 && status <= 299) {
        return "text-successful";
      } else {
        return "text-danger";
      }
    }
    get createdAt() {
      return moment(this.args.event.created_at).format("YYYY-MM-DD HH:mm:ss");
    }
    get completion() {
      const seconds = Math.floor(this.args.event.duration / 10.0) / 100.0;
      return _discourseI18n.default.t("admin.web_hooks.events.completed_in", {
        count: seconds
      });
    }
    get expandRequestIcon() {
      return this.expandDetails === this.expandDetailsRequestKey ? "ellipsis" : "ellipsis-vertical";
    }
    get expandResponseIcon() {
      return this.expandDetails === this.expandDetailsResponseKey ? "ellipsis" : "ellipsis-vertical";
    }
    redeliver() {
      return this.dialog.yesNoConfirm({
        message: _discourseI18n.default.t("admin.web_hooks.events.redeliver_confirm"),
        didConfirm: async () => {
          try {
            const json = await (0, _ajax.ajax)(`/admin/api/web_hooks/${this.args.event.web_hook_id}/events/${this.args.event.id}/redeliver`, {
              type: "POST"
            });
            this.args.event.setProperties(json.web_hook_event);
          } catch (e) {
            (0, _ajaxError.popupAjaxError)(e);
          }
        }
      });
    }
    static #_6 = (() => dt7948.n(this.prototype, "redeliver", [_object.action]))();
    toggleRequest() {
      if (this.expandDetails !== this.expandDetailsRequestKey) {
        const headers = {
          "Request URL": this.args.event.request_url,
          "Request method": "POST",
          ...(0, _formatter.ensureJSON)(this.args.event.headers)
        };
        this.headers = (0, _formatter.plainJSON)(headers);
        this.body = (0, _formatter.prettyJSON)(this.args.event.payload);
        this.expandDetails = this.expandDetailsRequestKey;
        this.bodyLabel = _discourseI18n.default.t("admin.web_hooks.events.payload");
      } else {
        this.expandDetails = null;
      }
    }
    static #_7 = (() => dt7948.n(this.prototype, "toggleRequest", [_object.action]))();
    toggleResponse() {
      if (this.expandDetails !== this.expandDetailsResponseKey) {
        this.headers = (0, _formatter.plainJSON)(this.args.event.response_headers);
        this.body = this.args.event.response_body;
        this.expandDetails = this.expandDetailsResponseKey;
        this.bodyLabel = _discourseI18n.default.t("admin.web_hooks.events.body");
      } else {
        this.expandDetails = null;
      }
    }
    static #_8 = (() => dt7948.n(this.prototype, "toggleResponse", [_object.action]))();
  }
  _exports.default = WebhookEvent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, WebhookEvent);
});