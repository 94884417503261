define("admin/controllers/admin-user-badges", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/runloop", "@ember/service", "discourse/lib/ajax-error", "discourse/lib/grant-badge-utils", "discourse/models/user-badge", "discourse-common/utils/decorators", "discourse-i18n", "admin/models/admin-user"], function (_exports, _controller, _object, _computed, _runloop, _service, _ajaxError, _grantBadgeUtils, _userBadge, _decorators, _discourseI18n, _adminUser3) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminUserBadgesController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "adminUser", [_controller.inject]))();
    #adminUser = (() => (dt7948.i(this, "adminUser"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "user", [(0, _computed.alias)("adminUser.model")]))();
    #user = (() => (dt7948.i(this, "user"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "userBadges", [(0, _computed.alias)("model")]))();
    #userBadges = (() => (dt7948.i(this, "userBadges"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "allBadges", [(0, _computed.alias)("badges")]))();
    #allBadges = (() => (dt7948.i(this, "allBadges"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "sortedBadges", [(0, _computed.sort)("model", "badgeSortOrder")]))();
    #sortedBadges = (() => (dt7948.i(this, "sortedBadges"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "noAvailableBadges", [(0, _computed.empty)("availableBadges")]))();
    #noAvailableBadges = (() => (dt7948.i(this, "noAvailableBadges"), void 0))();
    badgeSortOrder = ["granted_at:desc"];
    availableBadges() {
      return (0, _grantBadgeUtils.grantableBadges)(this.get("allBadges"), this.get("userBadges"));
    }
    static #_8 = (() => dt7948.n(this.prototype, "availableBadges", [(0, _decorators.default)("allBadges.[]", "userBadges.[]")]))();
    groupedBadges() {
      const allBadges = this.model;
      let grouped = {};
      allBadges.forEach(b => {
        grouped[b.badge_id] = grouped[b.badge_id] || [];
        grouped[b.badge_id].push(b);
      });
      let expanded = [];
      const expandedBadges = allBadges.get("expandedBadges") || [];
      Object.values(grouped).forEach(function (badges) {
        let lastGranted = badges[0].granted_at;
        badges.forEach(badge => {
          lastGranted = lastGranted < badge.granted_at ? badge.granted_at : lastGranted;
        });
        if (badges.length === 1 || expandedBadges.includes(badges[0].badge.id)) {
          badges.forEach(badge => expanded.push(badge));
          return;
        }
        let result = {
          badge: badges[0].badge,
          granted_at: lastGranted,
          badges,
          count: badges.length,
          grouped: true
        };
        expanded.push(result);
      });
      expanded.forEach(badgeGroup => {
        const user = badgeGroup.granted_by;
        if (user) {
          badgeGroup.granted_by = _adminUser3.default.create(user);
        }
      });
      return expanded.sortBy("granted_at").reverse();
    }
    static #_9 = (() => dt7948.n(this.prototype, "groupedBadges", [(0, _decorators.default)("model", "model.[]", "model.expandedBadges.[]")]))();
    expandGroup(userBadge) {
      const model = this.model;
      model.set("expandedBadges", model.get("expandedBadges") || []);
      model.get("expandedBadges").pushObject(userBadge.badge.id);
    }
    static #_10 = (() => dt7948.n(this.prototype, "expandGroup", [_object.action]))();
    performGrantBadge() {
      _userBadge.default.grant(this.selectedBadgeId, this.get("user.username"), this.badgeReason).then(newBadge => {
        this.set("badgeReason", "");
        this.userBadges.pushObject(newBadge);
        (0, _runloop.next)(() => {
          // Update the selected badge ID after the combobox has re-rendered.
          const newSelectedBadge = this.availableBadges[0];
          if (newSelectedBadge) {
            this.set("selectedBadgeId", newSelectedBadge.get("id"));
          }
        });
      }, function (error) {
        (0, _ajaxError.popupAjaxError)(error);
      });
    }
    static #_11 = (() => dt7948.n(this.prototype, "performGrantBadge", [_object.action]))();
    revokeBadge(userBadge) {
      return this.dialog.yesNoConfirm({
        message: _discourseI18n.default.t("admin.badges.revoke_confirm"),
        didConfirm: () => {
          return userBadge.revoke().then(() => {
            this.model.removeObject(userBadge);
          });
        }
      });
    }
    static #_12 = (() => dt7948.n(this.prototype, "revokeBadge", [_object.action]))();
  }
  _exports.default = AdminUserBadgesController;
});