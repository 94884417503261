define("admin/components/setting-validation-message", ["exports", "@ember/component", "@ember/template-factory"], function (_exports, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="validation-error {{unless this.message 'hidden'}}">
    {{d-icon "xmark"}}
    {{this.message}}
  </div>
  */
  {
    "id": "8U8b3pqB",
    "block": "[[[10,0],[15,0,[29,[\"validation-error \",[52,[51,[30,0,[\"message\"]]],\"hidden\"]]]],[12],[1,\"\\n  \"],[1,[28,[35,1],[\"xmark\"],null]],[1,\"\\n  \"],[1,[30,0,[\"message\"]]],[1,\"\\n\"],[13]],[],false,[\"unless\",\"d-icon\"]]",
    "moduleName": "admin/components/setting-validation-message.hbs",
    "isStrictMode": false
  });
  class SettingValidationMessage extends _component.default {}
  _exports.default = SettingValidationMessage;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SettingValidationMessage);
});