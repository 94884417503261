define("admin/routes/admin-emojis", ["exports", "@ember/object", "discourse/lib/ajax", "discourse/routes/discourse"], function (_exports, _object, _ajax, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminEmojisRoute extends _discourse.default {
    async model() {
      const emojis = await (0, _ajax.ajax)("/admin/customize/emojis.json");
      return emojis.map(emoji => _object.default.create(emoji));
    }
  }
  _exports.default = AdminEmojisRoute;
});