define("admin/controllers/admin-api-keys-show", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/service", "@ember/utils", "discourse/lib/ajax-error", "discourse/mixins/buffered-content", "admin/components/modal/api-key-urls"], function (_exports, _controller, _object, _computed, _service, _utils, _ajaxError, _bufferedContent, _apiKeyUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminApiKeysShowController extends _controller.default.extend((0, _bufferedContent.bufferedProperty)("model")) {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "isNew", [(0, _computed.empty)("model.id")]))();
    #isNew = (() => (dt7948.i(this, "isNew"), void 0))();
    saveDescription() {
      const buffered = this.buffered;
      const attrs = buffered.getProperties("description");
      this.model.save(attrs).then(() => {
        this.set("editingDescription", false);
        this.rollbackBuffer();
      }).catch(_ajaxError.popupAjaxError);
    }
    static #_4 = (() => dt7948.n(this.prototype, "saveDescription", [_object.action]))();
    cancel() {
      const id = this.get("userField.id");
      if ((0, _utils.isEmpty)(id)) {
        this.destroyAction(this.userField);
      } else {
        this.rollbackBuffer();
        this.set("editing", false);
      }
    }
    static #_5 = (() => dt7948.n(this.prototype, "cancel", [_object.action]))();
    editDescription() {
      this.toggleProperty("editingDescription");
      if (!this.editingDescription) {
        this.rollbackBuffer();
      }
    }
    static #_6 = (() => dt7948.n(this.prototype, "editDescription", [_object.action]))();
    revokeKey(key) {
      key.revoke().catch(_ajaxError.popupAjaxError);
    }
    static #_7 = (() => dt7948.n(this.prototype, "revokeKey", [_object.action]))();
    deleteKey(key) {
      key.destroyRecord().then(() => this.router.transitionTo("adminApiKeys.index")).catch(_ajaxError.popupAjaxError);
    }
    static #_8 = (() => dt7948.n(this.prototype, "deleteKey", [_object.action]))();
    undoRevokeKey(key) {
      key.undoRevoke().catch(_ajaxError.popupAjaxError);
    }
    static #_9 = (() => dt7948.n(this.prototype, "undoRevokeKey", [_object.action]))();
    showURLs(urls) {
      this.modal.show(_apiKeyUrls.default, {
        model: {
          urls
        }
      });
    }
    static #_10 = (() => dt7948.n(this.prototype, "showURLs", [_object.action]))();
  }
  _exports.default = AdminApiKeysShowController;
});