define("admin/models/watched-word", ["exports", "@ember/object", "discourse/lib/ajax", "discourse-i18n"], function (_exports, _object, _ajax, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class WatchedWord extends _object.default {
    static findAll() {
      return (0, _ajax.ajax)("/admin/customize/watched_words.json").then(list => {
        const actions = {};
        list.actions.forEach(action => {
          actions[action] = [];
        });
        list.words.forEach(watchedWord => {
          actions[watchedWord.action].pushObject(WatchedWord.create(watchedWord));
        });
        return Object.keys(actions).map(nameKey => {
          return _object.default.create({
            nameKey,
            name: _discourseI18n.default.t("admin.watched_words.actions." + nameKey),
            words: actions[nameKey],
            compiledRegularExpression: list.compiled_regular_expressions[nameKey]
          });
        });
      });
    }
    save() {
      return (0, _ajax.ajax)("/admin/customize/watched_words" + (this.id ? "/" + this.id : "") + ".json", {
        type: this.id ? "PUT" : "POST",
        data: {
          words: this.words,
          replacement: this.replacement,
          action_key: this.action,
          case_sensitive: this.isCaseSensitive,
          html: this.isHtml
        },
        dataType: "json"
      });
    }
    destroy() {
      return (0, _ajax.ajax)("/admin/customize/watched_words/" + this.id + ".json", {
        type: "DELETE"
      });
    }
  }
  _exports.default = WatchedWord;
});