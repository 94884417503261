define("admin/components/site-settings/category-list", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/render-modifiers/modifiers/did-update", "discourse/models/category", "select-kit/components/category-selector", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _didUpdate, _category, _categorySelector, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CategoryList extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "selectedCategories", [_tracking.tracked], function () {
      return [];
    }))();
    #selectedCategories = (() => (dt7948.i(this, "selectedCategories"), void 0))();
    constructor() {
      super(...arguments);
      this.pendingCategoriesRequest = Promise.resolve();
      this.valueChanged();
    }
    get categoryIds() {
      return this.args.value.split("|").filter(Boolean);
    }
    async updateSelectedCategories(previousRequest1) {
      const categories1 = await _category.default.asyncFindByIds(this.categoryIds);
      // This is to prevent a race. We want to ensure that the update to
      // selectedCategories for this request happens after the update for the
      // previous request.
      await previousRequest1;
      this.selectedCategories = categories1;
    }
    valueChanged() {
      const previousRequest1 = this.pendingCategoriesRequest;
      this.pendingCategoriesRequest = this.updateSelectedCategories(previousRequest1);
    }
    static #_2 = (() => dt7948.n(this.prototype, "valueChanged", [_object.action]))();
    onChangeSelectedCategories(value1) {
      this.args.changeValueCallback((value1 || []).mapBy("id").join("|"));
    }
    static #_3 = (() => dt7948.n(this.prototype, "onChangeSelectedCategories", [_object.action]))();
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div ...attributes {{didUpdate this.valueChanged @value}}>
          <CategorySelector
            @categories={{this.selectedCategories}}
            @onChange={{this.onChangeSelectedCategories}}
          />
        </div>
      
    */
    {
      "id": "ivhyvTN5",
      "block": "[[[1,\"\\n    \"],[11,0],[17,1],[4,[32,0],[[30,0,[\"valueChanged\"]],[30,2]],null],[12],[1,\"\\n      \"],[8,[32,1],null,[[\"@categories\",\"@onChange\"],[[30,0,[\"selectedCategories\"]],[30,0,[\"onChangeSelectedCategories\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"&attrs\",\"@value\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/admin/components/site-settings/category-list.js",
      "scope": () => [_didUpdate.default, _categorySelector.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = CategoryList;
});