define("admin/components/modal/api-key-urls", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    @title={{i18n "admin.api.scopes.allowed_urls"}}
    @closeModal={{@closeModal}}
  >
    <:body>
      <div>
        <ul>
          {{#each @model.urls as |url|}}
            <li>
              <code>{{url}}</code>
            </li>
          {{/each}}
        </ul>
      </div>
    </:body>
  </DModal>
  */
  {
    "id": "v0QrIBO4",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@closeModal\"],[[28,[37,1],[\"admin.api.scopes.allowed_urls\"],null],[30,1]]],[[\"body\"],[[[[1,\"\\n    \"],[10,0],[12],[1,\"\\n      \"],[10,\"ul\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,2,[\"urls\"]]],null]],null],null,[[[1,\"          \"],[10,\"li\"],[12],[1,\"\\n            \"],[10,\"code\"],[12],[1,[30,3]],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[3]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\",\"@model\",\"url\"],false,[\"d-modal\",\"i18n\",\"each\",\"-track-array\"]]",
    "moduleName": "admin/components/modal/api-key-urls.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});