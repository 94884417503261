define("admin/components/schema-theme-setting/editor/tree", ["exports", "@ember/helper", "@ember/modifier", "truth-helpers", "discourse/components/d-button", "discourse-common/helpers/d-icon", "admin/components/schema-theme-setting/editor/tree-node", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _helper, _modifier, _truthHelpers, _dButton, _dIcon, _treeNode, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <ul class="schema-theme-setting-editor__tree">
      {{#if @backButtonText}}
        <li
          role="link"
          class="schema-theme-setting-editor__tree-node --back-btn"
          {{on "click" @clickBack}}
        >
          <div class="schema-theme-setting-editor__tree-node-text">
            {{dIcon "arrow-left"}}
            {{@backButtonText}}
          </div>
        </li>
      {{/if}}
  
      {{#each @data as |object index|}}
        <TreeNode
          @index={{index}}
          @object={{object}}
          @active={{eq @activeIndex index}}
          @onClick={{fn @updateIndex index}}
          @onChildClick={{@onChildClick}}
          @schema={{@schema}}
          @addChildItem={{@addChildItem}}
          @generateSchemaTitle={{@generateSchemaTitle}}
          @registerInputFieldObserver={{@registerInputFieldObserver}}
          @unregisterInputFieldObserver={{@unregisterInputFieldObserver}}
        />
      {{/each}}
  
      <li class="schema-theme-setting-editor__tree-node --parent --add-button">
        <DButton
          @action={{@addItem}}
          @translatedLabel={{@schema.name}}
          @icon="plus"
          class="btn-transparent schema-theme-setting-editor__tree-add-button --root"
        />
      </li>
    </ul>
  
  */
  {
    "id": "vWdYUmg+",
    "block": "[[[1,\"\\n  \"],[10,\"ul\"],[14,0,\"schema-theme-setting-editor__tree\"],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"      \"],[11,\"li\"],[24,\"role\",\"link\"],[24,0,\"schema-theme-setting-editor__tree-node --back-btn\"],[4,[32,0],[\"click\",[30,2]],null],[12],[1,\"\\n        \"],[10,0],[14,0,\"schema-theme-setting-editor__tree-node-text\"],[12],[1,\"\\n          \"],[1,[28,[32,1],[\"arrow-left\"],null]],[1,\"\\n          \"],[1,[30,1]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[42,[28,[31,2],[[28,[31,2],[[30,3]],null]],null],null,[[[1,\"      \"],[8,[32,2],null,[[\"@index\",\"@object\",\"@active\",\"@onClick\",\"@onChildClick\",\"@schema\",\"@addChildItem\",\"@generateSchemaTitle\",\"@registerInputFieldObserver\",\"@unregisterInputFieldObserver\"],[[30,5],[30,4],[28,[32,3],[[30,6],[30,5]],null],[28,[32,4],[[30,7],[30,5]],null],[30,8],[30,9],[30,10],[30,11],[30,12],[30,13]]],null],[1,\"\\n\"]],[4,5]],null],[1,\"\\n    \"],[10,\"li\"],[14,0,\"schema-theme-setting-editor__tree-node --parent --add-button\"],[12],[1,\"\\n      \"],[8,[32,5],[[24,0,\"btn-transparent schema-theme-setting-editor__tree-add-button --root\"]],[[\"@action\",\"@translatedLabel\",\"@icon\"],[[30,14],[30,9,[\"name\"]],\"plus\"]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@backButtonText\",\"@clickBack\",\"@data\",\"object\",\"index\",\"@activeIndex\",\"@updateIndex\",\"@onChildClick\",\"@schema\",\"@addChildItem\",\"@generateSchemaTitle\",\"@registerInputFieldObserver\",\"@unregisterInputFieldObserver\",\"@addItem\"],false,[\"if\",\"each\",\"-track-array\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/admin/components/schema-theme-setting/editor/tree.js",
    "scope": () => [_modifier.on, _dIcon.default, _treeNode.default, _truthHelpers.eq, _helper.fn, _dButton.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)());
});