define("admin/controllers/admin-user-fields", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/service", "discourse/lib/ajax-error", "discourse-i18n"], function (_exports, _controller, _object, _computed, _service, _ajaxError, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MAX_FIELDS = 30;
  class AdminUserFieldsController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    fieldTypes = null;
    fieldSortOrder = ["position"];
    static #_2 = (() => dt7948.g(this.prototype, "createDisabled", [(0, _computed.gte)("model.length", MAX_FIELDS)]))();
    #createDisabled = (() => (dt7948.i(this, "createDisabled"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "sortedFields", [(0, _computed.sort)("model", "fieldSortOrder")]))();
    #sortedFields = (() => (dt7948.i(this, "sortedFields"), void 0))();
    get firstField() {
      return this.sortedFields[0];
    }
    get lastField() {
      return this.sortedFields[this.sortedFields.length - 1];
    }
    createField() {
      const f = this.store.createRecord("user-field", {
        field_type: "text",
        position: MAX_FIELDS
      });
      this.model.pushObject(f);
    }
    static #_4 = (() => dt7948.n(this.prototype, "createField", [_object.action]))();
    moveUp(f) {
      const idx = this.sortedFields.indexOf(f);
      if (idx) {
        const prev = this.sortedFields.objectAt(idx - 1);
        const prevPos = prev.get("position");
        prev.update({
          position: f.get("position")
        });
        f.update({
          position: prevPos
        });
      }
    }
    static #_5 = (() => dt7948.n(this.prototype, "moveUp", [_object.action]))();
    moveDown(f) {
      const idx = this.sortedFields.indexOf(f);
      if (idx > -1) {
        const next = this.sortedFields.objectAt(idx + 1);
        const nextPos = next.get("position");
        next.update({
          position: f.get("position")
        });
        f.update({
          position: nextPos
        });
      }
    }
    static #_6 = (() => dt7948.n(this.prototype, "moveDown", [_object.action]))();
    destroyField(f) {
      const model = this.model;

      // Only confirm if we already been saved
      if (f.get("id")) {
        this.dialog.yesNoConfirm({
          message: _discourseI18n.default.t("admin.user_fields.delete_confirm"),
          didConfirm: () => {
            return f.destroyRecord().then(function () {
              model.removeObject(f);
            }).catch(_ajaxError.popupAjaxError);
          }
        });
      } else {
        model.removeObject(f);
      }
    }
    static #_7 = (() => dt7948.n(this.prototype, "destroyField", [_object.action]))();
  }
  _exports.default = AdminUserFieldsController;
});