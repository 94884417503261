define("admin/models/theme-settings", ["exports", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error", "admin/mixins/setting-object"], function (_exports, _object, _ajax, _ajaxError, _settingObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ThemeSettings extends _object.default.extend(_settingObject.default) {
    updateSetting(themeId, newValue) {
      if (this.objects_schema) {
        newValue = JSON.stringify(newValue);
      }
      return (0, _ajax.ajax)(`/admin/themes/${themeId}/setting`, {
        type: "PUT",
        data: {
          name: this.setting,
          value: newValue
        }
      });
    }
    loadMetadata(themeId) {
      return (0, _ajax.ajax)(`/admin/themes/${themeId}/objects_setting_metadata/${this.setting}.json`).then(result => this.set("metadata", result)).catch(_ajaxError.popupAjaxError);
    }
  }
  _exports.default = ThemeSettings;
});