define("admin/components/dashboard-new-features", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/render-modifiers/modifiers/did-insert", "@ember/service", "@ember/template", "discourse/lib/ajax", "discourse-common/helpers/i18n", "discourse-common/utils/decorators", "admin/components/admin-config-area-card", "admin/components/dashboard-new-feature-item", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _didInsert, _service, _template2, _ajax, _i18n, _decorators, _adminConfigAreaCard, _dashboardNewFeatureItem, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DashboardNewFeatures extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "newFeatures", [_tracking.tracked], function () {
      return null;
    }))();
    #newFeatures = (() => (dt7948.i(this, "newFeatures"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "groupedNewFeatures", [_tracking.tracked], function () {
      return null;
    }))();
    #groupedNewFeatures = (() => (dt7948.i(this, "groupedNewFeatures"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "isLoaded", [_tracking.tracked], function () {
      return false;
    }))();
    #isLoaded = (() => (dt7948.i(this, "isLoaded"), void 0))();
    loadNewFeatures() {
      (0, _ajax.ajax)("/admin/whats-new.json").then(json1 => {
        const items1 = json1.new_features.reduce((acc1, feature1) => {
          const key1 = moment(feature1.released_at || feature1.created_at).format("YYYY-MM");
          acc1[key1] = acc1[key1] || [];
          acc1[key1].push(feature1);
          return acc1;
        }, {});
        this.groupedNewFeatures = Object.keys(items1).map(date1 => {
          return {
            date: moment.tz(date1, this.currentUser.user_option.timezone).format("MMMM YYYY"),
            features: items1[date1]
          };
        });
        this.isLoaded = true;
      }).finally(() => {
        this.isLoaded = true;
      });
    }
    static #_5 = (() => dt7948.n(this.prototype, "loadNewFeatures", [_decorators.bind]))();
    static #_6 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="admin-config-area" {{didInsert this.loadNewFeatures}}>
          {{#if this.groupedNewFeatures}}
            {{#each this.groupedNewFeatures as |groupedFeatures|}}
              <AdminConfigAreaCard @translatedHeading={{groupedFeatures.date}}>
                {{#each groupedFeatures.features as |feature|}}
                  <DashboardNewFeatureItem @item={{feature}} />
                {{/each}}
              </AdminConfigAreaCard>
            {{/each}}
          {{else if this.isLoaded}}
            {{htmlSafe
              (i18n
                "admin.dashboard.new_features.previous_announcements"
                url="https://meta.discourse.org/tags/c/announcements/67/release-notes"
              )
            }}
          {{/if}}
        </div>
      
    */
    {
      "id": "k8BscmuZ",
      "block": "[[[1,\"\\n    \"],[11,0],[24,0,\"admin-config-area\"],[4,[32,0],[[30,0,[\"loadNewFeatures\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"groupedNewFeatures\"]],[[[42,[28,[31,2],[[28,[31,2],[[30,0,[\"groupedNewFeatures\"]]],null]],null],null,[[[1,\"          \"],[8,[32,1],null,[[\"@translatedHeading\"],[[30,1,[\"date\"]]]],[[\"default\"],[[[[1,\"\\n\"],[42,[28,[31,2],[[28,[31,2],[[30,1,[\"features\"]]],null]],null],null,[[[1,\"              \"],[8,[32,2],null,[[\"@item\"],[[30,2]]],null],[1,\"\\n\"]],[2]],null],[1,\"          \"]],[]]]]],[1,\"\\n\"]],[1]],null]],[]],[[[41,[30,0,[\"isLoaded\"]],[[[1,\"        \"],[1,[28,[32,3],[[28,[32,4],[\"admin.dashboard.new_features.previous_announcements\"],[[\"url\"],[\"https://meta.discourse.org/tags/c/announcements/67/release-notes\"]]]],null]],[1,\"\\n      \"]],[]],null]],[]]],[1,\"    \"],[13],[1,\"\\n  \"]],[\"groupedFeatures\",\"feature\"],false,[\"if\",\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/admin/components/dashboard-new-features.js",
      "scope": () => [_didInsert.default, _adminConfigAreaCard.default, _dashboardNewFeatureItem.default, _template2.htmlSafe, _i18n.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = DashboardNewFeatures;
});