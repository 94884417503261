define("admin/routes/admin-email-incomings", ["exports", "discourse/routes/discourse", "admin/models/incoming-email"], function (_exports, _discourse, _incomingEmail) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminEmailIncomingsRoute extends _discourse.default {
    model() {
      return _incomingEmail.default.findAll({
        status: this.status
      });
    }
    setupController(controller, model) {
      controller.set("model", model);
      controller.set("filter.status", this.status);
    }
  }
  _exports.default = AdminEmailIncomingsRoute;
});