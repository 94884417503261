define("admin/components/admin-config-areas/about", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "admin/components/admin-config-area-card", "admin/components/admin-config-area-cards/about/contact-information", "admin/components/admin-config-area-cards/about/general-settings", "admin/components/admin-config-area-cards/about/your-organization", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _adminConfigAreaCard, _contactInformation, _generalSettings, _yourOrganization, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminConfigAreasAbout extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "saving", [_tracking.tracked], function () {
      return false;
    }))();
    #saving = (() => (dt7948.i(this, "saving"), void 0))();
    get generalSettings() {
      return {
        title: this.#lookupSettingFromData("title"),
        siteDescription: this.#lookupSettingFromData("site_description"),
        extendedSiteDescription: this.#lookupSettingFromData("extended_site_description"),
        aboutBannerImage: this.#lookupSettingFromData("about_banner_image")
      };
    }
    get contactInformation() {
      return {
        communityOwner: this.#lookupSettingFromData("community_owner"),
        contactEmail: this.#lookupSettingFromData("contact_email"),
        contactURL: this.#lookupSettingFromData("contact_url"),
        contactUsername: this.#lookupSettingFromData("site_contact_username"),
        contactGroupName: this.#lookupSettingFromData("site_contact_group_name")
      };
    }
    get yourOrganization() {
      return {
        companyName: this.#lookupSettingFromData("company_name"),
        governingLaw: this.#lookupSettingFromData("governing_law"),
        cityForDisputes: this.#lookupSettingFromData("city_for_disputes")
      };
    }
    setSavingStatus(status1) {
      this.saving = status1;
    }
    static #_2 = (() => dt7948.n(this.prototype, "setSavingStatus", [_object.action]))();
    #lookupSettingFromData(name1) {
      return this.args.data.findBy("setting", name1);
    }
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="admin-config-area">
          <div class="admin-config-area__primary-content">
            <AdminConfigAreaCard
              @heading="admin.config_areas.about.general_settings"
              class="admin-config-area-about__general-settings-section"
            >
              <AdminConfigAreasAboutGeneralSettings
                @generalSettings={{this.generalSettings}}
                @setGlobalSavingStatus={{this.setSavingStatus}}
                @globalSavingStatus={{this.saving}}
              />
            </AdminConfigAreaCard>
            <AdminConfigAreaCard
              @heading="admin.config_areas.about.contact_information"
              class="admin-config-area-about__contact-information-section"
            >
              <AdminConfigAreasAboutContactInformation
                @contactInformation={{this.contactInformation}}
                @setGlobalSavingStatus={{this.setSavingStatus}}
                @globalSavingStatus={{this.saving}}
              />
            </AdminConfigAreaCard>
            <AdminConfigAreaCard
              @heading="admin.config_areas.about.your_organization"
              class="admin-config-area-about__your-organization-section"
            >
              <AdminConfigAreasAboutYourOrganization
                @yourOrganization={{this.yourOrganization}}
                @setGlobalSavingStatus={{this.setSavingStatus}}
                @globalSavingStatus={{this.saving}}
              />
            </AdminConfigAreaCard>
          </div>
        </div>
      
    */
    {
      "id": "tzhvkeFS",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"admin-config-area\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"admin-config-area__primary-content\"],[12],[1,\"\\n        \"],[8,[32,0],[[24,0,\"admin-config-area-about__general-settings-section\"]],[[\"@heading\"],[\"admin.config_areas.about.general_settings\"]],[[\"default\"],[[[[1,\"\\n          \"],[8,[32,1],null,[[\"@generalSettings\",\"@setGlobalSavingStatus\",\"@globalSavingStatus\"],[[30,0,[\"generalSettings\"]],[30,0,[\"setSavingStatus\"]],[30,0,[\"saving\"]]]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n        \"],[8,[32,0],[[24,0,\"admin-config-area-about__contact-information-section\"]],[[\"@heading\"],[\"admin.config_areas.about.contact_information\"]],[[\"default\"],[[[[1,\"\\n          \"],[8,[32,2],null,[[\"@contactInformation\",\"@setGlobalSavingStatus\",\"@globalSavingStatus\"],[[30,0,[\"contactInformation\"]],[30,0,[\"setSavingStatus\"]],[30,0,[\"saving\"]]]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n        \"],[8,[32,0],[[24,0,\"admin-config-area-about__your-organization-section\"]],[[\"@heading\"],[\"admin.config_areas.about.your_organization\"]],[[\"default\"],[[[[1,\"\\n          \"],[8,[32,3],null,[[\"@yourOrganization\",\"@setGlobalSavingStatus\",\"@globalSavingStatus\"],[[30,0,[\"yourOrganization\"]],[30,0,[\"setSavingStatus\"]],[30,0,[\"saving\"]]]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/admin/components/admin-config-areas/about.js",
      "scope": () => [_adminConfigAreaCard.default, _generalSettings.default, _contactInformation.default, _yourOrganization.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AdminConfigAreasAbout;
});