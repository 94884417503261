define("admin/controllers/admin-permalinks", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/service", "@ember-decorators/object", "discourse/lib/utilities", "discourse-common/config/environment", "discourse-common/lib/debounce", "discourse-i18n", "admin/models/permalink"], function (_exports, _controller, _object, _computed, _service, _object2, _utilities, _environment, _debounce, _discourseI18n, _permalink) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPermalinksController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    loading = false;
    filter = null;
    static #_2 = (() => dt7948.g(this.prototype, "showSearch", [(0, _computed.or)("model.length", "filter")]))();
    #showSearch = (() => (dt7948.i(this, "showSearch"), void 0))();
    _debouncedShow() {
      _permalink.default.findAll(this.filter).then(result => {
        this.set("model", result);
        this.set("loading", false);
      });
    }
    show() {
      (0, _debounce.default)(this, this._debouncedShow, _environment.INPUT_DELAY);
    }
    static #_3 = (() => dt7948.n(this.prototype, "show", [(0, _object2.observes)("filter")]))();
    recordAdded(arg) {
      this.model.unshiftObject(arg);
    }
    static #_4 = (() => dt7948.n(this.prototype, "recordAdded", [_object.action]))();
    copyUrl(pl) {
      let linkElement = document.querySelector(`#admin-permalink-${pl.id}`);
      (0, _utilities.clipboardCopy)(linkElement.textContent);
    }
    static #_5 = (() => dt7948.n(this.prototype, "copyUrl", [_object.action]))();
    destroyRecord(record) {
      return this.dialog.yesNoConfirm({
        message: _discourseI18n.default.t("admin.permalink.delete_confirm"),
        didConfirm: () => {
          return record.destroy().then(deleted => {
            if (deleted) {
              this.model.removeObject(record);
            } else {
              this.dialog.alert(_discourseI18n.default.t("generic_error"));
            }
          }, function () {
            this.dialog.alert(_discourseI18n.default.t("generic_error"));
          });
        }
      });
    }
    static #_6 = (() => dt7948.n(this.prototype, "destroyRecord", [_object.action]))();
  }
  _exports.default = AdminPermalinksController;
});