define("admin/components/admin-config-areas/flags-settings", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/render-modifiers/modifiers/did-insert", "@ember/service", "discourse/components/d-breadcrumbs-item", "discourse/lib/ajax", "discourse-common/helpers/i18n", "discourse-common/utils/decorators", "admin/components/admin-filtered-site-settings", "admin/models/site-setting", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _didInsert, _service, _dBreadcrumbsItem, _ajax, _i18n, _decorators, _adminFilteredSiteSettings, _siteSetting, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminConfigAreasFlagsSettings extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "settings", [_tracking.tracked]))();
    #settings = (() => (dt7948.i(this, "settings"), void 0))();
    loadSettings() {
      (0, _ajax.ajax)("/admin/config/site_settings.json", {
        data: {
          filter_area: "flags"
        }
      }).then(result1 => {
        this.settings = [{
          name: "All",
          nameKey: "all_results",
          siteSettings: result1.site_settings.map(setting1 => _siteSetting.default.create(setting1))
        }];
      });
    }
    static #_3 = (() => dt7948.n(this.prototype, "loadSettings", [_decorators.bind]))();
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DBreadcrumbsItem
          @path="/admin/config/flags/settings"
          @label={{i18n "settings"}}
        />
    
        <div
          class="content-body admin-config-area__settings admin-detail pull-left"
          {{didInsert this.loadSettings}}
        >
          {{#if this.settings}}
            <AdminFilteredSiteSettings
              @initialFilter={{@initialFilter}}
              @onFilterChanged={{@onFilterChanged}}
              @settings={{this.settings}}
            />
          {{/if}}
        </div>
      
    */
    {
      "id": "gno/F9OP",
      "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@path\",\"@label\"],[\"/admin/config/flags/settings\",[28,[32,1],[\"settings\"],null]]],null],[1,\"\\n\\n    \"],[11,0],[24,0,\"content-body admin-config-area__settings admin-detail pull-left\"],[4,[32,2],[[30,0,[\"loadSettings\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"settings\"]],[[[1,\"        \"],[8,[32,3],null,[[\"@initialFilter\",\"@onFilterChanged\",\"@settings\"],[[30,1],[30,2],[30,0,[\"settings\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@initialFilter\",\"@onFilterChanged\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/admin/components/admin-config-areas/flags-settings.js",
      "scope": () => [_dBreadcrumbsItem.default, _i18n.default, _didInsert.default, _adminFilteredSiteSettings.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AdminConfigAreasFlagsSettings;
});