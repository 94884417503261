define("admin/components/site-settings/tag-group-list", ["exports", "@ember/component", "@ember/object", "discourse-common/utils/decorators", "@ember/template-factory"], function (_exports, _component, _object, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <TagGroupChooser
    @tagGroups={{this.selectedTagGroups}}
    @onChange={{this.onTagGroupChange}}
    @options={{hash filterPlaceholder="category.required_tag_group.placeholder"}}
  />
  */
  {
    "id": "3RzhmJhI",
    "block": "[[[8,[39,0],null,[[\"@tagGroups\",\"@onChange\",\"@options\"],[[30,0,[\"selectedTagGroups\"]],[30,0,[\"onTagGroupChange\"]],[28,[37,1],null,[[\"filterPlaceholder\"],[\"category.required_tag_group.placeholder\"]]]]],null]],[],false,[\"tag-group-chooser\",\"hash\"]]",
    "moduleName": "admin/components/site-settings/tag-group-list.hbs",
    "isStrictMode": false
  });
  class TagGroupList extends _component.default {
    selectedTagGroups(value) {
      return value.split("|").filter(Boolean);
    }
    static #_ = (() => dt7948.n(this.prototype, "selectedTagGroups", [(0, _decorators.default)("value")]))();
    onTagGroupChange(tagGroups) {
      this.set("value", tagGroups.join("|"));
    }
    static #_2 = (() => dt7948.n(this.prototype, "onTagGroupChange", [_object.action]))();
  }
  _exports.default = TagGroupList;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TagGroupList);
});