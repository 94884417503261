define("admin/templates/backups", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="admin-backups admin-config-page">
  
    <AdminPageHeader
      @titleLabel="admin.backups.title"
      @descriptionLabel="admin.backups.description"
      @learnMoreUrl="https://meta.discourse.org/t/create-download-and-restore-a-backup-of-your-discourse-database/122710"
    >
      <:breadcrumbs>
        <DBreadcrumbsItem
          @path="/admin/backups"
          @label={{i18n "admin.backups.title"}}
        />
      </:breadcrumbs>
      <:actions as |actions|>
        <AdminBackupsActions @actions={{actions}} @backups={{@model}} />
      </:actions>
      <:tabs>
        <NavItem
          @route="admin.backups.settings"
          @label="settings"
          class="admin-backups-tabs__settings"
        />
        <NavItem
          @route="admin.backups.index"
          @label="admin.backups.menu.backup_files"
          class="admin-backups-tabs__files"
        />
        <NavItem
          @route="admin.backups.logs"
          @label="admin.backups.menu.logs"
          class="admin-backups-tabs__logs"
        />
        <PluginOutlet @name="downloader" @connectorTagName="div" />
      </:tabs>
    </AdminPageHeader>
  
    <PluginOutlet @name="before-backup-list" @connectorTagName="div" />
  
    <div class="admin-container admin-config-page__main-area">
      {{outlet}}
    </div>
  </div>
  */
  {
    "id": "Wyswm2de",
    "block": "[[[10,0],[14,0,\"admin-backups admin-config-page\"],[12],[1,\"\\n\\n  \"],[8,[39,0],null,[[\"@titleLabel\",\"@descriptionLabel\",\"@learnMoreUrl\"],[\"admin.backups.title\",\"admin.backups.description\",\"https://meta.discourse.org/t/create-download-and-restore-a-backup-of-your-discourse-database/122710\"]],[[\"breadcrumbs\",\"actions\",\"tabs\"],[[[[1,\"\\n      \"],[8,[39,1],null,[[\"@path\",\"@label\"],[\"/admin/backups\",[28,[37,2],[\"admin.backups.title\"],null]]],null],[1,\"\\n    \"]],[]],[[[1,\"\\n      \"],[8,[39,3],null,[[\"@actions\",\"@backups\"],[[30,1],[30,2]]],null],[1,\"\\n    \"]],[1]],[[[1,\"\\n      \"],[8,[39,4],[[24,0,\"admin-backups-tabs__settings\"]],[[\"@route\",\"@label\"],[\"admin.backups.settings\",\"settings\"]],null],[1,\"\\n      \"],[8,[39,4],[[24,0,\"admin-backups-tabs__files\"]],[[\"@route\",\"@label\"],[\"admin.backups.index\",\"admin.backups.menu.backup_files\"]],null],[1,\"\\n      \"],[8,[39,4],[[24,0,\"admin-backups-tabs__logs\"]],[[\"@route\",\"@label\"],[\"admin.backups.logs\",\"admin.backups.menu.logs\"]],null],[1,\"\\n      \"],[8,[39,5],null,[[\"@name\",\"@connectorTagName\"],[\"downloader\",\"div\"]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n  \"],[8,[39,5],null,[[\"@name\",\"@connectorTagName\"],[\"before-backup-list\",\"div\"]],null],[1,\"\\n\\n  \"],[10,0],[14,0,\"admin-container admin-config-page__main-area\"],[12],[1,\"\\n    \"],[46,[28,[37,7],null,null],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"actions\",\"@model\"],false,[\"admin-page-header\",\"d-breadcrumbs-item\",\"i18n\",\"admin-backups-actions\",\"nav-item\",\"plugin-outlet\",\"component\",\"-outlet\"]]",
    "moduleName": "admin/templates/backups.hbs",
    "isStrictMode": false
  });
});