define("admin/controllers/admin-search-logs-term", ["exports", "@ember/controller", "discourse-i18n", "admin/controllers/admin-search-logs-index"], function (_exports, _controller, _discourseI18n, _adminSearchLogsIndex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminSearchLogsTermController extends _controller.default {
    loading = false;
    term = null;
    period = (() => _adminSearchLogsIndex.DEFAULT_PERIOD)();
    searchType = "all";
    searchTypeOptions = (() => [{
      id: "all",
      name: _discourseI18n.default.t("admin.logs.search_logs.types.all_search_types")
    }, {
      id: "header",
      name: _discourseI18n.default.t("admin.logs.search_logs.types.header")
    }, {
      id: "full_page",
      name: _discourseI18n.default.t("admin.logs.search_logs.types.full_page")
    }, {
      id: "click_through_only",
      name: _discourseI18n.default.t("admin.logs.search_logs.types.click_through_only")
    }])();
    get chartConfig() {
      return {
        type: "bar",
        data: {
          labels: this.model.data.map(r => r.x),
          datasets: [{
            data: this.model.data.map(r => r.y),
            label: this.model.title,
            backgroundColor: "rgba(200,220,240,1)",
            borderColor: "#08C"
          }]
        },
        options: {
          responsive: true,
          plugins: {
            tooltip: {
              callbacks: {
                title: context => moment(context[0].label, "YYYY-MM-DD").format("LL")
              }
            }
          },
          scales: {
            y: [{
              display: true,
              ticks: {
                stepSize: 1
              }
            }]
          }
        }
      };
    }
  }
  _exports.default = AdminSearchLogsTermController;
});