define("admin/controllers/admin-customize-robots-txt", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "discourse/lib/ajax", "discourse/lib/computed", "discourse/mixins/buffered-content"], function (_exports, _controller, _object, _computed, _ajax, _computed2, _bufferedContent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminCustomizeRobotsTxtController extends _controller.default.extend((0, _bufferedContent.bufferedProperty)("model")) {
    saved = false;
    isSaving = false;
    static #_ = (() => dt7948.g(this.prototype, "saveDisabled", [(0, _computed2.propertyEqual)("model.robots_txt", "buffered.robots_txt")]))();
    #saveDisabled = (() => (dt7948.i(this, "saveDisabled"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "resetDisabled", [(0, _computed.not)("model.overridden")]))();
    #resetDisabled = (() => (dt7948.i(this, "resetDisabled"), void 0))();
    save() {
      this.setProperties({
        isSaving: true,
        saved: false
      });
      (0, _ajax.ajax)("robots.json", {
        type: "PUT",
        data: {
          robots_txt: this.buffered.get("robots_txt")
        }
      }).then(data => {
        this.commitBuffer();
        this.set("saved", true);
        this.set("model.overridden", data.overridden);
      }).finally(() => this.set("isSaving", false));
    }
    static #_3 = (() => dt7948.n(this.prototype, "save", [_object.action]))();
    reset() {
      this.setProperties({
        isSaving: true,
        saved: false
      });
      (0, _ajax.ajax)("robots.json", {
        type: "DELETE"
      }).then(data => {
        this.buffered.set("robots_txt", data.robots_txt);
        this.commitBuffer();
        this.set("saved", true);
        this.set("model.overridden", false);
      }).finally(() => this.set("isSaving", false));
    }
    static #_4 = (() => dt7948.n(this.prototype, "reset", [_object.action]))();
  }
  _exports.default = AdminCustomizeRobotsTxtController;
});