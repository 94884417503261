define("admin/components/admin-watched-word", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "truth-helpers", "discourse/components/d-button", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _truthHelpers, _dButton, _i18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminWatchedWord extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    get tags() {
      return this.args.word.replacement.split(",");
    }
    async deleteWord() {
      try {
        await this.args.word.destroy();
        this.args.action(this.args.word);
      } catch (e1) {
        this.dialog.alert((0, _i18n.default)("generic_error_with_reason", {
          error: `http: ${e1.status} - ${e1.body}`
        }));
      }
    }
    static #_2 = (() => dt7948.n(this.prototype, "deleteWord", [_object.action]))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="watched-word">
          <DButton
            @action={{this.deleteWord}}
            @icon="xmark"
            class="btn-transparent delete-word-record"
          />
    
          <span>{{@word.word}}</span>
    
          {{#if (or (eq @actionKey "replace") (eq @actionKey "link"))}}
            &rarr;
            <span class="replacement">{{@word.replacement}}</span>
          {{else if (eq @actionKey "tag")}}
            &rarr;
            {{#each this.tags as |tag|}}
              <span class="tag">{{tag}}</span>
            {{/each}}
          {{/if}}
    
          {{#if @word.case_sensitive}}
            <span class="case-sensitive">
              {{i18n "admin.watched_words.case_sensitive"}}
            </span>
          {{/if}}
    
          {{#if @word.html}}
            <span class="html">{{i18n "admin.watched_words.html"}}</span>
          {{/if}}
        </div>
      
    */
    {
      "id": "ys6MTMZe",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"watched-word\"],[12],[1,\"\\n      \"],[8,[32,0],[[24,0,\"btn-transparent delete-word-record\"]],[[\"@action\",\"@icon\"],[[30,0,[\"deleteWord\"]],\"xmark\"]],null],[1,\"\\n\\n      \"],[10,1],[12],[1,[30,1,[\"word\"]]],[13],[1,\"\\n\\n\"],[41,[28,[32,1],[[28,[32,2],[[30,2],\"replace\"],null],[28,[32,2],[[30,2],\"link\"],null]],null],[[[1,\"        →\\n        \"],[10,1],[14,0,\"replacement\"],[12],[1,[30,1,[\"replacement\"]]],[13],[1,\"\\n\"]],[]],[[[41,[28,[32,2],[[30,2],\"tag\"],null],[[[1,\"        →\\n\"],[42,[28,[31,2],[[28,[31,2],[[30,0,[\"tags\"]]],null]],null],null,[[[1,\"          \"],[10,1],[14,0,\"tag\"],[12],[1,[30,3]],[13],[1,\"\\n\"]],[3]],null],[1,\"      \"]],[]],null]],[]]],[1,\"\\n\"],[41,[30,1,[\"case_sensitive\"]],[[[1,\"        \"],[10,1],[14,0,\"case-sensitive\"],[12],[1,\"\\n          \"],[1,[28,[32,3],[\"admin.watched_words.case_sensitive\"],null]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,1,[\"html\"]],[[[1,\"        \"],[10,1],[14,0,\"html\"],[12],[1,[28,[32,3],[\"admin.watched_words.html\"],null]],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@word\",\"@actionKey\",\"tag\"],false,[\"if\",\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/admin/components/admin-watched-word.js",
      "scope": () => [_dButton.default, _truthHelpers.or, _truthHelpers.eq, _i18n.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AdminWatchedWord;
});