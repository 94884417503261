define("admin/components/site-settings/value-list", ["exports", "@ember/component", "@ember/template-factory"], function (_exports, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <ValueList @values={{this.value}} />
  */
  {
    "id": "HXWduNnz",
    "block": "[[[8,[39,0],null,[[\"@values\"],[[30,0,[\"value\"]]]],null]],[],false,[\"value-list\"]]",
    "moduleName": "admin/components/site-settings/value-list.hbs",
    "isStrictMode": false
  });
  class ValueList extends _component.default {}
  _exports.default = ValueList;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ValueList);
});