define("admin/controllers/admin-plugins-index", ["exports", "@ember/controller", "@ember/object", "@ember/service", "discourse/lib/ajax-error", "admin/models/site-setting"], function (_exports, _controller, _object, _service, _ajaxError, _siteSetting) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsIndexController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "session", [_service.service]))();
    #session = (() => (dt7948.i(this, "session"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "adminPluginNavManager", [_service.service]))();
    #adminPluginNavManager = (() => (dt7948.i(this, "adminPluginNavManager"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    async togglePluginEnabled(plugin) {
      const oldValue = plugin.enabled;
      const newValue = !oldValue;
      try {
        plugin.enabled = newValue;
        await _siteSetting.default.update(plugin.enabledSetting, newValue);
        this.session.requiresRefresh = true;
      } catch (e) {
        plugin.enabled = oldValue;
        (0, _ajaxError.popupAjaxError)(e);
      }
    }

    // NOTE: See also AdminPluginsController, there is some duplication here
    // while we convert plugins to use_new_show_route
    static #_4 = (() => dt7948.n(this.prototype, "togglePluginEnabled", [_object.action]))();
    get adminRoutes() {
      return this.allAdminRoutes.filter(route => this.routeExists(route));
    }
    get allAdminRoutes() {
      return this.model.filter(plugin => plugin?.enabled && plugin?.adminRoute).map(plugin => {
        return Object.assign(plugin.adminRoute, {
          plugin_id: plugin.id
        });
      });
    }
    routeExists(route) {
      try {
        if (route.use_new_show_route) {
          this.router.urlFor(route.full_location, route.location);
        } else {
          this.router.urlFor(route.full_location);
        }
        return true;
      } catch (e) {
        return false;
      }
    }
  }
  _exports.default = AdminPluginsIndexController;
});