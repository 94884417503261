define("admin/templates/user-fields", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="user-fields">
    <h2>{{i18n "admin.user_fields.title"}}</h2>
  
    <p class="desc">{{i18n "admin.user_fields.help"}}</p>
  
    {{#if this.model}}
      {{#each this.sortedFields as |uf|}}
        <AdminUserFieldItem
          @userField={{uf}}
          @fieldTypes={{this.fieldTypes}}
          @firstField={{this.firstField}}
          @lastField={{this.lastField}}
          @destroyAction={{this.destroyField}}
          @moveUpAction={{this.moveUp}}
          @moveDownAction={{this.moveDown}}
        />
      {{/each}}
    {{/if}}
  
    <DButton
      @disabled={{this.createDisabled}}
      @action={{this.createField}}
      @label="admin.user_fields.create"
      @icon="plus"
      class="btn-primary"
    />
  </div>
  */
  {
    "id": "Z2fnXROr",
    "block": "[[[10,0],[14,0,\"user-fields\"],[12],[1,\"\\n  \"],[10,\"h2\"],[12],[1,[28,[35,0],[\"admin.user_fields.title\"],null]],[13],[1,\"\\n\\n  \"],[10,2],[14,0,\"desc\"],[12],[1,[28,[35,0],[\"admin.user_fields.help\"],null]],[13],[1,\"\\n\\n\"],[41,[30,0,[\"model\"]],[[[42,[28,[37,3],[[28,[37,3],[[30,0,[\"sortedFields\"]]],null]],null],null,[[[1,\"      \"],[8,[39,4],null,[[\"@userField\",\"@fieldTypes\",\"@firstField\",\"@lastField\",\"@destroyAction\",\"@moveUpAction\",\"@moveDownAction\"],[[30,1],[30,0,[\"fieldTypes\"]],[30,0,[\"firstField\"]],[30,0,[\"lastField\"]],[30,0,[\"destroyField\"]],[30,0,[\"moveUp\"]],[30,0,[\"moveDown\"]]]],null],[1,\"\\n\"]],[1]],null]],[]],null],[1,\"\\n  \"],[8,[39,5],[[24,0,\"btn-primary\"]],[[\"@disabled\",\"@action\",\"@label\",\"@icon\"],[[30,0,[\"createDisabled\"]],[30,0,[\"createField\"]],\"admin.user_fields.create\",\"plus\"]],null],[1,\"\\n\"],[13]],[\"uf\"],false,[\"i18n\",\"if\",\"each\",\"-track-array\",\"admin-user-field-item\",\"d-button\"]]",
    "moduleName": "admin/templates/user-fields.hbs",
    "isStrictMode": false
  });
});