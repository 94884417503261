define("admin/routes/admin-embedding", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminEmbeddingRoute extends _route.default {
    model() {
      return this.store.find("embedding");
    }
    setupController(controller, model) {
      controller.set("embedding", model);
    }
  }
  _exports.default = AdminEmbeddingRoute;
});