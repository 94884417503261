define("admin/routes/admin-customize-themes-edit", ["exports", "@ember/object", "@ember/routing/route", "@ember/service", "discourse-i18n"], function (_exports, _object, _route, _service, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminCustomizeThemesEditRoute extends _route.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    model(params) {
      const all = this.modelFor("adminCustomizeThemes");
      const model = all.findBy("id", parseInt(params.theme_id, 10));
      if (model) {
        return {
          model,
          target: params.target,
          field_name: params.field_name
        };
      } else {
        this.router.replaceWith("adminCustomizeThemes.index");
      }
    }
    serialize(wrapper) {
      return {
        model: wrapper.model,
        target: wrapper.target || "common",
        field_name: wrapper.field_name || "scss",
        theme_id: wrapper.model.get("id")
      };
    }
    setupController(controller, wrapper) {
      const fields = wrapper.model.get("fields")[wrapper.target].map(f => f.name);
      if (wrapper.model.remote_theme && wrapper.model.remote_theme.is_git) {
        this.router.transitionTo("adminCustomizeThemes.index");
        return;
      }
      if (!fields.includes(wrapper.field_name)) {
        this.router.transitionTo("adminCustomizeThemes.edit", wrapper.model.id, wrapper.target, fields[0]);
        return;
      }
      controller.set("model", wrapper.model);
      controller.setTargetName(wrapper.target || "common");
      controller.set("fieldName", wrapper.field_name || "scss");
      this.controllerFor("adminCustomizeThemes").set("editingTheme", true);
      this.set("shouldAlertUnsavedChanges", true);
    }
    willTransition(transition) {
      if (this.get("controller.model.changed") && this.shouldAlertUnsavedChanges && transition.intent.name !== this.routeName) {
        transition.abort();
        this.dialog.confirm({
          message: _discourseI18n.default.t("admin.customize.theme.unsaved_changes_alert"),
          confirmButtonLabel: "admin.customize.theme.discard",
          cancelButtonLabel: "admin.customize.theme.stay",
          didConfirm: () => {
            this.set("shouldAlertUnsavedChanges", false);
            transition.retry();
          }
        });
      }
    }
    static #_3 = (() => dt7948.n(this.prototype, "willTransition", [_object.action]))();
  }
  _exports.default = AdminCustomizeThemesEditRoute;
});