define("admin/templates/web-hooks-show", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <LinkTo @route="adminWebHooks" class="go-back">
    {{d-icon "arrow-left"}}
    {{i18n "admin.web_hooks.go_back"}}
  </LinkTo>
  
  <div class="admin-webhooks__summary">
    <h1>
      {{this.model.payload_url}}
  
      <DButton
        @action={{this.edit}}
        @icon="far-pen-to-square"
        @title={{i18n "admin.web_hooks.edit"}}
        class="no-text admin-webhooks__edit-button"
      />
  
      <DButton
        @action={{this.destroyWebhook}}
        @icon="xmark"
        @title="delete"
        class="destroy btn-danger admin-webhooks__delete-button"
      />
    </h1>
  
    <div>
      <span class="admin-webhooks__description-label">
        {{i18n "admin.web_hooks.description_label"}}:
      </span>
  
      {{this.model.description}}
    </div>
  </div>
  
  <WebhookEvents @webhookId={{this.model.id}} @status={{this.status}} />
  */
  {
    "id": "MiBHH7Hj",
    "block": "[[[8,[39,0],[[24,0,\"go-back\"]],[[\"@route\"],[\"adminWebHooks\"]],[[\"default\"],[[[[1,\"\\n  \"],[1,[28,[35,1],[\"arrow-left\"],null]],[1,\"\\n  \"],[1,[28,[35,2],[\"admin.web_hooks.go_back\"],null]],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[10,0],[14,0,\"admin-webhooks__summary\"],[12],[1,\"\\n  \"],[10,\"h1\"],[12],[1,\"\\n    \"],[1,[30,0,[\"model\",\"payload_url\"]]],[1,\"\\n\\n    \"],[8,[39,3],[[24,0,\"no-text admin-webhooks__edit-button\"]],[[\"@action\",\"@icon\",\"@title\"],[[30,0,[\"edit\"]],\"far-pen-to-square\",[28,[37,2],[\"admin.web_hooks.edit\"],null]]],null],[1,\"\\n\\n    \"],[8,[39,3],[[24,0,\"destroy btn-danger admin-webhooks__delete-button\"]],[[\"@action\",\"@icon\",\"@title\"],[[30,0,[\"destroyWebhook\"]],\"xmark\",\"delete\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[12],[1,\"\\n    \"],[10,1],[14,0,\"admin-webhooks__description-label\"],[12],[1,\"\\n      \"],[1,[28,[35,2],[\"admin.web_hooks.description_label\"],null]],[1,\":\\n    \"],[13],[1,\"\\n\\n    \"],[1,[30,0,[\"model\",\"description\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@webhookId\",\"@status\"],[[30,0,[\"model\",\"id\"]],[30,0,[\"status\"]]]],null]],[],false,[\"link-to\",\"d-icon\",\"i18n\",\"d-button\",\"webhook-events\"]]",
    "moduleName": "admin/templates/web-hooks-show.hbs",
    "isStrictMode": false
  });
});