define("admin/controllers/admin-embedding", ["exports", "@ember/controller", "@ember/object", "discourse/lib/ajax-error", "discourse-common/utils/decorators"], function (_exports, _controller, _object, _ajaxError, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminEmbeddingController extends _controller.default {
    saved = false;
    embedding = null;

    // show settings if we have at least one created host
    showSecondary() {
      const hosts = this.get("embedding.embeddable_hosts");
      return hosts.length && hosts.findBy("isCreated");
    }
    static #_ = (() => dt7948.n(this.prototype, "showSecondary", [(0, _decorators.default)("embedding.embeddable_hosts.@each.isCreated")]))();
    embeddingCode(baseUrl) {
      const html = `<div id='discourse-comments'></div>
<meta name='discourse-username' content='DISCOURSE_USERNAME'>

<script type="text/javascript">
  DiscourseEmbed = {
    discourseUrl: '${baseUrl}/',
    discourseEmbedUrl: 'EMBED_URL',
    // className: 'CLASS_NAME',
  };

  (function() {
    var d = document.createElement('script'); d.type = 'text/javascript'; d.async = true;
    d.src = DiscourseEmbed.discourseUrl + 'javascripts/embed.js';
    (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(d);
  })();
</script>`;
      return html;
    }
    static #_2 = (() => dt7948.n(this.prototype, "embeddingCode", [(0, _decorators.default)("embedding.base_url")]))();
    saveChanges() {
      const embedding = this.embedding;
      const updates = embedding.getProperties(embedding.get("fields"));
      this.set("saved", false);
      this.embedding.update(updates).then(() => this.set("saved", true)).catch(_ajaxError.popupAjaxError);
    }
    static #_3 = (() => dt7948.n(this.prototype, "saveChanges", [_object.action]))();
    addHost() {
      const host = this.store.createRecord("embeddable-host");
      this.get("embedding.embeddable_hosts").pushObject(host);
    }
    static #_4 = (() => dt7948.n(this.prototype, "addHost", [_object.action]))();
    deleteHost(host) {
      this.get("embedding.embeddable_hosts").removeObject(host);
    }
    static #_5 = (() => dt7948.n(this.prototype, "deleteHost", [_object.action]))();
  }
  _exports.default = AdminEmbeddingController;
});