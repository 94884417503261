define("admin/components/schema-theme-setting/types/string", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/component", "@ember/modifier", "@ember/object", "truth-helpers", "discourse/helpers/concat-class", "discourse-i18n", "admin/components/schema-theme-setting/field-input-description", "@ember/template-factory"], function (_exports, _component, _tracking, _component2, _modifier, _object, _truthHelpers, _concatClass, _discourseI18n, _fieldInputDescription, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SchemaThemeSettingTypeString extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "touched", [_tracking.tracked], function () {
      return false;
    }))();
    #touched = (() => (dt7948.i(this, "touched"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "value", [_tracking.tracked], function () {
      return this.args.value || "";
    }))();
    #value = (() => (dt7948.i(this, "value"), void 0))();
    minLength = this.args.spec.validations?.min_length;
    maxLength = this.args.spec.validations?.max_length;
    required = this.args.spec.required;
    onInput(event1) {
      this.touched = true;
      const newValue1 = event1.currentTarget.value;
      this.args.onChange(newValue1);
      this.value = newValue1;
    }
    static #_3 = (() => dt7948.n(this.prototype, "onInput", [_object.action]))();
    get validationErrorMessage() {
      if (!this.touched) {
        return;
      }
      const valueLength1 = this.value.length;
      if (valueLength1 === 0) {
        if (this.required) {
          return _discourseI18n.default.t("admin.customize.theme.schema.fields.required");
        } else {
          return;
        }
      }
      if (this.minLength && valueLength1 < this.minLength) {
        return _discourseI18n.default.t("admin.customize.theme.schema.fields.string.too_short", {
          count: this.minLength
        });
      }
    }
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <Input
          class="--string"
          @value={{this.value}}
          {{on "input" this.onInput}}
          required={{this.required}}
          minLength={{this.minLength}}
          maxLength={{this.maxLength}}
        />
    
        <div class="schema-field__input-supporting-text">
          {{#if (and @description (not this.validationErrorMessage))}}
            <FieldInputDescription @description={{@description}} />
          {{/if}}
    
          {{#if this.validationErrorMessage}}
            <div class="schema-field__input-error">
              {{this.validationErrorMessage}}
            </div>
          {{/if}}
    
          {{#if this.maxLength}}
            <div
              class={{concatClass
                "schema-field__input-count"
                (if this.validationErrorMessage " --error")
              }}
            >
              {{this.value.length}}/{{this.maxLength}}
            </div>
          {{/if}}
        </div>
      
    */
    {
      "id": "WCQ7PlwH",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"--string\"],[16,\"required\",[30,0,[\"required\"]]],[16,\"minLength\",[30,0,[\"minLength\"]]],[16,\"maxLength\",[30,0,[\"maxLength\"]]],[4,[32,1],[\"input\",[30,0,[\"onInput\"]]],null]],[[\"@value\"],[[30,0,[\"value\"]]]],null],[1,\"\\n\\n    \"],[10,0],[14,0,\"schema-field__input-supporting-text\"],[12],[1,\"\\n\"],[41,[28,[32,2],[[30,1],[28,[32,3],[[30,0,[\"validationErrorMessage\"]]],null]],null],[[[1,\"        \"],[8,[32,4],null,[[\"@description\"],[[30,1]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"validationErrorMessage\"]],[[[1,\"        \"],[10,0],[14,0,\"schema-field__input-error\"],[12],[1,\"\\n          \"],[1,[30,0,[\"validationErrorMessage\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"maxLength\"]],[[[1,\"        \"],[10,0],[15,0,[28,[32,5],[\"schema-field__input-count\",[52,[30,0,[\"validationErrorMessage\"]],\" --error\"]],null]],[12],[1,\"\\n          \"],[1,[30,0,[\"value\",\"length\"]]],[1,\"/\"],[1,[30,0,[\"maxLength\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@description\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/admin/components/schema-theme-setting/types/string.js",
      "scope": () => [_component2.Input, _modifier.on, _truthHelpers.and, _truthHelpers.not, _fieldInputDescription.default, _concatClass.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = SchemaThemeSettingTypeString;
});