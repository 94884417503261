define("admin/components/admin-page-subheader", ["exports", "@glimmer/component", "@ember/helper", "@ember/template", "discourse-common/helpers/i18n", "admin/components/admin-page-action-button", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _template2, _i18n, _adminPageActionButton, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPageSubheader extends _component.default {
    get title() {
      if (this.args.titleLabelTranslated) {
        return this.args.titleLabelTranslated;
      } else if (this.args.titleLabel) {
        return (0, _i18n.default)(this.args.titleLabel);
      }
    }
    get description() {
      if (this.args.descriptionLabelTranslated) {
        return this.args.descriptionLabelTranslated;
      } else if (this.args.descriptionLabel) {
        return (0, _i18n.default)(this.args.descriptionLabel);
      }
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="admin-page-subheader">
          <div class="admin-page-subheader__title-row">
            <h3 class="admin-page-subheader__title">{{this.title}}</h3>
            <div class="admin-page-subheader__actions">
              {{yield
                (hash
                  Primary=PrimaryButton Default=DefaultButton Danger=DangerButton
                )
                to="actions"
              }}
            </div>
          </div>
    
          {{#if this.description}}
            <p class="admin-page-subheader__description">
              {{htmlSafe this.description}}
              {{#if @learnMoreUrl}}
                <span class="admin-page-subheader__learn-more">
                  {{htmlSafe
                    (i18n "learn_more_with_link" url=@learnMoreUrl)
                  }}</span>
              {{/if}}
            </p>
          {{/if}}
        </div>
      
    */
    {
      "id": "SR3eJphu",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"admin-page-subheader\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"admin-page-subheader__title-row\"],[12],[1,\"\\n        \"],[10,\"h3\"],[14,0,\"admin-page-subheader__title\"],[12],[1,[30,0,[\"title\"]]],[13],[1,\"\\n        \"],[10,0],[14,0,\"admin-page-subheader__actions\"],[12],[1,\"\\n          \"],[18,2,[[28,[32,0],null,[[\"Primary\",\"Default\",\"Danger\"],[[32,1],[32,2],[32,3]]]]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"description\"]],[[[1,\"        \"],[10,2],[14,0,\"admin-page-subheader__description\"],[12],[1,\"\\n          \"],[1,[28,[32,4],[[30,0,[\"description\"]]],null]],[1,\"\\n\"],[41,[30,1],[[[1,\"            \"],[10,1],[14,0,\"admin-page-subheader__learn-more\"],[12],[1,\"\\n              \"],[1,[28,[32,4],[[28,[32,5],[\"learn_more_with_link\"],[[\"url\"],[[30,1]]]]],null]],[13],[1,\"\\n\"]],[]],null],[1,\"        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@learnMoreUrl\",\"&actions\"],false,[\"yield\",\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/admin/components/admin-page-subheader.js",
      "scope": () => [_helper.hash, _adminPageActionButton.PrimaryButton, _adminPageActionButton.DefaultButton, _adminPageActionButton.DangerButton, _template2.htmlSafe, _i18n.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AdminPageSubheader;
});