define("admin/controllers/admin-reports-show", ["exports", "@ember/controller", "discourse-common/utils/decorators"], function (_exports, _controller, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminReportsShowController extends _controller.default {
    queryParams = ["start_date", "end_date", "filters", "chart_grouping", "mode"];
    start_date = null;
    end_date = null;
    filters = null;
    chart_grouping = null;
    reportOptions(type) {
      let options = {
        table: {
          perPage: 50,
          limit: 50,
          formatNumbers: false
        }
      };
      if (type === "top_referred_topics") {
        options.table.limit = 10;
      }
      if (type === "site_traffic") {
        options.stackedChart = {
          hiddenLabels: ["page_view_other", "page_view_crawler"]
        };
      }
      options.chartGrouping = this.chart_grouping;
      return options;
    }
    static #_ = (() => dt7948.n(this.prototype, "reportOptions", [(0, _decorators.default)("model.type")]))();
  }
  _exports.default = AdminReportsShowController;
});