define("admin/components/site-customization-change-field", ["exports", "@ember/component", "@ember/template-factory"], function (_exports, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.field}}
    <section class="field">
      <b>{{i18n this.name}}</b>: ({{i18n
        "character_count"
        count=this.field.length
      }})
      <br />
      <Textarea @value={{this.field}} class="plain" />
    </section>
  {{/if}}
  */
  {
    "id": "a8G4Kimu",
    "block": "[[[41,[30,0,[\"field\"]],[[[1,\"  \"],[10,\"section\"],[14,0,\"field\"],[12],[1,\"\\n    \"],[10,\"b\"],[12],[1,[28,[35,1],[[30,0,[\"name\"]]],null]],[13],[1,\": (\"],[1,[28,[35,1],[\"character_count\"],[[\"count\"],[[30,0,[\"field\",\"length\"]]]]]],[1,\")\\n    \"],[10,\"br\"],[12],[13],[1,\"\\n    \"],[8,[39,2],[[24,0,\"plain\"]],[[\"@value\"],[[30,0,[\"field\"]]]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"i18n\",\"textarea\"]]",
    "moduleName": "admin/components/site-customization-change-field.hbs",
    "isStrictMode": false
  });
  class SiteCustomizationChangeField extends _component.default {}
  _exports.default = SiteCustomizationChangeField;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SiteCustomizationChangeField);
});