define("admin/routes/admin-customize-themes-show", ["exports", "@ember/object", "@ember/routing/route", "@ember/service", "discourse/mixins/scroll-top", "discourse-i18n", "admin/models/theme"], function (_exports, _object, _route, _service, _scrollTop, _discourseI18n, _theme) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminCustomizeThemesShowRoute extends _route.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    serialize(model) {
      return {
        theme_id: model.get("id")
      };
    }
    model(params) {
      const all = this.modelFor("adminCustomizeThemes");
      const model = all.findBy("id", parseInt(params.theme_id, 10));
      if (model) {
        return model;
      } else {
        this.router.replaceWith("adminCustomizeThemes.index");
      }
    }
    setupController(controller, model) {
      super.setupController(...arguments);
      const parentController = this.controllerFor("adminCustomizeThemes");
      parentController.setProperties({
        editingTheme: false,
        currentTab: model.get("component") ? _theme.COMPONENTS : _theme.THEMES
      });
      controller.setProperties({
        model,
        parentController,
        allThemes: parentController.get("model"),
        colorSchemeId: model.get("color_scheme_id"),
        colorSchemes: parentController.get("model.extras.color_schemes"),
        editingName: false,
        editingThemeSetting: false,
        userLocale: parentController.get("model.extras.locale")
      });
      this.handleHighlight(model);
    }
    deactivate() {
      this.handleHighlight();
    }
    handleHighlight(theme) {
      this.get("controller.allThemes").filter(t => t.get("selected")).forEach(t => t.set("selected", false));
      if (theme) {
        theme.set("selected", true);
      }
    }
    didTransition() {
      (0, _scrollTop.scrollTop)();
    }
    static #_3 = (() => dt7948.n(this.prototype, "didTransition", [_object.action]))();
    willTransition(transition) {
      const model = this.controller.model;
      if (model.warnUnassignedComponent) {
        transition.abort();
        this.dialog.yesNoConfirm({
          message: _discourseI18n.default.t("admin.customize.theme.unsaved_parent_themes"),
          didConfirm: () => {
            model.set("recentlyInstalled", false);
            transition.retry();
          },
          didCancel: () => model.set("recentlyInstalled", false)
        });
      }
    }
    static #_4 = (() => dt7948.n(this.prototype, "willTransition", [_object.action]))();
  }
  _exports.default = AdminCustomizeThemesShowRoute;
});