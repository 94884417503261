define("admin/routes/admin-config-flags-edit", ["exports", "@ember/service", "discourse/routes/discourse", "discourse-i18n"], function (_exports, _service, _discourse, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminConfigFlagsEditRoute extends _discourse.default {
    static #_ = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    model(params) {
      return this.site.flagTypes.findBy("id", parseInt(params.flag_id, 10));
    }
    titleToken() {
      return _discourseI18n.default.t("admin.config_areas.flags.edit_header");
    }
  }
  _exports.default = AdminConfigFlagsEditRoute;
});