define("admin/routes/admin-backups-index", ["exports", "@ember/routing/route", "discourse-common/utils/decorators", "admin/models/backup"], function (_exports, _route, _decorators, _backup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminBackupsIndexRoute extends _route.default {
    activate() {
      this.messageBus.subscribe("/admin/backups", this.onMessage);
    }
    deactivate() {
      this.messageBus.unsubscribe("/admin/backups", this.onMessage);
    }
    async model() {
      const backups = await _backup.default.find();
      return backups.map(backup => _backup.default.create(backup));
    }
    onMessage(backups) {
      this.controller.set("model", backups.map(backup => _backup.default.create(backup)));
    }
    static #_ = (() => dt7948.n(this.prototype, "onMessage", [_decorators.bind]))();
  }
  _exports.default = AdminBackupsIndexRoute;
});