define("admin/routes/admin-backups", ["exports", "@ember/object", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/preload-store", "discourse/routes/discourse", "discourse-common/lib/get-url", "discourse-common/utils/decorators", "discourse-i18n", "admin/components/modal/start-backup", "admin/models/backup", "admin/models/backup-status"], function (_exports, _object, _service, _ajax, _ajaxError, _preloadStore, _discourse, _getUrl, _decorators, _discourseI18n, _startBackup2, _backup, _backupStatus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const LOG_CHANNEL = "/admin/backups/logs";
  class AdminBackupsRoute extends _discourse.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "messageBus", [_service.service]))();
    #messageBus = (() => (dt7948.i(this, "messageBus"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    titleToken() {
      return _discourseI18n.default.t("admin.backups.title");
    }
    activate() {
      this.messageBus.subscribe(LOG_CHANNEL, this.onMessage);
    }
    deactivate() {
      this.messageBus.unsubscribe(LOG_CHANNEL, this.onMessage);
    }
    async model() {
      const status = await _preloadStore.default.getAndRemove("operations_status", () => (0, _ajax.ajax)("/admin/backups/status.json"));
      return _backupStatus.default.create({
        isOperationRunning: status.is_operation_running,
        canRollback: status.can_rollback,
        allowRestore: status.allow_restore
      });
    }
    onMessage(log) {
      if (log.message === "[STARTED]") {
        this.currentUser.set("hideReadOnlyAlert", true);
        this.controllerFor("adminBackups").set("model.isOperationRunning", true);
        this.controllerFor("adminBackupsLogs").get("logs").clear();
      } else if (log.message === "[FAILED]") {
        this.controllerFor("adminBackups").set("model.isOperationRunning", false);
        this.dialog.alert(_discourseI18n.default.t("admin.backups.operations.failed", {
          operation: log.operation
        }));
      } else if (log.message === "[SUCCESS]") {
        this.currentUser.set("hideReadOnlyAlert", false);
        this.controllerFor("adminBackups").set("model.isOperationRunning", false);
        if (log.operation === "restore") {
          // redirect to homepage when the restore is done (session might be lost)
          window.location = (0, _getUrl.default)("/");
        }
      } else {
        this.controllerFor("adminBackupsLogs").get("logs").pushObject(_object.default.create(log));
      }
    }
    static #_6 = (() => dt7948.n(this.prototype, "onMessage", [_decorators.bind]))();
    showStartBackupModal() {
      this.modal.show(_startBackup2.default, {
        model: {
          startBackup: this.startBackup
        }
      });
    }
    static #_7 = (() => dt7948.n(this.prototype, "showStartBackupModal", [_object.action]))();
    startBackup(withUploads) {
      this.router.transitionTo("admin.backups.logs");
      _backup.default.start(withUploads).then(result => {
        if (!result.success) {
          this.dialog.alert(result.message);
        }
      });
    }
    static #_8 = (() => dt7948.n(this.prototype, "startBackup", [_object.action]))();
    destroyBackup(backup) {
      return this.dialog.yesNoConfirm({
        message: _discourseI18n.default.t("admin.backups.operations.destroy.confirm"),
        didConfirm: () => {
          backup.destroy().then(() => this.controllerFor("adminBackupsIndex").get("model").removeObject(backup));
        }
      });
    }
    static #_9 = (() => dt7948.n(this.prototype, "destroyBackup", [_object.action]))();
    startRestore(backup) {
      this.dialog.yesNoConfirm({
        message: _discourseI18n.default.t("admin.backups.operations.restore.confirm"),
        didConfirm: () => {
          this.router.transitionTo("admin.backups.logs");
          backup.restore();
        }
      });
    }
    static #_10 = (() => dt7948.n(this.prototype, "startRestore", [_object.action]))();
    cancelOperation() {
      this.dialog.yesNoConfirm({
        message: _discourseI18n.default.t("admin.backups.operations.cancel.confirm"),
        didConfirm: () => {
          _backup.default.cancel().then(() => {
            this.controllerFor("adminBackups").set("model.isOperationRunning", false);
          });
        }
      });
    }
    static #_11 = (() => dt7948.n(this.prototype, "cancelOperation", [_object.action]))();
    rollback() {
      return this.dialog.yesNoConfirm({
        message: _discourseI18n.default.t("admin.backups.operations.rollback.confirm"),
        didConfirm: () => {
          _backup.default.rollback().then(result => {
            if (!result.success) {
              this.dialog.alert(result.message);
            } else {
              // redirect to homepage (session might be lost)
              window.location = (0, _getUrl.default)("/");
            }
          });
        }
      });
    }
    static #_12 = (() => dt7948.n(this.prototype, "rollback", [_object.action]))();
    uploadSuccess(filename) {
      this.dialog.alert(_discourseI18n.default.t("admin.backups.upload.success", {
        filename
      }));
    }
    static #_13 = (() => dt7948.n(this.prototype, "uploadSuccess", [_object.action]))();
    uploadError(filename, message) {
      this.dialog.alert(_discourseI18n.default.t("admin.backups.upload.error", {
        filename,
        message
      }));
    }
    static #_14 = (() => dt7948.n(this.prototype, "uploadError", [_object.action]))();
    remoteUploadSuccess() {
      _backup.default.find().then(backups => backups.map(backup => _backup.default.create(backup))).then(backups => {
        this.controllerFor("adminBackupsIndex").set("model", backups.map(backup => _backup.default.create(backup)));
      }).catch(error => {
        this.dialog.alert(_discourseI18n.default.t("admin.backups.backup_storage_error", {
          error_message: (0, _ajaxError.extractError)(error)
        }));
        return [];
      });
    }
    static #_15 = (() => dt7948.n(this.prototype, "remoteUploadSuccess", [_object.action]))();
  }
  _exports.default = AdminBackupsRoute;
});