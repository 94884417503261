define("admin/components/modal/merge-users-prompt", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    @title={{html-safe
      (i18n "admin.user.merge.prompt.title" username=@model.user.username)
    }}
    @closeModal={{@closeModal}}
  >
    <:body>
      <p>
        {{html-safe
          (i18n
            "admin.user.merge.prompt.description" username=@model.user.username
          )
        }}
      </p>
      <EmailGroupUserChooser
        @value={{this.targetUsername}}
        @options={{hash
          maximum=1
          filterPlaceholder="admin.user.merge.prompt.target_username_placeholder"
        }}
      />
    </:body>
    <:footer>
      <DButton
        class="btn-primary"
        @action={{fn @model.showMergeConfirmation (get this.targetUsername "0")}}
        @icon="trash-can"
        @disabled={{this.mergeDisabled}}
        @translatedLabel={{i18n
          "admin.user.merge.confirmation.transfer_and_delete"
          username=@model.user.username
        }}
      />
      <DButton @action={{@closeModal}} @label="admin.user.merge.prompt.cancel" />
    </:footer>
  </DModal>
  */
  {
    "id": "hcBXBSv7",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@closeModal\"],[[28,[37,1],[[28,[37,2],[\"admin.user.merge.prompt.title\"],[[\"username\"],[[30,1,[\"user\",\"username\"]]]]]],null],[30,2]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[10,2],[12],[1,\"\\n      \"],[1,[28,[35,1],[[28,[37,2],[\"admin.user.merge.prompt.description\"],[[\"username\"],[[30,1,[\"user\",\"username\"]]]]]],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[8,[39,3],null,[[\"@value\",\"@options\"],[[30,0,[\"targetUsername\"]],[28,[37,4],null,[[\"maximum\",\"filterPlaceholder\"],[1,\"admin.user.merge.prompt.target_username_placeholder\"]]]]],null],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,5],[[24,0,\"btn-primary\"]],[[\"@action\",\"@icon\",\"@disabled\",\"@translatedLabel\"],[[28,[37,6],[[30,1,[\"showMergeConfirmation\"]],[28,[37,7],[[30,0,[\"targetUsername\"]],\"0\"],null]],null],\"trash-can\",[30,0,[\"mergeDisabled\"]],[28,[37,2],[\"admin.user.merge.confirmation.transfer_and_delete\"],[[\"username\"],[[30,1,[\"user\",\"username\"]]]]]]],null],[1,\"\\n    \"],[8,[39,5],null,[[\"@action\",\"@label\"],[[30,2],\"admin.user.merge.prompt.cancel\"]],null],[1,\"\\n  \"]],[]]]]]],[\"@model\",\"@closeModal\"],false,[\"d-modal\",\"html-safe\",\"i18n\",\"email-group-user-chooser\",\"hash\",\"d-button\",\"fn\",\"get\"]]",
    "moduleName": "admin/components/modal/merge-users-prompt.hbs",
    "isStrictMode": false
  });
  class MergeUsersPrompt extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "targetUsername", [_tracking.tracked]))();
    #targetUsername = (() => (dt7948.i(this, "targetUsername"), void 0))();
    get mergeDisabled() {
      return !this.targetUsername || this.args.model.user.username === this.targetUsername[0];
    }
  }
  _exports.default = MergeUsersPrompt;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, MergeUsersPrompt);
});