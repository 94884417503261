define("admin/components/site-text-summary", ["exports", "@glimmer/component", "@ember/helper", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "discourse/components/d-button", "discourse/helpers/concat-class", "discourse/lib/highlight-html", "discourse-common/utils/decorators", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _object, _didInsert, _dButton, _concatClass, _highlightHtml, _decorators, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SiteTextSummary extends _component.default {
    highlightSearchTerm(element1) {
      const term1 = this.#searchTerm();
      if (term1) {
        (0, _highlightHtml.default)(element1.querySelector(".site-text-id, .site-text-value"), term1, {
          className: "text-highlight"
        });
      }
    }
    static #_ = (() => dt7948.n(this.prototype, "highlightSearchTerm", [_object.action]))();
    onClick() {
      this.args.editAction(this.siteText);
    }
    static #_2 = (() => dt7948.n(this.prototype, "onClick", [_object.action]))();
    #searchTerm() {
      const regex1 = this.args.searchRegex;
      const siteText1 = this.args.siteText;
      if (regex1 && siteText1) {
        const matches1 = siteText1.value.match(new RegExp(regex1, "i"));
        if (matches1) {
          return matches1[0];
        }
      }
      return this.args.term;
    }
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div
          class={{concatClass "site-text" (if @siteText.overridden "overridden")}}
          {{didInsert this.highlightSearchTerm}}
          data-site-text-id={{@siteText.id}}
        >
          <DButton
            @label="admin.site_text.edit"
            @action={{fn @editAction @siteText}}
            class="btn-default site-text-edit"
          />
          <h3 class="site-text-id">{{@siteText.id}}</h3>
          <div class="site-text-value">{{@siteText.value}}</div>
    
          <div class="clearfix"></div>
        </div>
      
    */
    {
      "id": "Ka4ukixz",
      "block": "[[[1,\"\\n    \"],[11,0],[16,0,[28,[32,0],[\"site-text\",[52,[30,1,[\"overridden\"]],\"overridden\"]],null]],[16,\"data-site-text-id\",[30,1,[\"id\"]]],[4,[32,1],[[30,0,[\"highlightSearchTerm\"]]],null],[12],[1,\"\\n      \"],[8,[32,2],[[24,0,\"btn-default site-text-edit\"]],[[\"@label\",\"@action\"],[\"admin.site_text.edit\",[28,[32,3],[[30,2],[30,1]],null]]],null],[1,\"\\n      \"],[10,\"h3\"],[14,0,\"site-text-id\"],[12],[1,[30,1,[\"id\"]]],[13],[1,\"\\n      \"],[10,0],[14,0,\"site-text-value\"],[12],[1,[30,1,[\"value\"]]],[13],[1,\"\\n\\n      \"],[10,0],[14,0,\"clearfix\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@siteText\",\"@editAction\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/admin/components/site-text-summary.js",
      "scope": () => [_concatClass.default, _didInsert.default, _dButton.default, _helper.fn],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = SiteTextSummary;
});