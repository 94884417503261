define("admin/templates/watched-words", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="admin-contents">
    <div class="admin-controls">
      <div class="controls">
        <div class="inline-form">
          <DButton @action={{this.toggleMenu}} @icon="bars" class="menu-toggle" />
          <TextField
            @value={{this.filter}}
            @placeholderKey="admin.watched_words.search"
            class="no-blur"
          />
          <DButton
            @action={{this.clearFilter}}
            @label="admin.watched_words.clear_filter"
          />
        </div>
      </div>
    </div>
  
    <div class="admin-nav pull-left">
      <ul class="nav nav-stacked">
        {{#each this.model as |action|}}
          <li class={{action.nameKey}}>
            <LinkTo @route="adminWatchedWords.action" @model={{action.nameKey}}>
              {{action.name}}
              {{#if action.words}}<span
                  class="count"
                >({{action.words.length}})</span>{{/if}}
            </LinkTo>
          </li>
        {{/each}}
      </ul>
    </div>
  
    <div class="admin-detail pull-left mobile-closed watched-words-detail">
      {{outlet}}
    </div>
  
    <div class="clearfix"></div>
  </div>
  */
  {
    "id": "INGd3fav",
    "block": "[[[10,0],[14,0,\"admin-contents\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"admin-controls\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"inline-form\"],[12],[1,\"\\n        \"],[8,[39,0],[[24,0,\"menu-toggle\"]],[[\"@action\",\"@icon\"],[[30,0,[\"toggleMenu\"]],\"bars\"]],null],[1,\"\\n        \"],[8,[39,1],[[24,0,\"no-blur\"]],[[\"@value\",\"@placeholderKey\"],[[30,0,[\"filter\"]],\"admin.watched_words.search\"]],null],[1,\"\\n        \"],[8,[39,0],null,[[\"@action\",\"@label\"],[[30,0,[\"clearFilter\"]],\"admin.watched_words.clear_filter\"]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"admin-nav pull-left\"],[12],[1,\"\\n    \"],[10,\"ul\"],[14,0,\"nav nav-stacked\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"model\"]]],null]],null],null,[[[1,\"        \"],[10,\"li\"],[15,0,[30,1,[\"nameKey\"]]],[12],[1,\"\\n          \"],[8,[39,4],null,[[\"@route\",\"@model\"],[\"adminWatchedWords.action\",[30,1,[\"nameKey\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,1,[\"name\"]]],[1,\"\\n            \"],[41,[30,1,[\"words\"]],[[[10,1],[14,0,\"count\"],[12],[1,\"(\"],[1,[30,1,[\"words\",\"length\"]]],[1,\")\"],[13]],[]],null],[1,\"\\n          \"]],[]]]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"admin-detail pull-left mobile-closed watched-words-detail\"],[12],[1,\"\\n    \"],[46,[28,[37,7],null,null],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"clearfix\"],[12],[13],[1,\"\\n\"],[13]],[\"action\"],false,[\"d-button\",\"text-field\",\"each\",\"-track-array\",\"link-to\",\"if\",\"component\",\"-outlet\"]]",
    "moduleName": "admin/templates/watched-words.hbs",
    "isStrictMode": false
  });
});