define("admin/templates/reports-show", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <AdminReport
    @showAllReportsLink={{true}}
    @dataSourceName={{this.model.type}}
    @filters={{this.model}}
    @reportOptions={{this.reportOptions}}
    @showFilteringUI={{true}}
    @onRefresh={{route-action "onParamsChange"}}
  />
  */
  {
    "id": "AH3RjilN",
    "block": "[[[8,[39,0],null,[[\"@showAllReportsLink\",\"@dataSourceName\",\"@filters\",\"@reportOptions\",\"@showFilteringUI\",\"@onRefresh\"],[true,[30,0,[\"model\",\"type\"]],[30,0,[\"model\"]],[30,0,[\"reportOptions\"]],true,[28,[37,1],[\"onParamsChange\"],null]]],null]],[],false,[\"admin-report\",\"route-action\"]]",
    "moduleName": "admin/templates/reports-show.hbs",
    "isStrictMode": false
  });
});