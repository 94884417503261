define("admin/components/theme-settings-editor", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse-i18n", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _discourseI18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="settings-editor">
    <div>
      <AceEditor
        @content={{this.editedContent}}
        @onChange={{fn (mut this.editedContent)}}
        @mode="html"
      />
  
      {{#each this.errors as |error|}}
        <div class="validation-error">
          {{d-icon "xmark"}}
          <b>{{error.setting}}</b>:
          {{error.errorMessage}}
        </div>
      {{/each}}
    </div>
  
    <div class="buttons">
      <DButton
        @action={{this.save}}
        id="save"
        class="btn-primary save"
        @disabled={{this.saveButtonDisabled}}
        @translatedLabel={{i18n "admin.customize.theme.save"}}
      />
    </div>
  </div>
  */
  {
    "id": "wm/ay5i3",
    "block": "[[[10,0],[14,0,\"settings-editor\"],[12],[1,\"\\n  \"],[10,0],[12],[1,\"\\n    \"],[8,[39,0],null,[[\"@content\",\"@onChange\",\"@mode\"],[[30,0,[\"editedContent\"]],[28,[37,1],[[28,[37,2],[[30,0,[\"editedContent\"]]],null]],null],\"html\"]],null],[1,\"\\n\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"errors\"]]],null]],null],null,[[[1,\"      \"],[10,0],[14,0,\"validation-error\"],[12],[1,\"\\n        \"],[1,[28,[35,5],[\"xmark\"],null]],[1,\"\\n        \"],[10,\"b\"],[12],[1,[30,1,[\"setting\"]]],[13],[1,\":\\n        \"],[1,[30,1,[\"errorMessage\"]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"buttons\"],[12],[1,\"\\n    \"],[8,[39,6],[[24,1,\"save\"],[24,0,\"btn-primary save\"]],[[\"@action\",\"@disabled\",\"@translatedLabel\"],[[30,0,[\"save\"]],[30,0,[\"saveButtonDisabled\"]],[28,[37,7],[\"admin.customize.theme.save\"],null]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"error\"],false,[\"ace-editor\",\"fn\",\"mut\",\"each\",\"-track-array\",\"d-icon\",\"d-button\",\"i18n\"]]",
    "moduleName": "admin/components/theme-settings-editor.hbs",
    "isStrictMode": false
  });
  class ThemeSettingsEditor extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "editedContent", [_tracking.tracked], function () {
      return JSON.stringify(this.condensedThemeSettings, null, "\t");
    }))();
    #editedContent = (() => (dt7948.i(this, "editedContent"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "errors", [_tracking.tracked], function () {
      return [];
    }))();
    #errors = (() => (dt7948.i(this, "errors"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "saving", [_tracking.tracked], function () {
      return false;
    }))();
    #saving = (() => (dt7948.i(this, "saving"), void 0))();
    // we need to store the controller being passed in so that when we
    // call `save` we have not lost context of the argument
    customizeThemeShowController = this.args.model?.controller;
    get saveButtonDisabled() {
      return !this.documentChanged || this.saving;
    }
    get documentChanged() {
      try {
        if (!this.editedContent) {
          return false;
        }
        const editedContentString = JSON.stringify(JSON.parse(this.editedContent));
        const themeSettingsString = JSON.stringify(this.condensedThemeSettings);
        if (editedContentString.localeCompare(themeSettingsString) !== 0) {
          this.errors = [];
          return true;
        } else {
          return false;
        }
      } catch {
        return true;
      }
    }
    get theme() {
      return this.args.model?.model;
    }
    get condensedThemeSettings() {
      if (!this.theme) {
        return null;
      }
      return this.theme.settings.map(setting => ({
        setting: setting.setting,
        value: setting.value
      }));
    }

    // validates the following:
    // each setting must have a 'setting' and a 'value' key and no other keys
    validateSettingsKeys(settings) {
      return settings.reduce((acc, setting) => {
        if (!acc) {
          return acc;
        }
        if (!("setting" in setting)) {
          // must have a setting key
          return false;
        }
        if (!("value" in setting)) {
          // must have a value key
          return false;
        }
        if (Object.keys(setting).length > 2) {
          // at this point it's verified to have setting and value key - but must have no other keys
          return false;
        }
        return true;
      }, true);
    }
    async save() {
      this.saving = true;
      this.errors = [];
      this.success = "";
      if (!this.editedContent) {
        // no changes.
        return;
      }
      let newSettings = "";
      try {
        newSettings = JSON.parse(this.editedContent);
      } catch (e) {
        this.errors = [...this.errors, {
          setting: _discourseI18n.default.t("admin.customize.syntax_error"),
          errorMessage: e.message
        }];
        this.saving = false;
        return;
      }
      if (!this.validateSettingsKeys(newSettings)) {
        this.errors = [...this.errors, {
          setting: _discourseI18n.default.t("admin.customize.syntax_error"),
          errorMessage: _discourseI18n.default.t("admin.customize.validation_settings_keys")
        }];
        this.saving = false;
        return;
      }
      const originalNames = this.theme ? this.theme.settings.map(setting => setting.setting) : [];
      const newNames = newSettings.map(setting => setting.setting);
      const deletedNames = originalNames.filter(originalName => !newNames.find(newName => newName === originalName));
      const addedNames = newNames.filter(newName => !originalNames.find(originalName => originalName === newName));
      if (deletedNames.length) {
        this.errors = [...this.errors, {
          setting: deletedNames.join(", "),
          errorMessage: _discourseI18n.default.t("admin.customize.validation_settings_deleted")
        }];
      }
      if (addedNames.length) {
        this.errors = [...this.errors, {
          setting: addedNames.join(","),
          errorMessage: _discourseI18n.default.t("admin.customize.validation_settings_added")
        }];
      }
      if (this.errors.length) {
        this.saving = false;
        return;
      }
      const changedSettings = [];
      newSettings.forEach(newSetting => {
        const originalSetting = this.theme.settings.find(_originalSetting => _originalSetting.setting === newSetting.setting);
        if (originalSetting.value !== newSetting.value) {
          changedSettings.push({
            originalSetting,
            value: newSetting.value
          });
        }
      });
      for (let changedSetting of changedSettings) {
        try {
          await this.saveSetting(this.theme.id, changedSetting);
        } catch (err) {
          const errorObjects = JSON.parse(err.jqXHR.responseText).errors.map(error => ({
            setting: changedSetting.originalSetting.setting,
            errorMessage: error
          }));
          this.errors = [...this.errors, ...errorObjects];
        }
      }
      if (this.errors.length === 0) {
        this.editedContent = null;
      }
      this.saving = false;
      this.dialog.cancel();
      this.customizeThemeShowController.send("routeRefreshModel");
    }
    static #_5 = (() => dt7948.n(this.prototype, "save", [_object.action]))();
    async saveSetting(themeId, changedSetting) {
      return await changedSetting.originalSetting.updateSetting(themeId, changedSetting.value);
    }
  }
  _exports.default = ThemeSettingsEditor;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ThemeSettingsEditor);
});