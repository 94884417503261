define("admin/mixins/setting-component", ["exports", "@ember/debug", "@ember/object", "@ember/object/computed", "@ember/object/mixin", "@ember/service", "@ember/template", "@ember/utils", "rsvp", "discourse/components/modal/json-schema-editor", "discourse/lib/ajax", "discourse/lib/computed", "discourse/lib/constants", "discourse/lib/utilities", "discourse-common/lib/object", "discourse-i18n", "admin/components/modal/site-setting-default-categories"], function (_exports, _debug, _object, _computed, _mixin, _service, _template, _utils, _rsvp, _jsonSchemaEditor, _ajax, _computed2, _constants, _utilities, _object2, _discourseI18n, _siteSettingDefaultCategories) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CUSTOM_TYPES = ["bool", "integer", "enum", "list", "url_list", "host_list", "category_list", "value_list", "category", "uploaded_image_list", "compact_list", "secret_list", "upload", "group_list", "tag_list", "tag_group_list", "color", "simple_list", "emoji_list", "named_list", "file_size_restriction", "file_types_list"];
  const AUTO_REFRESH_ON_SAVE = ["logo", "logo_small", "large_icon"];
  const DEFAULT_USER_PREFERENCES = ["default_email_digest_frequency", "default_include_tl0_in_digests", "default_email_level", "default_email_messages_level", "default_email_mailing_list_mode", "default_email_mailing_list_mode_frequency", "default_email_previous_replies", "default_email_in_reply_to", "default_hide_profile_and_presence", "default_other_new_topic_duration_minutes", "default_other_auto_track_topics_after_msecs", "default_other_notification_level_when_replying", "default_other_external_links_in_new_tab", "default_other_enable_quoting", "default_other_enable_defer", "default_other_dynamic_favicon", "default_other_like_notification_frequency", "default_other_skip_new_user_tips", "default_topics_automatic_unpin", "default_categories_watching", "default_categories_tracking", "default_categories_muted", "default_categories_watching_first_post", "default_categories_normal", "default_tags_watching", "default_tags_tracking", "default_tags_muted", "default_tags_watching_first_post", "default_text_size", "default_title_count_mode", "default_navigation_menu_categories", "default_navigation_menu_tags", "default_sidebar_link_to_filtered_list", "default_sidebar_show_count_of_new_items"];
  var _default = _exports.default = _mixin.default.create({
    modal: (0, _service.service)(),
    router: (0, _service.service)(),
    site: (0, _service.service)(),
    dialog: (0, _service.service)(),
    attributeBindings: ["setting.setting:data-setting"],
    classNameBindings: [":row", ":setting", "overridden", "typeClass"],
    validationMessage: null,
    setting: null,
    content: (0, _computed.alias)("setting"),
    isSecret: (0, _computed.oneWay)("setting.secret"),
    componentName: (0, _computed2.fmt)("typeClass", "site-settings/%@"),
    overridden: (0, _computed2.propertyNotEqual)("setting.default", "buffered.value"),
    didInsertElement() {
      this._super(...arguments);
      this.element.addEventListener("keydown", this._handleKeydown);
    },
    willDestroyElement() {
      this._super(...arguments);
      this.element.removeEventListener("keydown", this._handleKeydown);
    },
    displayDescription: (0, _object.computed)("componentType", function () {
      return this.componentType !== "bool";
    }),
    dirty: (0, _object.computed)("buffered.value", "setting.value", function () {
      let bufferVal = this.get("buffered.value");
      let settingVal = this.setting?.value;
      if ((0, _utils.isNone)(bufferVal)) {
        bufferVal = "";
      }
      if ((0, _utils.isNone)(settingVal)) {
        settingVal = "";
      }
      return !(0, _object2.deepEqual)(bufferVal, settingVal);
    }),
    preview: (0, _object.computed)("setting", "buffered.value", function () {
      const setting = this.setting;
      const value = this.get("buffered.value");
      const preview = setting.preview;
      if (preview) {
        const escapedValue = preview.replace(/\{\{value\}\}/g, value);
        return (0, _template.htmlSafe)(`<div class='preview'>${escapedValue}</div>`);
      }
    }),
    typeClass: (0, _object.computed)("componentType", function () {
      const componentType = this.componentType;
      return componentType.replace(/\_/g, "-");
    }),
    settingName: (0, _object.computed)("setting.setting", "setting.label", function () {
      const setting = this.setting?.setting;
      const label = this.setting?.label;
      return label || setting.replace(/\_/g, " ");
    }),
    componentType: (0, _object.computed)("type", function () {
      const type = this.type;
      return CUSTOM_TYPES.includes(type) ? type : "string";
    }),
    type: (0, _object.computed)("setting", function () {
      const setting = this.setting;
      if (setting.type === "list" && setting.list_type) {
        return `${setting.list_type}_list`;
      }
      return setting.type;
    }),
    allowAny: (0, _object.computed)("setting.anyValue", function () {
      const anyValue = this.setting?.anyValue;
      return anyValue !== false;
    }),
    bufferedValues: (0, _object.computed)("buffered.value", function () {
      const value = this.get("buffered.value");
      return (0, _utilities.splitString)(value, "|");
    }),
    defaultValues: (0, _object.computed)("setting.defaultValues", function () {
      const value = this.setting?.defaultValues;
      return (0, _utilities.splitString)(value, "|");
    }),
    defaultIsAvailable: (0, _object.computed)("defaultValues", "bufferedValues", function () {
      const defaultValues = this.defaultValues;
      const bufferedValues = this.bufferedValues;
      return defaultValues.length > 0 && !defaultValues.every(value => bufferedValues.includes(value));
    }),
    settingEditButton: (0, _object.computed)("setting", function () {
      const setting = this.setting;
      if (setting.json_schema) {
        return {
          action: () => {
            this.modal.show(_jsonSchemaEditor.default, {
              model: {
                updateValue: value => {
                  this.buffered.set("value", value);
                },
                value: this.buffered.get("value"),
                settingName: setting.setting,
                jsonSchema: setting.json_schema
              }
            });
          },
          label: "admin.site_settings.json_schema.edit",
          icon: "pencil"
        };
      } else if (setting.objects_schema) {
        return {
          action: () => {
            this.router.transitionTo("adminCustomizeThemes.show.schema", setting.setting);
          },
          label: "admin.customize.theme.edit_objects_theme_setting",
          icon: "pencil"
        };
      }
    }),
    disableSaveButton: (0, _object.computed)("validationMessage", function () {
      return !!this.validationMessage;
    }),
    confirmChanges(settingKey) {
      return new _rsvp.Promise(resolve => {
        // Fallback is needed in case the setting does not have a custom confirmation
        // prompt/confirm defined.
        this.dialog.alert({
          message: _discourseI18n.default.t(`admin.site_settings.requires_confirmation_messages.${settingKey}.prompt`, {
            translatedFallback: _discourseI18n.default.t("admin.site_settings.requires_confirmation_messages.default.prompt")
          }),
          buttons: [{
            label: _discourseI18n.default.t(`admin.site_settings.requires_confirmation_messages.${settingKey}.confirm`, {
              translatedFallback: _discourseI18n.default.t("admin.site_settings.requires_confirmation_messages.default.confirm")
            }),
            class: "btn-primary",
            action: () => resolve(true)
          }, {
            label: _discourseI18n.default.t("no_value"),
            class: "btn-default",
            action: () => resolve(false)
          }]
        });
      });
    },
    update: (0, _object.action)(async function () {
      const key = this.buffered.get("setting");
      let confirm = true;
      if (this.buffered.get("requires_confirmation") === _constants.SITE_SETTING_REQUIRES_CONFIRMATION_TYPES.simple) {
        confirm = await this.confirmChanges(key);
      }
      if (!confirm) {
        this.cancel();
        return;
      }
      if (!DEFAULT_USER_PREFERENCES.includes(key)) {
        await this.save();
        return;
      }
      const data = {
        [key]: this.buffered.get("value")
      };
      const result = await (0, _ajax.ajax)(`/admin/site_settings/${key}/user_count.json`, {
        type: "PUT",
        data
      });
      const count = result.user_count;
      if (count > 0) {
        await this.modal.show(_siteSettingDefaultCategories.default, {
          model: {
            siteSetting: {
              count,
              key: key.replaceAll("_", " ")
            },
            setUpdateExistingUsers: this.setUpdateExistingUsers
          }
        });
        this.save();
      } else {
        await this.save();
      }
    }),
    setUpdateExistingUsers: (0, _object.action)(function (value) {
      this.updateExistingUsers = value;
    }),
    save: (0, _object.action)(async function () {
      try {
        await this._save();
        this.set("validationMessage", null);
        this.commitBuffer();
        if (AUTO_REFRESH_ON_SAVE.includes(this.setting.setting)) {
          this.afterSave();
        }
      } catch (e) {
        const json = e.jqXHR?.responseJSON;
        if (json?.errors) {
          let errorString = json.errors[0];
          if (json.html_message) {
            errorString = (0, _template.htmlSafe)(errorString);
          }
          this.set("validationMessage", errorString);
        } else {
          this.set("validationMessage", _discourseI18n.default.t("generic_error"));
        }
      }
    }),
    changeValueCallback: (0, _object.action)(function (value) {
      this.set("buffered.value", value);
    }),
    setValidationMessage: (0, _object.action)(function (message) {
      this.set("validationMessage", message);
    }),
    cancel: (0, _object.action)(function () {
      this.rollbackBuffer();
      this.set("validationMessage", null);
    }),
    resetDefault: (0, _object.action)(function () {
      this.set("buffered.value", this.setting.default);
      this.set("validationMessage", null);
    }),
    toggleSecret: (0, _object.action)(function () {
      this.toggleProperty("isSecret");
    }),
    setDefaultValues: (0, _object.action)(function () {
      this.set("buffered.value", this.bufferedValues.concat(this.defaultValues).uniq().join("|"));
      this.set("validationMessage", null);
      return false;
    }),
    _handleKeydown: (0, _object.action)(function (event) {
      if (event.key === "Enter" && event.target.classList.contains("input-setting-string")) {
        this.save();
      }
    }),
    async _save() {
      (false && (0, _debug.warn)("You should define a `_save` method", {
        id: "discourse.setting-component.missing-save"
      }));
    }
  });
});