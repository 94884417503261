define("admin/components/modal/color-scheme-select-base", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    @title={{i18n "admin.customize.colors.select_base.title"}}
    @closeModal={{@closeModal}}
  >
    <:body>
      {{i18n "admin.customize.colors.select_base.description"}}
      <ComboBox
        @content={{@model.baseColorSchemes}}
        @value={{this.selectedBaseThemeId}}
        @onChange={{fn (mut this.selectedBaseThemeId)}}
        @valueProperty="base_scheme_id"
      />
    </:body>
    <:footer>
      <DButton
        class="btn-primary"
        @action={{this.selectBase}}
        @icon="plus"
        @label="admin.customize.new"
      />
    </:footer>
  </DModal>
  */
  {
    "id": "PpMjc6Sq",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@closeModal\"],[[28,[37,1],[\"admin.customize.colors.select_base.title\"],null],[30,1]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[1,[28,[35,1],[\"admin.customize.colors.select_base.description\"],null]],[1,\"\\n    \"],[8,[39,2],null,[[\"@content\",\"@value\",\"@onChange\",\"@valueProperty\"],[[30,2,[\"baseColorSchemes\"]],[30,0,[\"selectedBaseThemeId\"]],[28,[37,3],[[28,[37,4],[[30,0,[\"selectedBaseThemeId\"]]],null]],null],\"base_scheme_id\"]],null],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,5],[[24,0,\"btn-primary\"]],[[\"@action\",\"@icon\",\"@label\"],[[30,0,[\"selectBase\"]],\"plus\",\"admin.customize.new\"]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\",\"@model\"],false,[\"d-modal\",\"i18n\",\"combo-box\",\"fn\",\"mut\",\"d-button\"]]",
    "moduleName": "admin/components/modal/color-scheme-select-base.hbs",
    "isStrictMode": false
  });
  class ColorSchemeSelectBase extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "selectedBaseThemeId", [_tracking.tracked], function () {
      return this.args.model.baseColorSchemes?.[0]?.base_scheme_id;
    }))();
    #selectedBaseThemeId = (() => (dt7948.i(this, "selectedBaseThemeId"), void 0))();
    selectBase() {
      this.args.model.newColorSchemeWithBase(this.selectedBaseThemeId);
      this.args.closeModal();
    }
    static #_2 = (() => dt7948.n(this.prototype, "selectBase", [_object.action]))();
  }
  _exports.default = ColorSchemeSelectBase;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ColorSchemeSelectBase);
});