define("admin/components/admin-backups-actions", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/helpers/route-action", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _routeAction, _ajax, _ajaxError, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminBackupsActions extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    toggleReadOnlyMode() {
      if (!this.site.isReadOnly) {
        this.dialog.yesNoConfirm({
          message: _discourseI18n.default.t("admin.backups.read_only.enable.confirm"),
          didConfirm: () => {
            this.currentUser.set("hideReadOnlyAlert", true);
            this.#toggleReadOnlyMode(true);
          }
        });
      } else {
        this.#toggleReadOnlyMode(false);
      }
    }
    static #_4 = (() => dt7948.n(this.prototype, "toggleReadOnlyMode", [_object.action]))();
    get rollbackDisabled() {
      return !this.rollbackEnabled;
    }
    get rollbackEnabled() {
      return this.args.backups.canRollback && this.args.backups.restoreEnabled && !this.args.backups.isOperationRunning;
    }
    async #toggleReadOnlyMode(enable1) {
      try {
        await (0, _ajax.ajax)("/admin/backups/readonly", {
          type: "PUT",
          data: {
            enable: enable1
          }
        });
        this.site.set("isReadOnly", enable1);
      } catch (err1) {
        (0, _ajaxError.popupAjaxError)(err1);
      }
    }
    static #_5 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if @backups.isOperationRunning}}
          <@actions.Danger
            @action={{routeAction "cancelOperation"}}
            @title="admin.backups.operations.cancel.title"
            @label="admin.backups.operations.cancel.label"
            @icon="xmark"
            class="admin-backups__cancel"
          />
        {{else}}
          <@actions.Primary
            @action={{routeAction "showStartBackupModal"}}
            @title="admin.backups.operations.backup.title"
            @label="admin.backups.operations.backup.label"
            @icon="rocket"
            class="admin-backups__start"
          />
        {{/if}}
    
        {{#if @backups.canRollback}}
          <@actions.Default
            @action={{routeAction "rollback"}}
            @label="admin.backups.operations.rollback.label"
            @title="admin.backups.operations.rollback.title"
            @icon="truck-medical"
            @disabled={{this.rollbackDisabled}}
            class="admin-backups__rollback"
          />
        {{/if}}
    
        <@actions.Default
          @icon={{if this.site.isReadOnly "far-eye-slash" "far-eye"}}
          @action={{this.toggleReadOnlyMode}}
          @disabled={{@backups.isOperationRunning}}
          @title={{if
            this.site.isReadOnly
            "admin.backups.read_only.disable.title"
            "admin.backups.read_only.enable.title"
          }}
          @label={{if
            this.site.isReadOnly
            "admin.backups.read_only.disable.label"
            "admin.backups.read_only.enable.label"
          }}
          class="admin-backups__toggle-read-only"
        />
      
    */
    {
      "id": "hjPKPNVN",
      "block": "[[[1,\"\\n\"],[41,[30,1,[\"isOperationRunning\"]],[[[1,\"      \"],[8,[30,2,[\"Danger\"]],[[24,0,\"admin-backups__cancel\"]],[[\"@action\",\"@title\",\"@label\",\"@icon\"],[[28,[32,0],[\"cancelOperation\"],null],\"admin.backups.operations.cancel.title\",\"admin.backups.operations.cancel.label\",\"xmark\"]],null],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[30,2,[\"Primary\"]],[[24,0,\"admin-backups__start\"]],[[\"@action\",\"@title\",\"@label\",\"@icon\"],[[28,[32,0],[\"showStartBackupModal\"],null],\"admin.backups.operations.backup.title\",\"admin.backups.operations.backup.label\",\"rocket\"]],null],[1,\"\\n\"]],[]]],[1,\"\\n\"],[41,[30,1,[\"canRollback\"]],[[[1,\"      \"],[8,[30,2,[\"Default\"]],[[24,0,\"admin-backups__rollback\"]],[[\"@action\",\"@label\",\"@title\",\"@icon\",\"@disabled\"],[[28,[32,0],[\"rollback\"],null],\"admin.backups.operations.rollback.label\",\"admin.backups.operations.rollback.title\",\"truck-medical\",[30,0,[\"rollbackDisabled\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[8,[30,2,[\"Default\"]],[[24,0,\"admin-backups__toggle-read-only\"]],[[\"@icon\",\"@action\",\"@disabled\",\"@title\",\"@label\"],[[52,[30,0,[\"site\",\"isReadOnly\"]],\"far-eye-slash\",\"far-eye\"],[30,0,[\"toggleReadOnlyMode\"]],[30,1,[\"isOperationRunning\"]],[52,[30,0,[\"site\",\"isReadOnly\"]],\"admin.backups.read_only.disable.title\",\"admin.backups.read_only.enable.title\"],[52,[30,0,[\"site\",\"isReadOnly\"]],\"admin.backups.read_only.disable.label\",\"admin.backups.read_only.enable.label\"]]],null],[1,\"\\n  \"]],[\"@backups\",\"@actions\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/admin/components/admin-backups-actions.js",
      "scope": () => [_routeAction.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AdminBackupsActions;
});