define("admin/routes/admin-plugins-show", ["exports", "@ember/service", "discourse/lib/ajax", "discourse/lib/text", "discourse/routes/discourse", "admin/models/admin-plugin"], function (_exports, _service, _ajax, _text, _discourse, _adminPlugin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsShowRoute extends _discourse.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "adminPluginNavManager", [_service.service]))();
    #adminPluginNavManager = (() => (dt7948.i(this, "adminPluginNavManager"), void 0))();
    async model(params) {
      const pluginId = (0, _text.sanitize)(params.plugin_id).substring(0, 100);
      const pluginAttrs = await (0, _ajax.ajax)(`/admin/plugins/${pluginId}.json`);
      return _adminPlugin.default.create(pluginAttrs);
    }
    afterModel(model) {
      this.adminPluginNavManager.currentPlugin = model;
    }
    deactivate() {
      this.adminPluginNavManager.currentPlugin = null;
    }
    titleToken() {
      return this.adminPluginNavManager.currentPlugin.nameTitleized;
    }
  }
  _exports.default = AdminPluginsShowRoute;
});