define("admin/helpers/check-icon", ["exports", "@ember/template", "discourse-common/lib/helpers", "discourse-common/lib/icon-library"], function (_exports, _template, _helpers, _iconLibrary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = checkIcon;
  (0, _helpers.registerRawHelper)("check-icon", checkIcon);
  function checkIcon(value) {
    let icon = value ? "check" : "xmark";
    return (0, _template.htmlSafe)((0, _iconLibrary.renderIcon)("string", icon));
  }
});