define("admin/routes/admin-site-text-edit", ["exports", "@ember/routing/route", "discourse/lib/ajax"], function (_exports, _route, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminSiteTextEditRoute extends _route.default {
    queryParams = {
      locale: {
        replace: true
      }
    };
    async model(params) {
      const result = await (0, _ajax.ajax)(`/admin/customize/site_texts/${params.id}?locale=${params.locale}`);
      return this.store.createRecord("site-text", result.site_text);
    }
    setupController(controller, siteText) {
      const locales = JSON.parse(this.siteSettings.available_locales);
      const localeFullName = locales.find(locale => {
        return locale.value === controller.locale;
      }).name;
      controller.setProperties({
        siteText,
        saved: false,
        localeFullName
      });
    }
  }
  _exports.default = AdminSiteTextEditRoute;
});