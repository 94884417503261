define("admin/models/staff-action-log", ["exports", "discourse/lib/ajax", "discourse/lib/utilities", "discourse/models/rest", "discourse-common/utils/decorators", "discourse-i18n", "admin/models/admin-user"], function (_exports, _ajax, _utilities, _rest, _decorators, _discourseI18n, _adminUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function format(label, value) {
    let escape = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
    return value ? `<b>${_discourseI18n.default.t(label)}</b>: ${escape ? (0, _utilities.escapeExpression)(value) : value}` : "";
  }
  class StaffActionLog extends _rest.default {
    static munge(json) {
      if (json.acting_user) {
        json.acting_user = _adminUser.default.create(json.acting_user);
      }
      if (json.target_user) {
        json.target_user = _adminUser.default.create(json.target_user);
      }
      return json;
    }
    static findAll(data) {
      return (0, _ajax.ajax)("/admin/logs/staff_action_logs.json", {
        data
      }).then(result => {
        return {
          staff_action_logs: result.staff_action_logs.map(s => StaffActionLog.create(s)),
          user_history_actions: result.user_history_actions
        };
      });
    }
    showFullDetails = false;
    actionName(actionName) {
      return _discourseI18n.default.t(`admin.logs.staff_actions.actions.${actionName}`);
    }
    static #_ = (() => dt7948.n(this.prototype, "actionName", [(0, _decorators.default)("action_name")]))();
    formattedDetails(email, ipAddress, topicId, postId, categoryId, newValue, previousValue, details, useCustomModalForDetails, useModalForDetails) {
      const postLink = postId ? `<a href data-link-post-id="${postId}">${postId}</a>` : null;
      const topicLink = topicId ? `<a href data-link-topic-id="${topicId}">${topicId}</a>` : null;
      let lines = [format("email", email), format("admin.logs.ip_address", ipAddress), format("admin.logs.topic_id", topicLink, false), format("admin.logs.post_id", postLink, false), format("admin.logs.category_id", categoryId)];
      if (!useCustomModalForDetails) {
        lines.push(format("admin.logs.staff_actions.new_value", newValue));
        lines.push(format("admin.logs.staff_actions.previous_value", previousValue));
      }
      if (!useModalForDetails && details) {
        lines = [...lines, ...(0, _utilities.escapeExpression)(details).split("\n")];
      }
      const formatted = lines.filter(l => l.length > 0).join("<br/>");
      return formatted.length > 0 ? formatted + "<br/>" : "";
    }
    static #_2 = (() => dt7948.n(this.prototype, "formattedDetails", [(0, _decorators.default)("email", "ip_address", "topic_id", "post_id", "category_id", "new_value", "previous_value", "details", "useCustomModalForDetails", "useModalForDetails")]))();
    useModalForDetails(details) {
      return details && details.length > 100;
    }
    static #_3 = (() => dt7948.n(this.prototype, "useModalForDetails", [(0, _decorators.default)("details")]))();
    useCustomModalForDetails(actionName) {
      return ["change_theme", "delete_theme", "tag_group_create", "tag_group_destroy", "tag_group_change"].includes(actionName);
    }
    static #_4 = (() => dt7948.n(this.prototype, "useCustomModalForDetails", [(0, _decorators.default)("action_name")]))();
  }
  _exports.default = StaffActionLog;
});