define("admin/components/site-settings/group-list", ["exports", "@ember/component", "@ember/object", "@ember/template-factory"], function (_exports, _component, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <ListSetting
    @value={{this.settingValue}}
    @choices={{this.groupChoices}}
    @settingName="name"
    @mandatoryValues={{this.setting.mandatory_values}}
    @nameProperty={{this.nameProperty}}
    @valueProperty={{this.valueProperty}}
    @onChange={{this.onChangeGroupListSetting}}
  />
  */
  {
    "id": "QngzxI2F",
    "block": "[[[8,[39,0],null,[[\"@value\",\"@choices\",\"@settingName\",\"@mandatoryValues\",\"@nameProperty\",\"@valueProperty\",\"@onChange\"],[[30,0,[\"settingValue\"]],[30,0,[\"groupChoices\"]],\"name\",[30,0,[\"setting\",\"mandatory_values\"]],[30,0,[\"nameProperty\"]],[30,0,[\"valueProperty\"]],[30,0,[\"onChangeGroupListSetting\"]]]],null]],[],false,[\"list-setting\"]]",
    "moduleName": "admin/components/site-settings/group-list.hbs",
    "isStrictMode": false
  });
  class GroupList extends _component.default {
    tokenSeparator = "|";
    nameProperty = "name";
    valueProperty = "id";
    get groupChoices() {
      return (this.site.groups || []).map(g => {
        return {
          name: g.name,
          id: g.id.toString()
        };
      });
    }
    static #_ = (() => dt7948.n(this.prototype, "groupChoices", [(0, _object.computed)("site.groups")]))();
    get settingValue() {
      return (this.value || "").split(this.tokenSeparator).filter(Boolean);
    }
    static #_2 = (() => dt7948.n(this.prototype, "settingValue", [(0, _object.computed)("value")]))();
    onChangeGroupListSetting(value) {
      this.set("value", value.join(this.tokenSeparator));
    }
    static #_3 = (() => dt7948.n(this.prototype, "onChangeGroupListSetting", [_object.action]))();
  }
  _exports.default = GroupList;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, GroupList);
});