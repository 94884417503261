define("admin/components/admin-config-area-cards/about/general-settings", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/components/form", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/helpers/i18n", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _service, _form, _ajax, _ajaxError, _i18n, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminConfigAreasAboutGeneralSettings extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "toasts", [_service.service]))();
    #toasts = (() => (dt7948.i(this, "toasts"), void 0))();
    name = this.args.generalSettings.title.value;
    summary = this.args.generalSettings.siteDescription.value;
    extendedDescription = this.args.generalSettings.extendedSiteDescription.value;
    aboutBannerImage = this.args.generalSettings.aboutBannerImage.value;
    get data() {
      return {
        name: this.args.generalSettings.title.value,
        summary: this.args.generalSettings.siteDescription.value,
        extendedDescription: this.args.generalSettings.extendedSiteDescription.value,
        aboutBannerImage: this.args.generalSettings.aboutBannerImage.value
      };
    }
    static #_2 = (() => dt7948.n(this.prototype, "data", [_tracking.cached]))();
    async save(data1) {
      try {
        this.args.setGlobalSavingStatus(true);
        await (0, _ajax.ajax)("/admin/config/about.json", {
          type: "PUT",
          data: {
            general_settings: {
              name: data1.name,
              summary: data1.summary,
              extended_description: data1.extendedDescription,
              about_banner_image: data1.aboutBannerImage
            }
          }
        });
        this.toasts.success({
          duration: 3000,
          data: {
            message: _discourseI18n.default.t("admin.config_areas.about.toasts.general_settings_saved")
          }
        });
      } catch (err1) {
        (0, _ajaxError.popupAjaxError)(err1);
      } finally {
        this.args.setGlobalSavingStatus(false);
      }
    }
    static #_3 = (() => dt7948.n(this.prototype, "save", [_object.action]))();
    setImage(upload1, _ref) {
      let {
        set: set1
      } = _ref;
      set1("aboutBannerImage", upload1?.url);
    }
    static #_4 = (() => dt7948.n(this.prototype, "setImage", [_object.action]))();
    static #_5 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <Form @data={{this.data}} @onSubmit={{this.save}} as |form|>
          <form.Field
            @name="name"
            @title={{i18n "admin.config_areas.about.community_name"}}
            @validation="required"
            @format="large"
            as |field|
          >
            <field.Input
              placeholder={{i18n
                "admin.config_areas.about.community_name_placeholder"
              }}
            />
          </form.Field>
    
          <form.Field
            @name="summary"
            @title={{i18n "admin.config_areas.about.community_summary"}}
            @format="large"
            as |field|
          >
            <field.Input />
          </form.Field>
    
          <form.Field
            @name="extendedDescription"
            @title={{i18n "admin.config_areas.about.community_description"}}
            as |field|
          >
            <field.Composer />
          </form.Field>
    
          <form.Field
            @name="aboutBannerImage"
            @title={{i18n "admin.config_areas.about.banner_image"}}
            @subtitle={{i18n "admin.config_areas.about.banner_image_help"}}
            @onSet={{this.setImage}}
            as |field|
          >
            <field.Image @type="site_setting" />
          </form.Field>
    
          <form.Submit
            @label="admin.config_areas.about.update"
            @disabled={{@globalSavingStatus}}
          />
        </Form>
      
    */
    {
      "id": "/+EJ1Y5c",
      "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@data\",\"@onSubmit\"],[[30,0,[\"data\"]],[30,0,[\"save\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[30,1,[\"Field\"]],null,[[\"@name\",\"@title\",\"@validation\",\"@format\"],[\"name\",[28,[32,1],[\"admin.config_areas.about.community_name\"],null],\"required\",\"large\"]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,2,[\"Input\"]],[[16,\"placeholder\",[28,[32,1],[\"admin.config_areas.about.community_name_placeholder\"],null]]],null,null],[1,\"\\n      \"]],[2]]]]],[1,\"\\n\\n      \"],[8,[30,1,[\"Field\"]],null,[[\"@name\",\"@title\",\"@format\"],[\"summary\",[28,[32,1],[\"admin.config_areas.about.community_summary\"],null],\"large\"]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,3,[\"Input\"]],null,null,null],[1,\"\\n      \"]],[3]]]]],[1,\"\\n\\n      \"],[8,[30,1,[\"Field\"]],null,[[\"@name\",\"@title\"],[\"extendedDescription\",[28,[32,1],[\"admin.config_areas.about.community_description\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,4,[\"Composer\"]],null,null,null],[1,\"\\n      \"]],[4]]]]],[1,\"\\n\\n      \"],[8,[30,1,[\"Field\"]],null,[[\"@name\",\"@title\",\"@subtitle\",\"@onSet\"],[\"aboutBannerImage\",[28,[32,1],[\"admin.config_areas.about.banner_image\"],null],[28,[32,1],[\"admin.config_areas.about.banner_image_help\"],null],[30,0,[\"setImage\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,5,[\"Image\"]],null,[[\"@type\"],[\"site_setting\"]],null],[1,\"\\n      \"]],[5]]]]],[1,\"\\n\\n      \"],[8,[30,1,[\"Submit\"]],null,[[\"@label\",\"@disabled\"],[\"admin.config_areas.about.update\",[30,6]]],null],[1,\"\\n    \"]],[1]]]]],[1,\"\\n  \"]],[\"form\",\"field\",\"field\",\"field\",\"field\",\"@globalSavingStatus\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/admin/components/admin-config-area-cards/about/general-settings.js",
      "scope": () => [_form.default, _i18n.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AdminConfigAreasAboutGeneralSettings;
});