define("admin/controllers/admin-user-index", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/service", "@ember/template", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/computed", "discourse/lib/url", "discourse/mixins/can-check-emails", "discourse-common/lib/get-url", "discourse-common/utils/decorators", "discourse-i18n", "admin/models/admin-user", "admin/components/modal/delete-posts-confirmation", "admin/components/modal/delete-user-posts-progress", "admin/components/modal/merge-users-confirmation", "admin/components/modal/merge-users-progress", "admin/components/modal/merge-users-prompt"], function (_exports, _controller, _object, _computed, _service, _template, _ajax, _ajaxError, _computed2, _url, _canCheckEmails, _getUrl, _decorators, _discourseI18n, _adminUser, _deletePostsConfirmation, _deleteUserPostsProgress, _mergeUsersConfirmation, _mergeUsersProgress, _mergeUsersPrompt) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminUserIndexController extends _controller.default.extend(_canCheckEmails.default) {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "adminTools", [_service.service]))();
    #adminTools = (() => (dt7948.i(this, "adminTools"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    originalPrimaryGroupId = null;
    customGroupIdsBuffer = null;
    availableGroups = null;
    userTitleValue = null;
    ssoExternalEmail = null;
    ssoLastPayload = null;
    static #_5 = (() => dt7948.g(this.prototype, "showBadges", [(0, _computed2.setting)("enable_badges")]))();
    #showBadges = (() => (dt7948.i(this, "showBadges"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "hasLockedTrustLevel", [(0, _computed.notEmpty)("model.manual_locked_trust_level")]))();
    #hasLockedTrustLevel = (() => (dt7948.i(this, "hasLockedTrustLevel"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "primaryGroupDirty", [(0, _computed2.propertyNotEqual)("originalPrimaryGroupId", "model.primary_group_id")]))();
    #primaryGroupDirty = (() => (dt7948.i(this, "primaryGroupDirty"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "canDisableSecondFactor", [(0, _computed.and)("model.second_factor_enabled", "model.can_disable_second_factor")]))();
    #canDisableSecondFactor = (() => (dt7948.i(this, "canDisableSecondFactor"), void 0))();
    static #_9 = (() => dt7948.g(this.prototype, "preferencesPath", [(0, _computed2.fmt)("model.username_lower", (0, _url.userPath)("%@/preferences"))]))();
    #preferencesPath = (() => (dt7948.i(this, "preferencesPath"), void 0))();
    customGroupIds(customGroups) {
      return customGroups.mapBy("id");
    }
    static #_10 = (() => dt7948.n(this.prototype, "customGroupIds", [(0, _decorators.default)("model.customGroups")]))();
    customGroupsDirty(buffer, original) {
      if (buffer === null) {
        return false;
      }
      return buffer.length === original.length ? buffer.any(id => !original.includes(id)) : true;
    }
    static #_11 = (() => dt7948.n(this.prototype, "customGroupsDirty", [(0, _decorators.default)("customGroupIdsBuffer", "customGroupIds")]))();
    automaticGroups(automaticGroups) {
      return automaticGroups.map(group => {
        const name = (0, _template.htmlSafe)(group.name);
        return `<a href="/g/${name}">${name}</a>`;
      }).join(", ");
    }
    static #_12 = (() => dt7948.n(this.prototype, "automaticGroups", [(0, _decorators.default)("model.automaticGroups")]))();
    associatedAccountsLoaded(associatedAccounts) {
      return typeof associatedAccounts !== "undefined";
    }
    static #_13 = (() => dt7948.n(this.prototype, "associatedAccountsLoaded", [(0, _decorators.default)("model.associated_accounts")]))();
    associatedAccounts(associatedAccounts) {
      return associatedAccounts.map(provider => `${provider.name} (${provider.description})`).join(", ");
    }
    static #_14 = (() => dt7948.n(this.prototype, "associatedAccounts", [(0, _decorators.default)("model.associated_accounts")]))();
    userFields(userFields) {
      return this.site.collectUserFields(userFields);
    }
    static #_15 = (() => dt7948.n(this.prototype, "userFields", [(0, _decorators.default)("model.user_fields.[]")]))();
    deleteAllPostsExplanation(canDeleteAllPosts, staff, postCount) {
      if (canDeleteAllPosts) {
        return null;
      }
      if (staff) {
        return _discourseI18n.default.t("admin.user.delete_posts_forbidden_because_staff");
      }
      if (postCount > this.siteSettings.delete_all_posts_max) {
        return _discourseI18n.default.t("admin.user.cant_delete_all_too_many_posts", {
          count: this.siteSettings.delete_all_posts_max
        });
      } else {
        return _discourseI18n.default.t("admin.user.cant_delete_all_posts", {
          count: this.siteSettings.delete_user_max_post_age
        });
      }
    }
    static #_16 = (() => dt7948.n(this.prototype, "deleteAllPostsExplanation", [(0, _decorators.default)("model.can_delete_all_posts", "model.staff", "model.post_count")]))();
    deleteExplanation(canBeDeleted, staff) {
      if (canBeDeleted) {
        return null;
      }
      if (staff) {
        return _discourseI18n.default.t("admin.user.delete_forbidden_because_staff");
      } else {
        return _discourseI18n.default.t("admin.user.delete_forbidden", {
          count: this.siteSettings.delete_user_max_post_age
        });
      }
    }
    static #_17 = (() => dt7948.n(this.prototype, "deleteExplanation", [(0, _decorators.default)("model.canBeDeleted", "model.staff")]))();
    postEditsByEditorFilter(username) {
      return {
        editor: username
      };
    }
    static #_18 = (() => dt7948.n(this.prototype, "postEditsByEditorFilter", [(0, _decorators.default)("model.username")]))();
    groupAdded(added) {
      this.model.groupAdded(added).catch(() => this.dialog.alert(_discourseI18n.default.t("generic_error")));
    }
    groupRemoved(groupId) {
      this.model.groupRemoved(groupId).then(() => {
        if (groupId === this.originalPrimaryGroupId) {
          this.set("originalPrimaryGroupId", null);
        }
      }).catch(() => this.dialog.alert(_discourseI18n.default.t("generic_error")));
    }
    ssoPayload(lastPayload) {
      return lastPayload.split("&");
    }
    static #_19 = (() => dt7948.n(this.prototype, "ssoPayload", [(0, _decorators.default)("ssoLastPayload")]))();
    impersonate() {
      return this.model.impersonate().then(() => _url.default.redirectTo("/")).catch(e => {
        if (e.status === 404) {
          this.dialog.alert(_discourseI18n.default.t("admin.impersonate.not_found"));
        } else {
          this.dialog.alert(_discourseI18n.default.t("admin.impersonate.invalid"));
        }
      });
    }
    static #_20 = (() => dt7948.n(this.prototype, "impersonate", [_object.action]))();
    logOut() {
      return this.model.logOut().then(() => this.dialog.alert(_discourseI18n.default.t("admin.user.logged_out")));
    }
    static #_21 = (() => dt7948.n(this.prototype, "logOut", [_object.action]))();
    resetBounceScore() {
      return this.model.resetBounceScore();
    }
    static #_22 = (() => dt7948.n(this.prototype, "resetBounceScore", [_object.action]))();
    approve() {
      return this.model.approve(this.currentUser);
    }
    static #_23 = (() => dt7948.n(this.prototype, "approve", [_object.action]))();
    _formatError(event) {
      return `http: ${event.status} - ${event.body}`;
    }
    static #_24 = (() => dt7948.n(this.prototype, "_formatError", [_object.action]))();
    deactivate() {
      return this.model.deactivate().then(() => this.model.setProperties({
        active: false,
        can_activate: true
      })).catch(e => {
        const error = _discourseI18n.default.t("admin.user.deactivate_failed", {
          error: this._formatError(e)
        });
        this.dialog.alert(error);
      });
    }
    static #_25 = (() => dt7948.n(this.prototype, "deactivate", [_object.action]))();
    sendActivationEmail() {
      return this.model.sendActivationEmail().then(() => this.dialog.alert(_discourseI18n.default.t("admin.user.activation_email_sent"))).catch(_ajaxError.popupAjaxError);
    }
    static #_26 = (() => dt7948.n(this.prototype, "sendActivationEmail", [_object.action]))();
    activate() {
      return this.model.activate().then(() => this.model.setProperties({
        active: true,
        can_deactivate: !this.model.staff
      })).catch(e => {
        const error = _discourseI18n.default.t("admin.user.activate_failed", {
          error: this._formatError(e)
        });
        this.dialog.alert(error);
      });
    }
    static #_27 = (() => dt7948.n(this.prototype, "activate", [_object.action]))();
    revokeAdmin() {
      return this.model.revokeAdmin();
    }
    static #_28 = (() => dt7948.n(this.prototype, "revokeAdmin", [_object.action]))();
    grantAdmin() {
      return this.model.grantAdmin().then(result => {
        if (result.email_confirmation_required) {
          this.dialog.alert(_discourseI18n.default.t("admin.user.grant_admin_confirm"));
        }
      }).catch(error => {
        const nonce = error.jqXHR?.responseJSON.second_factor_challenge_nonce;
        if (nonce) {
          this.router.transitionTo("second-factor-auth", {
            queryParams: {
              nonce
            }
          });
        } else {
          (0, _ajaxError.popupAjaxError)(error);
        }
      });
    }
    static #_29 = (() => dt7948.n(this.prototype, "grantAdmin", [_object.action]))();
    revokeModeration() {
      return this.model.revokeModeration();
    }
    static #_30 = (() => dt7948.n(this.prototype, "revokeModeration", [_object.action]))();
    grantModeration() {
      return this.model.grantModeration();
    }
    static #_31 = (() => dt7948.n(this.prototype, "grantModeration", [_object.action]))();
    saveTrustLevel() {
      return this.model.saveTrustLevel().then(() => window.location.reload()).catch(e => {
        let error;
        if (e.jqXHR.responseJSON && e.jqXHR.responseJSON.errors) {
          error = e.jqXHR.responseJSON.errors[0];
        }
        error = error || _discourseI18n.default.t("admin.user.trust_level_change_failed", {
          error: this._formatError(e)
        });
        this.dialog.alert(error);
      });
    }
    static #_32 = (() => dt7948.n(this.prototype, "saveTrustLevel", [_object.action]))();
    restoreTrustLevel() {
      return this.model.restoreTrustLevel();
    }
    static #_33 = (() => dt7948.n(this.prototype, "restoreTrustLevel", [_object.action]))();
    lockTrustLevel(locked) {
      return this.model.lockTrustLevel(locked).then(() => window.location.reload()).catch(e => {
        let error;
        if (e.jqXHR.responseJSON && e.jqXHR.responseJSON.errors) {
          error = e.jqXHR.responseJSON.errors[0];
        }
        error = error || _discourseI18n.default.t("admin.user.trust_level_change_failed", {
          error: this._formatError(e)
        });
        this.dialog.alert(error);
      });
    }
    static #_34 = (() => dt7948.n(this.prototype, "lockTrustLevel", [_object.action]))();
    unsilence() {
      return this.model.unsilence();
    }
    static #_35 = (() => dt7948.n(this.prototype, "unsilence", [_object.action]))();
    silence() {
      return this.model.silence();
    }
    static #_36 = (() => dt7948.n(this.prototype, "silence", [_object.action]))();
    anonymize() {
      const user = this.model;
      const performAnonymize = () => {
        this.model.anonymize().then(data => {
          if (data.success) {
            if (data.username) {
              document.location = (0, _getUrl.default)(`/admin/users/${user.get("id")}/${data.username}`);
            } else {
              document.location = (0, _getUrl.default)("/admin/users/list/active");
            }
          } else {
            this.dialog.alert(_discourseI18n.default.t("admin.user.anonymize_failed"));
            if (data.user) {
              user.setProperties(data.user);
            }
          }
        }).catch(() => this.dialog.alert(_discourseI18n.default.t("admin.user.anonymize_failed")));
      };
      this.dialog.alert({
        message: _discourseI18n.default.t("admin.user.anonymize_confirm"),
        class: "delete-user-modal",
        buttons: [{
          icon: "triangle-exclamation",
          label: _discourseI18n.default.t("admin.user.anonymize_yes"),
          class: "btn-danger",
          action: () => performAnonymize()
        }, {
          label: _discourseI18n.default.t("composer.cancel")
        }]
      });
    }
    static #_37 = (() => dt7948.n(this.prototype, "anonymize", [_object.action]))();
    disableSecondFactor() {
      return this.model.disableSecondFactor();
    }
    static #_38 = (() => dt7948.n(this.prototype, "disableSecondFactor", [_object.action]))();
    clearPenaltyHistory() {
      const user = this.model;
      const path = `/admin/users/${user.get("id")}/penalty_history`;
      return (0, _ajax.ajax)(path, {
        type: "DELETE"
      }).then(() => user.set("tl3_requirements.penalty_counts.total", 0)).catch(_ajaxError.popupAjaxError);
    }
    static #_39 = (() => dt7948.n(this.prototype, "clearPenaltyHistory", [_object.action]))();
    destroyUser() {
      const postCount = this.get("model.post_count");
      const maxPostCount = this.siteSettings.delete_all_posts_max;
      const location = document.location.pathname;
      const performDestroy = block => {
        this.dialog.notice(_discourseI18n.default.t("admin.user.deleting_user"));
        let formData = {
          context: location
        };
        if (block) {
          formData["block_email"] = true;
          formData["block_urls"] = true;
          formData["block_ip"] = true;
        }
        if (postCount <= maxPostCount) {
          formData["delete_posts"] = true;
        }
        this.model.destroy(formData).then(data => {
          if (data.deleted) {
            if (/^\/admin\/users\/list\//.test(location)) {
              document.location = location;
            } else {
              document.location = (0, _getUrl.default)("/admin/users/list/active");
            }
          } else {
            this.dialog.alert(_discourseI18n.default.t("admin.user.delete_failed"));
          }
        }).catch(() => {
          this.dialog.alert(_discourseI18n.default.t("admin.user.delete_failed"));
        });
      };
      this.dialog.alert({
        title: _discourseI18n.default.t("admin.user.delete_confirm_title"),
        message: _discourseI18n.default.t("admin.user.delete_confirm"),
        class: "delete-user-modal",
        buttons: [{
          label: _discourseI18n.default.t("admin.user.delete_dont_block"),
          class: "btn-primary",
          action: () => {
            return performDestroy(false);
          }
        }, {
          icon: "triangle-exclamation",
          label: _discourseI18n.default.t("admin.user.delete_and_block"),
          class: "btn-danger",
          action: () => {
            return performDestroy(true);
          }
        }, {
          label: _discourseI18n.default.t("composer.cancel")
        }]
      });
    }
    static #_40 = (() => dt7948.n(this.prototype, "destroyUser", [_object.action]))();
    promptTargetUser() {
      this.modal.show(_mergeUsersPrompt.default, {
        model: {
          user: this.model,
          showMergeConfirmation: this.showMergeConfirmation
        }
      });
    }
    static #_41 = (() => dt7948.n(this.prototype, "promptTargetUser", [_object.action]))();
    showMergeConfirmation(targetUsername) {
      this.modal.show(_mergeUsersConfirmation.default, {
        model: {
          username: this.model.username,
          targetUsername,
          merge: this.merge
        }
      });
    }
    static #_42 = (() => dt7948.n(this.prototype, "showMergeConfirmation", [_object.action]))();
    merge(targetUsername) {
      const user = this.model;
      const location = document.location.pathname;
      let formData = {
        context: location
      };
      if (targetUsername) {
        formData["target_username"] = targetUsername;
      }
      this.model.merge(formData).then(response => {
        if (response.success) {
          this.modal.show(_mergeUsersProgress.default);
        } else {
          this.dialog.alert(_discourseI18n.default.t("admin.user.merge_failed"));
        }
      }).catch(() => {
        _adminUser.default.find(user.id).then(u => user.setProperties(u));
        this.dialog.alert(_discourseI18n.default.t("admin.user.merge_failed"));
      });
    }
    static #_43 = (() => dt7948.n(this.prototype, "merge", [_object.action]))();
    viewActionLogs() {
      this.adminTools.showActionLogs(this, {
        target_user: this.get("model.username")
      });
    }
    static #_44 = (() => dt7948.n(this.prototype, "viewActionLogs", [_object.action]))();
    showSuspendModal() {
      this.adminTools.showSuspendModal(this.model);
    }
    static #_45 = (() => dt7948.n(this.prototype, "showSuspendModal", [_object.action]))();
    unsuspend() {
      this.model.unsuspend().catch(_ajaxError.popupAjaxError);
    }
    static #_46 = (() => dt7948.n(this.prototype, "unsuspend", [_object.action]))();
    showSilenceModal() {
      this.adminTools.showSilenceModal(this.model);
    }
    static #_47 = (() => dt7948.n(this.prototype, "showSilenceModal", [_object.action]))();
    saveUsername(newUsername) {
      const oldUsername = this.get("model.username");
      this.set("model.username", newUsername);
      const path = `/users/${oldUsername.toLowerCase()}/preferences/username`;
      return (0, _ajax.ajax)(path, {
        data: {
          new_username: newUsername
        },
        type: "PUT"
      }).catch(e => {
        this.set("model.username", oldUsername);
        (0, _ajaxError.popupAjaxError)(e);
      }).finally(() => this.toggleProperty("editingUsername"));
    }
    static #_48 = (() => dt7948.n(this.prototype, "saveUsername", [_object.action]))();
    saveName(newName) {
      const oldName = this.get("model.name");
      this.set("model.name", newName);
      const path = (0, _url.userPath)(`${this.get("model.username").toLowerCase()}.json`);
      return (0, _ajax.ajax)(path, {
        data: {
          name: newName
        },
        type: "PUT"
      }).catch(e => {
        this.set("model.name", oldName);
        (0, _ajaxError.popupAjaxError)(e);
      }).finally(() => this.toggleProperty("editingName"));
    }
    static #_49 = (() => dt7948.n(this.prototype, "saveName", [_object.action]))();
    saveTitle(newTitle) {
      const oldTitle = this.get("model.title");
      this.set("model.title", newTitle);
      const path = (0, _url.userPath)(`${this.get("model.username").toLowerCase()}.json`);
      return (0, _ajax.ajax)(path, {
        data: {
          title: newTitle
        },
        type: "PUT"
      }).catch(e => {
        this.set("model.title", oldTitle);
        (0, _ajaxError.popupAjaxError)(e);
      }).finally(() => this.toggleProperty("editingTitle"));
    }
    static #_50 = (() => dt7948.n(this.prototype, "saveTitle", [_object.action]))();
    saveCustomGroups() {
      const currentIds = this.customGroupIds;
      const bufferedIds = this.customGroupIdsBuffer;
      const availableGroups = this.availableGroups;
      bufferedIds.filter(id => !currentIds.includes(id)).forEach(id => this.groupAdded(availableGroups.findBy("id", id)));
      currentIds.filter(id => !bufferedIds.includes(id)).forEach(id => this.groupRemoved(id));
    }
    static #_51 = (() => dt7948.n(this.prototype, "saveCustomGroups", [_object.action]))();
    resetCustomGroups() {
      this.set("customGroupIdsBuffer", this.model.customGroups.mapBy("id"));
    }
    static #_52 = (() => dt7948.n(this.prototype, "resetCustomGroups", [_object.action]))();
    savePrimaryGroup() {
      const primaryGroupId = this.get("model.primary_group_id");
      const path = `/admin/users/${this.get("model.id")}/primary_group`;
      return (0, _ajax.ajax)(path, {
        type: "PUT",
        data: {
          primary_group_id: primaryGroupId
        }
      }).then(() => this.set("originalPrimaryGroupId", primaryGroupId)).catch(() => this.dialog.alert(_discourseI18n.default.t("generic_error")));
    }
    static #_53 = (() => dt7948.n(this.prototype, "savePrimaryGroup", [_object.action]))();
    resetPrimaryGroup() {
      this.set("model.primary_group_id", this.originalPrimaryGroupId);
    }
    static #_54 = (() => dt7948.n(this.prototype, "resetPrimaryGroup", [_object.action]))();
    deleteSSORecord() {
      return this.dialog.yesNoConfirm({
        message: _discourseI18n.default.t("admin.user.discourse_connect.confirm_delete"),
        didConfirm: () => this.model.deleteSSORecord()
      });
    }
    static #_55 = (() => dt7948.n(this.prototype, "deleteSSORecord", [_object.action]))();
    checkSsoEmail() {
      return (0, _ajax.ajax)((0, _url.userPath)(`${this.model.username_lower}/sso-email.json`), {
        data: {
          context: window.location.pathname
        }
      }).then(result => {
        if (result) {
          this.set("ssoExternalEmail", result.email);
        }
      });
    }
    static #_56 = (() => dt7948.n(this.prototype, "checkSsoEmail", [_object.action]))();
    checkSsoPayload() {
      return (0, _ajax.ajax)((0, _url.userPath)(`${this.model.username_lower}/sso-payload.json`), {
        data: {
          context: window.location.pathname
        }
      }).then(result => {
        if (result) {
          this.set("ssoLastPayload", result.payload);
        }
      });
    }
    static #_57 = (() => dt7948.n(this.prototype, "checkSsoPayload", [_object.action]))();
    showDeletePostsConfirmation() {
      this.modal.show(_deletePostsConfirmation.default, {
        model: {
          user: this.model,
          deleteAllPosts: this.deleteAllPosts
        }
      });
    }
    static #_58 = (() => dt7948.n(this.prototype, "showDeletePostsConfirmation", [_object.action]))();
    updateUserPostCount(count) {
      this.model.set("post_count", count);
    }
    static #_59 = (() => dt7948.n(this.prototype, "updateUserPostCount", [_object.action]))();
    deleteAllPosts() {
      this.modal.show(_deleteUserPostsProgress.default, {
        model: {
          user: this.model,
          updateUserPostCount: this.updateUserPostCount
        }
      });
    }
    static #_60 = (() => dt7948.n(this.prototype, "deleteAllPosts", [_object.action]))();
  }
  _exports.default = AdminUserIndexController;
});