define("admin/components/admin-report-chart", ["exports", "@glimmer/component", "discourse/lib/formatter", "discourse-common/lib/helpers", "admin/models/report", "admin/components/chart", "@ember/component", "@ember/template-factory"], function (_exports, _component, _formatter, _helpers, _report, _chart, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminReportChart extends _component.default {
    get chartConfig() {
      const {
        model: model1,
        options: options1
      } = this.args;
      const chartData1 = _report.default.collapse(model1, (0, _helpers.makeArray)(model1.chartData || model1.data, "weekly"), options1.chartGrouping);
      const prevChartData1 = (0, _helpers.makeArray)(model1.prevChartData || model1.prev_data);
      const labels1 = chartData1.map(d1 => d1.x);
      const data1 = {
        labels: labels1,
        datasets: [{
          data: chartData1.map(d1 => Math.round(parseFloat(d1.y))),
          backgroundColor: prevChartData1.length ? "transparent" : model1.secondary_color,
          borderColor: model1.primary_color,
          pointRadius: 3,
          borderWidth: 1,
          pointBackgroundColor: model1.primary_color,
          pointBorderColor: model1.primary_color
        }]
      };
      if (prevChartData1.length) {
        data1.datasets.push({
          data: prevChartData1.map(d1 => Math.round(parseFloat(d1.y))),
          borderColor: model1.primary_color,
          borderDash: [5, 5],
          backgroundColor: "transparent",
          borderWidth: 1,
          pointRadius: 0
        });
      }
      return {
        type: "line",
        data: data1,
        options: {
          plugins: {
            tooltip: {
              callbacks: {
                title: tooltipItem1 => moment(tooltipItem1[0].label, "YYYY-MM-DD").format("LL")
              }
            },
            legend: {
              display: false
            }
          },
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 0,
          animation: {
            duration: 0
          },
          layout: {
            padding: {
              left: 0,
              top: 0,
              right: 0,
              bottom: 0
            }
          },
          scales: {
            y: [{
              display: true,
              ticks: {
                callback: label1 => (0, _formatter.number)(label1),
                sampleSize: 5,
                maxRotation: 25,
                minRotation: 25
              }
            }],
            x: [{
              display: true,
              gridLines: {
                display: false
              },
              type: "time",
              time: {
                unit: _report.default.unitForGrouping(options1.chartGrouping)
              },
              ticks: {
                sampleSize: 5,
                maxRotation: 50,
                minRotation: 50
              }
            }]
          }
        }
      };
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <Chart @chartConfig={{this.chartConfig}} class="admin-report-chart" />
      
    */
    {
      "id": "49QJG6wf",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"admin-report-chart\"]],[[\"@chartConfig\"],[[30,0,[\"chartConfig\"]]]],null],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/admin/components/admin-report-chart.js",
      "scope": () => [_chart.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AdminReportChart;
});