define("admin/components/admin-reports", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/component", "@ember/render-modifiers/modifiers/did-insert", "@ember/routing", "@ember/service", "@ember/template", "discourse/components/conditional-loading-spinner", "discourse/lib/ajax", "discourse-common/helpers/d-icon", "discourse-common/helpers/i18n", "discourse-common/utils/decorators", "@ember/template-factory"], function (_exports, _component, _tracking, _component2, _didInsert, _routing, _service, _template2, _conditionalLoadingSpinner, _ajax, _dIcon, _i18n, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminReports extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "reports", [_tracking.tracked], function () {
      return null;
    }))();
    #reports = (() => (dt7948.i(this, "reports"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "filter", [_tracking.tracked], function () {
      return "";
    }))();
    #filter = (() => (dt7948.i(this, "filter"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "isLoading", [_tracking.tracked], function () {
      return false;
    }))();
    #isLoading = (() => (dt7948.i(this, "isLoading"), void 0))();
    loadReports() {
      this.isLoading = true;
      (0, _ajax.ajax)("/admin/reports").then(json1 => {
        this.reports = json1.reports;
      }).finally(() => this.isLoading = false);
    }
    static #_5 = (() => dt7948.n(this.prototype, "loadReports", [_decorators.bind]))();
    get filteredReports() {
      if (!this.reports) {
        return [];
      }
      let filteredReports1 = this.reports;
      if (this.filter) {
        const lowerCaseFilter1 = this.filter.toLowerCase();
        filteredReports1 = filteredReports1.filter(report1 => {
          return (report1.title || "").toLowerCase().includes(lowerCaseFilter1) || (report1.description || "").toLowerCase().includes(lowerCaseFilter1);
        });
      }
      const hiddenReports1 = (this.siteSettings.dashboard_hidden_reports || "").split("|").filter(Boolean);
      filteredReports1 = filteredReports1.filter(report1 => !hiddenReports1.includes(report1.type));
      return filteredReports1;
    }
    static #_6 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div {{didInsert this.loadReports}}>
          <ConditionalLoadingSpinner @condition={{this.isLoading}}>
            <div class="admin-reports-header">
              <h2>{{i18n "admin.reports.title"}}</h2>
              <Input
                class="admin-reports-header__filter"
                placeholder={{i18n "admin.filter_reports"}}
                @value={{this.filter}}
              />
            </div>
    
            <div class="alert alert-info">
              {{dIcon "book"}}
              {{htmlSafe (i18n "admin.reports.meta_doc")}}
            </div>
    
            <ul class="admin-reports-list">
              {{#each this.filteredReports as |report|}}
                <li class="admin-reports-list__report">
                  <LinkTo @route="adminReports.show" @model={{report.type}}>
                    <h3
                      class="admin-reports-list__report-title"
                    >{{report.title}}</h3>
                    {{#if report.description}}
                      <p class="admin-reports-list__report-description">
                        {{report.description}}
                      </p>
                    {{/if}}
                  </LinkTo>
                </li>
              {{/each}}
            </ul>
          </ConditionalLoadingSpinner>
        </div>
      
    */
    {
      "id": "EabjOUVj",
      "block": "[[[1,\"\\n    \"],[11,0],[4,[32,0],[[30,0,[\"loadReports\"]]],null],[12],[1,\"\\n      \"],[8,[32,1],null,[[\"@condition\"],[[30,0,[\"isLoading\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[10,0],[14,0,\"admin-reports-header\"],[12],[1,\"\\n          \"],[10,\"h2\"],[12],[1,[28,[32,2],[\"admin.reports.title\"],null]],[13],[1,\"\\n          \"],[8,[32,3],[[24,0,\"admin-reports-header__filter\"],[16,\"placeholder\",[28,[32,2],[\"admin.filter_reports\"],null]]],[[\"@value\"],[[30,0,[\"filter\"]]]],null],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[10,0],[14,0,\"alert alert-info\"],[12],[1,\"\\n          \"],[1,[28,[32,4],[\"book\"],null]],[1,\"\\n          \"],[1,[28,[32,5],[[28,[32,2],[\"admin.reports.meta_doc\"],null]],null]],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[10,\"ul\"],[14,0,\"admin-reports-list\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"filteredReports\"]]],null]],null],null,[[[1,\"            \"],[10,\"li\"],[14,0,\"admin-reports-list__report\"],[12],[1,\"\\n              \"],[8,[32,6],null,[[\"@route\",\"@model\"],[\"adminReports.show\",[30,1,[\"type\"]]]],[[\"default\"],[[[[1,\"\\n                \"],[10,\"h3\"],[14,0,\"admin-reports-list__report-title\"],[12],[1,[30,1,[\"title\"]]],[13],[1,\"\\n\"],[41,[30,1,[\"description\"]],[[[1,\"                  \"],[10,2],[14,0,\"admin-reports-list__report-description\"],[12],[1,\"\\n                    \"],[1,[30,1,[\"description\"]]],[1,\"\\n                  \"],[13],[1,\"\\n\"]],[]],null],[1,\"              \"]],[]]]]],[1,\"\\n            \"],[13],[1,\"\\n\"]],[1]],null],[1,\"        \"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"report\"],false,[\"each\",\"-track-array\",\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/admin/components/admin-reports.js",
      "scope": () => [_didInsert.default, _conditionalLoadingSpinner.default, _i18n.default, _component2.Input, _dIcon.default, _template2.htmlSafe, _routing.LinkTo],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AdminReports;
});