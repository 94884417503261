define("admin/routes/admin-customize-form-templates-edit", ["exports", "discourse/routes/discourse", "admin/models/form-template"], function (_exports, _discourse, _formTemplate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminCustomizeFormTemplatesEdit extends _discourse.default {
    model(params) {
      return _formTemplate.default.findById(params.id);
    }
    setupController(controller, model) {
      controller.set("model", model);
    }
  }
  _exports.default = AdminCustomizeFormTemplatesEdit;
});