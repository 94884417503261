define("admin/components/site-settings/uploaded-image-list", ["exports", "@ember/component", "@ember/object", "@ember/service", "admin/components/modal/uploaded-image-list", "@ember/template-factory"], function (_exports, _component, _object, _service, _uploadedImageList, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DButton
    @label="admin.site_settings.uploaded_image_list.label"
    @action={{fn
      this.showUploadModal
      (hash value=this.value setting=this.setting)
    }}
  />
  */
  {
    "id": "z9PlR0I6",
    "block": "[[[8,[39,0],null,[[\"@label\",\"@action\"],[\"admin.site_settings.uploaded_image_list.label\",[28,[37,1],[[30,0,[\"showUploadModal\"]],[28,[37,2],null,[[\"value\",\"setting\"],[[30,0,[\"value\"]],[30,0,[\"setting\"]]]]]],null]]],null]],[],false,[\"d-button\",\"fn\",\"hash\"]]",
    "moduleName": "admin/components/site-settings/uploaded-image-list.hbs",
    "isStrictMode": false
  });
  class UploadedImageList extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    showUploadModal(_ref) {
      let {
        value,
        setting
      } = _ref;
      this.modal.show(_uploadedImageList.default, {
        model: {
          title: `admin.site_settings.${setting.setting}.title`,
          changeValue: v => this.set("value", v),
          value
        }
      });
    }
    static #_2 = (() => dt7948.n(this.prototype, "showUploadModal", [_object.action]))();
  }
  _exports.default = UploadedImageList;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UploadedImageList);
});