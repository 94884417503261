define("admin/routes/admin-dashboard-general", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminDashboardGeneralRoute extends _discourse.default {
    activate() {
      this.controllerFor("admin-dashboard-general").fetchDashboard();
    }
  }
  _exports.default = AdminDashboardGeneralRoute;
});