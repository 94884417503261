define("admin/components/site-settings/emoji-list", ["exports", "@ember/component", "@ember/template-factory"], function (_exports, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <EmojiValueList
    @setting={{this.setting}}
    @values={{this.value}}
    @setValidationMessage={{this.setValidationMessage}}
  />
  */
  {
    "id": "cyEYf5fZ",
    "block": "[[[8,[39,0],null,[[\"@setting\",\"@values\",\"@setValidationMessage\"],[[30,0,[\"setting\"]],[30,0,[\"value\"]],[30,0,[\"setValidationMessage\"]]]],null]],[],false,[\"emoji-value-list\"]]",
    "moduleName": "admin/components/site-settings/emoji-list.hbs",
    "isStrictMode": false
  });
  class EmojiList extends _component.default {}
  _exports.default = EmojiList;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, EmojiList);
});