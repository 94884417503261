define("admin/mixins/setting-object", ["exports", "@ember/object", "@ember/object/computed", "@ember/object/mixin", "@ember/utils", "discourse-common/lib/object", "discourse-i18n"], function (_exports, _object, _computed, _mixin, _utils, _object2, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _mixin.default.create({
    overridden: (0, _object.computed)("value", "default", function () {
      let val = this.value;
      let defaultVal = this.default;
      if (val === null) {
        val = "";
      }
      if (defaultVal === null) {
        defaultVal = "";
      }
      return !(0, _object2.deepEqual)(val, defaultVal);
    }),
    computedValueProperty: (0, _object.computed)("valueProperty", "validValues.[]", function () {
      if ((0, _utils.isPresent)(this.valueProperty)) {
        return this.valueProperty;
      }
      if ((0, _utils.isPresent)(this.validValues.get("firstObject.value"))) {
        return "value";
      } else {
        return null;
      }
    }),
    computedNameProperty: (0, _object.computed)("nameProperty", "validValues.[]", function () {
      if ((0, _utils.isPresent)(this.nameProperty)) {
        return this.nameProperty;
      }
      if ((0, _utils.isPresent)(this.validValues.get("firstObject.name"))) {
        return "name";
      } else {
        return null;
      }
    }),
    validValues: (0, _object.computed)("valid_values", function () {
      const validValues = this.valid_values;
      const values = [];
      const translateNames = this.translate_names;
      (validValues || []).forEach(v => {
        if (v.name && v.name.length > 0 && translateNames) {
          values.addObject({
            name: _discourseI18n.default.t(v.name),
            value: v.value
          });
        } else {
          values.addObject(v);
        }
      });
      return values;
    }),
    allowsNone: (0, _object.computed)("valid_values", function () {
      if (this.valid_values?.includes("")) {
        return "admin.settings.none";
      }
    }),
    anyValue: (0, _computed.readOnly)("allow_any")
  });
});