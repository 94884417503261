define("admin/routes/admin-plugins", ["exports", "@ember/service", "discourse/routes/discourse", "discourse-i18n", "admin/models/admin-plugin"], function (_exports, _service, _discourse, _discourseI18n, _adminPlugin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsRoute extends _discourse.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    async model() {
      const plugins = await this.store.findAll("plugin");
      return plugins.map(plugin => _adminPlugin.default.create(plugin));
    }
    titleToken() {
      return _discourseI18n.default.t("admin.plugins.title");
    }
  }
  _exports.default = AdminPluginsRoute;
});