define("admin/components/admin-report-table-cell", ["exports", "@ember/component", "@ember/object/computed", "@ember-decorators/component", "discourse-common/utils/decorators", "@ember/template-factory"], function (_exports, _component, _computed, _component2, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{html-safe this.formattedValue}}
  */
  {
    "id": "EKidjxG+",
    "block": "[[[1,[28,[35,0],[[30,0,[\"formattedValue\"]]],null]]],[],false,[\"html-safe\"]]",
    "moduleName": "admin/components/admin-report-table-cell.hbs",
    "isStrictMode": false
  });
  const AdminReportTableCell = dt7948.c(class AdminReportTableCell extends _component.default {
    options = null;
    static #_ = (() => dt7948.g(this.prototype, "type", [(0, _computed.alias)("label.type")]))();
    #type = (() => (dt7948.i(this, "type"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "property", [(0, _computed.alias)("label.mainProperty")]))();
    #property = (() => (dt7948.i(this, "property"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "formattedValue", [(0, _computed.alias)("computedLabel.formattedValue")]))();
    #formattedValue = (() => (dt7948.i(this, "formattedValue"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "value", [(0, _computed.alias)("computedLabel.value")]))();
    #value = (() => (dt7948.i(this, "value"), void 0))();
    computedLabel(label, data, options) {
      return label.compute(data, options || {});
    }
    static #_5 = (() => dt7948.n(this.prototype, "computedLabel", [(0, _decorators.default)("label", "data", "options")]))();
  }, [(0, _component2.attributeBindings)("value:title"), (0, _component2.classNameBindings)("type", "property"), (0, _component2.classNames)("admin-report-table-cell"), (0, _component2.tagName)("td")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AdminReportTableCell);
});