define("admin/routes/admin-badges", ["exports", "@ember/object", "@ember/service", "discourse/lib/ajax", "discourse/models/badge", "discourse/models/badge-grouping", "discourse/routes/discourse", "discourse-i18n", "admin/components/modal/edit-badge-groupings"], function (_exports, _object, _service, _ajax, _badge, _badgeGrouping, _discourse, _discourseI18n, _editBadgeGroupings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminBadgesRoute extends _discourse.default {
    static #_ = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    _json = null;
    async model() {
      let json = await (0, _ajax.ajax)("/admin/badges.json");
      this._json = json;
      return _badge.default.createFromJson(json);
    }
    editGroupings() {
      const model = this.controllerFor("admin-badges").badgeGroupings;
      this.modal.show(_editBadgeGroupings.default, {
        model: {
          badgeGroupings: model,
          updateGroupings: this.updateGroupings
        }
      });
    }
    static #_2 = (() => dt7948.n(this.prototype, "editGroupings", [_object.action]))();
    updateGroupings(groupings) {
      this.controllerFor("admin-badges").set("badgeGroupings", groupings);
    }
    static #_3 = (() => dt7948.n(this.prototype, "updateGroupings", [_object.action]))();
    setupController(controller, model) {
      const json = this._json;
      const badgeTriggers = [];
      const badgeGroupings = [];
      Object.keys(json.admin_badges.triggers).forEach(k => {
        const id = json.admin_badges.triggers[k];
        badgeTriggers.push({
          id,
          name: _discourseI18n.default.t("admin.badges.trigger_type." + k)
        });
      });
      json.badge_groupings.forEach(function (badgeGroupingJson) {
        badgeGroupings.push(_badgeGrouping.default.create(badgeGroupingJson));
      });
      controller.badgeGroupings = badgeGroupings;
      controller.badgeTypes = json.badge_types;
      controller.protectedSystemFields = json.admin_badges.protected_system_fields;
      controller.badgeTriggers = badgeTriggers;
      controller.model = model;
    }
  }
  _exports.default = AdminBadgesRoute;
});