define("admin/templates/customize-email-style", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="row">
    <h2>{{i18n "admin.customize.email_style.heading"}}</h2>
  
    <p>{{i18n "admin.customize.email_style.instructions"}}</p>
  </div>
  
  {{outlet}}
  */
  {
    "id": "aH72KSZI",
    "block": "[[[10,0],[14,0,\"row\"],[12],[1,\"\\n  \"],[10,\"h2\"],[12],[1,[28,[35,0],[\"admin.customize.email_style.heading\"],null]],[13],[1,\"\\n\\n  \"],[10,2],[12],[1,[28,[35,0],[\"admin.customize.email_style.instructions\"],null]],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[46,[28,[37,2],null,null],null,null,null]],[],false,[\"i18n\",\"component\",\"-outlet\"]]",
    "moduleName": "admin/templates/customize-email-style.hbs",
    "isStrictMode": false
  });
});