define("admin/templates/backups-logs", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <DBreadcrumbsItem
    @path="/admin/backups/logs"
    @label={{i18n "admin.backups.menu.logs"}}
  />
  
  <AdminBackupsLogs @logs={{this.logs}} @status={{this.status}} />
  */
  {
    "id": "313vZDSe",
    "block": "[[[8,[39,0],null,[[\"@path\",\"@label\"],[\"/admin/backups/logs\",[28,[37,1],[\"admin.backups.menu.logs\"],null]]],null],[1,\"\\n\\n\"],[8,[39,2],null,[[\"@logs\",\"@status\"],[[30,0,[\"logs\"]],[30,0,[\"status\"]]]],null]],[],false,[\"d-breadcrumbs-item\",\"i18n\",\"admin-backups-logs\"]]",
    "moduleName": "admin/templates/backups-logs.hbs",
    "isStrictMode": false
  });
});