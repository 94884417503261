define("admin/components/schema-theme-setting/types/enum", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "admin/components/schema-theme-setting/field-input-description", "select-kit/components/combo-box", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _fieldInputDescription, _comboBox, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SchemaThemeSettingTypeEnum extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "value", [_tracking.tracked], function () {
      return this.args.value || this.args.spec.required && this.args.spec.default;
    }))();
    #value = (() => (dt7948.i(this, "value"), void 0))();
    get content() {
      return this.args.spec.choices.map(choice1 => {
        return {
          name: choice1,
          id: choice1
        };
      });
    }
    onInput(newVal1) {
      this.value = newVal1;
      this.args.onChange(newVal1);
    }
    static #_2 = (() => dt7948.n(this.prototype, "onInput", [_object.action]))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <ComboBox
          @content={{this.content}}
          @value={{this.value}}
          @onChange={{this.onInput}}
        />
        <FieldInputDescription @description={{@description}} />
      
    */
    {
      "id": "ak+4Kb4f",
      "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@content\",\"@value\",\"@onChange\"],[[30,0,[\"content\"]],[30,0,[\"value\"]],[30,0,[\"onInput\"]]]],null],[1,\"\\n    \"],[8,[32,1],null,[[\"@description\"],[[30,1]]],null],[1,\"\\n  \"]],[\"@description\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/admin/components/schema-theme-setting/types/enum.js",
      "scope": () => [_comboBox.default, _fieldInputDescription.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = SchemaThemeSettingTypeEnum;
});