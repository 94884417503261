define("admin/adapters/web-hook-event", ["exports", "discourse/adapters/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class WebHookEvent extends _rest.default {
    basePath() {
      return "/admin/api/";
    }
    appendQueryParams(path, findArgs, extension) {
      const urlSearchParams = new URLSearchParams();
      for (const [key, value] of Object.entries(findArgs)) {
        if (value && key !== "webhookId") {
          urlSearchParams.set(key, value);
        }
      }
      const queryString = urlSearchParams.toString();
      let url = `${path}/${findArgs.webhookId}${extension || ""}`;
      if (queryString) {
        url = `${url}?${queryString}`;
      }
      return url;
    }
  }
  _exports.default = WebHookEvent;
});