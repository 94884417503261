define("admin/routes/admin-user-badges", ["exports", "discourse/models/badge", "discourse/models/user-badge", "discourse/routes/discourse"], function (_exports, _badge, _userBadge, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminUserBadgesRoute extends _discourse.default {
    model() {
      const username = this.modelFor("adminUser").get("username");
      return _userBadge.default.findByUsername(username);
    }
    setupController(controller, model) {
      // Find all badges.
      controller.set("loading", true);
      _badge.default.findAll().then(function (badges) {
        controller.set("badges", badges);
        if (badges.length > 0) {
          let grantableBadges = controller.get("availableBadges");
          if (grantableBadges.length > 0) {
            controller.set("selectedBadgeId", grantableBadges[0].get("id"));
          }
        }
        controller.set("loading", false);
      });
      // Set the model.
      controller.set("model", model);
    }
  }
  _exports.default = AdminUserBadgesRoute;
});