define("admin/components/site-settings/simple-list", ["exports", "@ember/component", "@ember/object", "@ember/template-factory"], function (_exports, _component, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <SimpleList
    @values={{this.value}}
    @inputDelimiter={{this.inputDelimiter}}
    @onChange={{this.onChange}}
    @choices={{this.setting.choices}}
    @allowAny={{this.setting.allow_any}}
  />
  */
  {
    "id": "L2pr1CAZ",
    "block": "[[[8,[39,0],null,[[\"@values\",\"@inputDelimiter\",\"@onChange\",\"@choices\",\"@allowAny\"],[[30,0,[\"value\"]],[30,0,[\"inputDelimiter\"]],[30,0,[\"onChange\"]],[30,0,[\"setting\",\"choices\"]],[30,0,[\"setting\",\"allow_any\"]]]],null]],[],false,[\"simple-list\"]]",
    "moduleName": "admin/components/site-settings/simple-list.hbs",
    "isStrictMode": false
  });
  class SimpleList extends _component.default {
    inputDelimiter = "|";
    onChange(value) {
      this.set("value", value.join(this.inputDelimiter || "\n"));
    }
    static #_ = (() => dt7948.n(this.prototype, "onChange", [_object.action]))();
  }
  _exports.default = SimpleList;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SimpleList);
});