define("admin/components/admin-report-trust-level-counts", ["exports", "@ember/component", "@ember-decorators/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <td class="title">{{this.report.title}}</td>
  <td class="value">
    <LinkTo @route="adminUsersList.show" @model="newuser">
      {{number (value-at-tl this.report.data level="0")}}
    </LinkTo>
  </td>
  <td class="value">
    <LinkTo @route="adminUsersList.show" @model="basic">
      {{number (value-at-tl this.report.data level="1")}}
    </LinkTo>
  </td>
  <td class="value">
    <LinkTo @route="adminUsersList.show" @model="member">
      {{number (value-at-tl this.report.data level="2")}}
    </LinkTo>
  </td>
  <td class="value">
    <LinkTo @route="adminUsersList.show" @model="regular">
      {{number (value-at-tl this.report.data level="3")}}
    </LinkTo>
  </td>
  <td class="value">
    <LinkTo @route="adminUsersList.show" @model="leader">
      {{number (value-at-tl this.report.data level="4")}}
    </LinkTo>
  </td>
  */
  {
    "id": "8LSwWgN/",
    "block": "[[[10,\"td\"],[14,0,\"title\"],[12],[1,[30,0,[\"report\",\"title\"]]],[13],[1,\"\\n\"],[10,\"td\"],[14,0,\"value\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@route\",\"@model\"],[\"adminUsersList.show\",\"newuser\"]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,1],[[28,[37,2],[[30,0,[\"report\",\"data\"]]],[[\"level\"],[\"0\"]]]],null]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"],[10,\"td\"],[14,0,\"value\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@route\",\"@model\"],[\"adminUsersList.show\",\"basic\"]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,1],[[28,[37,2],[[30,0,[\"report\",\"data\"]]],[[\"level\"],[\"1\"]]]],null]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"],[10,\"td\"],[14,0,\"value\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@route\",\"@model\"],[\"adminUsersList.show\",\"member\"]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,1],[[28,[37,2],[[30,0,[\"report\",\"data\"]]],[[\"level\"],[\"2\"]]]],null]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"],[10,\"td\"],[14,0,\"value\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@route\",\"@model\"],[\"adminUsersList.show\",\"regular\"]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,1],[[28,[37,2],[[30,0,[\"report\",\"data\"]]],[[\"level\"],[\"3\"]]]],null]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"],[10,\"td\"],[14,0,\"value\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@route\",\"@model\"],[\"adminUsersList.show\",\"leader\"]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,1],[[28,[37,2],[[30,0,[\"report\",\"data\"]]],[[\"level\"],[\"4\"]]]],null]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[],false,[\"link-to\",\"number\",\"value-at-tl\"]]",
    "moduleName": "admin/components/admin-report-trust-level-counts.hbs",
    "isStrictMode": false
  });
  const AdminReportTrustLevelCounts = dt7948.c(class AdminReportTrustLevelCounts extends _component.default {}, [(0, _component2.tagName)("tr")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AdminReportTrustLevelCounts);
});