define("admin/models/api-key", ["exports", "@ember/object", "discourse/lib/ajax", "discourse/lib/computed", "discourse/models/rest", "discourse-common/utils/decorators", "admin/models/admin-user"], function (_exports, _object, _ajax, _computed, _rest, _decorators, _adminUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ApiKey extends _rest.default {
    static #_ = (() => dt7948.g(this.prototype, "truncatedKey", [(0, _computed.fmt)("truncated_key", "%@...")]))();
    #truncatedKey = (() => (dt7948.i(this, "truncatedKey"), void 0))();
    get user() {
      return this._user;
    }
    static #_2 = (() => dt7948.n(this.prototype, "user", [(0, _object.computed)("_user")]))();
    set user(value) {
      if (value && !(value instanceof _adminUser.default)) {
        this.set("_user", _adminUser.default.create(value));
      } else {
        this.set("_user", value);
      }
    }
    shortDescription(description) {
      if (!description || description.length < 40) {
        return description;
      }
      return `${description.substring(0, 40)}...`;
    }
    static #_3 = (() => dt7948.n(this.prototype, "shortDescription", [(0, _decorators.default)("description")]))();
    revoke() {
      return (0, _ajax.ajax)(`${this.basePath}/revoke`, {
        type: "POST"
      }).then(result => this.setProperties(result.api_key));
    }
    undoRevoke() {
      return (0, _ajax.ajax)(`${this.basePath}/undo-revoke`, {
        type: "POST"
      }).then(result => this.setProperties(result.api_key));
    }
    createProperties() {
      return this.getProperties("description", "username", "scopes");
    }
    basePath() {
      return this.store.adapterFor("api-key").pathFor(this.store, "api-key", this.id);
    }
    static #_4 = (() => dt7948.n(this.prototype, "basePath", [(0, _decorators.default)()]))();
  }
  _exports.default = ApiKey;
});