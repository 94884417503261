define("admin/templates/admin-badges/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <section class="current-badge content-body">
    <h2>{{i18n "admin.badges.badge_intro.title"}}</h2>
    <p>{{i18n "admin.badges.badge_intro.description"}}</p>
  </section>
  */
  {
    "id": "AiLneK7O",
    "block": "[[[10,\"section\"],[14,0,\"current-badge content-body\"],[12],[1,\"\\n  \"],[10,\"h2\"],[12],[1,[28,[35,0],[\"admin.badges.badge_intro.title\"],null]],[13],[1,\"\\n  \"],[10,2],[12],[1,[28,[35,0],[\"admin.badges.badge_intro.description\"],null]],[13],[1,\"\\n\"],[13]],[],false,[\"i18n\"]]",
    "moduleName": "admin/templates/admin-badges/index.hbs",
    "isStrictMode": false
  });
});