define("admin/templates/dashboard_security", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="sections">
    <PluginOutlet @name="admin-dashboard-security-top" @connectorTagName="div" />
  
    <div class="main-section">
      <AdminReport
        @dataSourceName="suspicious_logins"
        @filters={{this.lastWeekFilters}}
      />
  
      <AdminReport
        @dataSourceName="staff_logins"
        @filters={{this.lastWeekFilters}}
      />
  
      <PluginOutlet
        @name="admin-dashboard-security-bottom"
        @connectorTagName="div"
      />
    </div>
  </div>
  */
  {
    "id": "FUqw+FWb",
    "block": "[[[10,0],[14,0,\"sections\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@name\",\"@connectorTagName\"],[\"admin-dashboard-security-top\",\"div\"]],null],[1,\"\\n\\n  \"],[10,0],[14,0,\"main-section\"],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@dataSourceName\",\"@filters\"],[\"suspicious_logins\",[30,0,[\"lastWeekFilters\"]]]],null],[1,\"\\n\\n    \"],[8,[39,1],null,[[\"@dataSourceName\",\"@filters\"],[\"staff_logins\",[30,0,[\"lastWeekFilters\"]]]],null],[1,\"\\n\\n    \"],[8,[39,0],null,[[\"@name\",\"@connectorTagName\"],[\"admin-dashboard-security-bottom\",\"div\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"plugin-outlet\",\"admin-report\"]]",
    "moduleName": "admin/templates/dashboard_security.hbs",
    "isStrictMode": false
  });
});