define("admin/components/site-settings/tag-list", ["exports", "@ember/component", "@ember/object", "discourse-common/utils/decorators", "@ember/template-factory"], function (_exports, _component, _object, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <TagChooser
    @tags={{this.selectedTags}}
    @onChange={{this.changeSelectedTags}}
    @everyTag={{true}}
    @unlimitedTagCount={{true}}
    @options={{hash allowAny=false}}
  />
  */
  {
    "id": "aAAJV+pd",
    "block": "[[[8,[39,0],null,[[\"@tags\",\"@onChange\",\"@everyTag\",\"@unlimitedTagCount\",\"@options\"],[[30,0,[\"selectedTags\"]],[30,0,[\"changeSelectedTags\"]],true,true,[28,[37,1],null,[[\"allowAny\"],[false]]]]],null]],[],false,[\"tag-chooser\",\"hash\"]]",
    "moduleName": "admin/components/site-settings/tag-list.hbs",
    "isStrictMode": false
  });
  class TagList extends _component.default {
    selectedTags(value) {
      return value.split("|").filter(Boolean);
    }
    static #_ = (() => dt7948.n(this.prototype, "selectedTags", [(0, _decorators.default)("value")]))();
    changeSelectedTags(tags) {
      this.set("value", tags.join("|"));
    }
    static #_2 = (() => dt7948.n(this.prototype, "changeSelectedTags", [_object.action]))();
  }
  _exports.default = TagList;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TagList);
});