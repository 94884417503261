define("admin/controllers/admin-web-hooks-show", ["exports", "@glimmer/tracking", "@ember/controller", "@ember/object", "@ember/service", "discourse/lib/ajax-error", "discourse-i18n"], function (_exports, _tracking, _controller, _object, _service, _ajaxError, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminWebHooksShowController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "adminWebHooks", [_controller.inject]))();
    #adminWebHooks = (() => (dt7948.i(this, "adminWebHooks"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "status", [_tracking.tracked]))();
    #status = (() => (dt7948.i(this, "status"), void 0))();
    queryParams = ["status"];
    edit() {
      return this.router.transitionTo("adminWebHooks.edit", this.model);
    }
    static #_5 = (() => dt7948.n(this.prototype, "edit", [_object.action]))();
    destroyWebhook() {
      return this.dialog.deleteConfirm({
        message: _discourseI18n.default.t("admin.web_hooks.delete_confirm"),
        didConfirm: async () => {
          try {
            await this.model.destroyRecord();
            this.adminWebHooks.model.removeObject(this.model);
            this.router.transitionTo("adminWebHooks");
          } catch (e) {
            (0, _ajaxError.popupAjaxError)(e);
          }
        }
      });
    }
    static #_6 = (() => dt7948.n(this.prototype, "destroyWebhook", [_object.action]))();
  }
  _exports.default = AdminWebHooksShowController;
});