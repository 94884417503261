define("admin/controllers/admin-customize-email-templates", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/service"], function (_exports, _controller, _object, _computed, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminCustomizeEmailTemplatesController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    titleSorting = ["title"];
    static #_2 = (() => dt7948.g(this.prototype, "sortedTemplates", [(0, _computed.sort)("emailTemplates", "titleSorting")]))();
    #sortedTemplates = (() => (dt7948.i(this, "sortedTemplates"), void 0))();
    onSelectTemplate(template) {
      this.router.transitionTo("adminCustomizeEmailTemplates.edit", template);
    }
    static #_3 = (() => dt7948.n(this.prototype, "onSelectTemplate", [_object.action]))();
  }
  _exports.default = AdminCustomizeEmailTemplatesController;
});