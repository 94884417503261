define("admin/routes/admin-backups-settings", ["exports", "discourse/routes/discourse", "discourse-i18n", "admin/models/site-setting"], function (_exports, _discourse, _discourseI18n, _siteSetting) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminBackupsSettingsRoute extends _discourse.default {
    queryParams = {
      filter: {
        replace: true
      }
    };
    titleToken() {
      return _discourseI18n.default.t("admin.backups.settings");
    }
    async model(params) {
      return {
        settings: await _siteSetting.default.findAll({
          categories: ["backups"]
        }),
        initialFilter: params.filter
      };
    }
  }
  _exports.default = AdminBackupsSettingsRoute;
});