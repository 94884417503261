define("admin/controllers/admin-email-index", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/service", "@ember/template", "@ember-decorators/object", "discourse/lib/ajax", "discourse/lib/utilities", "discourse-i18n"], function (_exports, _controller, _object, _computed, _service, _template, _object2, _ajax, _utilities, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminEmailIndexController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "sendTestEmailDisabled", [(0, _computed.empty)("testEmailAddress")]))();
    #sendTestEmailDisabled = (() => (dt7948.i(this, "sendTestEmailDisabled"), void 0))();
    /**
      Is the "send test email" button disabled?
       @property sendTestEmailDisabled
    **/
    /**
      Clears the 'sentTestEmail' property on successful send.
       @method testEmailAddressChanged
    **/
    testEmailAddressChanged() {
      this.set("sentTestEmail", false);
    }

    /**
      Sends a test email to the currently entered email address
       @method sendTestEmail
    **/
    static #_3 = (() => dt7948.n(this.prototype, "testEmailAddressChanged", [(0, _object2.observes)("testEmailAddress")]))();
    sendTestEmail() {
      this.setProperties({
        sendingEmail: true,
        sentTestEmail: false
      });
      (0, _ajax.ajax)("/admin/email/test", {
        type: "POST",
        data: {
          email_address: this.testEmailAddress
        }
      }).then(response => this.set("sentTestEmailMessage", response.sent_test_email_message)).catch(e => {
        if (e.jqXHR.responseJSON?.errors) {
          this.dialog.alert({
            message: (0, _template.htmlSafe)(_discourseI18n.default.t("admin.email.error", {
              server_error: (0, _utilities.escapeExpression)(e.jqXHR.responseJSON.errors[0])
            }))
          });
        } else {
          this.dialog.alert({
            message: _discourseI18n.default.t("admin.email.test_error")
          });
        }
      }).finally(() => this.set("sendingEmail", false));
    }
    static #_4 = (() => dt7948.n(this.prototype, "sendTestEmail", [_object.action]))();
  }
  _exports.default = AdminEmailIndexController;
});