define("admin/controllers/admin-dashboard", ["exports", "@ember/controller", "@ember/object", "@ember/service", "discourse/lib/computed", "discourse-common/utils/decorators", "admin/models/admin-dashboard", "admin/models/version-check"], function (_exports, _controller, _object, _service, _computed, _decorators, _adminDashboard, _versionCheck) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PROBLEMS_CHECK_MINUTES = 1;
  class AdminDashboardController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "exceptionController", [(0, _controller.inject)("exception")]))();
    #exceptionController = (() => (dt7948.i(this, "exceptionController"), void 0))();
    isLoading = false;
    dashboardFetchedAt = null;
    static #_4 = (() => dt7948.g(this.prototype, "showVersionChecks", [(0, _computed.setting)("version_checks")]))();
    #showVersionChecks = (() => (dt7948.i(this, "showVersionChecks"), void 0))();
    get visibleTabs() {
      return (this.siteSettings.dashboard_visible_tabs || "").split("|").filter(Boolean);
    }
    static #_5 = (() => dt7948.n(this.prototype, "visibleTabs", [(0, _object.computed)("siteSettings.dashboard_visible_tabs")]))();
    get isModerationTabVisible() {
      return this.visibleTabs.includes("moderation");
    }
    static #_6 = (() => dt7948.n(this.prototype, "isModerationTabVisible", [(0, _object.computed)("visibleTabs")]))();
    get isSecurityTabVisible() {
      return this.visibleTabs.includes("security");
    }
    static #_7 = (() => dt7948.n(this.prototype, "isSecurityTabVisible", [(0, _object.computed)("visibleTabs")]))();
    get isReportsTabVisible() {
      return this.visibleTabs.includes("reports");
    }
    static #_8 = (() => dt7948.n(this.prototype, "isReportsTabVisible", [(0, _object.computed)("visibleTabs")]))();
    fetchProblems() {
      if (this.isLoadingProblems) {
        return;
      }
      if (!this.problemsFetchedAt || moment().subtract(PROBLEMS_CHECK_MINUTES, "minutes").toDate() > this.problemsFetchedAt) {
        this._loadProblems();
      }
    }
    fetchDashboard() {
      const versionChecks = this.siteSettings.version_checks;
      if (this.isLoading || !versionChecks) {
        return;
      }
      if (!this.dashboardFetchedAt || moment().subtract(30, "minutes").toDate() > this.dashboardFetchedAt) {
        this.set("isLoading", true);
        _adminDashboard.default.fetch().then(model => {
          let properties = {
            dashboardFetchedAt: new Date()
          };
          if (versionChecks) {
            properties.versionCheck = _versionCheck.default.create(model.version_check);
          }
          this.setProperties(properties);
        }).catch(e => {
          this.exceptionController.set("thrown", e.jqXHR);
          this.router.replaceWith("exception");
        }).finally(() => {
          this.set("isLoading", false);
        });
      }
    }
    _loadProblems() {
      this.setProperties({
        loadingProblems: true,
        problemsFetchedAt: new Date()
      });
      _adminDashboard.default.fetchProblems().then(model => this.set("problems", model.problems)).finally(() => this.set("loadingProblems", false));
    }
    problemsTimestamp(problemsFetchedAt) {
      return moment(problemsFetchedAt).locale("en").format("LLL");
    }
    static #_9 = (() => dt7948.n(this.prototype, "problemsTimestamp", [(0, _decorators.default)("problemsFetchedAt")]))();
    refreshProblems() {
      this._loadProblems();
    }
    static #_10 = (() => dt7948.n(this.prototype, "refreshProblems", [_object.action]))();
  }
  _exports.default = AdminDashboardController;
});