define("admin/controllers/admin-api-keys-new", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/service", "@ember/utils", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/utils/decorators", "discourse-i18n", "admin/components/modal/api-key-urls"], function (_exports, _controller, _object, _computed, _service, _utils, _ajax, _ajaxError, _decorators, _discourseI18n, _apiKeyUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminApiKeysNewController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    userModes = (() => [{
      id: "all",
      name: _discourseI18n.default.t("admin.api.all_users")
    }, {
      id: "single",
      name: _discourseI18n.default.t("admin.api.single_user")
    }])();
    scopeModes = (() => [{
      id: "granular",
      name: _discourseI18n.default.t("admin.api.scopes.granular")
    }, {
      id: "read_only",
      name: _discourseI18n.default.t("admin.api.scopes.read_only")
    }, {
      id: "global",
      name: _discourseI18n.default.t("admin.api.scopes.global")
    }])();
    globalScopes = null;
    scopes = null;
    static #_3 = (() => dt7948.g(this.prototype, "showUserSelector", [(0, _computed.equal)("userMode", "single")]))();
    #showUserSelector = (() => (dt7948.i(this, "showUserSelector"), void 0))();
    init() {
      super.init(...arguments);
      this._loadScopes();
    }
    saveDisabled(model, showUserSelector) {
      if ((0, _utils.isBlank)(model.description)) {
        return true;
      }
      if (showUserSelector && (0, _utils.isBlank)(model.username)) {
        return true;
      }
      return false;
    }
    static #_4 = (() => dt7948.n(this.prototype, "saveDisabled", [(0, _decorators.default)("model.{description,username}", "showUserSelector")]))();
    updateUsername(selected) {
      this.set("model.username", (0, _object.get)(selected, "firstObject"));
    }
    static #_5 = (() => dt7948.n(this.prototype, "updateUsername", [_object.action]))();
    changeUserMode(userMode) {
      if (userMode === "all") {
        this.model.set("username", null);
      }
      this.set("userMode", userMode);
    }
    static #_6 = (() => dt7948.n(this.prototype, "changeUserMode", [_object.action]))();
    changeScopeMode(scopeMode) {
      this.set("scopeMode", scopeMode);
    }
    static #_7 = (() => dt7948.n(this.prototype, "changeScopeMode", [_object.action]))();
    save() {
      if (this.scopeMode === "granular") {
        const selectedScopes = Object.values(this.scopes).flat().filterBy("selected");
        this.model.set("scopes", selectedScopes);
      } else if (this.scopeMode === "read_only") {
        this.model.set("scopes", [this.globalScopes.findBy("key", "read")]);
      } else if (this.scopeMode === "all") {
        this.model.set("scopes", null);
      }
      return this.model.save().catch(_ajaxError.popupAjaxError);
    }
    static #_8 = (() => dt7948.n(this.prototype, "save", [_object.action]))();
    continue() {
      this.router.transitionTo("adminApiKeys.show", this.model.id);
    }
    static #_9 = (() => dt7948.n(this.prototype, "continue", [_object.action]))();
    showURLs(urls) {
      this.modal.show(_apiKeyUrls.default, {
        model: {
          urls
        }
      });
    }
    static #_10 = (() => dt7948.n(this.prototype, "showURLs", [_object.action]))();
    _loadScopes() {
      return (0, _ajax.ajax)("/admin/api/keys/scopes.json").then(data => {
        // remove global scopes because there is a different dropdown
        this.set("globalScopes", data.scopes.global);
        delete data.scopes.global;
        this.set("scopes", data.scopes);
      }).catch(_ajaxError.popupAjaxError);
    }
  }
  _exports.default = AdminApiKeysNewController;
});