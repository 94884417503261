define("admin/models/email-log", ["exports", "@ember/object", "discourse/lib/ajax", "discourse-common/lib/get-url", "admin/models/admin-user"], function (_exports, _object, _ajax, _getUrl, _adminUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class EmailLog extends _object.default {
    static create(attrs) {
      attrs = attrs || {};
      if (attrs.user) {
        attrs.user = _adminUser.default.create(attrs.user);
      }
      if (attrs.post_url) {
        attrs.post_url = (0, _getUrl.default)(attrs.post_url);
      }
      return super.create(attrs);
    }
    static findAll(filter, offset) {
      filter = filter || {};
      offset = offset || 0;
      const status = filter.status || "sent";
      delete filter.status;
      return (0, _ajax.ajax)(`/admin/email/${status}.json?offset=${offset}`, {
        data: filter
      }).then(logs => logs.map(log => EmailLog.create(log)));
    }
  }
  _exports.default = EmailLog;
});