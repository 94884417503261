define("admin/routes/admin-customize-email-templates", ["exports", "@ember/object", "@ember/routing/route", "@ember/service"], function (_exports, _object, _route, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminCustomizeEmailTemplatesRoute extends _route.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    model() {
      return this.store.findAll("email-template");
    }
    setupController(controller, model) {
      controller.set("emailTemplates", model);
    }
    didTransition() {
      const editController = this.controllerFor("adminCustomizeEmailTemplates.edit");
      if (!editController.emailTemplate) {
        this.router.transitionTo("adminCustomizeEmailTemplates.edit", this.controller.get("sortedTemplates.firstObject"));
      }
    }
    static #_2 = (() => dt7948.n(this.prototype, "didTransition", [_object.action]))();
  }
  _exports.default = AdminCustomizeEmailTemplatesRoute;
});