define("admin/controllers/admin-customize-themes-show", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/computed", "discourse-common/lib/helpers", "discourse-common/utils/decorators", "discourse-i18n", "admin/components/theme-settings-editor", "admin/models/theme", "admin/models/theme-settings", "admin/components/theme-upload-add"], function (_exports, _controller, _object, _computed, _service, _ajax, _ajaxError, _computed2, _helpers, _decorators, _discourseI18n, _themeSettingsEditor, _theme, _themeSettings, _themeUploadAdd) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const THEME_UPLOAD_VAR = 2;
  class AdminCustomizeThemesShowController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    editRouteName = "adminCustomizeThemes.edit";
    static #_5 = (() => dt7948.g(this.prototype, "downloadUrl", [(0, _computed2.url)("model.id", "/admin/customize/themes/%@/export")]))();
    #downloadUrl = (() => (dt7948.i(this, "downloadUrl"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "previewUrl", [(0, _computed2.url)("model.id", "/admin/themes/%@/preview")]))();
    #previewUrl = (() => (dt7948.i(this, "previewUrl"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "getTranslationsUrl", [(0, _computed2.url)("model.id", "model.locale", "/admin/themes/%@/translations/%@")]))();
    #getTranslationsUrl = (() => (dt7948.i(this, "getTranslationsUrl"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "addButtonDisabled", [(0, _computed.empty)("selectedChildThemeId")]))();
    #addButtonDisabled = (() => (dt7948.i(this, "addButtonDisabled"), void 0))();
    static #_9 = (() => dt7948.g(this.prototype, "parentThemesNames", [(0, _computed.mapBy)("model.parentThemes", "name")]))();
    #parentThemesNames = (() => (dt7948.i(this, "parentThemesNames"), void 0))();
    static #_10 = (() => dt7948.g(this.prototype, "availableParentThemes", [(0, _computed.filterBy)("allThemes", "component", false)]))();
    #availableParentThemes = (() => (dt7948.i(this, "availableParentThemes"), void 0))();
    static #_11 = (() => dt7948.g(this.prototype, "availableActiveParentThemes", [(0, _computed.filterBy)("availableParentThemes", "isActive")]))();
    #availableActiveParentThemes = (() => (dt7948.i(this, "availableActiveParentThemes"), void 0))();
    static #_12 = (() => dt7948.g(this.prototype, "availableThemesNames", [(0, _computed.mapBy)("availableParentThemes", "name")]))();
    #availableThemesNames = (() => (dt7948.i(this, "availableThemesNames"), void 0))();
    static #_13 = (() => dt7948.g(this.prototype, "availableActiveThemesNames", [(0, _computed.mapBy)("availableActiveParentThemes", "name")]))();
    #availableActiveThemesNames = (() => (dt7948.i(this, "availableActiveThemesNames"), void 0))();
    static #_14 = (() => dt7948.g(this.prototype, "availableActiveChildThemes", [(0, _computed.filterBy)("availableChildThemes", "hasParents")]))();
    #availableActiveChildThemes = (() => (dt7948.i(this, "availableActiveChildThemes"), void 0))();
    static #_15 = (() => dt7948.g(this.prototype, "availableComponentsNames", [(0, _computed.mapBy)("availableChildThemes", "name")]))();
    #availableComponentsNames = (() => (dt7948.i(this, "availableComponentsNames"), void 0))();
    static #_16 = (() => dt7948.g(this.prototype, "availableActiveComponentsNames", [(0, _computed.mapBy)("availableActiveChildThemes", "name")]))();
    #availableActiveComponentsNames = (() => (dt7948.i(this, "availableActiveComponentsNames"), void 0))();
    static #_17 = (() => dt7948.g(this.prototype, "childThemesNames", [(0, _computed.mapBy)("model.childThemes", "name")]))();
    #childThemesNames = (() => (dt7948.i(this, "childThemesNames"), void 0))();
    static #_18 = (() => dt7948.g(this.prototype, "extraFiles", [(0, _computed.filterBy)("model.theme_fields", "target", "extra_js")]))();
    #extraFiles = (() => (dt7948.i(this, "extraFiles"), void 0))();
    static #_19 = (() => dt7948.g(this.prototype, "hasSettings", [(0, _computed.notEmpty)("settings")]))();
    #hasSettings = (() => (dt7948.i(this, "hasSettings"), void 0))();
    static #_20 = (() => dt7948.g(this.prototype, "hasTranslations", [(0, _computed.notEmpty)("translations")]))();
    #hasTranslations = (() => (dt7948.i(this, "hasTranslations"), void 0))();
    static #_21 = (() => dt7948.g(this.prototype, "sourceIsHttp", [(0, _computed.match)("model.remote_theme.remote_url", /^http(s)?:\/\//)]))();
    #sourceIsHttp = (() => (dt7948.i(this, "sourceIsHttp"), void 0))();
    static #_22 = (() => dt7948.g(this.prototype, "settings", [(0, _computed.readOnly)("model.settings")]))();
    #settings = (() => (dt7948.i(this, "settings"), void 0))();
    showCheckboxes() {
      return !this.model.component || this.model.remote_theme;
    }
    static #_23 = (() => dt7948.n(this.prototype, "showCheckboxes", [(0, _decorators.default)("model.component", "model.remote_theme")]))();
    editedFieldsFormatted() {
      const descriptions = [];
      ["common", "desktop", "mobile"].forEach(target => {
        const fields = this.editedFieldsForTarget(target);
        if (fields.length < 1) {
          return;
        }
        let resultString = _discourseI18n.default.t("admin.customize.theme." + target);
        const formattedFields = fields.map(f => _discourseI18n.default.t("admin.customize.theme." + f.name + ".text")).join(" , ");
        resultString += `: ${formattedFields}`;
        descriptions.push(resultString);
      });
      return descriptions;
    }
    static #_24 = (() => dt7948.n(this.prototype, "editedFieldsFormatted", [(0, _decorators.default)("model.editedFields")]))();
    colorSchemeChanged(colorSchemeId, existingId) {
      colorSchemeId = colorSchemeId === null ? null : parseInt(colorSchemeId, 10);
      return colorSchemeId !== existingId;
    }
    static #_25 = (() => dt7948.n(this.prototype, "colorSchemeChanged", [(0, _decorators.default)("colorSchemeId", "model.color_scheme_id")]))();
    selectableChildThemes(available, childThemes) {
      if (available) {
        const themes = !childThemes ? available : available.filter(theme => !childThemes.includes(theme));
        return themes.length === 0 ? null : themes;
      }
    }
    static #_26 = (() => dt7948.n(this.prototype, "selectableChildThemes", [(0, _decorators.default)("availableChildThemes", "model.childThemes.[]", "model")]))();
    relativesSelectorSettingsForComponent() {
      return _object.default.create({
        list_type: "compact",
        type: "list",
        preview: null,
        anyValue: false,
        setting: "parent_theme_ids",
        label: _discourseI18n.default.t("admin.customize.theme.component_on_themes"),
        choices: this.availableThemesNames,
        default: this.parentThemesNames.join("|"),
        value: this.parentThemesNames.join("|"),
        defaultValues: this.availableActiveThemesNames.join("|"),
        allThemes: this.allThemes,
        setDefaultValuesLabel: _discourseI18n.default.t("admin.customize.theme.add_all_themes")
      });
    }
    static #_27 = (() => dt7948.n(this.prototype, "relativesSelectorSettingsForComponent", [(0, _decorators.default)("model.parentThemes.[]")]))();
    relativesSelectorSettingsForTheme() {
      return _object.default.create({
        list_type: "compact",
        type: "list",
        preview: null,
        anyValue: false,
        setting: "child_theme_ids",
        label: _discourseI18n.default.t("admin.customize.theme.included_components"),
        choices: this.availableComponentsNames,
        default: this.childThemesNames.join("|"),
        value: this.childThemesNames.join("|"),
        defaultValues: this.availableActiveComponentsNames.join("|"),
        allThemes: this.allThemes,
        setDefaultValuesLabel: _discourseI18n.default.t("admin.customize.theme.add_all")
      });
    }
    static #_28 = (() => dt7948.n(this.prototype, "relativesSelectorSettingsForTheme", [(0, _decorators.default)("model.parentThemes.[]")]))();
    availableChildThemes(allThemes) {
      if (!this.get("model.component")) {
        const themeId = this.get("model.id");
        return allThemes.filter(theme => theme.get("id") !== themeId && theme.get("component"));
      }
    }
    static #_29 = (() => dt7948.n(this.prototype, "availableChildThemes", [(0, _decorators.default)("allThemes", "model.component", "model")]))();
    convertKey(component) {
      const type = component ? "component" : "theme";
      return `admin.customize.theme.convert_${type}`;
    }
    static #_30 = (() => dt7948.n(this.prototype, "convertKey", [(0, _decorators.default)("model.component")]))();
    convertIcon(component) {
      return component ? "cube" : "";
    }
    static #_31 = (() => dt7948.n(this.prototype, "convertIcon", [(0, _decorators.default)("model.component")]))();
    convertTooltip(component) {
      const type = component ? "component" : "theme";
      return `admin.customize.theme.convert_${type}_tooltip`;
    }
    static #_32 = (() => dt7948.n(this.prototype, "convertTooltip", [(0, _decorators.default)("model.component")]))();
    translations(translations) {
      return translations.map(setting => _themeSettings.default.create({
        ...setting,
        textarea: true
      }));
    }
    static #_33 = (() => dt7948.n(this.prototype, "translations", [(0, _decorators.default)("model.translations")]))();
    hasOverwrittenHistory(localVersion, remoteVersion, commitsBehind) {
      return localVersion !== remoteVersion && commitsBehind === -1;
    }
    static #_34 = (() => dt7948.n(this.prototype, "hasOverwrittenHistory", [(0, _decorators.default)("model.remote_theme.local_version", "model.remote_theme.remote_version", "model.remote_theme.commits_behind")]))();
    showRemoteError(errorMessage, updating) {
      return errorMessage && !updating;
    }
    static #_35 = (() => dt7948.n(this.prototype, "showRemoteError", [(0, _decorators.default)("model.remoteError", "updatingRemote")]))();
    finishInstall(remoteUrl, localVersion, commitsBehind) {
      return remoteUrl && !localVersion && !commitsBehind;
    }
    static #_36 = (() => dt7948.n(this.prototype, "finishInstall", [(0, _decorators.default)("model.remote_theme.remote_url", "model.remote_theme.local_version", "model.remote_theme.commits_behind")]))();
    editedFieldsForTarget(target) {
      return this.get("model.editedFields").filter(field => field.target === target);
    }
    commitSwitchType() {
      const model = this.model;
      const newValue = !model.get("component");
      model.set("component", newValue);
      if (newValue) {
        this.set("parentController.currentTab", _theme.COMPONENTS);
      } else {
        this.set("parentController.currentTab", _theme.THEMES);
      }
      model.saveChanges("component").then(() => {
        this.set("colorSchemeId", null);
        model.setProperties({
          default: false,
          color_scheme_id: null,
          user_selectable: false,
          child_themes: [],
          childThemes: []
        });
        this.get("parentController.model.content").forEach(theme => {
          const children = (0, _helpers.makeArray)(theme.get("childThemes"));
          const rawChildren = (0, _helpers.makeArray)(theme.get("child_themes"));
          const index = children ? children.indexOf(model) : -1;
          if (index > -1) {
            children.splice(index, 1);
            rawChildren.splice(index, 1);
            theme.setProperties({
              childThemes: children,
              child_themes: rawChildren
            });
          }
        });
      }).catch(_ajaxError.popupAjaxError);
    }
    transitionToEditRoute() {
      this.router.transitionTo(this.editRouteName, this.get("model.id"), "common", "scss");
    }
    remoteThemeLink(remoteThemeUrl, remoteThemeBranch) {
      return remoteThemeBranch ? `${remoteThemeUrl.replace(/\.git$/, "")}/tree/${remoteThemeBranch}` : remoteThemeUrl;
    }
    static #_37 = (() => dt7948.n(this.prototype, "remoteThemeLink", [(0, _decorators.default)("model.remote_theme.remote_url", "model.remote_theme.branch")]))();
    showConvert(userId, defaultTheme) {
      return userId > 0 && !defaultTheme;
    }
    static #_38 = (() => dt7948.n(this.prototype, "showConvert", [(0, _decorators.default)("model.user.id", "model.default")]))();
    refreshModel() {
      this.send("routeRefreshModel");
    }
    static #_39 = (() => dt7948.n(this.prototype, "refreshModel", [_object.action]))();
    updateToLatest() {
      this.set("updatingRemote", true);
      this.model.updateToLatest().catch(_ajaxError.popupAjaxError).finally(() => {
        this.set("updatingRemote", false);
      });
    }
    static #_40 = (() => dt7948.n(this.prototype, "updateToLatest", [_object.action]))();
    checkForThemeUpdates() {
      this.set("updatingRemote", true);
      this.model.checkForUpdates().catch(_ajaxError.popupAjaxError).finally(() => {
        this.set("updatingRemote", false);
      });
    }
    static #_41 = (() => dt7948.n(this.prototype, "checkForThemeUpdates", [_object.action]))();
    addUploadModal() {
      this.modal.show(_themeUploadAdd.default, {
        model: {
          themeFields: this.model.theme_fields,
          addUpload: this.addUpload
        }
      });
    }
    static #_42 = (() => dt7948.n(this.prototype, "addUploadModal", [_object.action]))();
    addUpload(info) {
      let model = this.model;
      model.setField("common", info.name, "", info.upload_id, THEME_UPLOAD_VAR);
      model.saveChanges("theme_fields").catch(e => (0, _ajaxError.popupAjaxError)(e));
    }
    static #_43 = (() => dt7948.n(this.prototype, "addUpload", [_object.action]))();
    get availableLocales() {
      return JSON.parse(this.siteSettings.available_locales);
    }
    get locale() {
      return this.get("model.locale") || this.userLocale || this.siteSettings.default_locale;
    }
    updateLocale(value) {
      this.set("model.locale", value);
      (0, _ajax.ajax)(this.getTranslationsUrl).then(_ref => {
        let {
          translations
        } = _ref;
        return this.set("model.translations", translations);
      });
    }
    static #_44 = (() => dt7948.n(this.prototype, "updateLocale", [_object.action]))();
    cancelChangeScheme() {
      this.set("colorSchemeId", this.get("model.color_scheme_id"));
    }
    static #_45 = (() => dt7948.n(this.prototype, "cancelChangeScheme", [_object.action]))();
    changeScheme() {
      let schemeId = this.colorSchemeId;
      this.set("model.color_scheme_id", schemeId === null ? null : parseInt(schemeId, 10));
      this.model.saveChanges("color_scheme_id");
    }
    static #_46 = (() => dt7948.n(this.prototype, "changeScheme", [_object.action]))();
    startEditingName() {
      this.set("oldName", this.get("model.name"));
      this.set("editingName", true);
    }
    static #_47 = (() => dt7948.n(this.prototype, "startEditingName", [_object.action]))();
    cancelEditingName() {
      this.set("model.name", this.oldName);
      this.set("editingName", false);
    }
    static #_48 = (() => dt7948.n(this.prototype, "cancelEditingName", [_object.action]))();
    finishedEditingName() {
      this.model.saveChanges("name");
      this.set("editingName", false);
    }
    static #_49 = (() => dt7948.n(this.prototype, "finishedEditingName", [_object.action]))();
    editTheme() {
      if (this.get("model.remote_theme.is_git")) {
        this.dialog.confirm({
          message: _discourseI18n.default.t("admin.customize.theme.edit_confirm"),
          didConfirm: () => this.transitionToEditRoute()
        });
      } else {
        this.transitionToEditRoute();
      }
    }
    static #_50 = (() => dt7948.n(this.prototype, "editTheme", [_object.action]))();
    applyDefault() {
      const model = this.model;
      model.saveChanges("default").then(() => {
        if (model.get("default")) {
          this.allThemes.forEach(theme => {
            if (theme !== model && theme.get("default")) {
              theme.set("default", false);
            }
          });
        }
      });
    }
    static #_51 = (() => dt7948.n(this.prototype, "applyDefault", [_object.action]))();
    applyUserSelectable() {
      this.model.saveChanges("user_selectable");
    }
    static #_52 = (() => dt7948.n(this.prototype, "applyUserSelectable", [_object.action]))();
    applyAutoUpdateable() {
      this.model.saveChanges("auto_update");
    }
    static #_53 = (() => dt7948.n(this.prototype, "applyAutoUpdateable", [_object.action]))();
    addChildTheme() {
      let themeId = parseInt(this.selectedChildThemeId, 10);
      let theme = this.allThemes.findBy("id", themeId);
      this.model.addChildTheme(theme).then(() => this.store.findAll("theme"));
    }
    static #_54 = (() => dt7948.n(this.prototype, "addChildTheme", [_object.action]))();
    removeUpload(upload) {
      return this.dialog.yesNoConfirm({
        message: _discourseI18n.default.t("admin.customize.theme.delete_upload_confirm"),
        didConfirm: () => this.model.removeField(upload)
      });
    }
    static #_55 = (() => dt7948.n(this.prototype, "removeUpload", [_object.action]))();
    removeChildTheme(theme) {
      this.model.removeChildTheme(theme).then(() => this.store.findAll("theme"));
    }
    static #_56 = (() => dt7948.n(this.prototype, "removeChildTheme", [_object.action]))();
    destroyTheme() {
      return this.dialog.yesNoConfirm({
        message: _discourseI18n.default.t("admin.customize.delete_confirm", {
          theme_name: this.get("model.name")
        }),
        didConfirm: () => {
          const model = this.model;
          model.setProperties({
            recentlyInstalled: false
          });
          model.destroyRecord().then(() => {
            this.allThemes.removeObject(model);
            this.router.transitionTo("adminCustomizeThemes");
          });
        }
      });
    }
    static #_57 = (() => dt7948.n(this.prototype, "destroyTheme", [_object.action]))();
    showThemeSettingsEditor() {
      this.dialog.alert({
        title: "Edit Settings",
        bodyComponent: _themeSettingsEditor.default,
        bodyComponentModel: {
          model: this.model,
          controller: this
        },
        class: "theme-settings-editor-dialog"
      });
    }
    static #_58 = (() => dt7948.n(this.prototype, "showThemeSettingsEditor", [_object.action]))();
    switchType() {
      const relatives = this.get("model.component") ? this.get("model.parentThemes") : this.get("model.childThemes");
      let message = _discourseI18n.default.t(`${this.convertKey}_alert_generic`);
      if (relatives && relatives.length > 0) {
        message = _discourseI18n.default.t(`${this.convertKey}_alert`, {
          relatives: relatives.map(relative => relative.get("name")).join(", ")
        });
      }
      return this.dialog.yesNoConfirm({
        message,
        didConfirm: () => this.commitSwitchType()
      });
    }
    static #_59 = (() => dt7948.n(this.prototype, "switchType", [_object.action]))();
    enableComponent() {
      this.model.set("enabled", true);
      this.model.saveChanges("enabled").catch(() => this.model.set("enabled", false));
    }
    static #_60 = (() => dt7948.n(this.prototype, "enableComponent", [_object.action]))();
    disableComponent() {
      this.model.set("enabled", false);
      this.model.saveChanges("enabled").catch(() => this.model.set("enabled", true));
    }
    static #_61 = (() => dt7948.n(this.prototype, "disableComponent", [_object.action]))();
    editColorScheme() {
      this.router.transitionTo("adminCustomize.colors.show", this.colorSchemeId);
    }
    static #_62 = (() => dt7948.n(this.prototype, "editColorScheme", [_object.action]))();
  }
  _exports.default = AdminCustomizeThemesShowController;
});