define("admin/components/site-settings/color", ["exports", "@ember/component", "@ember/object", "@ember/template-factory"], function (_exports, _component, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <ColorInput
    @hexValue={{readonly this.value}}
    @valid={{this.valid}}
    @onlyHex={{false}}
    @styleSelection={{false}}
    @onChangeColor={{this.onChangeColor}}
  />
  */
  {
    "id": "TKnKmIRS",
    "block": "[[[8,[39,0],null,[[\"@hexValue\",\"@valid\",\"@onlyHex\",\"@styleSelection\",\"@onChangeColor\"],[[28,[37,1],[[30,0,[\"value\"]]],null],[30,0,[\"valid\"]],false,false,[30,0,[\"onChangeColor\"]]]],null]],[],false,[\"color-input\",\"readonly\"]]",
    "moduleName": "admin/components/site-settings/color.hbs",
    "isStrictMode": false
  });
  function RGBToHex(rgb) {
    // Choose correct separator
    let sep = rgb.includes(",") ? "," : " ";
    // Turn "rgb(r,g,b)" into [r,g,b]
    rgb = rgb.slice(4).split(")")[0].split(sep);
    let r = (+rgb[0]).toString(16),
      g = (+rgb[1]).toString(16),
      b = (+rgb[2]).toString(16);
    if (r.length === 1) {
      r = "0" + r;
    }
    if (g.length === 1) {
      g = "0" + g;
    }
    if (b.length === 1) {
      b = "0" + b;
    }
    return "#" + r + g + b;
  }
  class Color extends _component.default {
    get valid() {
      let value = this.value.toLowerCase();
      let testColor = new Option().style;
      testColor.color = value;
      if (!testColor.color && !value.startsWith("#")) {
        value = `#${value}`;
        testColor = new Option().style;
        testColor.color = value;
      }
      let hexifiedColor = RGBToHex(testColor.color);
      if (hexifiedColor.includes("NaN")) {
        hexifiedColor = testColor.color;
      }
      return testColor.color && hexifiedColor === value;
    }
    static #_ = (() => dt7948.n(this.prototype, "valid", [(0, _object.computed)("value")]))();
    onChangeColor(color) {
      this.set("value", color);
    }
    static #_2 = (() => dt7948.n(this.prototype, "onChangeColor", [_object.action]))();
  }
  _exports.default = Color;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Color);
});