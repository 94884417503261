define("admin/components/site-settings/named-list", ["exports", "@ember/component", "@ember/object", "@ember/template-factory"], function (_exports, _component, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <ListSetting
    @value={{this.settingValue}}
    @settingName={{this.setting.setting}}
    @choices={{this.settingChoices}}
    @nameProperty="name"
    @valueProperty="value"
    @onChange={{this.onChangeListSetting}}
    @options={{hash allowAny=this.allowAny}}
  />
  */
  {
    "id": "w1q9oU68",
    "block": "[[[8,[39,0],null,[[\"@value\",\"@settingName\",\"@choices\",\"@nameProperty\",\"@valueProperty\",\"@onChange\",\"@options\"],[[30,0,[\"settingValue\"]],[30,0,[\"setting\",\"setting\"]],[30,0,[\"settingChoices\"]],\"name\",\"value\",[30,0,[\"onChangeListSetting\"]],[28,[37,1],null,[[\"allowAny\"],[[30,0,[\"allowAny\"]]]]]]],null]],[],false,[\"list-setting\",\"hash\"]]",
    "moduleName": "admin/components/site-settings/named-list.hbs",
    "isStrictMode": false
  });
  class NamedList extends _component.default {
    tokenSeparator = "|";
    get settingValue() {
      return this.value.toString().split(this.tokenSeparator).filter(Boolean);
    }
    static #_ = (() => dt7948.n(this.prototype, "settingValue", [(0, _object.computed)("value")]))();
    get settingChoices() {
      let choices = this.setting.choices;
      if (this.settingValue) {
        const valuesSet = new Set(choices.map(choice => choice.value));
        choices = choices.concat(this.settingValue.filter(value => !valuesSet.has(value)).map(value => ({
          name: value,
          value
        })));
      }
      return choices;
    }
    static #_2 = (() => dt7948.n(this.prototype, "settingChoices", [(0, _object.computed)("setting.choices.[]", "settingValue")]))();
    onChangeListSetting(value) {
      this.set("value", value.join(this.tokenSeparator));
    }
    static #_3 = (() => dt7948.n(this.prototype, "onChangeListSetting", [_object.action]))();
  }
  _exports.default = NamedList;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, NamedList);
});