define("admin/components/modal/form-template-validation-options", ["exports", "@ember/component", "@glimmer/component", "discourse-i18n", "@ember/template-factory"], function (_exports, _component, _component2, _discourseI18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    class="form-templates__validation-options admin-form-template-validation-options-modal"
    @title={{i18n "admin.form_templates.validations_modal.modal_title"}}
    @closeModal={{@closeModal}}
  >
    <:body>
      <table>
        <thead>
          <tr>
            {{#each this.tableHeaders as |header|}}
              <th>{{header}}</th>
            {{/each}}
          </tr>
        </thead>
        <tbody>
          {{#each this.validations as |item|}}
            <tr>
              <td><pre>{{item.key}}</pre></td>
              <td>{{item.type}}</td>
              <td>{{item.description}}</td>
            </tr>
          {{/each}}
        </tbody>
      </table>
    </:body>
  </DModal>
  */
  {
    "id": "WXjKqfQy",
    "block": "[[[8,[39,0],[[24,0,\"form-templates__validation-options admin-form-template-validation-options-modal\"]],[[\"@title\",\"@closeModal\"],[[28,[37,1],[\"admin.form_templates.validations_modal.modal_title\"],null],[30,1]]],[[\"body\"],[[[[1,\"\\n    \"],[10,\"table\"],[12],[1,\"\\n      \"],[10,\"thead\"],[12],[1,\"\\n        \"],[10,\"tr\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"tableHeaders\"]]],null]],null],null,[[[1,\"            \"],[10,\"th\"],[12],[1,[30,2]],[13],[1,\"\\n\"]],[2]],null],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"validations\"]]],null]],null],null,[[[1,\"          \"],[10,\"tr\"],[12],[1,\"\\n            \"],[10,\"td\"],[12],[10,\"pre\"],[12],[1,[30,3,[\"key\"]]],[13],[13],[1,\"\\n            \"],[10,\"td\"],[12],[1,[30,3,[\"type\"]]],[13],[1,\"\\n            \"],[10,\"td\"],[12],[1,[30,3,[\"description\"]]],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[3]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\",\"header\",\"item\"],false,[\"d-modal\",\"i18n\",\"each\",\"-track-array\"]]",
    "moduleName": "admin/components/modal/form-template-validation-options.hbs",
    "isStrictMode": false
  });
  const TABLE_HEADER_KEYS = ["key", "type", "description"];
  const VALIDATION_KEYS = ["required", "minimum", "maximum", "pattern", "type"];
  class FormTemplateValidationOptions extends _component2.default {
    get tableHeaders() {
      const translatedHeaders = [];
      TABLE_HEADER_KEYS.forEach(header => {
        translatedHeaders.push(_discourseI18n.default.t(`admin.form_templates.validations_modal.table_headers.${header}`));
      });
      return translatedHeaders;
    }
    get validations() {
      const translatedValidations = [];
      const prefix = "admin.form_templates.validations_modal.validations";
      VALIDATION_KEYS.forEach(validation => {
        translatedValidations.push({
          key: _discourseI18n.default.t(`${prefix}.${validation}.key`),
          type: _discourseI18n.default.t(`${prefix}.${validation}.type`),
          description: _discourseI18n.default.t(`${prefix}.${validation}.description`)
        });
      });
      return translatedValidations;
    }
  }
  _exports.default = FormTemplateValidationOptions;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FormTemplateValidationOptions);
});