define("admin/adapters/theme", ["exports", "discourse/adapters/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Theme extends _rest.default {
    jsonMode = true;
    basePath() {
      return "/admin/";
    }
    afterFindAll(results) {
      let map = {};
      results.forEach(theme => {
        map[theme.id] = theme;
      });
      results.forEach(theme => {
        let mapped = theme.get("child_themes") || [];
        mapped = mapped.map(t => map[t.id]);
        theme.set("childThemes", mapped);
        let mappedParents = theme.get("parent_themes") || [];
        mappedParents = mappedParents.map(t => map[t.id]);
        theme.set("parentThemes", mappedParents);
      });
      return results;
    }
  }
  _exports.default = Theme;
});