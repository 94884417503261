define("admin/routes/admin-backups-logs", ["exports", "@ember/object", "discourse/lib/preload-store", "discourse/routes/discourse", "discourse-i18n"], function (_exports, _object, _preloadStore, _discourse, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminBackupsLogsRoute extends _discourse.default {
    // since the logs are pushed via the message bus
    // we only want to preload them (hence the beforeModel hook)
    beforeModel() {
      const logs = this.controllerFor("adminBackupsLogs").get("logs");
      // preload the logs if any
      _preloadStore.default.getAndRemove("logs").then(function (preloadedLogs) {
        if (preloadedLogs && preloadedLogs.length) {
          // we need to filter out message like: "[SUCCESS]"
          // and convert POJOs to Ember Objects
          const newLogs = preloadedLogs.filter(log => {
            return log.message.length > 0 && log.message[0] !== "[";
          }).map(log => _object.default.create(log));
          logs.pushObjects(newLogs);
        }
      });
    }
    setupController() {
      /* prevent default behavior */
    }
    titleToken() {
      return _discourseI18n.default.t("admin.backups.menu.logs");
    }
  }
  _exports.default = AdminBackupsLogsRoute;
});