define("admin/templates/customize-colors", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="content-list color-schemes">
    <h3>{{i18n "admin.customize.colors.long_title"}}</h3>
  
    <ul>
      {{#each this.model as |scheme|}}
        {{#unless scheme.is_base}}
          <li>
            <LinkTo
              @route="adminCustomize.colors.show"
              @model={{scheme}}
              @replace={{true}}
            >
              {{d-icon "paintbrush"}}
              {{scheme.description}}
            </LinkTo>
          </li>
        {{/unless}}
      {{/each}}
    </ul>
  
    <DButton
      @action={{this.newColorScheme}}
      @icon="plus"
      @label="admin.customize.new"
      class="btn-default"
    />
  </div>
  
  {{outlet}}
  
  <div class="clearfix"></div>
  */
  {
    "id": "uEUrg4xc",
    "block": "[[[10,0],[14,0,\"content-list color-schemes\"],[12],[1,\"\\n  \"],[10,\"h3\"],[12],[1,[28,[35,0],[\"admin.customize.colors.long_title\"],null]],[13],[1,\"\\n\\n  \"],[10,\"ul\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"model\"]]],null]],null],null,[[[41,[51,[30,1,[\"is_base\"]]],[[[1,\"        \"],[10,\"li\"],[12],[1,\"\\n          \"],[8,[39,4],null,[[\"@route\",\"@model\",\"@replace\"],[\"adminCustomize.colors.show\",[30,1],true]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[35,5],[\"paintbrush\"],null]],[1,\"\\n            \"],[1,[30,1,[\"description\"]]],[1,\"\\n          \"]],[]]]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null]],[1]],null],[1,\"  \"],[13],[1,\"\\n\\n  \"],[8,[39,6],[[24,0,\"btn-default\"]],[[\"@action\",\"@icon\",\"@label\"],[[30,0,[\"newColorScheme\"]],\"plus\",\"admin.customize.new\"]],null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[46,[28,[37,8],null,null],null,null,null],[1,\"\\n\\n\"],[10,0],[14,0,\"clearfix\"],[12],[13]],[\"scheme\"],false,[\"i18n\",\"each\",\"-track-array\",\"unless\",\"link-to\",\"d-icon\",\"d-button\",\"component\",\"-outlet\"]]",
    "moduleName": "admin/templates/customize-colors.hbs",
    "isStrictMode": false
  });
});