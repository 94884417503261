define("admin/models/permalink", ["exports", "@ember/object", "discourse/lib/ajax", "discourse/lib/url", "discourse/models/category", "discourse-common/utils/decorators"], function (_exports, _object, _ajax, _url, _category2, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Permalink extends _object.default {
    static findAll(filter) {
      return (0, _ajax.ajax)("/admin/permalinks.json", {
        data: {
          filter
        }
      }).then(function (permalinks) {
        return permalinks.map(p => Permalink.create(p));
      });
    }
    save() {
      return (0, _ajax.ajax)("/admin/permalinks.json", {
        type: "POST",
        data: {
          url: this.url,
          permalink_type: this.permalink_type,
          permalink_type_value: this.permalink_type_value
        }
      });
    }
    category(category_id) {
      return _category2.default.findById(category_id);
    }
    static #_ = (() => dt7948.n(this.prototype, "category", [(0, _decorators.default)("category_id")]))();
    linkIsExternal(external_url) {
      return !_url.default.isInternal(external_url);
    }
    static #_2 = (() => dt7948.n(this.prototype, "linkIsExternal", [(0, _decorators.default)("external_url")]))();
    destroy() {
      return (0, _ajax.ajax)("/admin/permalinks/" + this.id + ".json", {
        type: "DELETE"
      });
    }
  }
  _exports.default = Permalink;
});