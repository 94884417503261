define("admin/controllers/admin-web-hooks-index", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/service", "discourse/lib/ajax-error", "discourse-i18n"], function (_exports, _controller, _object, _computed, _service, _ajaxError, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminWebHooksIndexController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "adminWebHooks", [_controller.inject]))();
    #adminWebHooks = (() => (dt7948.i(this, "adminWebHooks"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "contentTypes", [(0, _computed.alias)("adminWebHooks.contentTypes")]))();
    #contentTypes = (() => (dt7948.i(this, "contentTypes"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "defaultEventTypes", [(0, _computed.alias)("adminWebHooks.defaultEventTypes")]))();
    #defaultEventTypes = (() => (dt7948.i(this, "defaultEventTypes"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "deliveryStatuses", [(0, _computed.alias)("adminWebHooks.deliveryStatuses")]))();
    #deliveryStatuses = (() => (dt7948.i(this, "deliveryStatuses"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "eventTypes", [(0, _computed.alias)("adminWebHooks.eventTypes")]))();
    #eventTypes = (() => (dt7948.i(this, "eventTypes"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "model", [(0, _computed.alias)("adminWebHooks.model")]))();
    #model = (() => (dt7948.i(this, "model"), void 0))();
    destroyWebhook(webhook) {
      return this.dialog.deleteConfirm({
        message: _discourseI18n.default.t("admin.web_hooks.delete_confirm"),
        didConfirm: async () => {
          try {
            await webhook.destroyRecord();
            this.model.removeObject(webhook);
          } catch (e) {
            (0, _ajaxError.popupAjaxError)(e);
          }
        }
      });
    }
    static #_8 = (() => dt7948.n(this.prototype, "destroyWebhook", [_object.action]))();
    loadMore() {
      this.model.loadMore();
    }
    static #_9 = (() => dt7948.n(this.prototype, "loadMore", [_object.action]))();
  }
  _exports.default = AdminWebHooksIndexController;
});