define("admin/components/admin-report-table-row", ["exports", "@ember/component", "@ember-decorators/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each this.labels as |label|}}
    <AdminReportTableCell
      @label={{label}}
      @data={{this.data}}
      @options={{this.options}}
    />
  {{/each}}
  */
  {
    "id": "81CURoUf",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"labels\"]]],null]],null],null,[[[1,\"  \"],[8,[39,2],null,[[\"@label\",\"@data\",\"@options\"],[[30,1],[30,0,[\"data\"]],[30,0,[\"options\"]]]],null],[1,\"\\n\"]],[1]],null]],[\"label\"],false,[\"each\",\"-track-array\",\"admin-report-table-cell\"]]",
    "moduleName": "admin/components/admin-report-table-row.hbs",
    "isStrictMode": false
  });
  const AdminReportTableRow = dt7948.c(class AdminReportTableRow extends _component.default {
    options = null;
  }, [(0, _component2.classNames)("admin-report-table-row"), (0, _component2.tagName)("tr")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AdminReportTableRow);
});