define("admin/controllers/admin-api-keys-index", ["exports", "@ember/controller", "@ember/object", "discourse/lib/ajax-error"], function (_exports, _controller, _object, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminApiKeysIndexController extends _controller.default {
    loading = false;
    revokeKey(key) {
      key.revoke().catch(_ajaxError.popupAjaxError);
    }
    static #_ = (() => dt7948.n(this.prototype, "revokeKey", [_object.action]))();
    undoRevokeKey(key) {
      key.undoRevoke().catch(_ajaxError.popupAjaxError);
    }
    static #_2 = (() => dt7948.n(this.prototype, "undoRevokeKey", [_object.action]))();
    loadMore() {
      if (this.loading || this.model.loaded) {
        return;
      }
      const limit = 50;
      this.set("loading", true);
      this.store.findAll("api-key", {
        offset: this.model.length,
        limit
      }).then(keys => {
        this.model.addObjects(keys);
        if (keys.length < limit) {
          this.model.set("loaded", true);
        }
      }).finally(() => {
        this.set("loading", false);
      });
    }
    static #_3 = (() => dt7948.n(this.prototype, "loadMore", [_object.action]))();
  }
  _exports.default = AdminApiKeysIndexController;
});