define("admin/controllers/admin-email-skipped", ["exports", "@ember-decorators/object", "discourse-common/config/environment", "discourse-common/lib/debounce", "admin/controllers/admin-email-logs"], function (_exports, _object, _environment, _debounce, _adminEmailLogs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminEmailSkippedController extends _adminEmailLogs.default {
    filterEmailLogs() {
      (0, _debounce.default)(this, this.loadLogs, _environment.INPUT_DELAY);
    }
    static #_ = (() => dt7948.n(this.prototype, "filterEmailLogs", [(0, _object.observes)("filter.{status,user,address,type}")]))();
  }
  _exports.default = AdminEmailSkippedController;
});