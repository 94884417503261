define("admin/models/backup", ["exports", "@ember/object", "message-bus-client", "discourse/lib/ajax"], function (_exports, _object, _messageBusClient, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Backup extends _object.default {
    static find() {
      return (0, _ajax.ajax)("/admin/backups.json");
    }
    static start(withUploads) {
      if (withUploads === undefined) {
        withUploads = true;
      }
      return (0, _ajax.ajax)("/admin/backups", {
        type: "POST",
        data: {
          with_uploads: withUploads,
          client_id: _messageBusClient.default.clientId
        }
      });
    }
    static cancel() {
      return (0, _ajax.ajax)("/admin/backups/cancel.json", {
        type: "DELETE"
      });
    }
    static rollback() {
      return (0, _ajax.ajax)("/admin/backups/rollback.json", {
        type: "POST"
      });
    }
    destroy() {
      return (0, _ajax.ajax)("/admin/backups/" + this.filename, {
        type: "DELETE"
      });
    }
    restore() {
      return (0, _ajax.ajax)("/admin/backups/" + this.filename + "/restore", {
        type: "POST",
        data: {
          client_id: _messageBusClient.default.clientId
        }
      });
    }
  }
  _exports.default = Backup;
});