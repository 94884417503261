define("admin/controllers/admin-customize-colors", ["exports", "@ember/controller", "@ember/object", "@ember/service", "discourse-common/utils/decorators", "discourse-i18n", "admin/components/modal/color-scheme-select-base"], function (_exports, _controller, _object, _service, _decorators, _discourseI18n, _colorSchemeSelectBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminCustomizeColorsController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    baseColorScheme() {
      return this.model.findBy("is_base", true);
    }
    static #_3 = (() => dt7948.n(this.prototype, "baseColorScheme", [(0, _decorators.default)("model.@each.id")]))();
    baseColorSchemes() {
      return this.model.filterBy("is_base", true);
    }
    static #_4 = (() => dt7948.n(this.prototype, "baseColorSchemes", [(0, _decorators.default)("model.@each.id")]))();
    baseColors(baseColorScheme) {
      const baseColorsHash = _object.default.create({});
      baseColorScheme.get("colors").forEach(color => {
        baseColorsHash.set(color.get("name"), color);
      });
      return baseColorsHash;
    }
    static #_5 = (() => dt7948.n(this.prototype, "baseColors", [(0, _decorators.default)("baseColorScheme")]))();
    newColorSchemeWithBase(baseKey) {
      const base = this.baseColorSchemes.findBy("base_scheme_id", baseKey);
      const newColorScheme = base.copy();
      newColorScheme.setProperties({
        name: _discourseI18n.default.t("admin.customize.colors.new_name"),
        base_scheme_id: base.get("base_scheme_id")
      });
      newColorScheme.save().then(() => {
        this.model.pushObject(newColorScheme);
        newColorScheme.set("savingStatus", null);
        this.router.replaceWith("adminCustomize.colors.show", newColorScheme);
      });
    }
    static #_6 = (() => dt7948.n(this.prototype, "newColorSchemeWithBase", [_object.action]))();
    newColorScheme() {
      this.modal.show(_colorSchemeSelectBase.default, {
        model: {
          baseColorSchemes: this.baseColorSchemes,
          newColorSchemeWithBase: this.newColorSchemeWithBase
        }
      });
    }
    static #_7 = (() => dt7948.n(this.prototype, "newColorScheme", [_object.action]))();
  }
  _exports.default = AdminCustomizeColorsController;
});