define("admin/routes/admin-plugins-index", ["exports", "@ember/routing/route", "@ember/service"], function (_exports, _route, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsIndexRoute extends _route.default {
    static #_ = (() => dt7948.g(this.prototype, "adminPluginNavManager", [_service.service]))();
    #adminPluginNavManager = (() => (dt7948.i(this, "adminPluginNavManager"), void 0))();
    afterModel() {
      this.adminPluginNavManager.viewingPluginsList = true;
    }
    deactivate() {
      this.adminPluginNavManager.viewingPluginsList = false;
    }
  }
  _exports.default = AdminPluginsIndexRoute;
});