define("admin/templates/plugins", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.showTopNav}}
    <div class="admin-page-header">
      <DBreadcrumbsContainer />
      <DBreadcrumbsItem @path="/admin" @label={{i18n "admin_title"}} />
      <DBreadcrumbsItem
        @path="/admin/plugins"
        @label={{i18n "admin.plugins.title"}}
      />
      <div class="admin-nav-submenu">
        <HorizontalOverflowNav class="main-nav nav plugin-nav">
          <NavItem @route="adminPlugins.index" @label="admin.plugins.title" />
          {{#each this.adminRoutes as |route|}}
            {{#if route.use_new_show_route}}
              <NavItem
                @route={{route.full_location}}
                @label={{route.label}}
                @routeParam={{route.location}}
                @class="admin-plugin-tab-nav-item"
                data-plugin-nav-tab-id={{route.plugin_id}}
              />
            {{else}}
              <NavItem
                @route={{route.full_location}}
                @label={{route.label}}
                @class="admin-plugin-tab-nav-item"
                data-plugin-nav-tab-id={{route.plugin_id}}
              />
            {{/if}}
          {{/each}}
        </HorizontalOverflowNav>
      </div>
    </div>
  {{/if}}
  
  <div class="admin-container -no-header">
    {{#each this.brokenAdminRoutes as |route|}}
      <div class="alert alert-error">
        {{i18n "admin.plugins.broken_route" name=(i18n route.label)}}
      </div>
    {{/each}}
  
    {{outlet}}
  </div>
  */
  {
    "id": "GuGuKSAc",
    "block": "[[[41,[30,0,[\"showTopNav\"]],[[[1,\"  \"],[10,0],[14,0,\"admin-page-header\"],[12],[1,\"\\n    \"],[8,[39,1],null,null,null],[1,\"\\n    \"],[8,[39,2],null,[[\"@path\",\"@label\"],[\"/admin\",[28,[37,3],[\"admin_title\"],null]]],null],[1,\"\\n    \"],[8,[39,2],null,[[\"@path\",\"@label\"],[\"/admin/plugins\",[28,[37,3],[\"admin.plugins.title\"],null]]],null],[1,\"\\n    \"],[10,0],[14,0,\"admin-nav-submenu\"],[12],[1,\"\\n      \"],[8,[39,4],[[24,0,\"main-nav nav plugin-nav\"]],null,[[\"default\"],[[[[1,\"\\n        \"],[8,[39,5],null,[[\"@route\",\"@label\"],[\"adminPlugins.index\",\"admin.plugins.title\"]],null],[1,\"\\n\"],[42,[28,[37,7],[[28,[37,7],[[30,0,[\"adminRoutes\"]]],null]],null],null,[[[41,[30,1,[\"use_new_show_route\"]],[[[1,\"            \"],[8,[39,5],[[16,\"data-plugin-nav-tab-id\",[30,1,[\"plugin_id\"]]]],[[\"@route\",\"@label\",\"@routeParam\",\"@class\"],[[30,1,[\"full_location\"]],[30,1,[\"label\"]],[30,1,[\"location\"]],\"admin-plugin-tab-nav-item\"]],null],[1,\"\\n\"]],[]],[[[1,\"            \"],[8,[39,5],[[16,\"data-plugin-nav-tab-id\",[30,1,[\"plugin_id\"]]]],[[\"@route\",\"@label\",\"@class\"],[[30,1,[\"full_location\"]],[30,1,[\"label\"]],\"admin-plugin-tab-nav-item\"]],null],[1,\"\\n\"]],[]]]],[1]],null],[1,\"      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[10,0],[14,0,\"admin-container -no-header\"],[12],[1,\"\\n\"],[42,[28,[37,7],[[28,[37,7],[[30,0,[\"brokenAdminRoutes\"]]],null]],null],null,[[[1,\"    \"],[10,0],[14,0,\"alert alert-error\"],[12],[1,\"\\n      \"],[1,[28,[35,3],[\"admin.plugins.broken_route\"],[[\"name\"],[[28,[37,3],[[30,2,[\"label\"]]],null]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[2]],null],[1,\"\\n  \"],[46,[28,[37,9],null,null],null,null,null],[1,\"\\n\"],[13]],[\"route\",\"route\"],false,[\"if\",\"d-breadcrumbs-container\",\"d-breadcrumbs-item\",\"i18n\",\"horizontal-overflow-nav\",\"nav-item\",\"each\",\"-track-array\",\"component\",\"-outlet\"]]",
    "moduleName": "admin/templates/plugins.hbs",
    "isStrictMode": false
  });
});