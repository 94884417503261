define("admin/templates/config-flags", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <AdminPageHeader
    @titleLabel="admin.config_areas.flags.header"
    @descriptionLabel="admin.config_areas.flags.subheader"
    @learnMoreUrl="https://meta.discourse.org/t/moderation-flags/325589"
    @hideTabs={{this.hideTabs}}
  >
    <:breadcrumbs>
      <DBreadcrumbsItem
        @path="/admin/config/flags"
        @label={{i18n "admin.config_areas.flags.header"}}
      />
    </:breadcrumbs>
    <:tabs>
      <NavItem
        @route="adminConfig.flags.settings"
        @label="settings"
        class="admin-flags-tabs__settings"
      />
      <NavItem
        @route="adminConfig.flags.index"
        @label="admin.config_areas.flags.flags_tab"
        class="admin-flags-tabs__flags"
      />
    </:tabs>
  </AdminPageHeader>
  
  <div class="admin-container admin-config-page__main-area">
    {{outlet}}
  </div>
  */
  {
    "id": "a+dZjASd",
    "block": "[[[8,[39,0],null,[[\"@titleLabel\",\"@descriptionLabel\",\"@learnMoreUrl\",\"@hideTabs\"],[\"admin.config_areas.flags.header\",\"admin.config_areas.flags.subheader\",\"https://meta.discourse.org/t/moderation-flags/325589\",[30,0,[\"hideTabs\"]]]],[[\"breadcrumbs\",\"tabs\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@path\",\"@label\"],[\"/admin/config/flags\",[28,[37,2],[\"admin.config_areas.flags.header\"],null]]],null],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,3],[[24,0,\"admin-flags-tabs__settings\"]],[[\"@route\",\"@label\"],[\"adminConfig.flags.settings\",\"settings\"]],null],[1,\"\\n    \"],[8,[39,3],[[24,0,\"admin-flags-tabs__flags\"]],[[\"@route\",\"@label\"],[\"adminConfig.flags.index\",\"admin.config_areas.flags.flags_tab\"]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n\"],[10,0],[14,0,\"admin-container admin-config-page__main-area\"],[12],[1,\"\\n  \"],[46,[28,[37,5],null,null],null,null,null],[1,\"\\n\"],[13]],[],false,[\"admin-page-header\",\"d-breadcrumbs-item\",\"i18n\",\"nav-item\",\"component\",\"-outlet\"]]",
    "moduleName": "admin/templates/config-flags.hbs",
    "isStrictMode": false
  });
});