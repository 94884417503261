define("admin/components/watched-word-uploader", ["exports", "@ember/component", "@ember/object/computed", "@ember-decorators/component", "discourse/lib/uploads", "discourse/mixins/uppy-upload", "discourse-i18n", "@ember/template-factory"], function (_exports, _component, _computed, _component2, _uploads, _uppyUpload, _discourseI18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <label class="btn btn-default {{if this.addDisabled 'disabled'}}">
    {{d-icon "upload"}}
    {{i18n "admin.watched_words.form.upload"}}
    <input
      class="hidden-upload-field"
      disabled={{this.addDisabled}}
      type="file"
    />
  </label>
  */
  {
    "id": "zTjzctb3",
    "block": "[[[10,\"label\"],[15,0,[29,[\"btn btn-default \",[52,[30,0,[\"addDisabled\"]],\"disabled\"]]]],[12],[1,\"\\n  \"],[1,[28,[35,1],[\"upload\"],null]],[1,\"\\n  \"],[1,[28,[35,2],[\"admin.watched_words.form.upload\"],null]],[1,\"\\n  \"],[10,\"input\"],[14,0,\"hidden-upload-field\"],[15,\"disabled\",[30,0,[\"addDisabled\"]]],[14,4,\"file\"],[12],[13],[1,\"\\n\"],[13]],[],false,[\"if\",\"d-icon\",\"i18n\"]]",
    "moduleName": "admin/components/watched-word-uploader.hbs",
    "isStrictMode": false
  });
  const WatchedWordUploader = dt7948.c(class WatchedWordUploader extends _component.default.extend(_uppyUpload.default) {
    type = "txt";
    uploadUrl = "/admin/customize/watched_words/upload";
    static #_ = (() => dt7948.g(this.prototype, "addDisabled", [(0, _computed.alias)("uploading")]))();
    #addDisabled = (() => (dt7948.i(this, "addDisabled"), void 0))();
    preventDirectS3Uploads = true;
    validateUploadedFilesOptions() {
      return {
        skipValidation: true
      };
    }
    _perFileData() {
      return {
        action_key: this.actionKey
      };
    }
    uploadDone() {
      if (this) {
        _uploads.dialog.alert(_discourseI18n.default.t("admin.watched_words.form.upload_successful"));
        this.done();
      }
    }
  }, [(0, _component2.classNames)("watched-words-uploader")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, WatchedWordUploader);
});