define("admin/components/theme-setting-relatives-selector", ["exports", "admin/components/site-setting"], function (_exports, _siteSetting) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ThemeSettingRelativesSelectorComponent extends _siteSetting.default {
    _save() {
      return this.model.save({
        [this.setting.setting]: this.convertNamesToIds()
      }).then(() => this.store.findAll("theme"));
    }
    convertNamesToIds() {
      return this.get("buffered.value").split("|").filter(Boolean).map(themeName => {
        if (themeName !== "") {
          return this.setting.allThemes.find(theme => theme.name === themeName).id;
        }
        return themeName;
      });
    }
  }
  _exports.default = ThemeSettingRelativesSelectorComponent;
});