define("admin/templates/dashboard-new-features", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <ConditionalLoadingSpinner @condition={{this.isLoading}}>
    <div class="admin-config-area">
      <h2>{{i18n "admin.dashboard.new_features.title"}}</h2>
      <p>{{html-safe (i18n "admin.dashboard.new_features.subtitle")}}</p>
      <DashboardNewFeatures />
    </div>
  </ConditionalLoadingSpinner>
  */
  {
    "id": "CP0Dl7ml",
    "block": "[[[8,[39,0],null,[[\"@condition\"],[[30,0,[\"isLoading\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"admin-config-area\"],[12],[1,\"\\n    \"],[10,\"h2\"],[12],[1,[28,[35,1],[\"admin.dashboard.new_features.title\"],null]],[13],[1,\"\\n    \"],[10,2],[12],[1,[28,[35,2],[[28,[37,1],[\"admin.dashboard.new_features.subtitle\"],null]],null]],[13],[1,\"\\n    \"],[8,[39,3],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"conditional-loading-spinner\",\"i18n\",\"html-safe\",\"dashboard-new-features\"]]",
    "moduleName": "admin/templates/dashboard-new-features.hbs",
    "isStrictMode": false
  });
});