define("admin/components/schema-theme-setting/editor/child-tree", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/helper", "@ember/modifier", "@ember/object", "discourse/components/d-button", "discourse-common/helpers/d-icon", "admin/components/schema-theme-setting/editor/child-tree-node", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _helper, _modifier, _object, _dButton, _dIcon, _childTreeNode, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SchemaThemeSettingNewEditorChildTree extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "expanded", [_tracking.tracked], function () {
      return true;
    }))();
    #expanded = (() => (dt7948.i(this, "expanded"), void 0))();
    toggleVisibility() {
      this.expanded = !this.expanded;
    }
    static #_2 = (() => dt7948.n(this.prototype, "toggleVisibility", [_object.action]))();
    onChildClick(index1) {
      return this.args.onChildClick(index1, this.args.name, this.args.parentNodeIndex, this.args.parentNodeText);
    }
    static #_3 = (() => dt7948.n(this.prototype, "onChildClick", [_object.action]))();
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div
          class="schema-theme-setting-editor__tree-node --heading"
          role="button"
          {{on "click" this.toggleVisibility}}
        >
          {{@name}}
          {{dIcon (if this.expanded "chevron-down" "chevron-right")}}
        </div>
    
        {{#if this.expanded}}
          <ul>
            {{#each @objects as |object index|}}
              <ChildTreeNode
                @index={{index}}
                @object={{object}}
                @onChildClick={{fn this.onChildClick index}}
                @schema={{@schema}}
                @generateSchemaTitle={{@generateSchemaTitle}}
                data-test-parent-index={{@parentNodeIndex}}
              />
            {{/each}}
    
            <li class="schema-theme-setting-editor__tree-node --child --add-button">
              <DButton
                @action={{fn @addChildItem @name @parentNodeIndex}}
                @translatedLabel={{@schema.name}}
                @icon="plus"
                class="btn-transparent schema-theme-setting-editor__tree-add-button --child"
                data-test-parent-index={{@parentNodeIndex}}
              />
            </li>
          </ul>
        {{/if}}
      
    */
    {
      "id": "uY5OaUYF",
      "block": "[[[1,\"\\n    \"],[11,0],[24,0,\"schema-theme-setting-editor__tree-node --heading\"],[24,\"role\",\"button\"],[4,[32,0],[\"click\",[30,0,[\"toggleVisibility\"]]],null],[12],[1,\"\\n      \"],[1,[30,1]],[1,\"\\n      \"],[1,[28,[32,1],[[52,[30,0,[\"expanded\"]],\"chevron-down\",\"chevron-right\"]],null]],[1,\"\\n    \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"expanded\"]],[[[1,\"      \"],[10,\"ul\"],[12],[1,\"\\n\"],[42,[28,[31,2],[[28,[31,2],[[30,2]],null]],null],null,[[[1,\"          \"],[8,[32,2],[[16,\"data-test-parent-index\",[30,5]]],[[\"@index\",\"@object\",\"@onChildClick\",\"@schema\",\"@generateSchemaTitle\"],[[30,4],[30,3],[28,[32,3],[[30,0,[\"onChildClick\"]],[30,4]],null],[30,6],[30,7]]],null],[1,\"\\n\"]],[3,4]],null],[1,\"\\n        \"],[10,\"li\"],[14,0,\"schema-theme-setting-editor__tree-node --child --add-button\"],[12],[1,\"\\n          \"],[8,[32,4],[[24,0,\"btn-transparent schema-theme-setting-editor__tree-add-button --child\"],[16,\"data-test-parent-index\",[30,5]]],[[\"@action\",\"@translatedLabel\",\"@icon\"],[[28,[32,3],[[30,8],[30,1],[30,5]],null],[30,6,[\"name\"]],\"plus\"]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@name\",\"@objects\",\"object\",\"index\",\"@parentNodeIndex\",\"@schema\",\"@generateSchemaTitle\",\"@addChildItem\"],false,[\"if\",\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/admin/components/schema-theme-setting/editor/child-tree.js",
      "scope": () => [_modifier.on, _dIcon.default, _childTreeNode.default, _helper.fn, _dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = SchemaThemeSettingNewEditorChildTree;
});