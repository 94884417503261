define("admin/components/admin-config-area-card", ["exports", "@glimmer/component", "@glimmer/tracking", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminConfigAreaCard extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "collapsed", [_tracking.tracked], function () {
      return false;
    }))();
    #collapsed = (() => (dt7948.i(this, "collapsed"), void 0))();
    get computedHeading() {
      if (this.args.heading) {
        return _discourseI18n.default.t(this.args.heading);
      }
      return this.args.translatedHeading;
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <section class="admin-config-area-card" ...attributes>
          <h3 class="admin-config-area-card__title">{{this.computedHeading}}</h3>
          <div class="admin-config-area-card__content">
            {{yield}}
          </div>
        </section>
      
    */
    {
      "id": "euUP9tRP",
      "block": "[[[1,\"\\n    \"],[11,\"section\"],[24,0,\"admin-config-area-card\"],[17,1],[12],[1,\"\\n      \"],[10,\"h3\"],[14,0,\"admin-config-area-card__title\"],[12],[1,[30,0,[\"computedHeading\"]]],[13],[1,\"\\n      \"],[10,0],[14,0,\"admin-config-area-card__content\"],[12],[1,\"\\n        \"],[18,2,null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"&attrs\",\"&default\"],false,[\"yield\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/admin/components/admin-config-area-card.js",
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AdminConfigAreaCard;
});