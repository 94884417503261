define("admin/components/silence-details", ["exports", "@ember/component", "@ember-decorators/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="penalty-reason-controls">
    <label>
      <div class="silence-reason-label">
        {{html-safe (i18n "admin.user.silence_reason_label")}}
      </div>
    </label>
    <TextField
      @value={{this.reason}}
      @placeholderKey="admin.user.silence_reason_placeholder"
      class="silence-reason"
    />
  </div>
  
  <label>
    <div class="silence-message-label">
      {{i18n "admin.user.silence_message"}}
    </div>
  </label>
  <Textarea
    @value={{this.message}}
    class="silence-message"
    placeholder={{i18n "admin.user.silence_message_placeholder"}}
  />
  */
  {
    "id": "8gl27KFN",
    "block": "[[[10,0],[14,0,\"penalty-reason-controls\"],[12],[1,\"\\n  \"],[10,\"label\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"silence-reason-label\"],[12],[1,\"\\n      \"],[1,[28,[35,0],[[28,[37,1],[\"admin.user.silence_reason_label\"],null]],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[8,[39,2],[[24,0,\"silence-reason\"]],[[\"@value\",\"@placeholderKey\"],[[30,0,[\"reason\"]],\"admin.user.silence_reason_placeholder\"]],null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"label\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"silence-message-label\"],[12],[1,\"\\n    \"],[1,[28,[35,1],[\"admin.user.silence_message\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[8,[39,3],[[24,0,\"silence-message\"],[16,\"placeholder\",[28,[37,1],[\"admin.user.silence_message_placeholder\"],null]]],[[\"@value\"],[[30,0,[\"message\"]]]],null]],[],false,[\"html-safe\",\"i18n\",\"text-field\",\"textarea\"]]",
    "moduleName": "admin/components/silence-details.hbs",
    "isStrictMode": false
  });
  const SilenceDetails = dt7948.c(class SilenceDetails extends _component.default {}, [(0, _component2.tagName)("")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SilenceDetails);
});