define("admin/routes/admin-site-text-index", ["exports", "@ember/routing/route", "@ember/service", "discourse/lib/implicit-injections"], function (_exports, _route, _service, _implicitInjections) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const AdminSiteTextIndexRoute = dt7948.c(class AdminSiteTextIndexRoute extends _route.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "store", [_service.service]))();
    #store = (() => (dt7948.i(this, "store"), void 0))();
    queryParams = {
      q: {
        replace: true
      },
      overridden: {
        replace: true
      },
      outdated: {
        replace: true
      },
      untranslated: {
        replace: true
      },
      locale: {
        replace: true
      }
    };
    model(params) {
      return this.store.find("site-text", {
        q: params.q,
        overridden: params.overridden ?? false,
        outdated: params.outdated ?? false,
        untranslated: params.untranslated ?? false,
        locale: params.locale ?? this.siteSettings.default_locale,
        only_selected_locale: params.onlySelectedLocale ?? false
      });
    }
  }, [_implicitInjections.disableImplicitInjections]);
  var _default = _exports.default = AdminSiteTextIndexRoute;
});