define("admin/routes/admin-logs-staff-action-logs", ["exports", "@ember/object", "@ember/service", "discourse/routes/discourse"], function (_exports, _object, _service, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminLogsStaffActionLogsRoute extends _discourse.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    queryParams = {
      filters: {
        refreshModel: true
      }
    };
    beforeModel(transition) {
      const params = transition.to.queryParams;
      const controller = this.controllerFor("admin-logs-staff-action-logs");
      if (controller.filters === null || params.force_refresh) {
        controller.resetFilters();
      }
    }
    deserializeQueryParam(value, urlKey, defaultValueType) {
      if (urlKey === "filters" && value) {
        return _object.default.create(JSON.parse(decodeURIComponent(value)));
      }
      return super.deserializeQueryParam(value, urlKey, defaultValueType);
    }
    serializeQueryParam(value, urlKey, defaultValueType) {
      if (urlKey === "filters") {
        if (value && Object.keys(value).length > 0) {
          return JSON.stringify(value);
        } else {
          return null;
        }
      }
      return super.serializeQueryParam(value, urlKey, defaultValueType);
    }
    onFiltersChange(filters) {
      this.router.transitionTo("adminLogs.staffActionLogs", {
        queryParams: {
          filters
        }
      });
    }
    static #_2 = (() => dt7948.n(this.prototype, "onFiltersChange", [_object.action]))();
  }
  _exports.default = AdminLogsStaffActionLogsRoute;
});