define("admin/components/email-styles-editor", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/service", "discourse-common/utils/decorators", "discourse-i18n", "@ember/template-factory"], function (_exports, _component, _object, _computed, _service, _decorators, _discourseI18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="row">
    <div class="admin-controls">
      <nav>
        <ul class="nav nav-pills">
          <li>
            <LinkTo
              @route="adminCustomizeEmailStyle.edit"
              @model="html"
              @replace={{true}}
            >
              {{i18n "admin.customize.email_style.html"}}
            </LinkTo>
          </li>
          <li>
            <LinkTo
              @route="adminCustomizeEmailStyle.edit"
              @model="css"
              @replace={{true}}
            >
              {{i18n "admin.customize.email_style.css"}}
            </LinkTo>
          </li>
        </ul>
      </nav>
    </div>
  </div>
  
  <AceEditor
    @content={{this.editorContents}}
    @onChange={{fn (mut this.editorContents)}}
    @mode={{this.currentEditorMode}}
    @editorId={{this.editorId}}
    @save={{@save}}
  />
  
  <div class="admin-footer">
    <div class="buttons">
      <DButton
        @action={{this.reset}}
        @disabled={{this.resetDisabled}}
        @label="admin.customize.email_style.reset"
        class="btn-default"
      />
    </div>
  </div>
  */
  {
    "id": "PUBhRC7e",
    "block": "[[[10,0],[14,0,\"row\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"admin-controls\"],[12],[1,\"\\n    \"],[10,\"nav\"],[12],[1,\"\\n      \"],[10,\"ul\"],[14,0,\"nav nav-pills\"],[12],[1,\"\\n        \"],[10,\"li\"],[12],[1,\"\\n          \"],[8,[39,0],null,[[\"@route\",\"@model\",\"@replace\"],[\"adminCustomizeEmailStyle.edit\",\"html\",true]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[35,1],[\"admin.customize.email_style.html\"],null]],[1,\"\\n          \"]],[]]]]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,\"li\"],[12],[1,\"\\n          \"],[8,[39,0],null,[[\"@route\",\"@model\",\"@replace\"],[\"adminCustomizeEmailStyle.edit\",\"css\",true]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[35,1],[\"admin.customize.email_style.css\"],null]],[1,\"\\n          \"]],[]]]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[8,[39,2],null,[[\"@content\",\"@onChange\",\"@mode\",\"@editorId\",\"@save\"],[[30,0,[\"editorContents\"]],[28,[37,3],[[28,[37,4],[[30,0,[\"editorContents\"]]],null]],null],[30,0,[\"currentEditorMode\"]],[30,0,[\"editorId\"]],[30,1]]],null],[1,\"\\n\\n\"],[10,0],[14,0,\"admin-footer\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"buttons\"],[12],[1,\"\\n    \"],[8,[39,5],[[24,0,\"btn-default\"]],[[\"@action\",\"@disabled\",\"@label\"],[[30,0,[\"reset\"]],[30,0,[\"resetDisabled\"]],\"admin.customize.email_style.reset\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@save\"],false,[\"link-to\",\"i18n\",\"ace-editor\",\"fn\",\"mut\",\"d-button\"]]",
    "moduleName": "admin/components/email-styles-editor.hbs",
    "isStrictMode": false
  });
  class EmailStylesEditor extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "editorId", [(0, _computed.reads)("fieldName")]))();
    #editorId = (() => (dt7948.i(this, "editorId"), void 0))();
    currentEditorMode(fieldName) {
      return fieldName === "css" ? "scss" : fieldName;
    }
    static #_3 = (() => dt7948.n(this.prototype, "currentEditorMode", [(0, _decorators.default)("fieldName")]))();
    resetDisabled(fieldName) {
      return this.get(`styles.${fieldName}`) === this.get(`styles.default_${fieldName}`);
    }
    static #_4 = (() => dt7948.n(this.prototype, "resetDisabled", [(0, _decorators.default)("fieldName", "styles.html", "styles.css")]))();
    get editorContents() {
      return this.styles[this.fieldName];
    }
    static #_5 = (() => dt7948.n(this.prototype, "editorContents", [(0, _object.computed)("styles", "fieldName")]))();
    set editorContents(value) {
      this.styles.setField(this.fieldName, value);
    }
    reset() {
      this.dialog.yesNoConfirm({
        message: _discourseI18n.default.t("admin.customize.email_style.reset_confirm", {
          fieldName: _discourseI18n.default.t(`admin.customize.email_style.${this.fieldName}`)
        }),
        didConfirm: () => {
          this.styles.setField(this.fieldName, this.styles.get(`default_${this.fieldName}`));
          this.notifyPropertyChange("editorContents");
        }
      });
    }
    static #_6 = (() => dt7948.n(this.prototype, "reset", [_object.action]))();
  }
  _exports.default = EmailStylesEditor;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, EmailStylesEditor);
});