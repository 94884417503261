define("admin/controllers/admin-email-sent", ["exports", "@ember-decorators/object", "discourse-common/config/environment", "discourse-common/lib/debounce", "admin/controllers/admin-email-logs"], function (_exports, _object, _environment, _debounce, _adminEmailLogs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminEmailSentController extends _adminEmailLogs.default {
    ccAddressDisplayThreshold = 2;
    sortWithAddressFilter = addresses => {
      if (!Array.isArray(addresses) || addresses.length === 0) {
        return [];
      }
      const targetEmail = this.filter.address;
      if (!targetEmail) {
        return addresses;
      }
      return addresses.sort((a, b) => {
        if (a.includes(targetEmail) && !b.includes(targetEmail)) {
          return -1;
        }
        if (!a.includes(targetEmail) && b.includes(targetEmail)) {
          return 1;
        }
        return 0;
      });
    };
    filterEmailLogs() {
      (0, _debounce.default)(this, this.loadLogs, _environment.INPUT_DELAY);
    }
    static #_ = (() => dt7948.n(this.prototype, "filterEmailLogs", [(0, _object.observes)("filter.{status,user,address,type,reply_key}")]))();
  }
  _exports.default = AdminEmailSentController;
});