define("admin/controllers/admin", ["exports", "@ember/controller", "@ember/service", "@ember/string", "discourse-common/utils/decorators"], function (_exports, _controller, _service, _string, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    showAdminSidebar() {
      return this.currentUser.use_admin_sidebar;
    }
    static #_3 = (() => dt7948.n(this.prototype, "showAdminSidebar", [(0, _decorators.default)("currentUser.use_admin_sidebar")]))();
    showGroups(enableGroupDirectory) {
      return !enableGroupDirectory;
    }
    static #_4 = (() => dt7948.n(this.prototype, "showGroups", [(0, _decorators.default)("siteSettings.enable_group_directory")]))();
    showBadges(enableBadges) {
      return this.currentUser.get("admin") && enableBadges;
    }
    static #_5 = (() => dt7948.n(this.prototype, "showBadges", [(0, _decorators.default)("siteSettings.enable_badges")]))();
    adminContentsClassName(currentPath) {
      let cssClasses = currentPath.split(".").filter(segment => {
        return segment !== "index" && segment !== "loading" && segment !== "show" && segment !== "admin";
      }).map(_string.dasherize).join(" ");

      // this is done to avoid breaking css customizations
      if (cssClasses.includes("dashboard")) {
        cssClasses = `${cssClasses} dashboard-next`;
      }
      return cssClasses;
    }
    static #_6 = (() => dt7948.n(this.prototype, "adminContentsClassName", [(0, _decorators.default)("router._router.currentPath")]))();
  }
  _exports.default = AdminController;
});