define("admin/routes/admin", ["exports", "@glimmer/tracking", "@ember/service", "discourse/routes/discourse", "discourse-i18n"], function (_exports, _tracking, _service, _discourse, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminRoute extends _discourse.default {
    static #_ = (() => dt7948.g(this.prototype, "sidebarState", [_service.service]))();
    #sidebarState = (() => (dt7948.i(this, "sidebarState"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "store", [_service.service]))();
    #store = (() => (dt7948.i(this, "store"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "adminSidebarStateManager", [_service.service]))();
    #adminSidebarStateManager = (() => (dt7948.i(this, "adminSidebarStateManager"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "initialSidebarState", [_tracking.tracked]))();
    #initialSidebarState = (() => (dt7948.i(this, "initialSidebarState"), void 0))();
    titleToken() {
      return _discourseI18n.default.t("admin_title");
    }
    activate() {
      this.adminSidebarStateManager.maybeForceAdminSidebar({
        onlyIfAlreadyActive: false
      });
      this.controllerFor("application").setProperties({
        showTop: false
      });
    }
    deactivate(transition) {
      this.controllerFor("application").set("showTop", true);
      if (this.adminSidebarStateManager.currentUserUsingAdminSidebar) {
        if (!transition?.to.name.startsWith("admin")) {
          this.adminSidebarStateManager.stopForcingAdminSidebar();
        }
      }
    }
  }
  _exports.default = AdminRoute;
});