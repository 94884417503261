define("admin/controllers/admin-backups-logs", ["exports", "@ember/controller", "@ember/object/computed"], function (_exports, _controller, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminBackupsLogsController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "adminBackups", [_controller.inject]))();
    #adminBackups = (() => (dt7948.i(this, "adminBackups"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "status", [(0, _computed.alias)("adminBackups.model")]))();
    #status = (() => (dt7948.i(this, "status"), void 0))();
    logs = [];
  }
  _exports.default = AdminBackupsLogsController;
});