define("admin/models/incoming-email", ["exports", "@ember/object", "discourse/lib/ajax", "admin/models/admin-user"], function (_exports, _object, _ajax, _adminUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class IncomingEmail extends _object.default {
    static create(attrs) {
      attrs = attrs || {};
      if (attrs.user) {
        attrs.user = _adminUser.default.create(attrs.user);
      }
      return super.create(attrs);
    }
    static find(id) {
      return (0, _ajax.ajax)(`/admin/email/incoming/${id}.json`);
    }
    static findByBounced(id) {
      return (0, _ajax.ajax)(`/admin/email/incoming_from_bounced/${id}.json`);
    }
    static findAll(filter, offset) {
      filter = filter || {};
      offset = offset || 0;
      const status = filter.status || "received";
      delete filter.status;
      return (0, _ajax.ajax)(`/admin/email/${status}.json?offset=${offset}`, {
        data: filter
      }).then(incomings => incomings.map(incoming => IncomingEmail.create(incoming)));
    }
  }
  _exports.default = IncomingEmail;
});