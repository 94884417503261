define("admin/components/highlighted-code", ["exports", "@glimmer/component", "@ember/service", "ember-modifier", "discourse/lib/highlight-syntax", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _emberModifier, _highlightSyntax, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class HighlightedCode extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "session", [_service.service]))();
    #session = (() => (dt7948.i(this, "session"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    highlight = (() => (0, _emberModifier.modifier)(async element1 => {
      const code1 = document.createElement("code");
      code1.classList.add(`lang-${this.args.lang}`);
      code1.textContent = this.args.code;
      const pre1 = document.createElement("pre");
      pre1.appendChild(code1);
      element1.replaceChildren(pre1);
      await (0, _highlightSyntax.default)(pre1, this.siteSettings, this.session);
    }))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div {{this.highlight}}></div>
      
    */
    {
      "id": "9DJTIwx1",
      "block": "[[[1,\"\\n    \"],[11,0],[4,[30,0,[\"highlight\"]],null,null],[12],[13],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/admin/components/highlighted-code.js",
      "isStrictMode": true
    }), this))();
  }
  _exports.default = HighlightedCode;
});