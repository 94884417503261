define("admin/controllers/admin-email-preview-digest", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/service", "discourse/lib/ajax-error", "admin/models/email-preview"], function (_exports, _controller, _object, _computed, _service, _ajaxError, _emailPreview) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminEmailPreviewDigestController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    username = null;
    lastSeen = null;
    static #_2 = (() => dt7948.g(this.prototype, "emailEmpty", [(0, _computed.empty)("email")]))();
    #emailEmpty = (() => (dt7948.i(this, "emailEmpty"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "sendEmailDisabled", [(0, _computed.or)("emailEmpty", "sendingEmail")]))();
    #sendEmailDisabled = (() => (dt7948.i(this, "sendEmailDisabled"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "showSendEmailForm", [(0, _computed.notEmpty)("model.html_content")]))();
    #showSendEmailForm = (() => (dt7948.i(this, "showSendEmailForm"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "htmlEmpty", [(0, _computed.empty)("model.html_content")]))();
    #htmlEmpty = (() => (dt7948.i(this, "htmlEmpty"), void 0))();
    toggleShowHtml(event) {
      event?.preventDefault();
      this.toggleProperty("showHtml");
    }
    static #_6 = (() => dt7948.n(this.prototype, "toggleShowHtml", [_object.action]))();
    updateUsername(selected) {
      this.set("username", (0, _object.get)(selected, "firstObject"));
    }
    static #_7 = (() => dt7948.n(this.prototype, "updateUsername", [_object.action]))();
    refresh() {
      const model = this.model;
      this.set("loading", true);
      this.set("sentEmail", false);
      let username = this.username;
      if (!username) {
        username = this.currentUser.get("username");
        this.set("username", username);
      }
      _emailPreview.default.findDigest(username, this.lastSeen).then(email => {
        model.setProperties(email.getProperties("html_content", "text_content"));
        this.set("loading", false);
      });
    }
    static #_8 = (() => dt7948.n(this.prototype, "refresh", [_object.action]))();
    sendEmail() {
      this.set("sendingEmail", true);
      this.set("sentEmail", false);
      _emailPreview.default.sendDigest(this.username, this.lastSeen, this.email).then(result => {
        if (result.errors) {
          this.dialog.alert(result.errors);
        } else {
          this.set("sentEmail", true);
        }
      }).catch(_ajaxError.popupAjaxError).finally(() => {
        this.set("sendingEmail", false);
      });
    }
    static #_9 = (() => dt7948.n(this.prototype, "sendEmail", [_object.action]))();
  }
  _exports.default = AdminEmailPreviewDigestController;
});