define("admin/templates/customize-email-templates-edit", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <ComboBox
    @value={{this.emailTemplate.id}}
    @content={{this.adminCustomizeEmailTemplates.sortedTemplates}}
    @onChange={{this.adminCustomizeEmailTemplates.onSelectTemplate}}
    @nameProperty="title"
  />
  
  <div class="email-template">
    <label>{{i18n "admin.customize.email_templates.subject"}}</label>
    {{#if this.hasMultipleSubjects}}
      <h3><LinkTo
          @route="adminSiteText"
          @query={{hash q=this.hasMultipleSubjects}}
        >{{i18n
            "admin.customize.email_templates.multiple_subjects"
          }}</LinkTo></h3>
    {{else}}
      <Input @value={{this.buffered.subject}} />
    {{/if}}
    <br />
  
    <label>{{i18n "admin.customize.email_templates.body"}}</label>
    <DEditor @value={{this.buffered.body}} />
  
    <SaveControls
      @model={{this.emailTemplate}}
      @action={{action "saveChanges"}}
      @saved={{this.saved}}
      @saveDisabled={{this.saveDisabled}}
    >
      {{#if this.emailTemplate.can_revert}}
        <DButton
          @action={{action "revertChanges"}}
          @label="admin.customize.email_templates.revert"
        />
      {{/if}}
    </SaveControls>
  </div>
  */
  {
    "id": "699n87yJ",
    "block": "[[[8,[39,0],null,[[\"@value\",\"@content\",\"@onChange\",\"@nameProperty\"],[[30,0,[\"emailTemplate\",\"id\"]],[30,0,[\"adminCustomizeEmailTemplates\",\"sortedTemplates\"]],[30,0,[\"adminCustomizeEmailTemplates\",\"onSelectTemplate\"]],\"title\"]],null],[1,\"\\n\\n\"],[10,0],[14,0,\"email-template\"],[12],[1,\"\\n  \"],[10,\"label\"],[12],[1,[28,[35,1],[\"admin.customize.email_templates.subject\"],null]],[13],[1,\"\\n\"],[41,[30,0,[\"hasMultipleSubjects\"]],[[[1,\"    \"],[10,\"h3\"],[12],[8,[39,3],null,[[\"@route\",\"@query\"],[\"adminSiteText\",[28,[37,4],null,[[\"q\"],[[30,0,[\"hasMultipleSubjects\"]]]]]]],[[\"default\"],[[[[1,[28,[35,1],[\"admin.customize.email_templates.multiple_subjects\"],null]]],[]]]]],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,5],null,[[\"@value\"],[[30,0,[\"buffered\",\"subject\"]]]],null],[1,\"\\n\"]],[]]],[1,\"  \"],[10,\"br\"],[12],[13],[1,\"\\n\\n  \"],[10,\"label\"],[12],[1,[28,[35,1],[\"admin.customize.email_templates.body\"],null]],[13],[1,\"\\n  \"],[8,[39,6],null,[[\"@value\"],[[30,0,[\"buffered\",\"body\"]]]],null],[1,\"\\n\\n  \"],[8,[39,7],null,[[\"@model\",\"@action\",\"@saved\",\"@saveDisabled\"],[[30,0,[\"emailTemplate\"]],[28,[37,8],[[30,0],\"saveChanges\"],null],[30,0,[\"saved\"]],[30,0,[\"saveDisabled\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"emailTemplate\",\"can_revert\"]],[[[1,\"      \"],[8,[39,9],null,[[\"@action\",\"@label\"],[[28,[37,8],[[30,0],\"revertChanges\"],null],\"admin.customize.email_templates.revert\"]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[]]]]],[1,\"\\n\"],[13]],[],false,[\"combo-box\",\"i18n\",\"if\",\"link-to\",\"hash\",\"input\",\"d-editor\",\"save-controls\",\"action\",\"d-button\"]]",
    "moduleName": "admin/templates/customize-email-templates-edit.hbs",
    "isStrictMode": false
  });
});