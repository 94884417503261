define("admin/components/admin-editable-field", ["exports", "@ember/component", "@ember/object", "@ember-decorators/component", "@ember/template-factory"], function (_exports, _component, _object, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="field">{{i18n this.name}}</div>
  <div class="value">
    {{#if this.editing}}
      <TextField
        @value={{this.buffer}}
        @autofocus="autofocus"
        @autocomplete="off"
      />
    {{else}}
      <a href {{on "click" this.edit}} class="inline-editable-field">
        <span>{{this.value}}</span>
      </a>
    {{/if}}
  </div>
  <div class="controls">
    {{#if this.editing}}
      <DButton
        class="btn-default"
        @action={{this.save}}
        @label="admin.user_fields.save"
      />
      <a href {{on "click" this.edit}}>{{i18n "cancel"}}</a>
    {{else}}
      <DButton class="btn-default" @action={{this.edit}} @icon="pencil" />
    {{/if}}
  </div>
  */
  {
    "id": "yGSBiqc/",
    "block": "[[[10,0],[14,0,\"field\"],[12],[1,[28,[35,0],[[30,0,[\"name\"]]],null]],[13],[1,\"\\n\"],[10,0],[14,0,\"value\"],[12],[1,\"\\n\"],[41,[30,0,[\"editing\"]],[[[1,\"    \"],[8,[39,2],null,[[\"@value\",\"@autofocus\",\"@autocomplete\"],[[30,0,[\"buffer\"]],\"autofocus\",\"off\"]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[11,3],[24,6,\"\"],[24,0,\"inline-editable-field\"],[4,[38,3],[\"click\",[30,0,[\"edit\"]]],null],[12],[1,\"\\n      \"],[10,1],[12],[1,[30,0,[\"value\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n\"],[41,[30,0,[\"editing\"]],[[[1,\"    \"],[8,[39,4],[[24,0,\"btn-default\"]],[[\"@action\",\"@label\"],[[30,0,[\"save\"]],\"admin.user_fields.save\"]],null],[1,\"\\n    \"],[11,3],[24,6,\"\"],[4,[38,3],[\"click\",[30,0,[\"edit\"]]],null],[12],[1,[28,[35,0],[\"cancel\"],null]],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,4],[[24,0,\"btn-default\"]],[[\"@action\",\"@icon\"],[[30,0,[\"edit\"]],\"pencil\"]],null],[1,\"\\n\"]],[]]],[13]],[],false,[\"i18n\",\"if\",\"text-field\",\"on\",\"d-button\"]]",
    "moduleName": "admin/components/admin-editable-field.hbs",
    "isStrictMode": false
  });
  const AdminEditableField = dt7948.c(class AdminEditableField extends _component.default {
    buffer = "";
    editing = false;
    edit(event) {
      event?.preventDefault();
      this.set("buffer", this.value);
      this.toggleProperty("editing");
    }
    static #_ = (() => dt7948.n(this.prototype, "edit", [_object.action]))();
    save() {
      // Action has to toggle 'editing' property.
      this.action(this.buffer);
    }
    static #_2 = (() => dt7948.n(this.prototype, "save", [_object.action]))();
  }, [(0, _component2.tagName)("")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AdminEditableField);
});