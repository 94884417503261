define("admin/routes/admin-site-settings", ["exports", "@ember/object", "discourse/routes/discourse", "admin/models/site-setting"], function (_exports, _object, _discourse, _siteSetting) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminSiteSettingsRoute extends _discourse.default {
    queryParams = {
      filter: {
        replace: true
      }
    };
    model() {
      return _siteSetting.default.findAll();
    }
    afterModel(siteSettings) {
      const controller = this.controllerFor("adminSiteSettings");
      if (!controller.get("visibleSiteSettings")) {
        controller.set("visibleSiteSettings", siteSettings);
      }
    }
    refreshAll() {
      _siteSetting.default.findAll().then(settings => {
        this.controllerFor("adminSiteSettings").set("model", settings);
      });
    }
    static #_ = (() => dt7948.n(this.prototype, "refreshAll", [_object.action]))();
  }
  _exports.default = AdminSiteSettingsRoute;
});