define("admin/controllers/admin-badges/show", ["exports", "@glimmer/tracking", "@ember/controller", "@ember/object", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/lib/get-url", "discourse-i18n", "admin/components/modal/badge-preview"], function (_exports, _tracking, _controller, _object, _service, _ajax, _ajaxError, _getUrl, _discourseI18n, _badgePreview) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const FORM_FIELDS = ["allow_title", "multiple_grant", "listable", "auto_revoke", "enabled", "show_posts", "target_posts", "name", "description", "long_description", "icon", "image_upload_id", "image_url", "query", "badge_grouping_id", "trigger", "badge_type_id"];
  class AdminBadgesShowController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "toasts", [_service.service]))();
    #toasts = (() => (dt7948.i(this, "toasts"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "adminBadges", [_controller.inject]))();
    #adminBadges = (() => (dt7948.i(this, "adminBadges"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "model", [_tracking.tracked]))();
    #model = (() => (dt7948.i(this, "model"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "previewLoading", [_tracking.tracked], function () {
      return false;
    }))();
    #previewLoading = (() => (dt7948.i(this, "previewLoading"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "selectedGraphicType", [_tracking.tracked], function () {
      return null;
    }))();
    #selectedGraphicType = (() => (dt7948.i(this, "selectedGraphicType"), void 0))();
    static #_9 = (() => dt7948.g(this.prototype, "userBadges", [_tracking.tracked]))();
    #userBadges = (() => (dt7948.i(this, "userBadges"), void 0))();
    static #_10 = (() => dt7948.g(this.prototype, "userBadgesAll", [_tracking.tracked]))();
    #userBadgesAll = (() => (dt7948.i(this, "userBadgesAll"), void 0))();
    get formData() {
      const data = (0, _object.getProperties)(this.model, ...FORM_FIELDS);
      if (data.icon === "") {
        data.icon = undefined;
      }
      return data;
    }
    static #_11 = (() => dt7948.n(this.prototype, "formData", [_tracking.cached]))();
    currentBadgeGrouping(data) {
      return this.badgeGroupings.find(bg => bg.id === data.badge_grouping_id)?.name;
    }
    static #_12 = (() => dt7948.n(this.prototype, "currentBadgeGrouping", [_object.action]))();
    get badgeTypes() {
      return this.adminBadges.badgeTypes;
    }
    get badgeGroupings() {
      return this.adminBadges.badgeGroupings;
    }
    get badgeTriggers() {
      return this.adminBadges.badgeTriggers;
    }
    get protectedSystemFields() {
      return this.adminBadges.protectedSystemFields;
    }
    get readOnly() {
      return this.model.system;
    }
    setup() {
      // this is needed because the model doesnt have default values
      // Using `set` here isn't ideal, but we don't know that tracking is set up on the model yet.
      if (this.model) {
        if (!this.model.badge_type_id) {
          this.model.set("badge_type_id", this.badgeTypes?.[0]?.id);
        }
        if (!this.model.badge_grouping_id) {
          this.model.set("badge_grouping_id", this.badgeGroupings?.[0]?.id);
        }
        if (!this.model.trigger) {
          this.model.set("trigger", this.badgeTriggers?.[0]?.id);
        }
      }
    }
    hasQuery(query) {
      return query?.trim?.()?.length > 0;
    }
    get textCustomizationPrefix() {
      return `badges.${this.model.i18n_name}.`;
    }
    onSetImage(upload, _ref) {
      let {
        set
      } = _ref;
      if (upload) {
        set("image_upload_id", upload.id);
        set("image_url", (0, _getUrl.default)(upload.url));
        set("icon", null);
      } else {
        set("image_upload_id", "");
        set("image_url", "");
      }
    }
    static #_13 = (() => dt7948.n(this.prototype, "onSetImage", [_object.action]))();
    onSetIcon(value, _ref2) {
      let {
        set
      } = _ref2;
      set("icon", value);
      set("image_upload_id", "");
      set("image_url", "");
    }
    static #_14 = (() => dt7948.n(this.prototype, "onSetIcon", [_object.action]))();
    showPreview(badge, explain, event) {
      event?.preventDefault();
      this.preview(badge, explain);
    }
    static #_15 = (() => dt7948.n(this.prototype, "showPreview", [_object.action]))();
    validateForm(data, _ref3) {
      let {
        addError,
        removeError
      } = _ref3;
      if (!data.icon && !data.image_url) {
        addError("icon", {
          title: "Icon",
          message: _discourseI18n.default.t("admin.badges.icon_or_image")
        });
        addError("image_url", {
          title: "Image",
          message: _discourseI18n.default.t("admin.badges.icon_or_image")
        });
      } else {
        removeError("image_url");
        removeError("icon");
      }
    }
    static #_16 = (() => dt7948.n(this.prototype, "validateForm", [_object.action]))();
    async preview(badge, explain) {
      try {
        this.previewLoading = true;
        const model = await (0, _ajax.ajax)("/admin/badges/preview.json", {
          type: "POST",
          data: {
            sql: badge.query,
            target_posts: !!badge.target_posts,
            trigger: badge.trigger,
            explain
          }
        });
        this.modal.show(_badgePreview.default, {
          model: {
            badge: model
          }
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        this.dialog.alert("Network error");
      } finally {
        this.previewLoading = false;
      }
    }
    static #_17 = (() => dt7948.n(this.prototype, "preview", [_object.action]))();
    async handleSubmit(formData) {
      let fields = FORM_FIELDS;
      if (formData.system) {
        const protectedFields = this.protectedSystemFields || [];
        fields = fields.filter(f => !protectedFields.includes(f));
      }
      const data = {};
      fields.forEach(function (field) {
        data[field] = formData[field];
      });
      const newBadge = !this.model.id;
      try {
        this.model = await this.model.save(data);
        this.toasts.success({
          data: {
            message: _discourseI18n.default.t("saved")
          }
        });
        if (newBadge) {
          const adminBadges = this.get("adminBadges.model");
          if (!adminBadges.includes(this.model)) {
            adminBadges.pushObject(this.model);
          }
          return this.router.transitionTo("adminBadges.show", this.model.id);
        }
      } catch (error) {
        return (0, _ajaxError.popupAjaxError)(error);
      }
    }
    static #_18 = (() => dt7948.n(this.prototype, "handleSubmit", [_object.action]))();
    registerApi(api) {
      this.formApi = api;
    }
    static #_19 = (() => dt7948.n(this.prototype, "registerApi", [_object.action]))();
    async handleDelete() {
      if (!this.model?.id) {
        return this.router.transitionTo("adminBadges.index");
      }
      return this.dialog.yesNoConfirm({
        message: _discourseI18n.default.t("admin.badges.delete_confirm"),
        didConfirm: async () => {
          try {
            await this.formApi.reset();
            await this.model.destroy();
            this.adminBadges.model.removeObject(this.model);
            this.router.transitionTo("adminBadges.index");
          } catch {
            this.dialog.alert(_discourseI18n.default.t("generic_error"));
          }
        }
      });
    }
    static #_20 = (() => dt7948.n(this.prototype, "handleDelete", [_object.action]))();
  }
  _exports.default = AdminBadgesShowController;
});