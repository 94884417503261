define("admin/templates/api-keys", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <PluginOutlet @name="admin-api-keys">
    {{outlet}}
  </PluginOutlet>
  */
  {
    "id": "bTv95OaP",
    "block": "[[[8,[39,0],null,[[\"@name\"],[\"admin-api-keys\"]],[[\"default\"],[[[[1,\"\\n  \"],[46,[28,[37,2],null,null],null,null,null],[1,\"\\n\"]],[]]]]]],[],false,[\"plugin-outlet\",\"component\",\"-outlet\"]]",
    "moduleName": "admin/templates/api-keys.hbs",
    "isStrictMode": false
  });
});