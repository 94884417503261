define("admin/routes/admin-email-preview-digest", ["exports", "discourse/routes/discourse", "admin/models/email-preview"], function (_exports, _discourse, _emailPreview) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminEmailPreviewDigestRoute extends _discourse.default {
    model() {
      return _emailPreview.default.findDigest(this.currentUser.get("username"));
    }
    afterModel(model) {
      const controller = this.controllerFor("adminEmailPreviewDigest");
      controller.setProperties({
        model,
        username: this.currentUser.get("username"),
        lastSeen: (0, _emailPreview.oneWeekAgo)(),
        showHtml: true
      });
    }
  }
  _exports.default = AdminEmailPreviewDigestRoute;
});