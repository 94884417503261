define("admin/controllers/admin-logs-staff-action-logs", ["exports", "@ember/controller", "@ember/object", "@ember/runloop", "@ember/service", "discourse/lib/export-csv", "discourse/lib/export-result", "discourse-common/utils/decorators", "discourse-i18n", "admin/components/modal/staff-action-log-change", "admin/components/modal/staff-action-log-details"], function (_exports, _controller, _object, _runloop, _service, _exportCsv, _exportResult, _decorators, _discourseI18n, _staffActionLogChange, _staffActionLogDetails) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminLogsStaffActionLogsController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "store", [_service.service]))();
    #store = (() => (dt7948.i(this, "store"), void 0))();
    queryParams = ["filters"];
    model = null;
    filters = null;
    userHistoryActions = null;
    actionFilter(name) {
      return name ? _discourseI18n.default.t("admin.logs.staff_actions.actions." + name) : null;
    }
    static #_3 = (() => dt7948.n(this.prototype, "actionFilter", [(0, _decorators.default)("filters.action_name")]))();
    filtersExists(filters) {
      return filters && Object.keys(filters).length > 0;
    }
    static #_4 = (() => dt7948.n(this.prototype, "filtersExists", [(0, _decorators.default)("filters")]))();
    _refresh() {
      this.store.findAll("staff-action-log", this.filters).then(result => {
        this.set("model", result);
        if (!this.userHistoryActions) {
          this.set("userHistoryActions", result.extras.user_history_actions.map(historyAction => ({
            id: historyAction.id,
            action_id: historyAction.action_id,
            name: _discourseI18n.default.t("admin.logs.staff_actions.actions." + historyAction.id),
            name_raw: historyAction.id
          })).sort((a, b) => a.name.localeCompare(b.name)));
        }
      });
    }
    scheduleRefresh() {
      (0, _runloop.scheduleOnce)("afterRender", this, this._refresh);
    }
    resetFilters() {
      this.setProperties({
        model: _object.default.create({
          loadingMore: true
        }),
        filters: _object.default.create()
      });
      this.scheduleRefresh();
    }
    changeFilters(props) {
      this.set("model", _object.default.create({
        loadingMore: true
      }));
      if (!this.filters) {
        this.set("filters", _object.default.create());
      }
      Object.keys(props).forEach(key => {
        if (props[key] === undefined || props[key] === null) {
          this.filters.set(key, undefined);
          delete this.filters[key];
        } else {
          this.filters.set(key, props[key]);
        }
      });
      this.send("onFiltersChange", this.filters);
      this.scheduleRefresh();
    }
    filterActionIdChanged(filterActionId) {
      if (filterActionId) {
        this.changeFilters({
          action_name: filterActionId,
          action_id: this.userHistoryActions.findBy("id", filterActionId).action_id
        });
      }
    }
    static #_5 = (() => dt7948.n(this.prototype, "filterActionIdChanged", [_object.action]))();
    clearFilter(key, event) {
      event?.preventDefault();
      if (key === "actionFilter") {
        this.set("filterActionId", null);
        this.changeFilters({
          action_name: null,
          action_id: null,
          custom_type: null
        });
      } else {
        this.changeFilters({
          [key]: null
        });
      }
    }
    static #_6 = (() => dt7948.n(this.prototype, "clearFilter", [_object.action]))();
    clearAllFilters(event) {
      event?.preventDefault();
      this.set("filterActionId", null);
      this.resetFilters();
    }
    static #_7 = (() => dt7948.n(this.prototype, "clearAllFilters", [_object.action]))();
    filterByAction(logItem, event) {
      event?.preventDefault();
      this.changeFilters({
        action_name: logItem.get("action_name"),
        action_id: logItem.get("action"),
        custom_type: logItem.get("custom_type")
      });
    }
    static #_8 = (() => dt7948.n(this.prototype, "filterByAction", [_object.action]))();
    filterByStaffUser(acting_user, event) {
      event?.preventDefault();
      this.changeFilters({
        acting_user: acting_user.username
      });
    }
    static #_9 = (() => dt7948.n(this.prototype, "filterByStaffUser", [_object.action]))();
    filterByTargetUser(target_user, event) {
      event?.preventDefault();
      this.changeFilters({
        target_user: target_user.username
      });
    }
    static #_10 = (() => dt7948.n(this.prototype, "filterByTargetUser", [_object.action]))();
    filterBySubject(subject, event) {
      event?.preventDefault();
      this.changeFilters({
        subject
      });
    }
    static #_11 = (() => dt7948.n(this.prototype, "filterBySubject", [_object.action]))();
    exportStaffActionLogs() {
      (0, _exportCsv.exportEntity)("staff_action").then(_exportResult.outputExportResult);
    }
    static #_12 = (() => dt7948.n(this.prototype, "exportStaffActionLogs", [_object.action]))();
    loadMore() {
      this.model.loadMore();
    }
    static #_13 = (() => dt7948.n(this.prototype, "loadMore", [_object.action]))();
    showDetailsModal(model, event) {
      event?.preventDefault();
      this.modal.show(_staffActionLogDetails.default, {
        model: {
          staffActionLog: model
        }
      });
    }
    static #_14 = (() => dt7948.n(this.prototype, "showDetailsModal", [_object.action]))();
    showCustomDetailsModal(model, event) {
      event?.preventDefault();
      this.modal.show(_staffActionLogChange.default, {
        model: {
          staffActionLog: model
        }
      });
    }
    static #_15 = (() => dt7948.n(this.prototype, "showCustomDetailsModal", [_object.action]))();
  }
  _exports.default = AdminLogsStaffActionLogsController;
});