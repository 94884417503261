define("admin/templates/site-settings-category", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.filteredContent}}
    <section class="form-horizontal settings">
      {{#each this.filteredContent as |setting|}}
        <SiteSetting
          @setting={{setting}}
          @afterSave={{route-action "refreshAll"}}
        />
      {{/each}}
      {{#if this.category.hasMore}}
        <p class="warning">{{i18n
            "admin.site_settings.more_site_setting_results"
            count=this.category.maxResults
          }}</p>
      {{/if}}
    </section>
  {{else}}
    <br />
    {{i18n "admin.site_settings.no_results"}}
  {{/if}}
  */
  {
    "id": "oFrtKgH0",
    "block": "[[[41,[30,0,[\"filteredContent\"]],[[[1,\"  \"],[10,\"section\"],[14,0,\"form-horizontal settings\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"filteredContent\"]]],null]],null],null,[[[1,\"      \"],[8,[39,3],null,[[\"@setting\",\"@afterSave\"],[[30,1],[28,[37,4],[\"refreshAll\"],null]]],null],[1,\"\\n\"]],[1]],null],[41,[30,0,[\"category\",\"hasMore\"]],[[[1,\"      \"],[10,2],[14,0,\"warning\"],[12],[1,[28,[35,5],[\"admin.site_settings.more_site_setting_results\"],[[\"count\"],[[30,0,[\"category\",\"maxResults\"]]]]]],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,\"br\"],[12],[13],[1,\"\\n  \"],[1,[28,[35,5],[\"admin.site_settings.no_results\"],null]],[1,\"\\n\"]],[]]]],[\"setting\"],false,[\"if\",\"each\",\"-track-array\",\"site-setting\",\"route-action\",\"i18n\"]]",
    "moduleName": "admin/templates/site-settings-category.hbs",
    "isStrictMode": false
  });
});