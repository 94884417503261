define("admin/templates/customize-themes-show-schema", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="customize-themes-show-schema__header row">
    <LinkTo
      @route="adminCustomizeThemes.show"
      @model={{@model.theme.id}}
      class="btn-transparent customize-themes-show-schema__back"
    >
      {{d-icon "arrow-left"}}{{@model.theme.name}}
    </LinkTo>
    <h2>
      {{i18n
        "admin.customize.theme.schema.title"
        (hash name=@model.setting.setting)
      }}
    </h2>
  </div>
  
  <SchemaThemeSetting::Editor
    @themeId={{@model.theme.id}}
    @setting={{@model.setting}}
  />
  */
  {
    "id": "gKbr8vSh",
    "block": "[[[10,0],[14,0,\"customize-themes-show-schema__header row\"],[12],[1,\"\\n  \"],[8,[39,0],[[24,0,\"btn-transparent customize-themes-show-schema__back\"]],[[\"@route\",\"@model\"],[\"adminCustomizeThemes.show\",[30,1,[\"theme\",\"id\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,1],[\"arrow-left\"],null]],[1,[30,1,[\"theme\",\"name\"]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n  \"],[10,\"h2\"],[12],[1,\"\\n    \"],[1,[28,[35,2],[\"admin.customize.theme.schema.title\",[28,[37,3],null,[[\"name\"],[[30,1,[\"setting\",\"setting\"]]]]]],null]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@themeId\",\"@setting\"],[[30,1,[\"theme\",\"id\"]],[30,1,[\"setting\"]]]],null]],[\"@model\"],false,[\"link-to\",\"d-icon\",\"i18n\",\"hash\",\"schema-theme-setting/editor\"]]",
    "moduleName": "admin/templates/customize-themes-show-schema.hbs",
    "isStrictMode": false
  });
});