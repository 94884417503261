define("admin/controllers/admin-dashboard-reports", ["exports", "@ember/controller", "@ember/object", "discourse-common/config/environment", "discourse-common/lib/debounce", "discourse-common/utils/decorators"], function (_exports, _controller, _object, _environment, _debounce, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminDashboardReportsController extends _controller.default {
    filter = null;
    filteredReports(reports, filter) {
      if (filter) {
        filter = filter.toLowerCase();
        reports = reports.filter(report => {
          return ((0, _object.get)(report, "title") || "").toLowerCase().includes(filter) || ((0, _object.get)(report, "description") || "").toLowerCase().includes(filter);
        });
      }
      const hiddenReports = (this.siteSettings.dashboard_hidden_reports || "").split("|").filter(Boolean);
      reports = reports.filter(report => !hiddenReports.includes(report.type));
      return reports;
    }
    static #_ = (() => dt7948.n(this.prototype, "filteredReports", [(0, _decorators.default)("model.[]", "filter", "siteSettings.dashboard_hidden_reports")]))();
    filterReports(filter) {
      (0, _debounce.default)(this, this._performFiltering, filter, _environment.INPUT_DELAY);
    }
    static #_2 = (() => dt7948.n(this.prototype, "filterReports", [_object.action]))();
    _performFiltering(filter) {
      this.set("filter", filter);
    }
  }
  _exports.default = AdminDashboardReportsController;
});