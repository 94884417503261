define("admin/routes/admin-email-skipped", ["exports", "admin/routes/admin-email-logs"], function (_exports, _adminEmailLogs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminEmailSkippedRoute extends _adminEmailLogs.default {
    status = "skipped";
  }
  _exports.default = AdminEmailSkippedRoute;
});