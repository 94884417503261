define("admin/controllers/admin-search-logs-index", ["exports", "@ember/controller", "discourse-i18n"], function (_exports, _controller, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.DEFAULT_PERIOD = void 0;
  const DEFAULT_PERIOD = _exports.DEFAULT_PERIOD = "yearly";
  class AdminSearchLogsIndexController extends _controller.default {
    loading = false;
    period = (() => DEFAULT_PERIOD)();
    searchType = "all";
    searchTypeOptions = (() => [{
      id: "all",
      name: _discourseI18n.default.t("admin.logs.search_logs.types.all_search_types")
    }, {
      id: "header",
      name: _discourseI18n.default.t("admin.logs.search_logs.types.header")
    }, {
      id: "full_page",
      name: _discourseI18n.default.t("admin.logs.search_logs.types.full_page")
    }])();
  }
  _exports.default = AdminSearchLogsIndexController;
});