define("admin/routes/admin-email-bounced", ["exports", "@ember/object", "@ember/service", "discourse/lib/ajax-error", "admin/models/incoming-email", "admin/routes/admin-email-logs", "admin/components/modal/incoming-email"], function (_exports, _object, _service, _ajaxError, _incomingEmail, _adminEmailLogs, _incomingEmail2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminEmailBouncedRoute extends _adminEmailLogs.default {
    static #_ = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    status = "bounced";
    async showIncomingEmail(id) {
      const model = await this.loadFromBounced(id);
      this.modal.show(_incomingEmail2.default, {
        model
      });
    }
    static #_2 = (() => dt7948.n(this.prototype, "showIncomingEmail", [_object.action]))();
    async loadFromBounced(id) {
      try {
        return await _incomingEmail.default.findByBounced(id);
      } catch (error) {
        (0, _ajaxError.popupAjaxError)(error);
      }
    }
    static #_3 = (() => dt7948.n(this.prototype, "loadFromBounced", [_object.action]))();
  }
  _exports.default = AdminEmailBouncedRoute;
});