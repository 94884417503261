define("admin/components/admin-nav", ["exports", "@ember/component", "@ember-decorators/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const AdminNav = dt7948.c(class AdminNav extends _component.default {
    static #_ = (() => (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="admin-controls">
          <nav>
            <ul class="nav nav-pills">
              {{yield}}
            </ul>
          </nav>
        </div>
      
    */
    {
      "id": "gAAPYJ5B",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"admin-controls\"],[12],[1,\"\\n      \"],[10,\"nav\"],[12],[1,\"\\n        \"],[10,\"ul\"],[14,0,\"nav nav-pills\"],[12],[1,\"\\n          \"],[18,1,null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"&default\"],false,[\"yield\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/admin/components/admin-nav.js",
      "isStrictMode": true
    }), this))();
  }, [(0, _component2.tagName)("")]);
  var _default = _exports.default = AdminNav;
});