define("admin/routes/admin-email-rejected", ["exports", "@ember/object", "@ember/service", "admin/models/incoming-email", "admin/routes/admin-email-incomings", "admin/components/modal/incoming-email"], function (_exports, _object, _service, _incomingEmail, _adminEmailIncomings, _incomingEmail2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminEmailRejectedRoute extends _adminEmailIncomings.default {
    static #_ = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    status = "rejected";
    async showIncomingEmail(id) {
      const model = await _incomingEmail.default.find(id);
      this.modal.show(_incomingEmail2.default, {
        model
      });
    }
    static #_2 = (() => dt7948.n(this.prototype, "showIncomingEmail", [_object.action]))();
  }
  _exports.default = AdminEmailRejectedRoute;
});