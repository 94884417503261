define("admin/models/email-preview", ["exports", "@ember/object", "discourse/lib/ajax"], function (_exports, _object, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.oneWeekAgo = oneWeekAgo;
  class EmailPreview extends _object.default {
    static findDigest(username, lastSeenAt) {
      return (0, _ajax.ajax)("/admin/email/preview-digest.json", {
        data: {
          last_seen_at: lastSeenAt || oneWeekAgo(),
          username
        }
      }).then(result => EmailPreview.create(result));
    }
    static sendDigest(username, lastSeenAt, email) {
      return (0, _ajax.ajax)("/admin/email/send-digest.json", {
        type: "POST",
        data: {
          last_seen_at: lastSeenAt || oneWeekAgo(),
          username,
          email
        }
      });
    }
  }
  _exports.default = EmailPreview;
  function oneWeekAgo() {
    return moment().locale("en").subtract(7, "days").format("YYYY-MM-DD");
  }
});