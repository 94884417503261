define("admin/routes/admin-site-settings-category", ["exports", "@ember/object", "discourse/routes/discourse", "discourse-i18n"], function (_exports, _object, _discourse, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminSiteSettingsCategoryRoute extends _discourse.default {
    model(params) {
      // The model depends on user input, so let the controller do the work:
      this.controllerFor("adminSiteSettingsCategory").set("categoryNameKey", params.category_id);
      this.controllerFor("adminSiteSettings").set("categoryNameKey", params.category_id);
      return _object.default.create({
        nameKey: params.category_id,
        name: _discourseI18n.default.t("admin.site_settings.categories." + params.category_id),
        siteSettings: this.controllerFor("adminSiteSettingsCategory").get("filteredContent")
      });
    }
  }
  _exports.default = AdminSiteSettingsCategoryRoute;
});