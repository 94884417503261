define("admin/templates/site-text", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="row site-texts">
    {{outlet}}
  </div>
  */
  {
    "id": "+18ogahV",
    "block": "[[[10,0],[14,0,\"row site-texts\"],[12],[1,\"\\n  \"],[46,[28,[37,1],null,null],null,null,null],[1,\"\\n\"],[13]],[],false,[\"component\",\"-outlet\"]]",
    "moduleName": "admin/templates/site-text.hbs",
    "isStrictMode": false
  });
});