define("admin/components/admin-penalty-post-action", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "discourse-common/utils/decorators", "discourse-i18n", "@ember/template-factory"], function (_exports, _component, _object, _computed, _decorators, _discourseI18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="penalty-post-controls">
    <label>
      <div class="penalty-post-label">
        {{html-safe (i18n "admin.user.penalty_post_actions")}}
      </div>
    </label>
    <ComboBox
      @value={{this.postAction}}
      @content={{this.penaltyActions}}
      @onChange={{this.penaltyChanged}}
    />
  </div>
  
  {{#if this.editing}}
    <div class="penalty-post-edit">
      <Textarea @value={{this.postEdit}} class="post-editor" />
    </div>
  {{/if}}
  */
  {
    "id": "kSs1CBRg",
    "block": "[[[10,0],[14,0,\"penalty-post-controls\"],[12],[1,\"\\n  \"],[10,\"label\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"penalty-post-label\"],[12],[1,\"\\n      \"],[1,[28,[35,0],[[28,[37,1],[\"admin.user.penalty_post_actions\"],null]],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[8,[39,2],null,[[\"@value\",\"@content\",\"@onChange\"],[[30,0,[\"postAction\"]],[30,0,[\"penaltyActions\"]],[30,0,[\"penaltyChanged\"]]]],null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"editing\"]],[[[1,\"  \"],[10,0],[14,0,\"penalty-post-edit\"],[12],[1,\"\\n    \"],[8,[39,4],[[24,0,\"post-editor\"]],[[\"@value\"],[[30,0,[\"postEdit\"]]]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"html-safe\",\"i18n\",\"combo-box\",\"if\",\"textarea\"]]",
    "moduleName": "admin/components/admin-penalty-post-action.hbs",
    "isStrictMode": false
  });
  const ACTIONS = ["delete", "delete_replies", "edit", "none"];
  class AdminPenaltyPostAction extends _component.default {
    postId = null;
    postAction = null;
    postEdit = null;
    static #_ = (() => dt7948.g(this.prototype, "editing", [(0, _computed.equal)("postAction", "edit")]))();
    #editing = (() => (dt7948.i(this, "editing"), void 0))();
    penaltyActions() {
      return ACTIONS.map(id => {
        return {
          id,
          name: _discourseI18n.default.t(`admin.user.penalty_post_${id}`)
        };
      });
    }
    static #_2 = (() => dt7948.n(this.prototype, "penaltyActions", [_decorators.default]))();
    penaltyChanged(postAction) {
      this.set("postAction", postAction);

      // If we switch to edit mode, jump to the edit textarea
      if (postAction === "edit") {
        this._focusEditTextarea();
      }
    }
    static #_3 = (() => dt7948.n(this.prototype, "penaltyChanged", [_object.action]))();
    _focusEditTextarea() {
      const elem = this.element;
      const body = elem.closest(".d-modal__body");
      body.scrollTo(0, body.clientHeight);
      elem.querySelector(".post-editor").focus();
    }
    static #_4 = (() => dt7948.n(this.prototype, "_focusEditTextarea", [_decorators.afterRender]))();
  }
  _exports.default = AdminPenaltyPostAction;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AdminPenaltyPostAction);
});