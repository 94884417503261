define("admin/components/webhook-status", ["exports", "@glimmer/component", "discourse-common/helpers/d-icon", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _dIcon, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class WebhookStatus extends _component.default {
    iconNames = ["far-circle", "circle-xmark", "circle", "circle"];
    iconClasses = ["text-muted", "text-danger", "text-successful", "text-muted"];
    get status() {
      const lastStatus1 = this.args.webhook.get("last_delivery_status");
      return this.args.deliveryStatuses.find(s1 => s1.id === lastStatus1);
    }
    get deliveryStatus() {
      return _discourseI18n.default.t(`admin.web_hooks.delivery_status.${this.status.name}`);
    }
    get iconName() {
      return this.iconNames[this.status.id - 1];
    }
    get iconClass() {
      return this.iconClasses[this.status.id - 1];
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{icon this.iconName class=this.iconClass}}
        {{this.deliveryStatus}}
      
    */
    {
      "id": "Xk2iQX8z",
      "block": "[[[1,\"\\n    \"],[1,[28,[32,0],[[30,0,[\"iconName\"]]],[[\"class\"],[[30,0,[\"iconClass\"]]]]]],[1,\"\\n    \"],[1,[30,0,[\"deliveryStatus\"]]],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/admin/components/webhook-status.js",
      "scope": () => [_dIcon.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = WebhookStatus;
});