define("admin/routes/admin-watched-words-action", ["exports", "@ember/object", "discourse/routes/discourse", "discourse-i18n"], function (_exports, _object, _discourse, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminWatchedWordsActionRoute extends _discourse.default {
    model(params) {
      const controller = this.controllerFor("adminWatchedWordsAction");
      controller.set("actionNameKey", params.action_id);
      return _object.default.create({
        nameKey: params.action_id,
        name: _discourseI18n.default.t("admin.watched_words.actions." + params.action_id),
        words: controller.filteredContent
      });
    }
  }
  _exports.default = AdminWatchedWordsActionRoute;
});