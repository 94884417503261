define("admin/components/admin-penalty-reason", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember-decorators/component", "discourse-common/utils/decorators", "discourse-i18n", "@ember/template-factory"], function (_exports, _component, _object, _computed, _component2, _decorators, _discourseI18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="penalty-reason-controls">
    {{#if (eq @penaltyType "suspend")}}
      <label class="suspend-reason-title">{{i18n
          "admin.user.suspend_reason_title"
        }}</label>
      <ComboBox
        @content={{this.reasons}}
        @value={{this.selectedReason}}
        @onChange={{this.setSelectedReason}}
        class="suspend-reason"
      />
  
      {{#if this.isCustomReason}}
        <TextField
          @value={{this.customReason}}
          @onChange={{this.setCustomReason}}
          class="suspend-reason"
        />
      {{/if}}
    {{else if (eq @penaltyType "silence")}}
      <label class="silence-reason-title">
        {{html-safe (i18n "admin.user.silence_reason_label")}}</label>
  
      <ComboBox
        @content={{this.reasons}}
        @value={{this.selectedReason}}
        @onChange={{this.setSelectedReason}}
        class="silence-reason"
      />
  
      {{#if this.isCustomReason}}
        <TextField
          @value={{this.customReason}}
          @onChange={{this.setCustomReason}}
          @placeholderKey="admin.user.silence_reason_placeholder"
          class="silence-reason"
        />
      {{/if}}
    {{/if}}
  </div>
  
  <div class="penalty-message-controls">
    <label>{{i18n "admin.user.suspend_message"}}</label>
    <Textarea
      @value={{this.message}}
      class="suspend-message"
      placeholder={{i18n "admin.user.suspend_message_placeholder"}}
    />
  </div>
  */
  {
    "id": "lbxty1LQ",
    "block": "[[[10,0],[14,0,\"penalty-reason-controls\"],[12],[1,\"\\n\"],[41,[28,[37,1],[[30,1],\"suspend\"],null],[[[1,\"    \"],[10,\"label\"],[14,0,\"suspend-reason-title\"],[12],[1,[28,[35,2],[\"admin.user.suspend_reason_title\"],null]],[13],[1,\"\\n    \"],[8,[39,3],[[24,0,\"suspend-reason\"]],[[\"@content\",\"@value\",\"@onChange\"],[[30,0,[\"reasons\"]],[30,0,[\"selectedReason\"]],[30,0,[\"setSelectedReason\"]]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"isCustomReason\"]],[[[1,\"      \"],[8,[39,4],[[24,0,\"suspend-reason\"]],[[\"@value\",\"@onChange\"],[[30,0,[\"customReason\"]],[30,0,[\"setCustomReason\"]]]],null],[1,\"\\n\"]],[]],null]],[]],[[[41,[28,[37,1],[[30,1],\"silence\"],null],[[[1,\"    \"],[10,\"label\"],[14,0,\"silence-reason-title\"],[12],[1,\"\\n      \"],[1,[28,[35,5],[[28,[37,2],[\"admin.user.silence_reason_label\"],null]],null]],[13],[1,\"\\n\\n    \"],[8,[39,3],[[24,0,\"silence-reason\"]],[[\"@content\",\"@value\",\"@onChange\"],[[30,0,[\"reasons\"]],[30,0,[\"selectedReason\"]],[30,0,[\"setSelectedReason\"]]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"isCustomReason\"]],[[[1,\"      \"],[8,[39,4],[[24,0,\"silence-reason\"]],[[\"@value\",\"@onChange\",\"@placeholderKey\"],[[30,0,[\"customReason\"]],[30,0,[\"setCustomReason\"]],\"admin.user.silence_reason_placeholder\"]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[]],null]],[]]],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"penalty-message-controls\"],[12],[1,\"\\n  \"],[10,\"label\"],[12],[1,[28,[35,2],[\"admin.user.suspend_message\"],null]],[13],[1,\"\\n  \"],[8,[39,6],[[24,0,\"suspend-message\"],[16,\"placeholder\",[28,[37,2],[\"admin.user.suspend_message_placeholder\"],null]]],[[\"@value\"],[[30,0,[\"message\"]]]],null],[1,\"\"],[13]],[\"@penaltyType\"],false,[\"if\",\"eq\",\"i18n\",\"combo-box\",\"text-field\",\"html-safe\",\"textarea\"]]",
    "moduleName": "admin/components/admin-penalty-reason.hbs",
    "isStrictMode": false
  });
  const CUSTOM_REASON_KEY = "custom";
  const AdminPenaltyReason = dt7948.c(class AdminPenaltyReason extends _component.default {
    selectedReason = (() => CUSTOM_REASON_KEY)();
    customReason = "";
    reasonKeys = (() => ["not_listening_to_staff", "consuming_staff_time", "combative", "in_wrong_place", "no_constructive_purpose", CUSTOM_REASON_KEY])();
    static #_ = (() => dt7948.g(this.prototype, "isCustomReason", [(0, _computed.equal)("selectedReason", CUSTOM_REASON_KEY)]))();
    #isCustomReason = (() => (dt7948.i(this, "isCustomReason"), void 0))();
    reasons(keys) {
      return keys.map(key => {
        return {
          id: key,
          name: _discourseI18n.default.t(`admin.user.suspend_reasons.${key}`)
        };
      });
    }
    static #_2 = (() => dt7948.n(this.prototype, "reasons", [(0, _decorators.default)("reasonKeys")]))();
    setSelectedReason(value) {
      this.set("selectedReason", value);
      this.setReason();
    }
    static #_3 = (() => dt7948.n(this.prototype, "setSelectedReason", [_object.action]))();
    setCustomReason(value) {
      this.set("customReason", value);
      this.setReason();
    }
    static #_4 = (() => dt7948.n(this.prototype, "setCustomReason", [_object.action]))();
    setReason() {
      if (this.isCustomReason) {
        this.set("reason", this.customReason);
      } else {
        this.set("reason", _discourseI18n.default.t(`admin.user.suspend_reasons.${this.selectedReason}`));
      }
    }
  }, [(0, _component2.tagName)("")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AdminPenaltyReason);
});