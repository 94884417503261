define("admin/components/admin-page-header", ["exports", "@glimmer/component", "@ember/helper", "@ember/template", "discourse/components/d-breadcrumbs-container", "discourse/components/d-breadcrumbs-item", "discourse/components/horizontal-overflow-nav", "discourse-common/helpers/i18n", "admin/components/admin-page-action-button", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _template2, _dBreadcrumbsContainer, _dBreadcrumbsItem, _horizontalOverflowNav, _i18n, _adminPageActionButton, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPageHeader extends _component.default {
    get title() {
      if (this.args.titleLabelTranslated) {
        return this.args.titleLabelTranslated;
      } else if (this.args.titleLabel) {
        return (0, _i18n.default)(this.args.titleLabel);
      }
    }
    get description() {
      if (this.args.descriptionLabelTranslated) {
        return this.args.descriptionLabelTranslated;
      } else if (this.args.descriptionLabel) {
        return (0, _i18n.default)(this.args.descriptionLabel);
      }
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="admin-page-header">
          <div class="admin-page-header__breadcrumbs">
            <DBreadcrumbsContainer />
            <DBreadcrumbsItem @path="/admin" @label={{i18n "admin_title"}} />
            {{yield to="breadcrumbs"}}
          </div>
    
          <div class="admin-page-header__title-row">
            {{#if this.title}}
              <h1 class="admin-page-header__title">{{this.title}}</h1>
            {{/if}}
    
            <div class="admin-page-header__actions">
              {{yield
                (hash
                  Primary=PrimaryButton Default=DefaultButton Danger=DangerButton
                )
                to="actions"
              }}
            </div>
          </div>
    
          {{#if this.description}}
            <p class="admin-page-header__description">
              {{htmlSafe this.description}}
              {{#if @learnMoreUrl}}
                <span class="admin-page-header__learn-more">{{htmlSafe
                    (i18n "learn_more_with_link" url=@learnMoreUrl)
                  }}</span>
              {{/if}}
            </p>
          {{/if}}
    
          {{#unless @hideTabs}}
            <div class="admin-nav-submenu">
              <HorizontalOverflowNav class="admin-nav-submenu__tabs">
                {{yield to="tabs"}}
              </HorizontalOverflowNav>
            </div>
          {{/unless}}
        </div>
      
    */
    {
      "id": "rAiRe2Cr",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"admin-page-header\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"admin-page-header__breadcrumbs\"],[12],[1,\"\\n        \"],[8,[32,0],null,null,null],[1,\"\\n        \"],[8,[32,1],null,[[\"@path\",\"@label\"],[\"/admin\",[28,[32,2],[\"admin_title\"],null]]],null],[1,\"\\n        \"],[18,3,null],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[10,0],[14,0,\"admin-page-header__title-row\"],[12],[1,\"\\n\"],[41,[30,0,[\"title\"]],[[[1,\"          \"],[10,\"h1\"],[14,0,\"admin-page-header__title\"],[12],[1,[30,0,[\"title\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"\\n        \"],[10,0],[14,0,\"admin-page-header__actions\"],[12],[1,\"\\n          \"],[18,4,[[28,[32,3],null,[[\"Primary\",\"Default\",\"Danger\"],[[32,4],[32,5],[32,6]]]]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"description\"]],[[[1,\"        \"],[10,2],[14,0,\"admin-page-header__description\"],[12],[1,\"\\n          \"],[1,[28,[32,7],[[30,0,[\"description\"]]],null]],[1,\"\\n\"],[41,[30,1],[[[1,\"            \"],[10,1],[14,0,\"admin-page-header__learn-more\"],[12],[1,[28,[32,7],[[28,[32,2],[\"learn_more_with_link\"],[[\"url\"],[[30,1]]]]],null]],[13],[1,\"\\n\"]],[]],null],[1,\"        \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[51,[30,2]],[[[1,\"        \"],[10,0],[14,0,\"admin-nav-submenu\"],[12],[1,\"\\n          \"],[8,[32,8],[[24,0,\"admin-nav-submenu__tabs\"]],null,[[\"default\"],[[[[1,\"\\n            \"],[18,5,null],[1,\"\\n          \"]],[]]]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@learnMoreUrl\",\"@hideTabs\",\"&breadcrumbs\",\"&actions\",\"&tabs\"],false,[\"yield\",\"if\",\"unless\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/admin/components/admin-page-header.js",
      "scope": () => [_dBreadcrumbsContainer.default, _dBreadcrumbsItem.default, _i18n.default, _helper.hash, _adminPageActionButton.PrimaryButton, _adminPageActionButton.DefaultButton, _adminPageActionButton.DangerButton, _template2.htmlSafe, _horizontalOverflowNav.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AdminPageHeader;
});