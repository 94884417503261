define("admin/routes/admin-search-logs-term", ["exports", "@ember/object", "discourse/lib/ajax", "discourse/lib/search", "discourse/lib/utilities", "discourse/routes/discourse"], function (_exports, _object, _ajax, _search, _utilities, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminSearchLogsTermRoute extends _discourse.default {
    queryParams = {
      term: {
        refreshModel: true
      },
      period: {
        refreshModel: true
      },
      searchType: {
        refreshModel: true
      }
    };
    async model(params) {
      this._params = params;
      const json = await (0, _ajax.ajax)(`/admin/logs/search_logs/term.json`, {
        data: {
          period: params.period,
          search_type: params.searchType,
          term: params.term
        }
      });

      // Add zero values for missing dates
      if (json.term.data.length > 0) {
        const startDate = json.term.period === "all" ? moment(json.term.data[0].x).format("YYYY-MM-DD") : moment(json.term.start_date).format("YYYY-MM-DD");
        const endDate = moment(json.term.end_date).format("YYYY-MM-DD");
        json.term.data = (0, _utilities.fillMissingDates)(json.term.data, startDate, endDate);
      }
      if (json.term.search_result) {
        json.term.search_result = await (0, _search.translateResults)(json.term.search_result);
      }
      const model = _object.default.create({
        type: "search_log_term"
      });
      model.setProperties(json.term);
      return model;
    }
    setupController(controller, model) {
      const params = this._params;
      controller.setProperties({
        model,
        term: params.term,
        period: params.period,
        searchType: params.searchType
      });
    }
  }
  _exports.default = AdminSearchLogsTermRoute;
});