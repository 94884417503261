define("admin/services/admin-plugin-nav-manager", ["exports", "@glimmer/tracking", "@ember/service", "discourse/lib/admin-plugin-config-nav"], function (_exports, _tracking, _service, _adminPluginConfigNav) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginNavManager extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "currentPlugin", [_tracking.tracked]))();
    #currentPlugin = (() => (dt7948.i(this, "currentPlugin"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "viewingPluginsList", [_tracking.tracked], function () {
      return false;
    }))();
    #viewingPluginsList = (() => (dt7948.i(this, "viewingPluginsList"), void 0))(); // NOTE (martin) This is a temporary solution so we know whether to
    // show the expanded header / nav on the admin plugin list or not.
    // This will be removed when all plugins follow the new "show route" pattern.
    get currentUserUsingAdminSidebar() {
      return this.currentUser?.use_admin_sidebar;
    }
    get currentConfigNav() {
      const configNav = (0, _adminPluginConfigNav.configNavForPlugin)(this.currentPlugin.id);
      const settingsNav = {
        mode: _adminPluginConfigNav.PLUGIN_NAV_MODE_TOP,
        links: [{
          label: "admin.plugins.change_settings_short",
          route: "adminPlugins.show.settings"
        }]
      };

      // Not all plugins have a more complex config UI and navigation,
      // in that case only the settings route will be available.
      if (!configNav) {
        return settingsNav;
      }

      // Automatically inject the settings link.
      if (!configNav.links.mapBy("route").includes("adminPlugins.show.settings")) {
        configNav.links.unshift(settingsNav.links[0]);
      }
      return configNav;
    }
    get currentPluginDefaultRoute() {
      const currentConfigNavLinks = this.currentConfigNav.links;
      const linksExceptSettings = currentConfigNavLinks.reject(link => link.route === "adminPlugins.show.settings");

      // Some plugins only have the Settings route, if so it's fine to use it as default.
      if (linksExceptSettings.length === 0) {
        return currentConfigNavLinks[0].route;
      }
      return linksExceptSettings[0].route;
    }
    get isSidebarMode() {
      return this.currentConfigNav.mode === _adminPluginConfigNav.PLUGIN_NAV_MODE_SIDEBAR;
    }
    get isTopMode() {
      return this.currentConfigNav.mode === _adminPluginConfigNav.PLUGIN_NAV_MODE_TOP;
    }
  }
  _exports.default = AdminPluginNavManager;
});