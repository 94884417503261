define("admin/controllers/admin-customize-email-style-edit", ["exports", "@ember/controller", "@ember/object", "@ember/service", "discourse-common/utils/decorators", "discourse-i18n"], function (_exports, _controller, _object, _service, _decorators, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminCustomizeEmailStyleEditController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    saveButtonText(isSaving) {
      return isSaving ? _discourseI18n.default.t("saving") : _discourseI18n.default.t("admin.customize.save");
    }
    static #_2 = (() => dt7948.n(this.prototype, "saveButtonText", [(0, _decorators.default)("model.isSaving")]))();
    saveDisabled(changed, isSaving) {
      return !changed || isSaving;
    }
    static #_3 = (() => dt7948.n(this.prototype, "saveDisabled", [(0, _decorators.default)("model.changed", "model.isSaving")]))();
    save() {
      if (!this.model.saving) {
        this.set("saving", true);
        this.model.update(this.model.getProperties("html", "css")).catch(e => {
          const msg = e.jqXHR.responseJSON && e.jqXHR.responseJSON.errors ? _discourseI18n.default.t("admin.customize.email_style.save_error_with_reason", {
            error: e.jqXHR.responseJSON.errors.join(". ")
          }) : _discourseI18n.default.t("generic_error");
          this.dialog.alert(msg);
        }).finally(() => this.set("model.changed", false));
      }
    }
    static #_4 = (() => dt7948.n(this.prototype, "save", [_object.action]))();
  }
  _exports.default = AdminCustomizeEmailStyleEditController;
});