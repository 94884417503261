define("admin/components/admin-config-areas/flags", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/constants", "discourse-common/helpers/i18n", "discourse-common/utils/decorators", "admin/components/admin-flag-item", "admin/components/admin-page-subheader", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _service, _ajax, _ajaxError, _constants, _i18n, _decorators, _adminFlagItem, _adminPageSubheader, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminConfigAreasFlags extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "flags", [_tracking.tracked], function () {
      return this.site.flagTypes;
    }))();
    #flags = (() => (dt7948.i(this, "flags"), void 0))();
    get addFlagButtonDisabled() {
      return this.flags.filter(flag1 => !Object.values(_constants.SYSTEM_FLAG_IDS).includes(flag1.id)).length >= this.siteSettings.custom_flags_limit;
    }
    isFirstFlag(flag1) {
      return this.flags.indexOf(flag1) === 1;
    }
    static #_4 = (() => dt7948.n(this.prototype, "isFirstFlag", [_decorators.bind]))();
    isLastFlag(flag1) {
      return this.flags.indexOf(flag1) === this.flags.length - 1;
    }
    static #_5 = (() => dt7948.n(this.prototype, "isLastFlag", [_decorators.bind]))();
    moveFlagCallback(flag1, direction1) {
      const fallbackFlags1 = [...this.flags];
      const flags1 = this.flags;
      const flagIndex1 = flags1.indexOf(flag1);
      const targetFlagIndex1 = direction1 === "up" ? flagIndex1 - 1 : flagIndex1 + 1;
      const targetFlag1 = flags1[targetFlagIndex1];
      flags1[flagIndex1] = targetFlag1;
      flags1[targetFlagIndex1] = flag1;
      this.flags = flags1;
      return (0, _ajax.ajax)(`/admin/config/flags/${flag1.id}/reorder/${direction1}`, {
        type: "PUT"
      }).catch(error1 => {
        this.flags = fallbackFlags1;
        return (0, _ajaxError.popupAjaxError)(error1);
      });
    }
    static #_6 = (() => dt7948.n(this.prototype, "moveFlagCallback", [_object.action]))();
    deleteFlagCallback(flag1) {
      return (0, _ajax.ajax)(`/admin/config/flags/${flag1.id}`, {
        type: "DELETE"
      }).then(() => {
        this.flags.removeObject(flag1);
      }).catch(error1 => (0, _ajaxError.popupAjaxError)(error1));
    }
    static #_7 = (() => dt7948.n(this.prototype, "deleteFlagCallback", [_object.action]))();
    static #_8 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <AdminPageSubheader @titleLabel="admin.config_areas.flags.flags_tab">
          <:actions as |actions|>
            <actions.Primary
              @route="adminConfig.flags.new"
              @title="admin.config_areas.flags.add"
              @label="admin.config_areas.flags.add"
              @icon="plus"
              @disabled={{this.addFlagButtonDisabled}}
              class="admin-flags__header-add-flag"
            />
          </:actions>
        </AdminPageSubheader>
        <div class="container admin-flags">
          <table class="admin-flags__items grid">
            <thead>
              <th>{{i18n "admin.config_areas.flags.description"}}</th>
              <th>{{i18n "admin.config_areas.flags.enabled"}}</th>
            </thead>
            <tbody>
              {{#each this.flags as |flag|}}
                <AdminFlagItem
                  @flag={{flag}}
                  @moveFlagCallback={{this.moveFlagCallback}}
                  @deleteFlagCallback={{this.deleteFlagCallback}}
                  @isFirstFlag={{this.isFirstFlag flag}}
                  @isLastFlag={{this.isLastFlag flag}}
                />
              {{/each}}
            </tbody>
          </table>
        </div>
      
    */
    {
      "id": "QY9/JUg3",
      "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@titleLabel\"],[\"admin.config_areas.flags.flags_tab\"]],[[\"actions\"],[[[[1,\"\\n        \"],[8,[30,1,[\"Primary\"]],[[24,0,\"admin-flags__header-add-flag\"]],[[\"@route\",\"@title\",\"@label\",\"@icon\",\"@disabled\"],[\"adminConfig.flags.new\",\"admin.config_areas.flags.add\",\"admin.config_areas.flags.add\",\"plus\",[30,0,[\"addFlagButtonDisabled\"]]]],null],[1,\"\\n      \"]],[1]]]]],[1,\"\\n    \"],[10,0],[14,0,\"container admin-flags\"],[12],[1,\"\\n      \"],[10,\"table\"],[14,0,\"admin-flags__items grid\"],[12],[1,\"\\n        \"],[10,\"thead\"],[12],[1,\"\\n          \"],[10,\"th\"],[12],[1,[28,[32,1],[\"admin.config_areas.flags.description\"],null]],[13],[1,\"\\n          \"],[10,\"th\"],[12],[1,[28,[32,1],[\"admin.config_areas.flags.enabled\"],null]],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"flags\"]]],null]],null],null,[[[1,\"            \"],[8,[32,2],null,[[\"@flag\",\"@moveFlagCallback\",\"@deleteFlagCallback\",\"@isFirstFlag\",\"@isLastFlag\"],[[30,2],[30,0,[\"moveFlagCallback\"]],[30,0,[\"deleteFlagCallback\"]],[28,[30,0,[\"isFirstFlag\"]],[[30,2]],null],[28,[30,0,[\"isLastFlag\"]],[[30,2]],null]]],null],[1,\"\\n\"]],[2]],null],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"actions\",\"flag\"],false,[\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/admin/components/admin-config-areas/flags.js",
      "scope": () => [_adminPageSubheader.default, _i18n.default, _adminFlagItem.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AdminConfigAreasFlags;
});