define("admin/models/admin-user", ["exports", "@ember/object/computed", "rsvp", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/computed", "discourse/lib/url", "discourse/models/group", "discourse/models/user", "discourse-common/lib/get-url", "discourse-common/utils/decorators", "discourse-i18n"], function (_exports, _computed, _rsvp, _ajax, _ajaxError, _computed2, _url, _group, _user, _getUrl, _decorators, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminUser extends _user.default {
    static find(user_id) {
      return (0, _ajax.ajax)(`/admin/users/${user_id}.json`).then(result => {
        result.loadedDetails = true;
        return AdminUser.create(result);
      });
    }
    static findAll(query, userFilter) {
      return (0, _ajax.ajax)(`/admin/users/list/${query}.json`, {
        data: userFilter
      }).then(users => users.map(u => AdminUser.create(u)));
    }
    adminUserView = true;
    static #_ = (() => dt7948.g(this.prototype, "customGroups", [(0, _computed.filter)("groups", g => !g.automatic && _group.default.create(g))]))();
    #customGroups = (() => (dt7948.i(this, "customGroups"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "automaticGroups", [(0, _computed.filter)("groups", g => g.automatic && _group.default.create(g))]))();
    #automaticGroups = (() => (dt7948.i(this, "automaticGroups"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "canViewProfile", [(0, _computed.or)("active", "staged")]))();
    #canViewProfile = (() => (dt7948.i(this, "canViewProfile"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "canResetBounceScore", [(0, _computed.gt)("bounce_score", 0)]))();
    #canResetBounceScore = (() => (dt7948.i(this, "canResetBounceScore"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "dirty", [(0, _computed2.propertyNotEqual)("originalTrustLevel", "trust_level")]))();
    #dirty = (() => (dt7948.i(this, "dirty"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "canLockTrustLevel", [(0, _computed.lt)("trust_level", 4)]))();
    #canLockTrustLevel = (() => (dt7948.i(this, "canLockTrustLevel"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "canSuspend", [(0, _computed.not)("staff")]))();
    #canSuspend = (() => (dt7948.i(this, "canSuspend"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "canSilence", [(0, _computed.not)("staff")]))();
    #canSilence = (() => (dt7948.i(this, "canSilence"), void 0))();
    bounceScore(bounce_score, reset_bounce_score_after) {
      if (bounce_score > 0) {
        return `${bounce_score} - ${moment(reset_bounce_score_after).format("LL")}`;
      } else {
        return bounce_score;
      }
    }
    static #_9 = (() => dt7948.n(this.prototype, "bounceScore", [(0, _decorators.default)("bounce_score", "reset_bounce_score_after")]))();
    bounceScoreExplanation(bounce_score) {
      if (bounce_score === 0) {
        return _discourseI18n.default.t("admin.user.bounce_score_explanation.none");
      } else if (bounce_score < this.siteSettings.bounce_score_threshold) {
        return _discourseI18n.default.t("admin.user.bounce_score_explanation.some");
      } else {
        return _discourseI18n.default.t("admin.user.bounce_score_explanation.threshold_reached");
      }
    }
    static #_10 = (() => dt7948.n(this.prototype, "bounceScoreExplanation", [(0, _decorators.default)("bounce_score")]))();
    bounceLink() {
      return (0, _getUrl.default)("/admin/email/bounced");
    }
    static #_11 = (() => dt7948.n(this.prototype, "bounceLink", [_decorators.default]))();
    resetBounceScore() {
      return (0, _ajax.ajax)(`/admin/users/${this.id}/reset-bounce-score`, {
        type: "POST"
      }).then(() => this.setProperties({
        bounce_score: 0,
        reset_bounce_score_after: null
      }));
    }
    groupAdded(added) {
      return (0, _ajax.ajax)(`/admin/users/${this.id}/groups`, {
        type: "POST",
        data: {
          group_id: added.id
        }
      }).then(() => this.groups.pushObject(added));
    }
    groupRemoved(groupId) {
      return (0, _ajax.ajax)(`/admin/users/${this.id}/groups/${groupId}`, {
        type: "DELETE"
      }).then(() => {
        this.set("groups.[]", this.groups.rejectBy("id", groupId));
        if (this.primary_group_id === groupId) {
          this.set("primary_group_id", null);
        }
      });
    }
    deleteAllPosts() {
      return (0, _ajax.ajax)(`/admin/users/${this.get("id")}/delete_posts_batch`, {
        type: "PUT"
      });
    }
    revokeAdmin() {
      return (0, _ajax.ajax)(`/admin/users/${this.id}/revoke_admin`, {
        type: "PUT"
      }).then(resp => {
        this.setProperties({
          admin: false,
          can_grant_admin: true,
          can_revoke_admin: false,
          can_be_merged: resp.can_be_merged,
          can_be_anonymized: resp.can_be_anonymized,
          can_be_deleted: resp.can_be_deleted,
          can_delete_all_posts: resp.can_delete_all_posts
        });
      });
    }
    grantAdmin(data) {
      return (0, _ajax.ajax)(`/admin/users/${this.id}/grant_admin`, {
        type: "PUT",
        data
      }).then(resp => {
        if (resp.success && !resp.email_confirmation_required) {
          this.setProperties({
            admin: true,
            can_grant_admin: false,
            can_revoke_admin: true
          });
        }
        return resp;
      });
    }
    revokeModeration() {
      return (0, _ajax.ajax)(`/admin/users/${this.id}/revoke_moderation`, {
        type: "PUT"
      }).then(resp => {
        this.setProperties({
          moderator: false,
          can_grant_moderation: true,
          can_revoke_moderation: false,
          can_be_merged: resp.can_be_merged,
          can_be_anonymized: resp.can_be_anonymized
        });
      }).catch(_ajaxError.popupAjaxError);
    }
    grantModeration() {
      return (0, _ajax.ajax)(`/admin/users/${this.id}/grant_moderation`, {
        type: "PUT"
      }).then(resp => {
        this.setProperties({
          moderator: true,
          can_grant_moderation: false,
          can_revoke_moderation: true,
          can_be_merged: resp.can_be_merged,
          can_be_anonymized: resp.can_be_anonymized
        });
      }).catch(_ajaxError.popupAjaxError);
    }
    disableSecondFactor() {
      return (0, _ajax.ajax)(`/admin/users/${this.id}/disable_second_factor`, {
        type: "PUT"
      }).then(() => {
        this.set("second_factor_enabled", false);
      }).catch(_ajaxError.popupAjaxError);
    }
    approve(approvedBy) {
      return (0, _ajax.ajax)(`/admin/users/${this.id}/approve`, {
        type: "PUT"
      }).then(() => {
        this.setProperties({
          can_approve: false,
          approved: true,
          approved_by: approvedBy
        });
      });
    }
    setOriginalTrustLevel() {
      this.set("originalTrustLevel", this.trust_level);
    }
    saveTrustLevel() {
      return (0, _ajax.ajax)(`/admin/users/${this.id}/trust_level`, {
        type: "PUT",
        data: {
          level: this.trust_level
        }
      });
    }
    restoreTrustLevel() {
      this.set("trust_level", this.originalTrustLevel);
    }
    lockTrustLevel(locked) {
      return (0, _ajax.ajax)(`/admin/users/${this.id}/trust_level_lock`, {
        type: "PUT",
        data: {
          locked: !!locked
        }
      });
    }
    suspendDuration(suspendedTill, suspendedAt) {
      suspendedAt = moment(suspendedAt);
      suspendedTill = moment(suspendedTill);
      return suspendedAt.format("L") + " - " + suspendedTill.format("L");
    }
    static #_12 = (() => dt7948.n(this.prototype, "suspendDuration", [(0, _decorators.default)("suspended_till", "suspended_at")]))();
    suspend(data) {
      return (0, _ajax.ajax)(`/admin/users/${this.id}/suspend`, {
        type: "PUT",
        data
      }).then(result => this.setProperties(result.suspension));
    }
    unsuspend() {
      return (0, _ajax.ajax)(`/admin/users/${this.id}/unsuspend`, {
        type: "PUT"
      }).then(result => this.setProperties(result.suspension));
    }
    logOut() {
      return (0, _ajax.ajax)("/admin/users/" + this.id + "/log_out", {
        type: "POST",
        data: {
          username_or_email: this.username
        }
      });
    }
    impersonate() {
      return (0, _ajax.ajax)("/admin/impersonate", {
        type: "POST",
        data: {
          username_or_email: this.username
        }
      });
    }
    activate() {
      return (0, _ajax.ajax)(`/admin/users/${this.id}/activate`, {
        type: "PUT"
      });
    }
    deactivate() {
      return (0, _ajax.ajax)(`/admin/users/${this.id}/deactivate`, {
        type: "PUT",
        data: {
          context: document.location.pathname
        }
      });
    }
    unsilence() {
      this.set("silencingUser", true);
      return (0, _ajax.ajax)(`/admin/users/${this.id}/unsilence`, {
        type: "PUT"
      }).then(result => this.setProperties(result.unsilence)).finally(() => this.set("silencingUser", false));
    }
    silence(data) {
      this.set("silencingUser", true);
      return (0, _ajax.ajax)(`/admin/users/${this.id}/silence`, {
        type: "PUT",
        data
      }).then(result => this.setProperties(result.silence)).finally(() => this.set("silencingUser", false));
    }
    sendActivationEmail() {
      return (0, _ajax.ajax)((0, _url.userPath)("action/send_activation_email"), {
        type: "POST",
        data: {
          username: this.username
        }
      });
    }
    anonymize() {
      return (0, _ajax.ajax)(`/admin/users/${this.id}/anonymize.json`, {
        type: "PUT"
      });
    }
    destroy(formData) {
      return (0, _ajax.ajax)(`/admin/users/${this.id}.json`, {
        type: "DELETE",
        data: formData
      }).then(data => {
        if (!data.deleted && data.user) {
          this.setProperties(data.user);
        }
        return data;
      }).catch(() => {
        this.find(this.id).then(u => this.setProperties(u));
      });
    }
    merge(formData) {
      return (0, _ajax.ajax)(`/admin/users/${this.id}/merge.json`, {
        type: "POST",
        data: formData
      });
    }
    loadDetails() {
      if (this.loadedDetails) {
        return _rsvp.Promise.resolve(this);
      }
      return AdminUser.find(this.id).then(result => {
        const userProperties = Object.assign(result, {
          loadedDetails: true
        });
        this.setProperties(userProperties);
      });
    }
    tl3Requirements(requirements) {
      if (requirements) {
        return this.store.createRecord("tl3Requirements", requirements);
      }
    }
    static #_13 = (() => dt7948.n(this.prototype, "tl3Requirements", [(0, _decorators.default)("tl3_requirements")]))();
    suspendedBy(user) {
      return user ? AdminUser.create(user) : null;
    }
    static #_14 = (() => dt7948.n(this.prototype, "suspendedBy", [(0, _decorators.default)("suspended_by")]))();
    silencedBy(user) {
      return user ? AdminUser.create(user) : null;
    }
    static #_15 = (() => dt7948.n(this.prototype, "silencedBy", [(0, _decorators.default)("silenced_by")]))();
    approvedBy(user) {
      return user ? AdminUser.create(user) : null;
    }
    static #_16 = (() => dt7948.n(this.prototype, "approvedBy", [(0, _decorators.default)("approved_by")]))();
    deleteSSORecord() {
      return (0, _ajax.ajax)(`/admin/users/${this.id}/sso_record.json`, {
        type: "DELETE"
      }).then(() => {
        this.set("single_sign_on_record", null);
      }).catch(_ajaxError.popupAjaxError);
    }
  }
  _exports.default = AdminUser;
});