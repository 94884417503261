define("admin/models/color-scheme-color", ["exports", "@ember/object", "@ember-decorators/object", "discourse/lib/computed", "discourse-common/utils/decorators", "discourse-i18n"], function (_exports, _object, _object2, _computed, _decorators, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ColorSchemeColor extends _object.default {
    static #_ = (() => dt7948.g(this.prototype, "overridden", [(0, _computed.propertyNotEqual)("hex", "default_hex")]))();
    #overridden = (() => (dt7948.i(this, "overridden"), void 0))(); // Whether the current value is different than Discourse's default color scheme.
    startTrackingChanges() {
      this.set("originals", {
        hex: this.hex || "FFFFFF"
      });

      // force changed property to be recalculated
      this.notifyPropertyChange("hex");
    }

    // Whether value has changed since it was last saved.
    static #_2 = (() => dt7948.n(this.prototype, "startTrackingChanges", [(0, _object2.on)("init")]))();
    changed(hex) {
      if (!this.originals) {
        return false;
      }
      if (hex !== this.originals.hex) {
        return true;
      }
      return false;
    }

    // Whether the saved value is different than Discourse's default color scheme.
    static #_3 = (() => dt7948.n(this.prototype, "changed", [(0, _decorators.default)("hex")]))();
    savedIsOverriden(defaultHex) {
      return this.originals.hex !== defaultHex;
    }
    static #_4 = (() => dt7948.n(this.prototype, "savedIsOverriden", [(0, _decorators.default)("default_hex", "hex")]))();
    revert() {
      this.set("hex", this.default_hex);
    }
    undo() {
      if (this.originals) {
        this.set("hex", this.originals.hex);
      }
    }
    translatedName(name) {
      if (!this.is_advanced) {
        return _discourseI18n.default.t(`admin.customize.colors.${name}.name`);
      } else {
        return name;
      }
    }
    static #_5 = (() => dt7948.n(this.prototype, "translatedName", [(0, _decorators.default)("name")]))();
    description(name) {
      if (!this.is_advanced) {
        return _discourseI18n.default.t(`admin.customize.colors.${name}.description`);
      } else {
        return "";
      }
    }

    /**
      brightness returns a number between 0 (darkest) to 255 (brightest).
      Undefined if hex is not a valid color.
       @property brightness
    **/
    static #_6 = (() => dt7948.n(this.prototype, "description", [(0, _decorators.default)("name")]))();
    brightness(hex) {
      if (hex.length === 6 || hex.length === 3) {
        if (hex.length === 3) {
          hex = hex.slice(0, 1) + hex.slice(0, 1) + hex.slice(1, 2) + hex.slice(1, 2) + hex.slice(2, 3) + hex.slice(2, 3);
        }
        return Math.round((parseInt(hex.slice(0, 2), 16) * 299 + parseInt(hex.slice(2, 4), 16) * 587 + parseInt(hex.slice(4, 6), 16) * 114) / 1000);
      }
    }
    static #_7 = (() => dt7948.n(this.prototype, "brightness", [(0, _decorators.default)("hex")]))();
    hexValueChanged() {
      if (this.hex) {
        this.set("hex", this.hex.toString().replace(/[^0-9a-fA-F]/g, ""));
      }
    }
    static #_8 = (() => dt7948.n(this.prototype, "hexValueChanged", [(0, _object2.observes)("hex")]))();
    valid(hex) {
      return hex.match(/^([0-9a-fA-F]{3}|[0-9a-fA-F]{6})$/) !== null;
    }
    static #_9 = (() => dt7948.n(this.prototype, "valid", [(0, _decorators.default)("hex")]))();
  }
  _exports.default = ColorSchemeColor;
});