define("admin/components/site-customization-change-details", ["exports", "@ember/component", "@ember/template-factory"], function (_exports, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <section class="field">
    <b>{{i18n "admin.customize.enabled"}}</b>:
    {{this.change.enabled}}
  </section>
  
  <SiteCustomizationChangeField
    @field={{this.change.stylesheet}}
    @name="admin.customize.css"
  />
  <SiteCustomizationChangeField
    @icon="mobile"
    @field={{this.change.mobile_stylesheet}}
    @name="admin.customize.css"
  />
  
  <SiteCustomizationChangeField
    @field={{this.change.header}}
    @name="admin.customize.header"
  />
  <SiteCustomizationChangeField
    @icon="mobile"
    @field={{this.change.mobile_header}}
    @name="admin.customize.header"
  />
  
  <SiteCustomizationChangeField
    @field={{this.change.top}}
    @name="admin.customize.top"
  />
  <SiteCustomizationChangeField
    @icon="mobile"
    @field={{this.change.mobile_top}}
    @name="admin.customize.top"
  />
  
  <SiteCustomizationChangeField
    @field={{this.change.footer}}
    @name="admin.customize.footer"
  />
  <SiteCustomizationChangeField
    @icon="mobile"
    @field={{this.change.mobile_footer}}
    @name="admin.customize.footer"
  />
  
  <SiteCustomizationChangeField
    @icon="file-text-o"
    @field={{this.change.head_tag}}
    @name="admin.customize.head_tag.text"
  />
  <SiteCustomizationChangeField
    @icon="file-text-o"
    @field={{this.change.body_tag}}
    @name="admin.customize.body_tag.text"
  />
  */
  {
    "id": "k4qI1mQt",
    "block": "[[[10,\"section\"],[14,0,\"field\"],[12],[1,\"\\n  \"],[10,\"b\"],[12],[1,[28,[35,0],[\"admin.customize.enabled\"],null]],[13],[1,\":\\n  \"],[1,[30,0,[\"change\",\"enabled\"]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@field\",\"@name\"],[[30,0,[\"change\",\"stylesheet\"]],\"admin.customize.css\"]],null],[1,\"\\n\"],[8,[39,1],null,[[\"@icon\",\"@field\",\"@name\"],[\"mobile\",[30,0,[\"change\",\"mobile_stylesheet\"]],\"admin.customize.css\"]],null],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@field\",\"@name\"],[[30,0,[\"change\",\"header\"]],\"admin.customize.header\"]],null],[1,\"\\n\"],[8,[39,1],null,[[\"@icon\",\"@field\",\"@name\"],[\"mobile\",[30,0,[\"change\",\"mobile_header\"]],\"admin.customize.header\"]],null],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@field\",\"@name\"],[[30,0,[\"change\",\"top\"]],\"admin.customize.top\"]],null],[1,\"\\n\"],[8,[39,1],null,[[\"@icon\",\"@field\",\"@name\"],[\"mobile\",[30,0,[\"change\",\"mobile_top\"]],\"admin.customize.top\"]],null],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@field\",\"@name\"],[[30,0,[\"change\",\"footer\"]],\"admin.customize.footer\"]],null],[1,\"\\n\"],[8,[39,1],null,[[\"@icon\",\"@field\",\"@name\"],[\"mobile\",[30,0,[\"change\",\"mobile_footer\"]],\"admin.customize.footer\"]],null],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@icon\",\"@field\",\"@name\"],[\"file-text-o\",[30,0,[\"change\",\"head_tag\"]],\"admin.customize.head_tag.text\"]],null],[1,\"\\n\"],[8,[39,1],null,[[\"@icon\",\"@field\",\"@name\"],[\"file-text-o\",[30,0,[\"change\",\"body_tag\"]],\"admin.customize.body_tag.text\"]],null]],[],false,[\"i18n\",\"site-customization-change-field\"]]",
    "moduleName": "admin/components/site-customization-change-details.hbs",
    "isStrictMode": false
  });
  class SiteCustomizationChangeDetails extends _component.default {}
  _exports.default = SiteCustomizationChangeDetails;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SiteCustomizationChangeDetails);
});