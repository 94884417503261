define("admin/routes/admin-web-hooks-edit", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminWebHooksEditRoute extends _discourse.default {
    serialize(model) {
      return {
        web_hook_id: model.id || "new"
      };
    }
    model(params) {
      if (params.web_hook_id === "new") {
        return this.store.createRecord("web-hook");
      }
      return this.store.find("web-hook", params.web_hook_id);
    }
    setupController(controller, model) {
      super.setupController(...arguments);
      if (model.get("isNew")) {
        model.set("web_hook_event_types", this.controllerFor("adminWebHooks").defaultEventTypes);
      }
      controller.set("saved", false);
    }
  }
  _exports.default = AdminWebHooksEditRoute;
});