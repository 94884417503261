define("admin/models/email-template", ["exports", "@ember/object", "discourse/lib/ajax", "discourse/models/rest"], function (_exports, _object, _ajax, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class EmailTemplate extends _rest.default {
    revert() {
      return (0, _ajax.ajax)(`/admin/customize/email_templates/${this.id}`, {
        type: "DELETE"
      }).then(result => (0, _object.getProperties)(result.email_template, "subject", "body", "can_revert"));
    }
  }
  _exports.default = EmailTemplate;
});