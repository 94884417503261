define("admin/models/color-scheme", ["exports", "@ember/array", "@ember/array/proxy", "@ember/object", "@ember/object/computed", "discourse/lib/ajax", "discourse-common/utils/decorators", "discourse-i18n", "admin/models/color-scheme-color"], function (_exports, _array, _proxy, _object, _computed, _ajax, _decorators, _discourseI18n, _colorSchemeColor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ColorSchemes extends _proxy.default {}
  class ColorScheme extends _object.default {
    static findAll() {
      const colorSchemes = ColorSchemes.create({
        content: [],
        loading: true
      });
      return (0, _ajax.ajax)("/admin/color_schemes").then(all => {
        all.forEach(colorScheme => {
          colorSchemes.pushObject(ColorScheme.create({
            id: colorScheme.id,
            name: colorScheme.name,
            is_base: colorScheme.is_base,
            theme_id: colorScheme.theme_id,
            theme_name: colorScheme.theme_name,
            base_scheme_id: colorScheme.base_scheme_id,
            user_selectable: colorScheme.user_selectable,
            colors: colorScheme.colors.map(c => {
              return _colorSchemeColor.default.create({
                name: c.name,
                hex: c.hex,
                default_hex: c.default_hex,
                is_advanced: c.is_advanced
              });
            })
          }));
        });
        return colorSchemes;
      });
    }
    static #_ = (() => dt7948.g(this.prototype, "newRecord", [(0, _computed.not)("id")]))();
    #newRecord = (() => (dt7948.i(this, "newRecord"), void 0))();
    init() {
      super.init(...arguments);
      this.startTrackingChanges();
    }
    description() {
      return "" + this.name;
    }
    static #_2 = (() => dt7948.n(this.prototype, "description", [_decorators.default]))();
    startTrackingChanges() {
      this.set("originals", {
        name: this.name,
        user_selectable: this.user_selectable
      });
    }
    schemeJson() {
      const buffer = [];
      this.colors.forEach(c => {
        buffer.push(`  "${c.get("name")}": "${c.get("hex")}"`);
      });
      return [`"${this.name}": {`, buffer.join(",\n"), "}"].join("\n");
    }
    copy() {
      const newScheme = ColorScheme.create({
        name: this.name,
        can_edit: true,
        colors: (0, _array.A)()
      });
      this.colors.forEach(c => {
        newScheme.colors.pushObject(_colorSchemeColor.default.create(c.getProperties("name", "hex", "default_hex")));
      });
      return newScheme;
    }
    changed(name, user_selectable) {
      if (!this.originals) {
        return false;
      }
      if (this.originals.name !== name) {
        return true;
      }
      if (this.originals.user_selectable !== user_selectable) {
        return true;
      }
      if (this.colors.any(c => c.get("changed"))) {
        return true;
      }
      return false;
    }
    static #_3 = (() => dt7948.n(this.prototype, "changed", [(0, _decorators.default)("name", "user_selectable", "colors.@each.changed", "saving")]))();
    disableSave(changed) {
      if (this.theme_id) {
        return false;
      }
      return !changed || this.saving || this.colors.any(c => !c.get("valid"));
    }
    static #_4 = (() => dt7948.n(this.prototype, "disableSave", [(0, _decorators.default)("changed")]))();
    save(opts) {
      if (this.is_base || this.disableSave) {
        return;
      }
      this.setProperties({
        savingStatus: _discourseI18n.default.t("saving"),
        saving: true
      });
      const data = {};
      if (!opts || !opts.enabledOnly) {
        data.name = this.name;
        data.user_selectable = this.user_selectable;
        data.base_scheme_id = this.base_scheme_id;
        data.colors = [];
        this.colors.forEach(c => {
          if (!this.id || c.get("changed")) {
            data.colors.pushObject(c.getProperties("name", "hex"));
          }
        });
      }
      return (0, _ajax.ajax)("/admin/color_schemes" + (this.id ? "/" + this.id : "") + ".json", {
        data: JSON.stringify({
          color_scheme: data
        }),
        type: this.id ? "PUT" : "POST",
        dataType: "json",
        contentType: "application/json"
      }).then(result => {
        if (result.id) {
          this.set("id", result.id);
        }
        if (!opts || !opts.enabledOnly) {
          this.startTrackingChanges();
          this.colors.forEach(c => c.startTrackingChanges());
        }
        this.setProperties({
          savingStatus: _discourseI18n.default.t("saved"),
          saving: false
        });
        this.notifyPropertyChange("description");
      });
    }
    updateUserSelectable(value) {
      if (!this.id) {
        return;
      }
      return (0, _ajax.ajax)(`/admin/color_schemes/${this.id}.json`, {
        data: JSON.stringify({
          color_scheme: {
            user_selectable: value
          }
        }),
        type: "PUT",
        dataType: "json",
        contentType: "application/json"
      });
    }
    destroy() {
      if (this.id) {
        return (0, _ajax.ajax)(`/admin/color_schemes/${this.id}`, {
          type: "DELETE"
        });
      }
    }
  }
  _exports.default = ColorScheme;
});