define("admin/controllers/admin-email-logs", ["exports", "@ember/controller", "@ember/object", "admin/models/email-log"], function (_exports, _controller, _object, _emailLog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminEmailLogsController extends _controller.default {
    loading = false;
    filter = (() => _object.default.create())();
    loadLogs(sourceModel, loadMore) {
      if (loadMore && this.loading || this.get("model.allLoaded")) {
        return;
      }
      this.set("loading", true);
      sourceModel = sourceModel || _emailLog.default;
      let args = {};
      Object.keys(this.filter).forEach(k => {
        if (this.filter[k]) {
          args[k] = this.filter[k];
        }
      });
      return sourceModel.findAll(args, loadMore ? this.get("model.length") : null).then(logs => {
        if (this.model && loadMore && logs.length < 50) {
          this.model.set("allLoaded", true);
        }
        if (this.model && loadMore) {
          this.model.addObjects(logs);
        } else {
          this.set("model", logs);
        }
      }).finally(() => this.set("loading", false));
    }
    loadMore() {
      this.loadLogs(_emailLog.default, true);
    }
    static #_ = (() => dt7948.n(this.prototype, "loadMore", [_object.action]))();
  }
  _exports.default = AdminEmailLogsController;
});