define("admin/components/modal/site-setting-default-categories", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    class="incoming-emails"
    @title={{html-safe @model.siteSetting.key}}
    @closeModal={{this.cancel}}
  >
    <:body>
      {{i18n
        "admin.site_settings.default_categories.modal_description"
        count=@model.siteSetting.count
      }}
    </:body>
    <:footer>
      <DButton
        @action={{this.updateExistingUsers}}
        class="btn-primary"
        @label="admin.site_settings.default_categories.modal_yes"
      />
      <DButton
        @action={{this.cancel}}
        @label="admin.site_settings.default_categories.modal_no"
      />
    </:footer>
  </DModal>
  */
  {
    "id": "kvsyjp31",
    "block": "[[[8,[39,0],[[24,0,\"incoming-emails\"]],[[\"@title\",\"@closeModal\"],[[28,[37,1],[[30,1,[\"siteSetting\",\"key\"]]],null],[30,0,[\"cancel\"]]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[1,[28,[35,2],[\"admin.site_settings.default_categories.modal_description\"],[[\"count\"],[[30,1,[\"siteSetting\",\"count\"]]]]]],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,3],[[24,0,\"btn-primary\"]],[[\"@action\",\"@label\"],[[30,0,[\"updateExistingUsers\"]],\"admin.site_settings.default_categories.modal_yes\"]],null],[1,\"\\n    \"],[8,[39,3],null,[[\"@action\",\"@label\"],[[30,0,[\"cancel\"]],\"admin.site_settings.default_categories.modal_no\"]],null],[1,\"\\n  \"]],[]]]]]],[\"@model\"],false,[\"d-modal\",\"html-safe\",\"i18n\",\"d-button\"]]",
    "moduleName": "admin/components/modal/site-setting-default-categories.hbs",
    "isStrictMode": false
  });
  class SiteSettingDefaultCategories extends _component2.default {
    updateExistingUsers() {
      this.args.model.setUpdateExistingUsers(true);
      this.args.closeModal();
    }
    static #_ = (() => dt7948.n(this.prototype, "updateExistingUsers", [_object.action]))();
    cancel() {
      this.args.model.setUpdateExistingUsers(false);
      this.args.closeModal();
    }
    static #_2 = (() => dt7948.n(this.prototype, "cancel", [_object.action]))();
  }
  _exports.default = SiteSettingDefaultCategories;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SiteSettingDefaultCategories);
});