define("admin/components/value-list", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember-decorators/component", "discourse-common/lib/helpers", "discourse-common/utils/decorators", "@ember/template-factory"], function (_exports, _component, _object, _computed, _component2, _helpers, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.collection}}
    <div class="values">
      {{#each this.collection as |value index|}}
        <div data-index={{index}} class="value">
          <DButton
            @action={{fn this.removeValue value}}
            @icon="xmark"
            class="remove-value-btn btn-small"
          />
  
          <Input
            title={{value}}
            @value={{value}}
            class="value-input"
            {{on "focusout" (fn this.changeValue index)}}
          />
  
          {{#if this.showUpDownButtons}}
            <DButton
              @action={{fn this.shift -1 index}}
              @icon="arrow-up"
              class="shift-up-value-btn btn-small"
            />
            <DButton
              @action={{fn this.shift 1 index}}
              @icon="arrow-down"
              class="shift-down-value-btn btn-small"
            />
          {{/if}}
        </div>
      {{/each}}
    </div>
  {{/if}}
  
  <ComboBox
    @valueProperty={{null}}
    @nameProperty={{null}}
    @value={{this.newValue}}
    @content={{this.filteredChoices}}
    @onChange={{this.selectChoice}}
    @options={{hash allowAny=true none=this.noneKey}}
  />
  */
  {
    "id": "/+fXei0s",
    "block": "[[[41,[30,0,[\"collection\"]],[[[1,\"  \"],[10,0],[14,0,\"values\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"collection\"]]],null]],null],null,[[[1,\"      \"],[10,0],[15,\"data-index\",[30,2]],[14,0,\"value\"],[12],[1,\"\\n        \"],[8,[39,3],[[24,0,\"remove-value-btn btn-small\"]],[[\"@action\",\"@icon\"],[[28,[37,4],[[30,0,[\"removeValue\"]],[30,1]],null],\"xmark\"]],null],[1,\"\\n\\n        \"],[8,[39,5],[[16,\"title\",[30,1]],[24,0,\"value-input\"],[4,[38,6],[\"focusout\",[28,[37,4],[[30,0,[\"changeValue\"]],[30,2]],null]],null]],[[\"@value\"],[[30,1]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"showUpDownButtons\"]],[[[1,\"          \"],[8,[39,3],[[24,0,\"shift-up-value-btn btn-small\"]],[[\"@action\",\"@icon\"],[[28,[37,4],[[30,0,[\"shift\"]],-1,[30,2]],null],\"arrow-up\"]],null],[1,\"\\n          \"],[8,[39,3],[[24,0,\"shift-down-value-btn btn-small\"]],[[\"@action\",\"@icon\"],[[28,[37,4],[[30,0,[\"shift\"]],1,[30,2]],null],\"arrow-down\"]],null],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n\"]],[1,2]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[8,[39,7],null,[[\"@valueProperty\",\"@nameProperty\",\"@value\",\"@content\",\"@onChange\",\"@options\"],[null,null,[30,0,[\"newValue\"]],[30,0,[\"filteredChoices\"]],[30,0,[\"selectChoice\"]],[28,[37,8],null,[[\"allowAny\",\"none\"],[true,[30,0,[\"noneKey\"]]]]]]],null]],[\"value\",\"index\"],false,[\"if\",\"each\",\"-track-array\",\"d-button\",\"fn\",\"input\",\"on\",\"combo-box\",\"hash\"]]",
    "moduleName": "admin/components/value-list.hbs",
    "isStrictMode": false
  });
  const ValueList = dt7948.c(class ValueList extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "inputInvalid", [(0, _computed.empty)("newValue")]))();
    #inputInvalid = (() => (dt7948.i(this, "inputInvalid"), void 0))();
    inputDelimiter = null;
    inputType = null;
    newValue = "";
    collection = null;
    values = null;
    static #_2 = (() => dt7948.g(this.prototype, "noneKey", [(0, _computed.reads)("addKey")]))();
    #noneKey = (() => (dt7948.i(this, "noneKey"), void 0))();
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      if (this.inputType === "array") {
        this.set("collection", this.values || []);
        return;
      }
      this.set("collection", this._splitValues(this.values, this.inputDelimiter || "\n"));
    }
    filteredChoices(choices, collection) {
      return (0, _helpers.makeArray)(choices).filter(i => !collection.includes(i));
    }
    static #_3 = (() => dt7948.n(this.prototype, "filteredChoices", [(0, _decorators.default)("choices.[]", "collection.[]")]))();
    keyDown(event) {
      if (event.key === "Enter") {
        this.send("addValue", this.newValue);
      }
    }
    changeValue(index, event) {
      this._replaceValue(index, event.target.value);
    }
    static #_4 = (() => dt7948.n(this.prototype, "changeValue", [_object.action]))();
    addValue(newValue) {
      if (this.inputInvalid) {
        return;
      }
      this.set("newValue", null);
      this._addValue(newValue);
    }
    static #_5 = (() => dt7948.n(this.prototype, "addValue", [_object.action]))();
    removeValue(value) {
      this._removeValue(value);
    }
    static #_6 = (() => dt7948.n(this.prototype, "removeValue", [_object.action]))();
    selectChoice(choice) {
      this._addValue(choice);
    }
    static #_7 = (() => dt7948.n(this.prototype, "selectChoice", [_object.action]))();
    shift(operation, index) {
      let futureIndex = index + operation;
      if (futureIndex > this.collection.length - 1) {
        futureIndex = 0;
      } else if (futureIndex < 0) {
        futureIndex = this.collection.length - 1;
      }
      const shiftedValue = this.collection[index];
      this.collection.removeAt(index);
      this.collection.insertAt(futureIndex, shiftedValue);
      this._saveValues();
    }
    static #_8 = (() => dt7948.n(this.prototype, "shift", [_object.action]))();
    _addValue(value) {
      this.collection.addObject(value);
      if (this.choices) {
        this.set("choices", this.choices.rejectBy("id", value));
      } else {
        this.set("choices", []);
      }
      this._saveValues();
    }
    _removeValue(value) {
      this.collection.removeObject(value);
      if (this.choices) {
        this.set("choices", this.choices.concat([value]).uniq());
      } else {
        this.set("choices", (0, _helpers.makeArray)(value));
      }
      this._saveValues();
    }
    _replaceValue(index, newValue) {
      this.collection.replace(index, 1, [newValue]);
      this._saveValues();
    }
    _saveValues() {
      if (this.inputType === "array") {
        this.set("values", this.collection);
        return;
      }
      this.set("values", this.collection.join(this.inputDelimiter || "\n"));
    }
    showUpDownButtons(collection) {
      return collection.length - 1 ? true : false;
    }
    static #_9 = (() => dt7948.n(this.prototype, "showUpDownButtons", [(0, _decorators.default)("collection")]))();
    _splitValues(values, delimiter) {
      if (values && values.length) {
        return values.split(delimiter).filter(x => x);
      } else {
        return [];
      }
    }
  }, [(0, _component2.classNames)("value-list")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ValueList);
});