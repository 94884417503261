define("admin/routes/admin-badges/award", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminBadgesAwardRoute extends _discourse.default {
    model(params) {
      if (params.badge_id !== "new") {
        return this.modelFor("adminBadges").findBy("id", parseInt(params.badge_id, 10));
      }
    }
    setupController(controller) {
      super.setupController(...arguments);
      controller.resetState();
    }
  }
  _exports.default = AdminBadgesAwardRoute;
});