define("admin/templates/api", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <AdminNav>
    <NavItem @route="adminApiKeys" @label="admin.api.title" />
    <NavItem @route="adminWebHooks" @label="admin.web_hooks.title" />
  </AdminNav>
  
  <div class="admin-container">
    {{outlet}}
  </div>
  */
  {
    "id": "ODUB03Pu",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,[[\"@route\",\"@label\"],[\"adminApiKeys\",\"admin.api.title\"]],null],[1,\"\\n  \"],[8,[39,1],null,[[\"@route\",\"@label\"],[\"adminWebHooks\",\"admin.web_hooks.title\"]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[10,0],[14,0,\"admin-container\"],[12],[1,\"\\n  \"],[46,[28,[37,3],null,null],null,null,null],[1,\"\\n\"],[13]],[],false,[\"admin-nav\",\"nav-item\",\"component\",\"-outlet\"]]",
    "moduleName": "admin/templates/api.hbs",
    "isStrictMode": false
  });
});