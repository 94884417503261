define("admin/adapters/customization-base", ["exports", "discourse/adapters/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CustomizationBase extends _rest.default {
    basePath() {
      return "/admin/customize/";
    }
  }
  _exports.default = CustomizationBase;
});