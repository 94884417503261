define("admin/components/plugin-commit-hash", ["exports", "@glimmer/component", "@ember/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PluginCommitHash extends _component.default {
    get shortCommitHash() {
      return this.args.plugin.commitHash?.slice(0, 7);
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if @plugin.commitHash}}
          <a
            href={{@plugin.commitUrl}}
            target="_blank"
            rel="noopener noreferrer"
            class="current commit-hash"
            title={{@plugin.commitHash}}
          >{{this.shortCommitHash}}</a>
        {{/if}}
      
    */
    {
      "id": "yjT3zsKr",
      "block": "[[[1,\"\\n\"],[41,[30,1,[\"commitHash\"]],[[[1,\"      \"],[10,3],[15,6,[30,1,[\"commitUrl\"]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[14,0,\"current commit-hash\"],[15,\"title\",[30,1,[\"commitHash\"]]],[12],[1,[30,0,[\"shortCommitHash\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@plugin\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/admin/components/plugin-commit-hash.js",
      "isStrictMode": true
    }), this))();
  }
  _exports.default = PluginCommitHash;
});