define("admin/components/flag-user", ["exports", "@ember/component", "@ember/template-factory"], function (_exports, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="flag-user">
    <LinkTo
      @route="adminUser"
      @models={{array this.user.id this.user.username}}
      class="flag-user-avatar"
    >
      {{avatar this.user imageSize="small"}}
    </LinkTo>
    <div class="flag-user-details">
      <div class="flag-user-who">
        <LinkTo
          @route="adminUser"
          @models={{array this.user.id this.user.username}}
          class="flag-user-username"
        >
          {{this.user.username}}
        </LinkTo>
        <div title={{raw-date this.date}} class="flag-user-date">
          {{format-age this.date}}
        </div>
      </div>
      <div class="flag-user-extra">
        {{yield}}
      </div>
    </div>
  </div>
  */
  {
    "id": "qN1o7z/6",
    "block": "[[[10,0],[14,0,\"flag-user\"],[12],[1,\"\\n  \"],[8,[39,0],[[24,0,\"flag-user-avatar\"]],[[\"@route\",\"@models\"],[\"adminUser\",[28,[37,1],[[30,0,[\"user\",\"id\"]],[30,0,[\"user\",\"username\"]]],null]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,2],[[30,0,[\"user\"]]],[[\"imageSize\"],[\"small\"]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n  \"],[10,0],[14,0,\"flag-user-details\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"flag-user-who\"],[12],[1,\"\\n      \"],[8,[39,0],[[24,0,\"flag-user-username\"]],[[\"@route\",\"@models\"],[\"adminUser\",[28,[37,1],[[30,0,[\"user\",\"id\"]],[30,0,[\"user\",\"username\"]]],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,0,[\"user\",\"username\"]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n      \"],[10,0],[15,\"title\",[28,[37,3],[[30,0,[\"date\"]]],null]],[14,0,\"flag-user-date\"],[12],[1,\"\\n        \"],[1,[28,[35,4],[[30,0,[\"date\"]]],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"flag-user-extra\"],[12],[1,\"\\n      \"],[18,1,null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&default\"],false,[\"link-to\",\"array\",\"avatar\",\"raw-date\",\"format-age\",\"yield\"]]",
    "moduleName": "admin/components/flag-user.hbs",
    "isStrictMode": false
  });
  class FlagUser extends _component.default {}
  _exports.default = FlagUser;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FlagUser);
});