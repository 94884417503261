define("admin/components/admin-site-settings-filter-controls", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/component", "@ember/modifier", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "@ember/render-modifiers/modifiers/did-update", "discourse/components/d-button", "discourse-common/helpers/i18n", "@ember/template-factory"], function (_exports, _component, _tracking, _component2, _modifier, _object, _didInsert, _didUpdate, _dButton, _i18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminSiteSettingsFilterControls extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "filter", [_tracking.tracked], function () {
      return this.args.initialFilter || "";
    }))();
    #filter = (() => (dt7948.i(this, "filter"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "onlyOverridden", [_tracking.tracked], function () {
      return false;
    }))();
    #onlyOverridden = (() => (dt7948.i(this, "onlyOverridden"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "isMenuOpen", [_tracking.tracked], function () {
      return false;
    }))();
    #isMenuOpen = (() => (dt7948.i(this, "isMenuOpen"), void 0))();
    clearFilter() {
      this.filter = "";
      this.onlyOverridden = false;
      this.onChangeFilter();
    }
    static #_4 = (() => dt7948.n(this.prototype, "clearFilter", [_object.action]))();
    onChangeFilter() {
      this.args.onChangeFilter({
        filter: this.filter,
        onlyOverridden: this.onlyOverridden
      });
    }
    static #_5 = (() => dt7948.n(this.prototype, "onChangeFilter", [_object.action]))();
    onChangeFilterInput(event1) {
      this.filter = event1.target.value;
      this.onChangeFilter();
    }
    static #_6 = (() => dt7948.n(this.prototype, "onChangeFilterInput", [_object.action]))();
    onToggleOverridden(event1) {
      this.onlyOverridden = event1.target.checked;
      this.onChangeFilter();
    }
    static #_7 = (() => dt7948.n(this.prototype, "onToggleOverridden", [_object.action]))();
    runInitialFilter() {
      if (this.args.initialFilter !== this.filter) {
        this.filter = this.args.initialFilter;
      }
      this.onChangeFilter();
    }
    static #_8 = (() => dt7948.n(this.prototype, "runInitialFilter", [_object.action]))();
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
      this.args.onToggleMenu();
    }
    static #_9 = (() => dt7948.n(this.prototype, "toggleMenu", [_object.action]))();
    static #_10 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div
          class="admin-controls admin-site-settings-filter-controls"
          {{didInsert this.runInitialFilter}}
          {{didUpdate this.runInitialFilter @initialFilter}}
        >
          <div class="controls">
            <div class="inline-form">
              {{#if @showMenu}}
                <DButton
                  @action={{this.toggleMenu}}
                  @icon={{if this.isMenuOpen "xmark" "bars"}}
                  class="menu-toggle"
                />
              {{/if}}
              <input
                {{on "input" this.onChangeFilterInput}}
                id="setting-filter"
                class="no-blur admin-site-settings-filter-controls__input"
                placeholder={{i18n "type_to_filter"}}
                autocomplete="off"
                type="text"
                value={{this.filter}}
              />
              <DButton
                @action={{this.clearFilter}}
                @label="admin.site_settings.clear_filter"
                id="clear-filter"
                class="btn-default"
              />
            </div>
          </div>
    
          <div class="search controls">
            <label>
              <Input
                @type="checkbox"
                @checked={{this.onlyOverridden}}
                class="toggle-overridden"
                id="setting-filter-toggle-overridden"
                {{on "click" this.onToggleOverridden}}
              />
              {{i18n "admin.settings.show_overriden"}}
            </label>
          </div>
        </div>
      
    */
    {
      "id": "qsKeA2UK",
      "block": "[[[1,\"\\n    \"],[11,0],[24,0,\"admin-controls admin-site-settings-filter-controls\"],[4,[32,0],[[30,0,[\"runInitialFilter\"]]],null],[4,[32,1],[[30,0,[\"runInitialFilter\"]],[30,1]],null],[12],[1,\"\\n      \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"inline-form\"],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"            \"],[8,[32,2],[[24,0,\"menu-toggle\"]],[[\"@action\",\"@icon\"],[[30,0,[\"toggleMenu\"]],[52,[30,0,[\"isMenuOpen\"]],\"xmark\",\"bars\"]]],null],[1,\"\\n\"]],[]],null],[1,\"          \"],[11,\"input\"],[24,1,\"setting-filter\"],[24,0,\"no-blur admin-site-settings-filter-controls__input\"],[16,\"placeholder\",[28,[32,3],[\"type_to_filter\"],null]],[24,\"autocomplete\",\"off\"],[16,2,[30,0,[\"filter\"]]],[24,4,\"text\"],[4,[32,4],[\"input\",[30,0,[\"onChangeFilterInput\"]]],null],[12],[13],[1,\"\\n          \"],[8,[32,2],[[24,1,\"clear-filter\"],[24,0,\"btn-default\"]],[[\"@action\",\"@label\"],[[30,0,[\"clearFilter\"]],\"admin.site_settings.clear_filter\"]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[10,0],[14,0,\"search controls\"],[12],[1,\"\\n        \"],[10,\"label\"],[12],[1,\"\\n          \"],[8,[32,5],[[24,0,\"toggle-overridden\"],[24,1,\"setting-filter-toggle-overridden\"],[4,[32,4],[\"click\",[30,0,[\"onToggleOverridden\"]]],null]],[[\"@type\",\"@checked\"],[\"checkbox\",[30,0,[\"onlyOverridden\"]]]],null],[1,\"\\n          \"],[1,[28,[32,3],[\"admin.settings.show_overriden\"],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@initialFilter\",\"@showMenu\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/admin/components/admin-site-settings-filter-controls.js",
      "scope": () => [_didInsert.default, _didUpdate.default, _dButton.default, _i18n.default, _modifier.on, _component2.Input],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AdminSiteSettingsFilterControls;
});