define("admin/controllers/admin-plugins-show-settings", ["exports", "@ember/controller", "@ember/object"], function (_exports, _controller, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsShowSettingsController extends _controller.default {
    filter = "";
    filterChanged(filterData) {
      this.set("filter", filterData.filter);
    }
    static #_ = (() => dt7948.n(this.prototype, "filterChanged", [_object.action]))();
  }
  _exports.default = AdminPluginsShowSettingsController;
});