define("admin/models/admin-dashboard", ["exports", "@ember/object", "discourse/lib/ajax"], function (_exports, _object, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const GENERAL_ATTRIBUTES = ["updated_at", "discourse_updated_at", "release_notes_link"];
  class AdminDashboard extends _object.default {
    static fetch() {
      return (0, _ajax.ajax)("/admin/dashboard.json").then(json => {
        const model = AdminDashboard.create();
        model.setProperties({
          version_check: json.version_check
        });
        return model;
      });
    }
    static fetchGeneral() {
      return (0, _ajax.ajax)("/admin/dashboard/general.json").then(json => {
        const model = AdminDashboard.create();
        const attributes = {};
        GENERAL_ATTRIBUTES.forEach(a => attributes[a] = json[a]);
        model.setProperties({
          reports: json.reports,
          attributes,
          loaded: true
        });
        return model;
      });
    }
    static fetchProblems() {
      return (0, _ajax.ajax)("/admin/dashboard/problems.json").then(json => {
        const model = AdminDashboard.create(json);
        model.set("loaded", true);
        return model;
      });
    }
  }
  _exports.default = AdminDashboard;
});