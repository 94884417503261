define("admin/components/schema-theme-setting/types/tags", ["exports", "truth-helpers", "admin/components/schema-theme-setting/field-input-description", "admin/components/schema-theme-setting/types/models", "select-kit/components/tag-chooser", "@ember/component", "@ember/template-factory"], function (_exports, _truthHelpers, _fieldInputDescription, _models, _tagChooser, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SchemaThemeSettingTypeTags extends _models.default {
    type = "tags";
    get tagChooserOption() {
      return {
        allowAny: false,
        maximum: this.max
      };
    }
    static #_ = (() => (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <TagChooser
          @tags={{this.value}}
          @onChange={{this.onInput}}
          @options={{this.tagChooserOption}}
          class={{if this.validationErrorMessage "--invalid"}}
        />
    
        <div class="schema-field__input-supporting-text">
          {{#if (and @description (not this.validationErrorMessage))}}
            <FieldInputDescription @description={{@description}} />
          {{/if}}
    
          {{#if this.validationErrorMessage}}
            <div class="schema-field__input-error">
              {{this.validationErrorMessage}}
            </div>
          {{/if}}
        </div>
      
    */
    {
      "id": "7iNKzOLB",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[16,0,[52,[30,0,[\"validationErrorMessage\"]],\"--invalid\"]]],[[\"@tags\",\"@onChange\",\"@options\"],[[30,0,[\"value\"]],[30,0,[\"onInput\"]],[30,0,[\"tagChooserOption\"]]]],null],[1,\"\\n\\n    \"],[10,0],[14,0,\"schema-field__input-supporting-text\"],[12],[1,\"\\n\"],[41,[28,[32,1],[[30,1],[28,[32,2],[[30,0,[\"validationErrorMessage\"]]],null]],null],[[[1,\"        \"],[8,[32,3],null,[[\"@description\"],[[30,1]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"validationErrorMessage\"]],[[[1,\"        \"],[10,0],[14,0,\"schema-field__input-error\"],[12],[1,\"\\n          \"],[1,[30,0,[\"validationErrorMessage\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@description\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/admin/components/schema-theme-setting/types/tags.js",
      "scope": () => [_tagChooser.default, _truthHelpers.and, _truthHelpers.not, _fieldInputDescription.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = SchemaThemeSettingTypeTags;
});