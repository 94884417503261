define("admin/components/report-filters/category", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "admin/components/report-filters/filter", "@ember/template-factory"], function (_exports, _component, _object, _computed, _filter, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <SearchAdvancedCategoryChooser
    @value={{this.category}}
    @onChange={{this.onChange}}
    @options={{hash filterable=true}}
  />
  */
  {
    "id": "ppGaiF2r",
    "block": "[[[8,[39,0],null,[[\"@value\",\"@onChange\",\"@options\"],[[30,0,[\"category\"]],[30,0,[\"onChange\"]],[28,[37,1],null,[[\"filterable\"],[true]]]]],null]],[],false,[\"search-advanced-category-chooser\",\"hash\"]]",
    "moduleName": "admin/components/report-filters/category.hbs",
    "isStrictMode": false
  });
  class Category extends _filter.default {
    static #_ = (() => dt7948.g(this.prototype, "category", [(0, _computed.readOnly)("filter.default")]))();
    #category = (() => (dt7948.i(this, "category"), void 0))();
    onChange(categoryId) {
      this.applyFilter(this.filter.id, categoryId || undefined);
    }
    static #_2 = (() => dt7948.n(this.prototype, "onChange", [_object.action]))();
  }
  _exports.default = Category;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Category);
});