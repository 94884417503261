define("admin/components/dashboard-problems", ["exports", "@glimmer/component", "@ember/helper", "@ember/object", "truth-helpers", "discourse/components/conditional-loading-section", "discourse/components/d-button", "discourse/helpers/concat-class", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/helpers/d-icon", "discourse-common/helpers/i18n", "admin/components/admin-notice", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _object, _truthHelpers, _conditionalLoadingSection, _dButton, _concatClass, _ajax, _ajaxError, _dIcon, _i18n, _adminNotice, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DashboardProblems extends _component.default {
    async dismissProblem(problem1) {
      try {
        await (0, _ajax.ajax)(`/admin/admin_notices/${problem1.id}`, {
          type: "DELETE"
        });
        this.args.problems.removeObject(problem1);
      } catch (error1) {
        (0, _ajaxError.popupAjaxError)(error1);
      }
    }
    static #_ = (() => dt7948.n(this.prototype, "dismissProblem", [_object.action]))();
    get problems() {
      return this.args.problems.sortBy("priority");
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if @problems.length}}
          <div class="section dashboard-problems">
            <div class="section-title">
              <h2>
                {{icon "heart"}}
                {{i18n "admin.dashboard.problems_found"}}
              </h2>
            </div>
    
            <div class="section-body">
              <ConditionalLoadingSection @isLoading={{@loadingProblems}}>
                <div class="problem-messages">
                  <ul>
                    {{#each this.problems as |problem|}}
                      <li
                        class={{concatClass
                          "dashboard-problem"
                          (concat "priority-" problem.priority)
                        }}
                      >
                        <AdminNotice
                          @icon={{if
                            (eq problem.priority "high")
                            "triangle-exclamation"
                          }}
                          @problem={{problem}}
                          @dismissCallback={{this.dismissProblem}}
                        />
                      </li>
                    {{/each}}
                  </ul>
                </div>
    
                <p class="actions">
                  <DButton
                    @action={{@refreshProblems}}
                    @icon="arrows-rotate"
                    @label="admin.dashboard.refresh_problems"
                    class="btn-default"
                  />
                  {{i18n "admin.dashboard.last_checked"}}:
                  {{@problemsTimestamp}}
                </p>
              </ConditionalLoadingSection>
            </div>
          </div>
        {{/if}}
      
    */
    {
      "id": "yThfMMPo",
      "block": "[[[1,\"\\n\"],[41,[30,1,[\"length\"]],[[[1,\"      \"],[10,0],[14,0,\"section dashboard-problems\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"section-title\"],[12],[1,\"\\n          \"],[10,\"h2\"],[12],[1,\"\\n            \"],[1,[28,[32,0],[\"heart\"],null]],[1,\"\\n            \"],[1,[28,[32,1],[\"admin.dashboard.problems_found\"],null]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[10,0],[14,0,\"section-body\"],[12],[1,\"\\n          \"],[8,[32,2],null,[[\"@isLoading\"],[[30,2]]],[[\"default\"],[[[[1,\"\\n            \"],[10,0],[14,0,\"problem-messages\"],[12],[1,\"\\n              \"],[10,\"ul\"],[12],[1,\"\\n\"],[42,[28,[31,2],[[28,[31,2],[[30,0,[\"problems\"]]],null]],null],null,[[[1,\"                  \"],[10,\"li\"],[15,0,[28,[32,3],[\"dashboard-problem\",[28,[32,4],[\"priority-\",[30,3,[\"priority\"]]],null]],null]],[12],[1,\"\\n                    \"],[8,[32,5],null,[[\"@icon\",\"@problem\",\"@dismissCallback\"],[[52,[28,[32,6],[[30,3,[\"priority\"]],\"high\"],null],\"triangle-exclamation\"],[30,3],[30,0,[\"dismissProblem\"]]]],null],[1,\"\\n                  \"],[13],[1,\"\\n\"]],[3]],null],[1,\"              \"],[13],[1,\"\\n            \"],[13],[1,\"\\n\\n            \"],[10,2],[14,0,\"actions\"],[12],[1,\"\\n              \"],[8,[32,7],[[24,0,\"btn-default\"]],[[\"@action\",\"@icon\",\"@label\"],[[30,4],\"arrows-rotate\",\"admin.dashboard.refresh_problems\"]],null],[1,\"\\n              \"],[1,[28,[32,1],[\"admin.dashboard.last_checked\"],null]],[1,\":\\n              \"],[1,[30,5]],[1,\"\\n            \"],[13],[1,\"\\n          \"]],[]]]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@problems\",\"@loadingProblems\",\"problem\",\"@refreshProblems\",\"@problemsTimestamp\"],false,[\"if\",\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/admin/components/dashboard-problems.js",
      "scope": () => [_dIcon.default, _i18n.default, _conditionalLoadingSection.default, _concatClass.default, _helper.concat, _adminNotice.default, _truthHelpers.eq, _dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = DashboardProblems;
});