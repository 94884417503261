define("admin/components/report-filters/bool", ["exports", "@ember/component", "@ember/object", "admin/components/report-filters/filter", "@ember/template-factory"], function (_exports, _component, _object, _filter, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Input
    @type="checkbox"
    @checked={{this.checked}}
    {{on "click" this.onChange}}
  />
  */
  {
    "id": "HGAWsltF",
    "block": "[[[8,[39,0],[[4,[38,1],[\"click\",[30,0,[\"onChange\"]]],null]],[[\"@type\",\"@checked\"],[\"checkbox\",[30,0,[\"checked\"]]]],null]],[],false,[\"input\",\"on\"]]",
    "moduleName": "admin/components/report-filters/bool.hbs",
    "isStrictMode": false
  });
  class Bool extends _filter.default {
    checked = false;
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      this.set("checked", !!this.filter.default);
    }
    onChange() {
      this.applyFilter(this.filter.id, !this.checked || undefined);
    }
    static #_ = (() => dt7948.n(this.prototype, "onChange", [_object.action]))();
  }
  _exports.default = Bool;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Bool);
});