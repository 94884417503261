define("admin/components/schema-theme-setting/types/integer", ["exports", "admin/components/schema-theme-setting/number-field"], function (_exports, _numberField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SchemaThemeSettingTypeInteger extends _numberField.default {
    inputMode = "numeric";
    pattern = "[0-9]*";
    parseValue(value) {
      return parseInt(value, 10);
    }
  }
  _exports.default = SchemaThemeSettingTypeInteger;
});