define("admin/models/screened-ip-address", ["exports", "@ember/object", "@ember/object/computed", "discourse/lib/ajax", "discourse-common/utils/decorators", "discourse-i18n"], function (_exports, _object, _computed, _ajax, _decorators, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ScreenedIpAddress extends _object.default {
    static findAll(filter) {
      return (0, _ajax.ajax)("/admin/logs/screened_ip_addresses.json", {
        data: {
          filter
        }
      }).then(screened_ips => screened_ips.map(b => ScreenedIpAddress.create(b)));
    }
    static #_ = (() => dt7948.g(this.prototype, "isBlocked", [(0, _computed.equal)("action_name", "block")]))();
    #isBlocked = (() => (dt7948.i(this, "isBlocked"), void 0))();
    actionName(actionName) {
      return _discourseI18n.default.t(`admin.logs.screened_ips.actions.${actionName}`);
    }
    static #_2 = (() => dt7948.n(this.prototype, "actionName", [(0, _decorators.default)("action_name")]))();
    isRange(ipAddress) {
      return ipAddress.indexOf("/") > 0;
    }
    static #_3 = (() => dt7948.n(this.prototype, "isRange", [(0, _decorators.default)("ip_address")]))();
    save() {
      return (0, _ajax.ajax)("/admin/logs/screened_ip_addresses" + (this.id ? "/" + this.id : "") + ".json", {
        type: this.id ? "PUT" : "POST",
        data: {
          ip_address: this.ip_address,
          action_name: this.action_name
        }
      });
    }
    destroy() {
      return (0, _ajax.ajax)("/admin/logs/screened_ip_addresses/" + this.id + ".json", {
        type: "DELETE"
      });
    }
  }
  _exports.default = ScreenedIpAddress;
});