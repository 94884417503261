define("admin/components/schema-theme-setting/types/models", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/utils", "discourse-i18n"], function (_exports, _component, _tracking, _object, _utils, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SchemaThemeSettingTypeModels extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "value", [_tracking.tracked], function () {
      return this.args.value;
    }))();
    #value = (() => (dt7948.i(this, "value"), void 0))();
    required = this.args.spec.required;
    min = this.args.spec.validations?.min;
    max = this.args.spec.validations?.max;
    type;
    onInput(newValue) {
      this.value = newValue;
      this.args.onChange(this.onChange(newValue));
    }
    static #_2 = (() => dt7948.n(this.prototype, "onInput", [_object.action]))();
    onChange(newValue) {
      return newValue;
    }
    get validationErrorMessage() {
      const isValueBlank = (0, _utils.isBlank)(this.value);
      if (!this.required && isValueBlank) {
        return;
      }
      if (this.min && this.value && this.value.length < this.min || this.required && isValueBlank) {
        return _discourseI18n.default.t(`admin.customize.theme.schema.fields.${this.type}.at_least`, {
          count: this.min || 1
        });
      }
    }
  }
  _exports.default = SchemaThemeSettingTypeModels;
});