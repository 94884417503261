define("admin/templates/customize-form-templates-new", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="new-form-template">
    <FormTemplate::InfoHeader />
    <FormTemplate::Form />
  </div>
  */
  {
    "id": "H4Zl1MHY",
    "block": "[[[10,0],[14,0,\"new-form-template\"],[12],[1,\"\\n  \"],[8,[39,0],null,null,null],[1,\"\\n  \"],[8,[39,1],null,null,null],[1,\"\\n\"],[13]],[],false,[\"form-template/info-header\",\"form-template/form\"]]",
    "moduleName": "admin/templates/customize-form-templates-new.hbs",
    "isStrictMode": false
  });
});