define("admin/components/modal/merge-users-confirmation", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "discourse-i18n", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _discourseI18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    @title={{html-safe
      (i18n "admin.user.merge.confirmation.title" username=@model.username)
    }}
    @closeModal={{@closeModal}}
  >
    <:body>
      <p>
        {{html-safe
          (i18n
            "admin.user.merge.confirmation.description"
            username=@model.username
            targetUsername=@model.targetUsername
            text=this.text
          )
        }}
      </p>
      <Input @type="text" @value={{this.value}} />
    </:body>
    <:footer>
      <DButton
        class="btn-danger"
        @action={{fn @model.merge @model.targetUsername}}
        @icon="trash-can"
        @disabled={{this.mergeDisabled}}
        @translatedLabel={{i18n
          "admin.user.merge.confirmation.transfer_and_delete"
          username=@model.username
        }}
      />
      <DButton
        @action={{@closeModal}}
        @label="admin.user.merge.confirmation.cancel"
      />
    </:footer>
  </DModal>
  */
  {
    "id": "xbXdnwIX",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@closeModal\"],[[28,[37,1],[[28,[37,2],[\"admin.user.merge.confirmation.title\"],[[\"username\"],[[30,1,[\"username\"]]]]]],null],[30,2]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[10,2],[12],[1,\"\\n      \"],[1,[28,[35,1],[[28,[37,2],[\"admin.user.merge.confirmation.description\"],[[\"username\",\"targetUsername\",\"text\"],[[30,1,[\"username\"]],[30,1,[\"targetUsername\"]],[30,0,[\"text\"]]]]]],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[8,[39,3],null,[[\"@type\",\"@value\"],[\"text\",[30,0,[\"value\"]]]],null],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,4],[[24,0,\"btn-danger\"]],[[\"@action\",\"@icon\",\"@disabled\",\"@translatedLabel\"],[[28,[37,5],[[30,1,[\"merge\"]],[30,1,[\"targetUsername\"]]],null],\"trash-can\",[30,0,[\"mergeDisabled\"]],[28,[37,2],[\"admin.user.merge.confirmation.transfer_and_delete\"],[[\"username\"],[[30,1,[\"username\"]]]]]]],null],[1,\"\\n    \"],[8,[39,4],null,[[\"@action\",\"@label\"],[[30,2],\"admin.user.merge.confirmation.cancel\"]],null],[1,\"\\n  \"]],[]]]]]],[\"@model\",\"@closeModal\"],false,[\"d-modal\",\"html-safe\",\"i18n\",\"input\",\"d-button\",\"fn\"]]",
    "moduleName": "admin/components/modal/merge-users-confirmation.hbs",
    "isStrictMode": false
  });
  class MergeUsersConfirmation extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "value", [_tracking.tracked]))();
    #value = (() => (dt7948.i(this, "value"), void 0))();
    get mergeDisabled() {
      return !this.value || this.text !== this.value;
    }
    get text() {
      return _discourseI18n.default.t("admin.user.merge.confirmation.text", {
        username: this.args.model.username,
        targetUsername: this.args.model.targetUsername
      });
    }
  }
  _exports.default = MergeUsersConfirmation;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, MergeUsersConfirmation);
});