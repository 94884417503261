define("admin/models/form-template", ["exports", "discourse/lib/ajax", "discourse/models/rest"], function (_exports, _ajax, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class FormTemplate extends _rest.default {
    static createTemplate(data) {
      return (0, _ajax.ajax)("/admin/customize/form-templates.json", {
        type: "POST",
        data
      });
    }
    static updateTemplate(id, data) {
      return (0, _ajax.ajax)(`/admin/customize/form-templates/${id}.json`, {
        type: "PUT",
        data
      });
    }
    static createOrUpdateTemplate(data) {
      if (data.id) {
        return this.updateTemplate(data.id, data);
      } else {
        return this.createTemplate(data);
      }
    }
    static deleteTemplate(id) {
      return (0, _ajax.ajax)(`/admin/customize/form-templates/${id}.json`, {
        type: "DELETE"
      });
    }
    static async findAll() {
      const result = await (0, _ajax.ajax)("/admin/customize/form-templates.json");
      return result.form_templates;
    }
    static async findById(id) {
      const result = await (0, _ajax.ajax)(`/admin/customize/form-templates/${id}.json`);
      return result.form_template;
    }
    static validateTemplate(data) {
      return (0, _ajax.ajax)(`/admin/customize/form-templates/preview.json`, {
        type: "GET",
        data
      });
    }
  }
  _exports.default = FormTemplate;
});