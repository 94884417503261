define("admin/controllers/admin-customize-email-templates-edit", ["exports", "@ember/controller", "@ember/object", "@ember/service", "discourse/lib/ajax-error", "discourse/mixins/buffered-content", "discourse-common/utils/decorators", "discourse-i18n"], function (_exports, _controller, _object, _service, _ajaxError, _bufferedContent, _decorators, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminCustomizeEmailTemplatesEditController extends _controller.default.extend((0, _bufferedContent.bufferedProperty)("emailTemplate")) {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "adminCustomizeEmailTemplates", [_controller.inject]))();
    #adminCustomizeEmailTemplates = (() => (dt7948.i(this, "adminCustomizeEmailTemplates"), void 0))();
    emailTemplate = null;
    saved = false;
    saveDisabled(body, subject) {
      return this.emailTemplate.body === body && this.emailTemplate.subject === subject;
    }
    static #_3 = (() => dt7948.n(this.prototype, "saveDisabled", [(0, _decorators.default)("buffered.body", "buffered.subject")]))();
    hasMultipleSubjects(buffered) {
      if (buffered.getProperties("subject")["subject"]) {
        return false;
      } else {
        return buffered.getProperties("id")["id"];
      }
    }
    static #_4 = (() => dt7948.n(this.prototype, "hasMultipleSubjects", [(0, _decorators.default)("buffered")]))();
    saveChanges() {
      this.set("saved", false);
      const buffered = this.buffered;
      this.emailTemplate.save(buffered.getProperties("subject", "body")).then(() => {
        this.set("saved", true);
      }).catch(_ajaxError.popupAjaxError);
    }
    static #_5 = (() => dt7948.n(this.prototype, "saveChanges", [_object.action]))();
    revertChanges() {
      this.set("saved", false);
      this.dialog.yesNoConfirm({
        title: _discourseI18n.default.t("admin.customize.email_templates.revert_confirm"),
        didConfirm: () => {
          return this.emailTemplate.revert().then(props => {
            const buffered = this.buffered;
            buffered.setProperties(props);
            this.commitBuffer();
          }).catch(_ajaxError.popupAjaxError);
        }
      });
    }
    static #_6 = (() => dt7948.n(this.prototype, "revertChanges", [_object.action]))();
  }
  _exports.default = AdminCustomizeEmailTemplatesEditController;
});