define("admin/templates/config-flags-settings", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <AdminConfigAreas::FlagsSettings
    @model={{this.model}}
    @onFilterChanged={{this.filterChangedCallback}}
    @initialFilter={{this.filter}}
  />
  */
  {
    "id": "r8q5ZDW/",
    "block": "[[[8,[39,0],null,[[\"@model\",\"@onFilterChanged\",\"@initialFilter\"],[[30,0,[\"model\"]],[30,0,[\"filterChangedCallback\"]],[30,0,[\"filter\"]]]],null]],[],false,[\"admin-config-areas/flags-settings\"]]",
    "moduleName": "admin/templates/config-flags-settings.hbs",
    "isStrictMode": false
  });
});