define("admin/components/modal/start-backup", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse-i18n", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _discourseI18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    @title={{i18n "admin.backups.operations.backup.confirm"}}
    @closeModal={{@closeModal}}
    class="start-backup-modal"
  >
    <:body>
      {{#if this.warningMessage}}
        <div class={{this.warningCssClasses}}>{{html-safe
            this.warningMessage
          }}</div>
      {{/if}}
      {{#if this.canManageUploadsInBackup}}
        <label class="checkbox-label">
          <Input @type="checkbox" @checked={{this.includeUploads}} />
          {{i18n "admin.backups.operations.backup.include_uploads"}}
        </label>
      {{/if}}
    </:body>
    <:footer>
      <DButton
        class="btn-primary"
        @action={{this.startBackup}}
        @label="yes_value"
      />
      <DButton class="btn-flat" @action={{@closeModal}} @label="cancel" />
    </:footer>
  </DModal>
  */
  {
    "id": "4myM27W0",
    "block": "[[[8,[39,0],[[24,0,\"start-backup-modal\"]],[[\"@title\",\"@closeModal\"],[[28,[37,1],[\"admin.backups.operations.backup.confirm\"],null],[30,1]]],[[\"body\",\"footer\"],[[[[1,\"\\n\"],[41,[30,0,[\"warningMessage\"]],[[[1,\"      \"],[10,0],[15,0,[30,0,[\"warningCssClasses\"]]],[12],[1,[28,[35,3],[[30,0,[\"warningMessage\"]]],null]],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"canManageUploadsInBackup\"]],[[[1,\"      \"],[10,\"label\"],[14,0,\"checkbox-label\"],[12],[1,\"\\n        \"],[8,[39,4],null,[[\"@type\",\"@checked\"],[\"checkbox\",[30,0,[\"includeUploads\"]]]],null],[1,\"\\n        \"],[1,[28,[35,1],[\"admin.backups.operations.backup.include_uploads\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[]],[[[1,\"\\n    \"],[8,[39,5],[[24,0,\"btn-primary\"]],[[\"@action\",\"@label\"],[[30,0,[\"startBackup\"]],\"yes_value\"]],null],[1,\"\\n    \"],[8,[39,5],[[24,0,\"btn-flat\"]],[[\"@action\",\"@label\"],[[30,1],\"cancel\"]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\"i18n\",\"if\",\"html-safe\",\"input\",\"d-button\"]]",
    "moduleName": "admin/components/modal/start-backup.hbs",
    "isStrictMode": false
  });
  class StartBackup extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "includeUploads", [_tracking.tracked], function () {
      return true;
    }))();
    #includeUploads = (() => (dt7948.i(this, "includeUploads"), void 0))();
    get canManageUploadsInBackup() {
      return !this.siteSettings.enable_s3_uploads || this.siteSettings.include_s3_uploads_in_backups;
    }
    get warningCssClasses() {
      return "";
    }
    get warningMessage() {
      if (this.siteSettings.enable_s3_uploads && !this.siteSettings.include_s3_uploads_in_backups) {
        return _discourseI18n.default.t("admin.backups.operations.backup.s3_upload_warning");
      }
      return "";
    }
    startBackup() {
      this.args.model.startBackup(this.includeUploads);
      this.args.closeModal();
    }
    static #_3 = (() => dt7948.n(this.prototype, "startBackup", [_object.action]))();
  }
  _exports.default = StartBackup;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, StartBackup);
});