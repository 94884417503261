define("admin/components/schema-theme-setting/types/boolean", ["exports", "@glimmer/component", "@ember/component", "@ember/modifier", "@ember/object", "admin/components/schema-theme-setting/field-input-description", "@ember/template-factory"], function (_exports, _component, _component2, _modifier, _object, _fieldInputDescription, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SchemaThemeSettingTypeBoolean extends _component.default {
    onInput(event1) {
      this.args.onChange(event1.currentTarget.checked);
    }
    static #_ = (() => dt7948.n(this.prototype, "onInput", [_object.action]))();
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <Input @checked={{@value}} {{on "input" this.onInput}} @type="checkbox" />
        <FieldInputDescription @description={{@description}} />
      
    */
    {
      "id": "Yhr7U84f",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[4,[32,1],[\"input\",[30,0,[\"onInput\"]]],null]],[[\"@checked\",\"@type\"],[[30,1],\"checkbox\"]],null],[1,\"\\n    \"],[8,[32,2],null,[[\"@description\"],[[30,2]]],null],[1,\"\\n  \"]],[\"@value\",\"@description\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/admin/components/schema-theme-setting/types/boolean.js",
      "scope": () => [_component2.Input, _modifier.on, _fieldInputDescription.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = SchemaThemeSettingTypeBoolean;
});