define("admin/routes/admin-customize-themes-index", ["exports", "@ember/routing/route", "discourse/lib/text"], function (_exports, _route, _text) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const externalResources = [{
    key: "admin.customize.theme.beginners_guide_title",
    link: "https://meta.discourse.org/t/91966",
    icon: "book"
  }, {
    key: "admin.customize.theme.developers_guide_title",
    link: "https://meta.discourse.org/t/93648",
    icon: "book"
  }, {
    key: "admin.customize.theme.browse_themes",
    link: "https://meta.discourse.org/c/theme",
    icon: "paintbrush"
  }];
  class AdminCustomizeThemesIndexRoute extends _route.default {
    setupController(controller) {
      super.setupController(...arguments);
      this.controllerFor("adminCustomizeThemes").set("editingTheme", false);
      controller.setProperties({
        externalResources,
        womanArtistEmojiURL: (0, _text.emojiUrlFor)("woman_artist:t5")
      });
    }
  }
  _exports.default = AdminCustomizeThemesIndexRoute;
});