define("admin/components/file-size-input", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/modifier", "@ember/object", "discourse/lib/utilities", "discourse-i18n", "select-kit/components/combo-box", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _modifier, _object, _utilities, _discourseI18n, _comboBox, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const UNIT_KB = "kb";
  const UNIT_MB = "mb";
  const UNIT_GB = "gb";
  class FileSizeInput extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "unit", [_tracking.tracked]))();
    #unit = (() => (dt7948.i(this, "unit"), void 0))();
    constructor() {
      super(...arguments);
      const sizeInKB1 = this.args.sizeValueKB;
      if (sizeInKB1 >= 1024 * 1024) {
        this.unit = UNIT_GB;
      } else if (sizeInKB1 >= 1024) {
        this.unit = UNIT_MB;
      } else {
        this.unit = UNIT_KB;
      }
    }
    get number() {
      const sizeInKB1 = this.args.sizeValueKB;
      if (!sizeInKB1) {
        return;
      }
      if (this.unit === UNIT_KB) {
        return sizeInKB1;
      }
      if (this.unit === UNIT_MB) {
        return sizeInKB1 / 1024;
      }
      if (this.unit === UNIT_GB) {
        return sizeInKB1 / 1024 / 1024;
      }
    }
    keyDown(event1) {
      (0, _utilities.allowOnlyNumericInput)(event1);
    }
    static #_2 = (() => dt7948.n(this.prototype, "keyDown", [_object.action]))();
    get dropdownOptions() {
      return [{
        label: _discourseI18n.default.t("number.human.storage_units.units.kb"),
        value: UNIT_KB
      }, {
        label: _discourseI18n.default.t("number.human.storage_units.units.mb"),
        value: UNIT_MB
      }, {
        label: _discourseI18n.default.t("number.human.storage_units.units.gb"),
        value: UNIT_GB
      }];
    }
    handleFileSizeChange(event1) {
      const value1 = parseFloat(event1.target.value);
      if (isNaN(value1)) {
        this.args.onChangeSize();
        return;
      }
      let sizeInKB1;
      switch (this.unit) {
        case "kb":
          sizeInKB1 = value1;
          break;
        case "mb":
          sizeInKB1 = value1 * 1024;
          break;
        case "gb":
          sizeInKB1 = value1 * 1024 * 1024;
          break;
      }
      this.args.onChangeSize(sizeInKB1);
      if (sizeInKB1 > this.args.max) {
        this.args.setValidationMessage(_discourseI18n.default.t("file_size_input.error.size_too_large", {
          provided_file_size: _discourseI18n.default.toHumanSize(sizeInKB1 * 1024),
          max_file_size: _discourseI18n.default.toHumanSize(this.args.max * 1024)
        }));
      } else if (sizeInKB1 < this.args.min) {
        this.args.setValidationMessage(_discourseI18n.default.t("file_size_input.error.size_too_small", {
          provided_file_size: _discourseI18n.default.toHumanSize(sizeInKB1 * 1024),
          min_file_size: _discourseI18n.default.toHumanSize(this.args.min * 1024)
        }));
      } else {
        this.args.setValidationMessage(null);
      }
    }
    static #_3 = (() => dt7948.n(this.prototype, "handleFileSizeChange", [_object.action]))();
    onFileSizeUnitChange(newUnit1) {
      this.unit = newUnit1;
    }
    static #_4 = (() => dt7948.n(this.prototype, "onFileSizeUnitChange", [_object.action]))();
    static #_5 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="file-size-picker">
          <input
            class="file-size-input"
            value={{this.number}}
            type="number"
            step="any"
            {{on "input" this.handleFileSizeChange}}
            {{on "keydown" this.keyDown}}
          />
          <ComboBox
            class="file-size-unit-selector"
            @valueProperty="value"
            @content={{this.dropdownOptions}}
            @value={{this.unit}}
            @onChange={{this.onFileSizeUnitChange}}
          />
        </div>
      
    */
    {
      "id": "5fO9llxp",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"file-size-picker\"],[12],[1,\"\\n      \"],[11,\"input\"],[24,0,\"file-size-input\"],[16,2,[30,0,[\"number\"]]],[24,\"step\",\"any\"],[24,4,\"number\"],[4,[32,0],[\"input\",[30,0,[\"handleFileSizeChange\"]]],null],[4,[32,0],[\"keydown\",[30,0,[\"keyDown\"]]],null],[12],[13],[1,\"\\n      \"],[8,[32,1],[[24,0,\"file-size-unit-selector\"]],[[\"@valueProperty\",\"@content\",\"@value\",\"@onChange\"],[\"value\",[30,0,[\"dropdownOptions\"]],[30,0,[\"unit\"]],[30,0,[\"onFileSizeUnitChange\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/admin/components/file-size-input.js",
      "scope": () => [_modifier.on, _comboBox.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = FileSizeInput;
});