define("admin/controllers/admin-site-text-index", ["exports", "@glimmer/tracking", "@ember/controller", "@ember/object", "@ember/service", "discourse/lib/implicit-injections", "discourse-common/lib/debounce", "admin/components/modal/reseed"], function (_exports, _tracking, _controller, _object, _service, _implicitInjections, _debounce, _reseed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let lastSearch;
  const AdminSiteTextIndexController = dt7948.c(class AdminSiteTextIndexController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "store", [_service.service]))();
    #store = (() => (dt7948.i(this, "store"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "locale", [_tracking.tracked]))();
    #locale = (() => (dt7948.i(this, "locale"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "q", [_tracking.tracked]))();
    #q = (() => (dt7948.i(this, "q"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "overridden", [_tracking.tracked]))();
    #overridden = (() => (dt7948.i(this, "overridden"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "outdated", [_tracking.tracked]))();
    #outdated = (() => (dt7948.i(this, "outdated"), void 0))();
    static #_9 = (() => dt7948.g(this.prototype, "untranslated", [_tracking.tracked]))();
    #untranslated = (() => (dt7948.i(this, "untranslated"), void 0))();
    static #_10 = (() => dt7948.g(this.prototype, "onlySelectedLocale", [_tracking.tracked]))();
    #onlySelectedLocale = (() => (dt7948.i(this, "onlySelectedLocale"), void 0))();
    static #_11 = (() => dt7948.g(this.prototype, "model", [_tracking.tracked]))();
    #model = (() => (dt7948.i(this, "model"), void 0))();
    static #_12 = (() => dt7948.g(this.prototype, "searching", [_tracking.tracked], function () {
      return false;
    }))();
    #searching = (() => (dt7948.i(this, "searching"), void 0))();
    static #_13 = (() => dt7948.g(this.prototype, "preferred", [_tracking.tracked], function () {
      return false;
    }))();
    #preferred = (() => (dt7948.i(this, "preferred"), void 0))();
    queryParams = ["q", "overridden", "outdated", "locale", "untranslated", "onlySelectedLocale"];
    get resolvedOverridden() {
      return [true, "true"].includes(this.overridden) ?? false;
    }
    get resolvedOutdated() {
      return [true, "true"].includes(this.outdated) ?? false;
    }
    get resolvedUntranslated() {
      return [true, "true"].includes(this.untranslated) ?? false;
    }
    get resolvedOnlySelectedLocale() {
      return [true, "true"].includes(this.onlySelectedLocale) ?? false;
    }
    get resolvedLocale() {
      return this.locale ?? this.siteSettings.default_locale;
    }
    get showUntranslated() {
      return this.siteSettings.admin_allow_filter_untranslated_text && this.resolvedLocale !== "en";
    }
    async _performSearch() {
      try {
        this.model = await this.store.find("site-text", {
          q: this.q,
          overridden: this.resolvedOverridden,
          outdated: this.resolvedOutdated,
          locale: this.resolvedLocale,
          untranslated: this.resolvedUntranslated,
          only_selected_locale: this.resolvedOnlySelectedLocale
        });
      } finally {
        this.searching = false;
      }
    }
    get availableLocales() {
      return JSON.parse(this.siteSettings.available_locales);
    }
    get fallbackLocaleFullName() {
      if (this.model.extras.fallback_locale) {
        return this.availableLocales.find(l => {
          return l.value === this.model.extras.fallback_locale;
        }).name;
      }
    }
    edit(siteText) {
      this.router.transitionTo("adminSiteText.edit", siteText.get("id"), {
        queryParams: {
          locale: this.resolvedLocale
        }
      });
    }
    static #_14 = (() => dt7948.n(this.prototype, "edit", [_object.action]))();
    toggleOverridden() {
      if (this.resolvedOverridden) {
        this.overridden = null;
      } else {
        this.overridden = true;
      }
      this.searching = true;
      (0, _debounce.default)(this, this._performSearch, 400);
    }
    static #_15 = (() => dt7948.n(this.prototype, "toggleOverridden", [_object.action]))();
    toggleOutdated() {
      if (this.resolvedOutdated) {
        this.outdated = null;
      } else {
        this.outdated = true;
      }
      this.searching = true;
      (0, _debounce.default)(this, this._performSearch, 400);
    }
    static #_16 = (() => dt7948.n(this.prototype, "toggleOutdated", [_object.action]))();
    toggleUntranslated() {
      if (this.resolvedUntranslated) {
        this.untranslated = null;
      } else {
        this.untranslated = true;
      }
      this.searching = true;
      (0, _debounce.default)(this, this._performSearch, 400);
    }
    static #_17 = (() => dt7948.n(this.prototype, "toggleUntranslated", [_object.action]))();
    toggleOnlySelectedLocale() {
      if (this.resolvedOnlySelectedLocale) {
        this.onlySelectedLocale = null;
      } else {
        this.onlySelectedLocale = true;
      }
      this.searching = true;
      (0, _debounce.default)(this, this._performSearch, 400);
    }
    static #_18 = (() => dt7948.n(this.prototype, "toggleOnlySelectedLocale", [_object.action]))();
    search() {
      const q = this.q;
      if (q !== lastSearch) {
        this.searching = true;
        (0, _debounce.default)(this, this._performSearch, 400);
        lastSearch = q;
      }
    }
    static #_19 = (() => dt7948.n(this.prototype, "search", [_object.action]))();
    updateLocale(value) {
      this.searching = true;
      this.locale = value;
      (0, _debounce.default)(this, this._performSearch, 400);
    }
    static #_20 = (() => dt7948.n(this.prototype, "updateLocale", [_object.action]))();
    showReseedModal() {
      this.modal.show(_reseed.default);
    }
    static #_21 = (() => dt7948.n(this.prototype, "showReseedModal", [_object.action]))();
  }, [_implicitInjections.disableImplicitInjections]);
  var _default = _exports.default = AdminSiteTextIndexController;
});