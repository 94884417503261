define("admin/components/form-template/info-header", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="form-templates--info">
    <h2>{{i18n "admin.form_templates.title"}}</h2>
    <p class="desc">{{i18n "admin.form_templates.help"}}</p>
  </div>
  */
  {
    "id": "0oR5cm9z",
    "block": "[[[10,0],[14,0,\"form-templates--info\"],[12],[1,\"\\n  \"],[10,\"h2\"],[12],[1,[28,[35,0],[\"admin.form_templates.title\"],null]],[13],[1,\"\\n  \"],[10,2],[14,0,\"desc\"],[12],[1,[28,[35,0],[\"admin.form_templates.help\"],null]],[13],[1,\"\\n\"],[13]],[],false,[\"i18n\"]]",
    "moduleName": "admin/components/form-template/info-header.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});