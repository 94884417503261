define("admin/adapters/staff-action-log", ["exports", "discourse/adapters/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class StaffActionLog extends _rest.default {
    basePath() {
      return "/admin/logs/";
    }
  }
  _exports.default = StaffActionLog;
});