define("admin/components/modal/delete-posts-confirmation", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "discourse-i18n", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _discourseI18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    @title={{html-safe
      (i18n
        "admin.user.delete_posts.confirmation.title" username=@model.user.username
      )
    }}
    @closeModal={{@closeModal}}
  >
    <:body>
      <p>{{html-safe
          (i18n
            "admin.user.delete_posts.confirmation.description"
            username=@model.user.username
            post_count=@model.user.post_count
            text=this.text
          )
        }}</p>
      <Input @type="text" @value={{this.value}} />
    </:body>
    <:footer>
      <DButton
        class="btn-danger"
        @action={{@model.deleteAllPosts}}
        @icon="trash-can"
        @disabled={{this.deleteDisabled}}
        @translatedLabel={{i18n
          "admin.user.delete_posts.confirmation.delete"
          username=@model.user.username
        }}
      />
      <DButton
        @action={{@closeModal}}
        @label="admin.user.delete_posts.confirmation.cancel"
      />
    </:footer>
  </DModal>
  */
  {
    "id": "vb3YGp7n",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@closeModal\"],[[28,[37,1],[[28,[37,2],[\"admin.user.delete_posts.confirmation.title\"],[[\"username\"],[[30,1,[\"user\",\"username\"]]]]]],null],[30,2]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[10,2],[12],[1,[28,[35,1],[[28,[37,2],[\"admin.user.delete_posts.confirmation.description\"],[[\"username\",\"post_count\",\"text\"],[[30,1,[\"user\",\"username\"]],[30,1,[\"user\",\"post_count\"]],[30,0,[\"text\"]]]]]],null]],[13],[1,\"\\n    \"],[8,[39,3],null,[[\"@type\",\"@value\"],[\"text\",[30,0,[\"value\"]]]],null],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,4],[[24,0,\"btn-danger\"]],[[\"@action\",\"@icon\",\"@disabled\",\"@translatedLabel\"],[[30,1,[\"deleteAllPosts\"]],\"trash-can\",[30,0,[\"deleteDisabled\"]],[28,[37,2],[\"admin.user.delete_posts.confirmation.delete\"],[[\"username\"],[[30,1,[\"user\",\"username\"]]]]]]],null],[1,\"\\n    \"],[8,[39,4],null,[[\"@action\",\"@label\"],[[30,2],\"admin.user.delete_posts.confirmation.cancel\"]],null],[1,\"\\n  \"]],[]]]]]],[\"@model\",\"@closeModal\"],false,[\"d-modal\",\"html-safe\",\"i18n\",\"input\",\"d-button\"]]",
    "moduleName": "admin/components/modal/delete-posts-confirmation.hbs",
    "isStrictMode": false
  });
  class DeletePostsConfirmation extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "value", [_tracking.tracked]))();
    #value = (() => (dt7948.i(this, "value"), void 0))();
    get text() {
      return _discourseI18n.default.t("admin.user.delete_posts.confirmation.text", {
        username: this.args.model.user.username,
        post_count: this.args.model.user.post_count
      });
    }
    get deleteDisabled() {
      return !this.value || this.text !== this.value;
    }
  }
  _exports.default = DeletePostsConfirmation;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DeletePostsConfirmation);
});