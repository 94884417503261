define("admin/templates/customize-themes", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <PluginOutlet @name="admin-customize-themes">
    {{#unless this.editingTheme}}
      <ThemesList
        @themes={{this.fullThemes}}
        @components={{this.childThemes}}
        @currentTab={{this.currentTab}}
        @installModal={{route-action "installModal"}}
      />
    {{/unless}}
  
    {{outlet}}
  </PluginOutlet>
  */
  {
    "id": "l01vg8jy",
    "block": "[[[8,[39,0],null,[[\"@name\"],[\"admin-customize-themes\"]],[[\"default\"],[[[[1,\"\\n\"],[41,[51,[30,0,[\"editingTheme\"]]],[[[1,\"    \"],[8,[39,2],null,[[\"@themes\",\"@components\",\"@currentTab\",\"@installModal\"],[[30,0,[\"fullThemes\"]],[30,0,[\"childThemes\"]],[30,0,[\"currentTab\"]],[28,[37,3],[\"installModal\"],null]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[46,[28,[37,5],null,null],null,null,null],[1,\"\\n\"]],[]]]]]],[],false,[\"plugin-outlet\",\"unless\",\"themes-list\",\"route-action\",\"component\",\"-outlet\"]]",
    "moduleName": "admin/templates/customize-themes.hbs",
    "isStrictMode": false
  });
});