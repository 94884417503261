define("admin/components/admin-form-row", ["exports", "@ember/component", "@ember-decorators/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="form-element label-area">
    {{#if this.label}}
      <label
        class={{concat-class (if (eq @type "checkbox") "checkbox-label")}}
      >{{i18n this.label}}</label>
    {{else}}
      &nbsp;
    {{/if}}
  </div>
  <div class="form-element input-area">
    {{#if this.wrapLabel}}
      <label
        class={{concat-class (if (eq @type "checkbox") "checkbox-label")}}
      >{{yield}}</label>
    {{else}}
      {{yield}}
    {{/if}}
  </div>
  */
  {
    "id": "t6jZ9Qwu",
    "block": "[[[10,0],[14,0,\"form-element label-area\"],[12],[1,\"\\n\"],[41,[30,0,[\"label\"]],[[[1,\"    \"],[10,\"label\"],[15,0,[28,[37,1],[[52,[28,[37,2],[[30,1],\"checkbox\"],null],\"checkbox-label\"]],null]],[12],[1,[28,[35,3],[[30,0,[\"label\"]]],null]],[13],[1,\"\\n\"]],[]],[[[1,\"     \\n\"]],[]]],[13],[1,\"\\n\"],[10,0],[14,0,\"form-element input-area\"],[12],[1,\"\\n\"],[41,[30,0,[\"wrapLabel\"]],[[[1,\"    \"],[10,\"label\"],[15,0,[28,[37,1],[[52,[28,[37,2],[[30,1],\"checkbox\"],null],\"checkbox-label\"]],null]],[12],[18,2,null],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[18,2,null],[1,\"\\n\"]],[]]],[13]],[\"@type\",\"&default\"],false,[\"if\",\"concat-class\",\"eq\",\"i18n\",\"yield\"]]",
    "moduleName": "admin/components/admin-form-row.hbs",
    "isStrictMode": false
  });
  const AdminFormRow = dt7948.c(class AdminFormRow extends _component.default {}, [(0, _component2.classNames)("row")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AdminFormRow);
});