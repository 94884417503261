define("admin/components/tags-uploader", ["exports", "@ember/component", "@ember/object/computed", "@ember/service", "discourse/mixins/uppy-upload", "discourse-i18n", "@ember/template-factory"], function (_exports, _component, _computed, _service, _uppyUpload, _discourseI18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <label class="btn btn-default {{if this.addDisabled 'disabled'}}">
    {{d-icon "upload"}}
    {{i18n "admin.watched_words.form.upload"}}
    <input
      class="hidden-upload-field"
      disabled={{this.addDisabled}}
      type="file"
      accept="text/plain,text/csv"
    />
  </label>
  <span class="instructions">{{i18n "tagging.upload_instructions"}}</span>
  */
  {
    "id": "9oRqcU2U",
    "block": "[[[10,\"label\"],[15,0,[29,[\"btn btn-default \",[52,[30,0,[\"addDisabled\"]],\"disabled\"]]]],[12],[1,\"\\n  \"],[1,[28,[35,1],[\"upload\"],null]],[1,\"\\n  \"],[1,[28,[35,2],[\"admin.watched_words.form.upload\"],null]],[1,\"\\n  \"],[10,\"input\"],[14,0,\"hidden-upload-field\"],[15,\"disabled\",[30,0,[\"addDisabled\"]]],[14,\"accept\",\"text/plain,text/csv\"],[14,4,\"file\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,1],[14,0,\"instructions\"],[12],[1,[28,[35,2],[\"tagging.upload_instructions\"],null]],[13]],[],false,[\"if\",\"d-icon\",\"i18n\"]]",
    "moduleName": "admin/components/tags-uploader.hbs",
    "isStrictMode": false
  });
  class TagsUploader extends _component.default.extend(_uppyUpload.default) {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    type = "csv";
    uploadUrl = "/tags/upload";
    static #_2 = (() => dt7948.g(this.prototype, "addDisabled", [(0, _computed.alias)("uploading")]))();
    #addDisabled = (() => (dt7948.i(this, "addDisabled"), void 0))();
    elementId = "tag-uploader";
    preventDirectS3Uploads = true;
    validateUploadedFilesOptions() {
      return {
        csvOnly: true
      };
    }
    uploadDone() {
      this.closeModal();
      this.refresh();
      this.dialog.alert(_discourseI18n.default.t("tagging.upload_successful"));
    }
  }
  _exports.default = TagsUploader;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TagsUploader);
});