define("admin/controllers/admin-customize-colors-show", ["exports", "@ember/controller", "@ember/object", "@ember/service", "discourse/lib/utilities", "discourse-common/lib/later", "discourse-i18n"], function (_exports, _controller, _object, _service, _utilities, _later, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminCustomizeColorsShowController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    onlyOverridden = false;
    get colors() {
      if (this.onlyOverridden) {
        return this.model.colors?.filterBy("overridden");
      } else {
        return this.model.colors;
      }
    }
    static #_3 = (() => dt7948.n(this.prototype, "colors", [(0, _object.computed)("model.colors.[]", "onlyOverridden")]))();
    revert(color) {
      color.revert();
    }
    static #_4 = (() => dt7948.n(this.prototype, "revert", [_object.action]))();
    undo(color) {
      color.undo();
    }
    static #_5 = (() => dt7948.n(this.prototype, "undo", [_object.action]))();
    copyToClipboard() {
      if ((0, _utilities.clipboardCopy)(this.model.schemeJson())) {
        this.set("model.savingStatus", _discourseI18n.default.t("admin.customize.copied_to_clipboard"));
      } else {
        this.set("model.savingStatus", _discourseI18n.default.t("admin.customize.copy_to_clipboard_error"));
      }
      (0, _later.default)(() => {
        this.set("model.savingStatus", null);
      }, 2000);
    }
    static #_6 = (() => dt7948.n(this.prototype, "copyToClipboard", [_object.action]))();
    copy() {
      const newColorScheme = this.model.copy();
      newColorScheme.set("name", _discourseI18n.default.t("admin.customize.colors.copy_name_prefix") + " " + this.get("model.name"));
      newColorScheme.save().then(() => {
        this.allColors.pushObject(newColorScheme);
        this.router.replaceWith("adminCustomize.colors.show", newColorScheme);
      });
    }
    static #_7 = (() => dt7948.n(this.prototype, "copy", [_object.action]))();
    save() {
      this.model.save();
    }
    static #_8 = (() => dt7948.n(this.prototype, "save", [_object.action]))();
    applyUserSelectable() {
      this.model.updateUserSelectable(this.get("model.user_selectable"));
    }
    static #_9 = (() => dt7948.n(this.prototype, "applyUserSelectable", [_object.action]))();
    destroy() {
      return this.dialog.yesNoConfirm({
        message: _discourseI18n.default.t("admin.customize.colors.delete_confirm"),
        didConfirm: () => {
          return this.model.destroy().then(() => {
            this.allColors.removeObject(this.model);
            this.router.replaceWith("adminCustomize.colors");
          });
        }
      });
    }
    static #_10 = (() => dt7948.n(this.prototype, "destroy", [_object.action]))();
  }
  _exports.default = AdminCustomizeColorsShowController;
});