define("admin/routes/admin-config-flags-settings", ["exports", "discourse/routes/discourse", "discourse-i18n"], function (_exports, _discourse, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminConfigFlagsIndexRoute extends _discourse.default {
    titleToken() {
      return _discourseI18n.default.t("admin.config_areas.flags.settings");
    }
  }
  _exports.default = AdminConfigFlagsIndexRoute;
});