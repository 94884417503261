define("admin/controllers/admin-site-settings", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/service", "@ember/utils", "discourse/lib/site-setting-filter", "discourse-common/config/environment", "discourse-common/utils/decorators"], function (_exports, _controller, _object, _computed, _service, _utils, _siteSettingFilter, _environment, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminSiteSettingsController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "allSiteSettings", [(0, _computed.alias)("model")]))();
    #allSiteSettings = (() => (dt7948.i(this, "allSiteSettings"), void 0))();
    filter = "";
    visibleSiteSettings = null;
    siteSettingFilter = null;
    filterContentNow(filterData, category) {
      this.siteSettingFilter ??= new _siteSettingFilter.default(this.allSiteSettings);
      if ((0, _utils.isEmpty)(this.allSiteSettings)) {
        return;
      }
      if ((0, _utils.isEmpty)(filterData.filter) && !filterData.onlyOverridden) {
        this.set("visibleSiteSettings", this.allSiteSettings);
        if (this.categoryNameKey === "all_results") {
          this.router.transitionTo("adminSiteSettings");
        }
        return;
      }
      this.set("filter", filterData.filter);
      const matchesGroupedByCategory = this.siteSettingFilter.filterSettings(filterData.filter, {
        onlyOverridden: filterData.onlyOverridden
      });
      const categoryMatches = matchesGroupedByCategory.findBy("nameKey", category);
      if (!categoryMatches || categoryMatches.count === 0) {
        category = "all_results";
      }
      this.set("visibleSiteSettings", matchesGroupedByCategory);
      this.router.transitionTo("adminSiteSettingsCategory", category || "all_results");
    }
    filterContent(filterData) {
      if (this._skipBounce) {
        this.set("_skipBounce", false);
      } else {
        if (!this.isDestroyed) {
          this.filterContentNow(filterData, this.categoryNameKey);
        }
      }
    }
    static #_3 = (() => dt7948.n(this.prototype, "filterContent", [(0, _decorators.debounce)(_environment.INPUT_DELAY)]))();
    filterChanged(filterData) {
      this.filterContent(filterData);
    }
    static #_4 = (() => dt7948.n(this.prototype, "filterChanged", [_object.action]))();
    toggleMenu() {
      const adminDetail = document.querySelector(".admin-detail");
      ["mobile-closed", "mobile-open"].forEach(state => {
        adminDetail.classList.toggle(state);
      });
    }
    static #_5 = (() => dt7948.n(this.prototype, "toggleMenu", [_object.action]))();
  }
  _exports.default = AdminSiteSettingsController;
});