define("admin/routes/admin-users-list", ["exports", "@ember/object", "@ember/service", "discourse/lib/export-csv", "discourse/lib/export-result", "discourse/routes/discourse", "admin/models/admin-user"], function (_exports, _object, _service, _exportCsv, _exportResult, _discourse, _adminUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminUsersListRoute extends _discourse.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    exportUsers() {
      (0, _exportCsv.exportEntity)("user_list", {
        trust_level: this.controllerFor("admin-users-list-show").get("query")
      }).then(_exportResult.outputExportResult);
    }
    static #_2 = (() => dt7948.n(this.prototype, "exportUsers", [_object.action]))();
    sendInvites() {
      this.router.transitionTo("userInvited", this.currentUser);
    }
    static #_3 = (() => dt7948.n(this.prototype, "sendInvites", [_object.action]))();
    deleteUser(user) {
      _adminUser.default.create(user).destroy({
        deletePosts: true
      });
    }
    static #_4 = (() => dt7948.n(this.prototype, "deleteUser", [_object.action]))();
  }
  _exports.default = AdminUsersListRoute;
});