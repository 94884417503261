define("admin/components/admin-report-stacked-chart", ["exports", "@glimmer/component", "discourse/lib/formatter", "discourse-common/lib/helpers", "admin/models/report", "admin/components/chart", "@ember/component", "@ember/template-factory"], function (_exports, _component, _formatter, _helpers, _report, _chart, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminReportStackedChart extends _component.default {
    get chartConfig() {
      const {
        model: model1
      } = this.args;
      const options1 = this.args.options || {};
      options1.hiddenLabels ??= [];
      const chartData1 = (0, _helpers.makeArray)(model1.chartData || model1.data).map(cd1 => ({
        label: cd1.label,
        color: cd1.color,
        data: _report.default.collapse(model1, cd1.data),
        req: cd1.req
      }));
      const data1 = {
        labels: chartData1[0].data.mapBy("x"),
        datasets: chartData1.map(cd1 => ({
          label: cd1.label,
          stack: "pageviews-stack",
          data: cd1.data,
          backgroundColor: cd1.color,
          hidden: options1.hiddenLabels.includes(cd1.req)
        }))
      };
      return {
        type: "bar",
        data: data1,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 0,
          hover: {
            mode: "index"
          },
          animation: {
            duration: 0
          },
          plugins: {
            tooltip: {
              mode: "index",
              intersect: false,
              callbacks: {
                beforeFooter: tooltipItem1 => {
                  const total1 = tooltipItem1.reduce((sum1, item1) => sum1 + parseInt(item1.parsed.y || 0, 10), 0);
                  return `= ${total1}`;
                },
                title: tooltipItem1 => moment(tooltipItem1[0].label, "YYYY-MM-DD").format("LL")
              }
            }
          },
          layout: {
            padding: {
              left: 0,
              top: 0,
              right: 0,
              bottom: 0
            }
          },
          scales: {
            y: [{
              stacked: true,
              display: true,
              ticks: {
                callback: label1 => (0, _formatter.number)(label1),
                sampleSize: 5,
                maxRotation: 25,
                minRotation: 25
              }
            }],
            x: [{
              display: true,
              gridLines: {
                display: false
              },
              type: "time",
              time: {
                unit: _report.default.unitForDatapoints(data1.labels.length)
              },
              ticks: {
                sampleSize: 5,
                maxRotation: 50,
                minRotation: 50
              }
            }]
          }
        }
      };
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <Chart
          @chartConfig={{this.chartConfig}}
          class="admin-report-chart admin-report-stacked-chart"
        />
      
    */
    {
      "id": "l30/Olbd",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"admin-report-chart admin-report-stacked-chart\"]],[[\"@chartConfig\"],[[30,0,[\"chartConfig\"]]]],null],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/admin/components/admin-report-stacked-chart.js",
      "scope": () => [_chart.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AdminReportStackedChart;
});