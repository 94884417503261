define("admin/components/site-settings/file-size-restriction", ["exports", "@glimmer/component", "@ember/object", "admin/components/file-size-input", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _fileSizeInput, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class FileSizeRestriction extends _component.default {
    changeSize(newValue1) {
      // Settings are stored as strings, this way the main site setting component
      // doesn't get confused and think the value has changed from default if the
      // admin sets it to the same number as the default.
      this.args.changeValueCallback(newValue1?.toString() ?? "");
    }
    static #_ = (() => dt7948.n(this.prototype, "changeSize", [_object.action]))();
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <FileSizeInput
          @sizeValueKB={{@value}}
          @onChangeSize={{this.changeSize}}
          @max={{@setting.max}}
          @min={{@setting.min}}
          @setValidationMessage={{@setValidationMessage}}
        />
      
    */
    {
      "id": "SNcha7Le",
      "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@sizeValueKB\",\"@onChangeSize\",\"@max\",\"@min\",\"@setValidationMessage\"],[[30,1],[30,0,[\"changeSize\"]],[30,2,[\"max\"]],[30,2,[\"min\"]],[30,3]]],null],[1,\"\\n  \"]],[\"@value\",\"@setting\",\"@setValidationMessage\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/admin/components/site-settings/file-size-restriction.js",
      "scope": () => [_fileSizeInput.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = FileSizeRestriction;
});