define("admin/components/site-settings/integer", ["exports", "@glimmer/component", "@ember/modifier", "@ember/object", "@ember/component", "@ember/template-factory"], function (_exports, _component, _modifier, _object, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SiteSettingsInteger extends _component.default {
    updateValue(event1) {
      const num1 = parseInt(event1.target.value, 10);
      if (isNaN(num1)) {
        return;
      }
      // Settings are stored as strings, this way the main site setting component
      // doesn't get confused and think the value has changed from default if the
      // admin sets it to the same number as the default.
      this.args.changeValueCallback(num1.toString());
    }
    static #_ = (() => dt7948.n(this.prototype, "updateValue", [_object.action]))();
    preventDecimal(event1) {
      if (event1.key === "." || event1.key === ",") {
        event1.preventDefault();
      }
    }
    static #_2 = (() => dt7948.n(this.prototype, "preventDecimal", [_object.action]))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <input
          {{on "keydown" this.preventDecimal}}
          {{on "input" this.updateValue}}
          type="number"
          value={{@value}}
          min={{if @setting.min @setting.min null}}
          max={{if @setting.max @setting.max null}}
          class="input-setting-integer"
          step="1"
        />
      
    */
    {
      "id": "T1Wmb65q",
      "block": "[[[1,\"\\n    \"],[11,\"input\"],[16,2,[30,1]],[16,\"min\",[52,[30,2,[\"min\"]],[30,2,[\"min\"]],null]],[16,\"max\",[52,[30,2,[\"max\"]],[30,2,[\"max\"]],null]],[24,0,\"input-setting-integer\"],[24,\"step\",\"1\"],[24,4,\"number\"],[4,[32,0],[\"keydown\",[30,0,[\"preventDecimal\"]]],null],[4,[32,0],[\"input\",[30,0,[\"updateValue\"]]],null],[12],[13],[1,\"\\n  \"]],[\"@value\",\"@setting\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/admin/components/site-settings/integer.js",
      "scope": () => [_modifier.on],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = SiteSettingsInteger;
});