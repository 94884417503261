define("admin/helpers/value-at-tl", ["exports", "discourse-common/lib/helpers"], function (_exports, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = valueAtTl;
  (0, _helpers.registerRawHelper)("value-at-tl", valueAtTl);
  function valueAtTl(data) {
    let params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    let tl = parseInt(params.level, 10);
    if (data) {
      let item = data.find(function (d) {
        return parseInt(d.x, 10) === tl;
      });
      if (item) {
        return item.y;
      } else {
        return 0;
      }
    }
  }
});