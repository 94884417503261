define("admin/templates/whats-new", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="admin-config-area">
    <AdminPageHeader
      @titleLabel="admin.dashboard.new_features.title"
      @descriptionLabel="admin.dashboard.new_features.subtitle"
      @learnMoreUrl="https://meta.discourse.org/tags/c/announcements/67/release-notes"
    >
      <:breadcrumbs>
        <DBreadcrumbsItem
          @path="/admin/whats-new"
          @label={{i18n "admin.dashboard.new_features.title"}}
        />
      </:breadcrumbs>
    </AdminPageHeader>
  
    <DashboardNewFeatures />
  </div>
  */
  {
    "id": "Gj0w4Z4v",
    "block": "[[[10,0],[14,0,\"admin-config-area\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@titleLabel\",\"@descriptionLabel\",\"@learnMoreUrl\"],[\"admin.dashboard.new_features.title\",\"admin.dashboard.new_features.subtitle\",\"https://meta.discourse.org/tags/c/announcements/67/release-notes\"]],[[\"breadcrumbs\"],[[[[1,\"\\n      \"],[8,[39,1],null,[[\"@path\",\"@label\"],[\"/admin/whats-new\",[28,[37,2],[\"admin.dashboard.new_features.title\"],null]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n  \"],[8,[39,3],null,null,null],[1,\"\\n\"],[13]],[],false,[\"admin-page-header\",\"d-breadcrumbs-item\",\"i18n\",\"dashboard-new-features\"]]",
    "moduleName": "admin/templates/whats-new.hbs",
    "isStrictMode": false
  });
});