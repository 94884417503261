define("admin/controllers/admin-logs-screened-emails", ["exports", "@ember/controller", "@ember/object", "discourse/lib/export-csv", "discourse/lib/export-result", "admin/models/screened-email"], function (_exports, _controller, _object, _exportCsv, _exportResult, _screenedEmail) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminLogsScreenedEmailsController extends _controller.default {
    loading = false;
    clearBlock(row) {
      row.clearBlock().then(function () {
        // feeling lazy
        window.location.reload();
      });
    }
    static #_ = (() => dt7948.n(this.prototype, "clearBlock", [_object.action]))();
    exportScreenedEmailList() {
      (0, _exportCsv.exportEntity)("screened_email").then(_exportResult.outputExportResult);
    }
    static #_2 = (() => dt7948.n(this.prototype, "exportScreenedEmailList", [_object.action]))();
    show() {
      this.set("loading", true);
      _screenedEmail.default.findAll().then(result => {
        this.set("model", result);
        this.set("loading", false);
      });
    }
  }
  _exports.default = AdminLogsScreenedEmailsController;
});