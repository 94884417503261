define("admin/components/secret-value-list", ["exports", "@ember/component", "@ember/object", "@ember/utils", "@ember-decorators/component", "discourse-i18n", "@ember/template-factory"], function (_exports, _component, _object, _utils, _component2, _discourseI18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.collection}}
    <div class="values">
      {{#each this.collection as |value index|}}
        <div class="value" data-index={{index}}>
          <DButton
            @action={{fn this.removeValue value}}
            @icon="xmark"
            class="remove-value-btn btn-small"
          />
          <Input
            @value={{value.key}}
            class="value-input"
            {{on "focusout" (fn this.changeKey index)}}
          />
          <Input
            @value={{value.secret}}
            class="value-input"
            @type={{if this.isSecret "password" "text"}}
            {{on "focusout" (fn this.changeSecret index)}}
          />
        </div>
      {{/each}}
    </div>
  {{/if}}
  
  <div class="value">
    <TextField
      @value={{this.newKey}}
      @placeholder={{this.setting.placeholder.key}}
      class="new-value-input key"
    />
    <Input
      @type="password"
      @value={{this.newSecret}}
      class="new-value-input secret"
      placeholder={{this.setting.placeholder.value}}
    />
    <DButton
      @action={{this.addValue}}
      @icon="plus"
      class="add-value-btn btn-small"
    />
  </div>
  */
  {
    "id": "EX+pJQRW",
    "block": "[[[41,[30,0,[\"collection\"]],[[[1,\"  \"],[10,0],[14,0,\"values\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"collection\"]]],null]],null],null,[[[1,\"      \"],[10,0],[14,0,\"value\"],[15,\"data-index\",[30,2]],[12],[1,\"\\n        \"],[8,[39,3],[[24,0,\"remove-value-btn btn-small\"]],[[\"@action\",\"@icon\"],[[28,[37,4],[[30,0,[\"removeValue\"]],[30,1]],null],\"xmark\"]],null],[1,\"\\n        \"],[8,[39,5],[[24,0,\"value-input\"],[4,[38,6],[\"focusout\",[28,[37,4],[[30,0,[\"changeKey\"]],[30,2]],null]],null]],[[\"@value\"],[[30,1,[\"key\"]]]],null],[1,\"\\n        \"],[8,[39,5],[[24,0,\"value-input\"],[4,[38,6],[\"focusout\",[28,[37,4],[[30,0,[\"changeSecret\"]],[30,2]],null]],null]],[[\"@value\",\"@type\"],[[30,1,[\"secret\"]],[52,[30,0,[\"isSecret\"]],\"password\",\"text\"]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[1,2]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[10,0],[14,0,\"value\"],[12],[1,\"\\n  \"],[8,[39,7],[[24,0,\"new-value-input key\"]],[[\"@value\",\"@placeholder\"],[[30,0,[\"newKey\"]],[30,0,[\"setting\",\"placeholder\",\"key\"]]]],null],[1,\"\\n  \"],[8,[39,5],[[24,0,\"new-value-input secret\"],[16,\"placeholder\",[30,0,[\"setting\",\"placeholder\",\"value\"]]]],[[\"@type\",\"@value\"],[\"password\",[30,0,[\"newSecret\"]]]],null],[1,\"\\n  \"],[8,[39,3],[[24,0,\"add-value-btn btn-small\"]],[[\"@action\",\"@icon\"],[[30,0,[\"addValue\"]],\"plus\"]],null],[1,\"\\n\"],[13]],[\"value\",\"index\"],false,[\"if\",\"each\",\"-track-array\",\"d-button\",\"fn\",\"input\",\"on\",\"text-field\"]]",
    "moduleName": "admin/components/secret-value-list.hbs",
    "isStrictMode": false
  });
  const SecretValueList = dt7948.c(class SecretValueList extends _component.default {
    inputDelimiter = null;
    collection = null;
    values = null;
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      this.set("collection", this._splitValues(this.values, this.inputDelimiter || "\n"));
    }
    changeKey(index, event) {
      const newValue = event.target.value;
      if (this._checkInvalidInput(newValue)) {
        return;
      }
      this._replaceValue(index, newValue, "key");
    }
    static #_ = (() => dt7948.n(this.prototype, "changeKey", [_object.action]))();
    changeSecret(index, event) {
      const newValue = event.target.value;
      if (this._checkInvalidInput(newValue)) {
        return;
      }
      this._replaceValue(index, newValue, "secret");
    }
    static #_2 = (() => dt7948.n(this.prototype, "changeSecret", [_object.action]))();
    addValue() {
      if (this._checkInvalidInput([this.newKey, this.newSecret])) {
        return;
      }
      this._addValue(this.newKey, this.newSecret);
      this.setProperties({
        newKey: "",
        newSecret: ""
      });
    }
    static #_3 = (() => dt7948.n(this.prototype, "addValue", [_object.action]))();
    removeValue(value) {
      this._removeValue(value);
    }
    static #_4 = (() => dt7948.n(this.prototype, "removeValue", [_object.action]))();
    _checkInvalidInput(inputs) {
      for (let input of inputs) {
        if ((0, _utils.isEmpty)(input) || input.includes("|")) {
          this.setValidationMessage(_discourseI18n.default.t("admin.site_settings.secret_list.invalid_input"));
          return true;
        }
      }
      this.setValidationMessage(null);
    }
    _addValue(value, secret) {
      this.collection.addObject({
        key: value,
        secret
      });
      this._saveValues();
    }
    _removeValue(value) {
      const collection = this.collection;
      collection.removeObject(value);
      this._saveValues();
    }
    _replaceValue(index, newValue, keyName) {
      let item = this.collection[index];
      (0, _object.set)(item, keyName, newValue);
      this._saveValues();
    }
    _saveValues() {
      this.set("values", this.collection.map(function (elem) {
        return `${elem.key}|${elem.secret}`;
      }).join("\n"));
    }
    _splitValues(values, delimiter) {
      if (values && values.length) {
        const keys = ["key", "secret"];
        let res = [];
        values.split(delimiter).forEach(function (str) {
          let object = {};
          str.split("|").forEach(function (a, i) {
            object[keys[i]] = a;
          });
          res.push(object);
        });
        return res;
      } else {
        return [];
      }
    }
  }, [(0, _component2.classNameBindings)(":value-list", ":secret-value-list")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SecretValueList);
});