define("admin/templates/customize-colors-index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <p class="about">{{i18n "admin.customize.colors.about"}}</p>
  */
  {
    "id": "dGwKK4qZ",
    "block": "[[[10,2],[14,0,\"about\"],[12],[1,[28,[35,0],[\"admin.customize.colors.about\"],null]],[13]],[],false,[\"i18n\"]]",
    "moduleName": "admin/templates/customize-colors-index.hbs",
    "isStrictMode": false
  });
});