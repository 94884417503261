define("admin/components/modal/staff-action-log-details", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    @title={{i18n "admin.logs.staff_actions.modal_title"}}
    @closeModal={{@closeModal}}
    class="log-details-modal"
  >
    <:body>
      <pre>{{@model.staffActionLog.details}}</pre>
    </:body>
    <:footer>
      <DButton @action={{@closeModal}} @label="close" />
    </:footer>
  </DModal>
  */
  {
    "id": "nKg8/U6t",
    "block": "[[[8,[39,0],[[24,0,\"log-details-modal\"]],[[\"@title\",\"@closeModal\"],[[28,[37,1],[\"admin.logs.staff_actions.modal_title\"],null],[30,1]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[10,\"pre\"],[12],[1,[30,2,[\"staffActionLog\",\"details\"]]],[13],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,2],null,[[\"@action\",\"@label\"],[[30,1],\"close\"]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\",\"@model\"],false,[\"d-modal\",\"i18n\",\"d-button\"]]",
    "moduleName": "admin/components/modal/staff-action-log-details.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});