define("admin/lib/template-form-fields", ["exports", "discourse-i18n"], function (_exports, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.templateFormFields = void 0;
  const templateFormFields = _exports.templateFormFields = [{
    type: "checkbox",
    structure: `- type: checkbox
  id: ${_discourseI18n.default.t("admin.form_templates.field_placeholders.id")}
  attributes:
    label: "${_discourseI18n.default.t("admin.form_templates.field_placeholders.label")}"
  validations:
    # ${_discourseI18n.default.t("admin.form_templates.field_placeholders.validations")}`
  }, {
    type: "input",
    structure: `- type: input
  id: ${_discourseI18n.default.t("admin.form_templates.field_placeholders.id")}
  attributes:
    label: "${_discourseI18n.default.t("admin.form_templates.field_placeholders.label")}"
    placeholder: "${_discourseI18n.default.t("admin.form_templates.field_placeholders.placeholder")}"
  validations:
    # ${_discourseI18n.default.t("admin.form_templates.field_placeholders.validations")}`
  }, {
    type: "textarea",
    structure: `- type: textarea
  id: ${_discourseI18n.default.t("admin.form_templates.field_placeholders.id")}
  attributes:
    label: "${_discourseI18n.default.t("admin.form_templates.field_placeholders.label")}"
    placeholder: "${_discourseI18n.default.t("admin.form_templates.field_placeholders.placeholder")}"
  validations:
    # ${_discourseI18n.default.t("admin.form_templates.field_placeholders.validations")}`
  }, {
    type: "dropdown",
    structure: `- type: dropdown
  id: ${_discourseI18n.default.t("admin.form_templates.field_placeholders.id")}
  choices:
    - "${_discourseI18n.default.t("admin.form_templates.field_placeholders.choices.first")}"
    - "${_discourseI18n.default.t("admin.form_templates.field_placeholders.choices.second")}"
    - "${_discourseI18n.default.t("admin.form_templates.field_placeholders.choices.third")}"
  attributes:
    none_label: "${_discourseI18n.default.t("admin.form_templates.field_placeholders.none_label")}"
    label: "${_discourseI18n.default.t("admin.form_templates.field_placeholders.label")}"
  validations:
    # ${_discourseI18n.default.t("admin.form_templates.field_placeholders.validations")}`
  }, {
    type: "upload",
    structure: `- type: upload
  id: ${_discourseI18n.default.t("admin.form_templates.field_placeholders.id")}
  attributes:
    file_types: ".jpg, .png, .gif"
    allow_multiple: false
    label: "${_discourseI18n.default.t("admin.form_templates.field_placeholders.label")}"
  validations:
    # ${_discourseI18n.default.t("admin.form_templates.field_placeholders.validations")}`
  }, {
    type: "multiselect",
    structure: `- type: multi-select
  id: ${_discourseI18n.default.t("admin.form_templates.field_placeholders.id")}
  choices:
    - "${_discourseI18n.default.t("admin.form_templates.field_placeholders.choices.first")}"
    - "${_discourseI18n.default.t("admin.form_templates.field_placeholders.choices.second")}"
    - "${_discourseI18n.default.t("admin.form_templates.field_placeholders.choices.third")}"
  attributes:
    none_label: "${_discourseI18n.default.t("admin.form_templates.field_placeholders.none_label")}"
    label: "${_discourseI18n.default.t("admin.form_templates.field_placeholders.label")}"
  validations:
    # ${_discourseI18n.default.t("admin.form_templates.field_placeholders.validations")}`
  }];
});