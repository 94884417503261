define("admin/controllers/admin-emojis", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/service", "discourse/lib/ajax", "discourse-i18n"], function (_exports, _controller, _object, _computed, _service, _ajax, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ALL_FILTER = "all";
  class AdminEmojisController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    filter = null;
    sorting = null;
    static #_2 = (() => dt7948.g(this.prototype, "sortedEmojis", [(0, _computed.sort)("filteredEmojis.[]", "sorting")]))();
    #sortedEmojis = (() => (dt7948.i(this, "sortedEmojis"), void 0))();
    init() {
      super.init(...arguments);
      this.setProperties({
        filter: ALL_FILTER,
        sorting: ["group", "name"]
      });
    }
    get emojiGroups() {
      return this.model.mapBy("group").uniq();
    }
    static #_3 = (() => dt7948.n(this.prototype, "emojiGroups", [(0, _object.computed)("model")]))();
    get sortingGroups() {
      return [ALL_FILTER].concat(this.emojiGroups);
    }
    static #_4 = (() => dt7948.n(this.prototype, "sortingGroups", [(0, _object.computed)("emojiGroups.[]")]))();
    get filteredEmojis() {
      if (!this.filter || this.filter === ALL_FILTER) {
        return this.model;
      } else {
        return this.model.filterBy("group", this.filter);
      }
    }
    static #_5 = (() => dt7948.n(this.prototype, "filteredEmojis", [(0, _object.computed)("model.[]", "filter")]))();
    _highlightEmojiList() {
      const customEmojiListEl = document.querySelector("#custom_emoji");
      if (customEmojiListEl && !customEmojiListEl.classList.contains("highlighted")) {
        customEmojiListEl.classList.add("highlighted");
        customEmojiListEl.addEventListener("animationend", () => {
          customEmojiListEl.classList.remove("highlighted");
        });
      }
    }
    filterGroups(value) {
      this.set("filter", value);
    }
    static #_6 = (() => dt7948.n(this.prototype, "filterGroups", [_object.action]))();
    emojiUploaded(emoji, group) {
      emoji.url += "?t=" + new Date().getTime();
      emoji.group = group;
      this.model.pushObject(_object.default.create(emoji));
      this._highlightEmojiList();
    }
    static #_7 = (() => dt7948.n(this.prototype, "emojiUploaded", [_object.action]))();
    destroyEmoji(emoji) {
      this.dialog.yesNoConfirm({
        message: _discourseI18n.default.t("admin.emoji.delete_confirm", {
          name: emoji.get("name")
        }),
        didConfirm: () => {
          return (0, _ajax.ajax)("/admin/customize/emojis/" + emoji.get("name"), {
            type: "DELETE"
          }).then(() => {
            this.model.removeObject(emoji);
          });
        }
      });
    }
    static #_8 = (() => dt7948.n(this.prototype, "destroyEmoji", [_object.action]))();
  }
  _exports.default = AdminEmojisController;
});