define("admin/components/site-settings/bool", ["exports", "@ember/component", "@ember/object", "@ember/utils", "@ember/template-factory"], function (_exports, _component, _object, _utils, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <label class="checkbox-label">
    <Input @type="checkbox" @checked={{this.enabled}} />
    <span>{{html-safe this.setting.description}}</span>
  </label>
  */
  {
    "id": "c4Vbi+M/",
    "block": "[[[10,\"label\"],[14,0,\"checkbox-label\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@type\",\"@checked\"],[\"checkbox\",[30,0,[\"enabled\"]]]],null],[1,\"\\n  \"],[10,1],[12],[1,[28,[35,1],[[30,0,[\"setting\",\"description\"]]],null]],[13],[1,\"\\n\"],[13]],[],false,[\"input\",\"html-safe\"]]",
    "moduleName": "admin/components/site-settings/bool.hbs",
    "isStrictMode": false
  });
  class Bool extends _component.default {
    get enabled() {
      if ((0, _utils.isEmpty)(this.value)) {
        return false;
      }
      return this.value.toString() === "true";
    }
    static #_ = (() => dt7948.n(this.prototype, "enabled", [(0, _object.computed)("value")]))();
    set enabled(value) {
      this.set("value", value ? "true" : "false");
    }
  }
  _exports.default = Bool;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Bool);
});