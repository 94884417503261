define("admin/components/admin-plugin-config-page", ["exports", "@glimmer/component", "@ember/helper", "@ember/service", "discourse/components/d-breadcrumbs-item", "discourse/components/nav-item", "discourse/components/plugin-outlet", "discourse-common/helpers/i18n", "admin/components/admin-page-header", "admin/components/admin-plugin-config-area", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _service, _dBreadcrumbsItem, _navItem, _pluginOutlet, _i18n, _adminPageHeader, _adminPluginConfigArea, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginConfigPage extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "adminPluginNavManager", [_service.service]))();
    #adminPluginNavManager = (() => (dt7948.i(this, "adminPluginNavManager"), void 0))();
    get mainAreaClasses() {
      let classes1 = ["admin-plugin-config-page__main-area"];
      if (this.adminPluginNavManager.isSidebarMode) {
        classes1.push("-with-inner-sidebar");
      } else {
        classes1.push("-without-inner-sidebar");
      }
      return classes1.join(" ");
    }
    linkText(navLink1) {
      if (navLink1.label) {
        return (0, _i18n.default)(navLink1.label);
      } else {
        return navLink1.text;
      }
    }
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="admin-plugin-config-page">
          <AdminPageHeader
            @titleLabelTranslated={{@plugin.nameTitleized}}
            @descriptionLabelTranslated={{@plugin.about}}
            @learnMoreUrl={{@plugin.linkUrl}}
          >
            <:breadcrumbs>
    
              <DBreadcrumbsItem
                @path="/admin/plugins"
                @label={{i18n "admin.plugins.title"}}
              />
              <DBreadcrumbsItem
                @path="/admin/plugins/{{@plugin.name}}"
                @label={{@plugin.nameTitleized}}
              />
            </:breadcrumbs>
            <:tabs>
              {{#if this.adminPluginNavManager.isTopMode}}
                {{#each
                  this.adminPluginNavManager.currentConfigNav.links
                  as |navLink|
                }}
                  <NavItem
                    @route={{navLink.route}}
                    @i18nLabel={{this.linkText navLink}}
                    title={{this.linkText navLink}}
                    class="admin-plugin-config-page__top-nav-item"
                  >
                    {{this.linkText navLink}}
                  </NavItem>
                {{/each}}
              {{/if}}
            </:tabs>
            <:actions as |actions|>
              <PluginOutlet
                @name="admin-plugin-config-page-actions"
                @outletArgs={{hash plugin=@plugin actions=actions}}
              />
            </:actions>
          </AdminPageHeader>
    
          <div class="admin-plugin-config-page__content">
            <div class={{this.mainAreaClasses}}>
              <AdminPluginConfigArea>
                {{yield}}
              </AdminPluginConfigArea>
            </div>
          </div>
        </div>
      
    */
    {
      "id": "wRTCMGZ+",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"admin-plugin-config-page\"],[12],[1,\"\\n      \"],[8,[32,0],null,[[\"@titleLabelTranslated\",\"@descriptionLabelTranslated\",\"@learnMoreUrl\"],[[30,1,[\"nameTitleized\"]],[30,1,[\"about\"]],[30,1,[\"linkUrl\"]]]],[[\"breadcrumbs\",\"tabs\",\"actions\"],[[[[1,\"\\n\\n          \"],[8,[32,1],null,[[\"@path\",\"@label\"],[\"/admin/plugins\",[28,[32,2],[\"admin.plugins.title\"],null]]],null],[1,\"\\n          \"],[8,[32,1],null,[[\"@path\",\"@label\"],[[29,[\"/admin/plugins/\",[30,1,[\"name\"]]]],[30,1,[\"nameTitleized\"]]]],null],[1,\"\\n        \"]],[]],[[[1,\"\\n\"],[41,[30,0,[\"adminPluginNavManager\",\"isTopMode\"]],[[[42,[28,[31,2],[[28,[31,2],[[30,0,[\"adminPluginNavManager\",\"currentConfigNav\",\"links\"]]],null]],null],null,[[[1,\"              \"],[8,[32,3],[[16,\"title\",[28,[30,0,[\"linkText\"]],[[30,2]],null]],[24,0,\"admin-plugin-config-page__top-nav-item\"]],[[\"@route\",\"@i18nLabel\"],[[30,2,[\"route\"]],[28,[30,0,[\"linkText\"]],[[30,2]],null]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[28,[30,0,[\"linkText\"]],[[30,2]],null]],[1,\"\\n              \"]],[]]]]],[1,\"\\n\"]],[2]],null]],[]],null],[1,\"        \"]],[]],[[[1,\"\\n          \"],[8,[32,4],null,[[\"@name\",\"@outletArgs\"],[\"admin-plugin-config-page-actions\",[28,[32,5],null,[[\"plugin\",\"actions\"],[[30,1],[30,3]]]]]],null],[1,\"\\n        \"]],[3]]]]],[1,\"\\n\\n      \"],[10,0],[14,0,\"admin-plugin-config-page__content\"],[12],[1,\"\\n        \"],[10,0],[15,0,[30,0,[\"mainAreaClasses\"]]],[12],[1,\"\\n          \"],[8,[32,6],null,null,[[\"default\"],[[[[1,\"\\n            \"],[18,4,null],[1,\"\\n          \"]],[]]]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@plugin\",\"navLink\",\"actions\",\"&default\"],false,[\"if\",\"each\",\"-track-array\",\"yield\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/admin/components/admin-plugin-config-page.js",
      "scope": () => [_adminPageHeader.default, _dBreadcrumbsItem.default, _i18n.default, _navItem.default, _pluginOutlet.default, _helper.hash, _adminPluginConfigArea.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AdminPluginConfigPage;
});