define("admin/components/schema-theme-setting/editor/child-tree-node", ["exports", "@ember/modifier", "discourse-common/helpers/d-icon", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _modifier, _dIcon, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <li
      role="link"
      class="schema-theme-setting-editor__tree-node --child"
      ...attributes
      {{on "click" @onChildClick}}
    >
      <div class="schema-theme-setting-editor__tree-node-text">
        <span>{{@generateSchemaTitle @object @schema @index}}</span>
        {{dIcon "chevron-right"}}
      </div>
    </li>
  
  */
  {
    "id": "2TURXdye",
    "block": "[[[1,\"\\n  \"],[11,\"li\"],[24,\"role\",\"link\"],[24,0,\"schema-theme-setting-editor__tree-node --child\"],[17,1],[4,[32,0],[\"click\",[30,2]],null],[12],[1,\"\\n    \"],[10,0],[14,0,\"schema-theme-setting-editor__tree-node-text\"],[12],[1,\"\\n      \"],[10,1],[12],[1,[28,[30,3],[[30,4],[30,5],[30,6]],null]],[13],[1,\"\\n      \"],[1,[28,[32,1],[\"chevron-right\"],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"&attrs\",\"@onChildClick\",\"@generateSchemaTitle\",\"@object\",\"@schema\",\"@index\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/admin/components/schema-theme-setting/editor/child-tree-node.js",
    "scope": () => [_modifier.on, _dIcon.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)());
});