define("admin/components/schema-theme-setting/editor/tree-node", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/helper", "@ember/modifier", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "truth-helpers", "discourse-common/helpers/d-icon", "admin/components/schema-theme-setting/editor/child-tree", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _helper, _modifier, _object, _didInsert, _truthHelpers, _dIcon, _childTree, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SchemaThemeSettingNewEditorTreeNode extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "text", [_tracking.tracked]))();
    #text = (() => (dt7948.i(this, "text"), void 0))();
    childObjectsProperties = this.findChildObjectsProperties(this.args.schema.properties);
    constructor() {
      super(...arguments);
      this.#setText();
    }
    registerInputFieldObserver() {
      this.args.registerInputFieldObserver(this.args.index, this.#setText.bind(this));
    }
    static #_2 = (() => dt7948.n(this.prototype, "registerInputFieldObserver", [_object.action]))();
    #setText() {
      this.text = this.args.generateSchemaTitle(this.args.object, this.args.schema, this.args.index);
    }
    findChildObjectsProperties(properties1) {
      const list1 = [];
      for (const [name1, spec1] of Object.entries(properties1)) {
        if (spec1.type === "objects") {
          this.args.object[name1] ||= [];
          list1.push({
            name: name1,
            schema: spec1.schema
          });
        }
      }
      return list1;
    }
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <li
          role="link"
          class="schema-theme-setting-editor__tree-node --parent
            {{if @active ' --active'}}"
          {{on "click" (fn @onClick @index)}}
        >
          <div class="schema-theme-setting-editor__tree-node-text">
            <span {{didInsert this.registerInputFieldObserver}}>{{this.text}}</span>
    
            {{#if (gt this.childObjectsProperties.length 0)}}
    
              {{dIcon (if @active "chevron-down" "chevron-right")}}
            {{else}}
              {{dIcon "chevron-right"}}
            {{/if}}
          </div>
    
          {{#if @active}}
            {{#each this.childObjectsProperties as |childObjectsProperty|}}
              <ChildTree
                @name={{childObjectsProperty.name}}
                @schema={{childObjectsProperty.schema}}
                @objects={{get @object childObjectsProperty.name}}
                @parentNodeText={{this.text}}
                @parentNodeIndex={{@index}}
                @onChildClick={{@onChildClick}}
                @addChildItem={{@addChildItem}}
                @generateSchemaTitle={{@generateSchemaTitle}}
              />
            {{/each}}
          {{/if}}
        </li>
      
    */
    {
      "id": "F6EiDh90",
      "block": "[[[1,\"\\n    \"],[11,\"li\"],[24,\"role\",\"link\"],[16,0,[29,[\"schema-theme-setting-editor__tree-node --parent\\n        \",[52,[30,1],\" --active\"]]]],[4,[32,0],[\"click\",[28,[32,1],[[30,2],[30,3]],null]],null],[12],[1,\"\\n      \"],[10,0],[14,0,\"schema-theme-setting-editor__tree-node-text\"],[12],[1,\"\\n        \"],[11,1],[4,[32,2],[[30,0,[\"registerInputFieldObserver\"]]],null],[12],[1,[30,0,[\"text\"]]],[13],[1,\"\\n\\n\"],[41,[28,[32,3],[[30,0,[\"childObjectsProperties\",\"length\"]],0],null],[[[1,\"\\n          \"],[1,[28,[32,4],[[52,[30,1],\"chevron-down\",\"chevron-right\"]],null]],[1,\"\\n\"]],[]],[[[1,\"          \"],[1,[28,[32,4],[\"chevron-right\"],null]],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n\\n\"],[41,[30,1],[[[42,[28,[31,2],[[28,[31,2],[[30,0,[\"childObjectsProperties\"]]],null]],null],null,[[[1,\"          \"],[8,[32,5],null,[[\"@name\",\"@schema\",\"@objects\",\"@parentNodeText\",\"@parentNodeIndex\",\"@onChildClick\",\"@addChildItem\",\"@generateSchemaTitle\"],[[30,4,[\"name\"]],[30,4,[\"schema\"]],[28,[32,6],[[30,5],[30,4,[\"name\"]]],null],[30,0,[\"text\"]],[30,3],[30,6],[30,7],[30,8]]],null],[1,\"\\n\"]],[4]],null]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@active\",\"@onClick\",\"@index\",\"childObjectsProperty\",\"@object\",\"@onChildClick\",\"@addChildItem\",\"@generateSchemaTitle\"],false,[\"if\",\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/admin/components/schema-theme-setting/editor/tree-node.js",
      "scope": () => [_modifier.on, _helper.fn, _didInsert.default, _truthHelpers.gt, _dIcon.default, _childTree.default, _helper.get],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = SchemaThemeSettingNewEditorTreeNode;
});