define("admin/components/color-input", ["exports", "@ember/component", "@ember/object", "@ember-decorators/component", "@ember-decorators/object", "@ember/template-factory"], function (_exports, _component, _object, _component2, _object2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.onlyHex}}<span class="add-on">#</span>{{/if}}<TextField
    @value={{this.hexValue}}
    @maxlength={{this.maxlength}}
    @input={{this.onHexInput}}
    class="hex-input"
  />
  <input
    class="picker"
    type="color"
    value={{this.normalizedHexValue}}
    {{on "input" this.onPickerInput}}
  />
  */
  {
    "id": "5UyV/Gr4",
    "block": "[[[41,[30,0,[\"onlyHex\"]],[[[10,1],[14,0,\"add-on\"],[12],[1,\"#\"],[13]],[]],null],[8,[39,1],[[24,0,\"hex-input\"]],[[\"@value\",\"@maxlength\",\"@input\"],[[30,0,[\"hexValue\"]],[30,0,[\"maxlength\"]],[30,0,[\"onHexInput\"]]]],null],[1,\"\\n\"],[11,\"input\"],[24,0,\"picker\"],[16,2,[30,0,[\"normalizedHexValue\"]]],[24,4,\"color\"],[4,[38,2],[\"input\",[30,0,[\"onPickerInput\"]]],null],[12],[13]],[],false,[\"if\",\"text-field\",\"on\"]]",
    "moduleName": "admin/components/color-input.hbs",
    "isStrictMode": false
  });

  /**
    An input field for a color.
  
    @param hexValue is a reference to the color's hex value.
    @param brightnessValue is a number from 0 to 255 representing the brightness of the color. See ColorSchemeColor.
    @params valid is a boolean indicating if the input field is a valid color.
  **/
  const ColorInput = dt7948.c(class ColorInput extends _component.default {
    onlyHex = true;
    styleSelection = true;
    get maxlength() {
      return this.onlyHex ? 6 : null;
    }
    static #_ = (() => dt7948.n(this.prototype, "maxlength", [(0, _object.computed)("onlyHex")]))();
    get normalizedHexValue() {
      return this.normalize(this.hexValue);
    }
    static #_2 = (() => dt7948.n(this.prototype, "normalizedHexValue", [(0, _object.computed)("hexValue")]))();
    normalize(color) {
      if (this._valid(color)) {
        if (!color.startsWith("#")) {
          color = "#" + color;
        }
        if (color.length === 4) {
          color = "#" + color.slice(1).split("").map(hex => hex + hex).join("");
        }
      }
      return color;
    }
    onHexInput(event) {
      if (this.onChangeColor) {
        this.onChangeColor(this.normalize(event.target.value || ""));
      }
    }
    static #_3 = (() => dt7948.n(this.prototype, "onHexInput", [_object.action]))();
    onPickerInput(event) {
      this.set("hexValue", event.target.value.replace("#", ""));
    }
    static #_4 = (() => dt7948.n(this.prototype, "onPickerInput", [_object.action]))();
    hexValueChanged() {
      const hex = this.hexValue;
      if (this.onChangeColor) {
        this.onChangeColor(this.normalize(hex));
      }
      if (this._valid()) {
        this.element.querySelector(".picker").value = this.normalize(hex);
      }
    }
    static #_5 = (() => dt7948.n(this.prototype, "hexValueChanged", [(0, _object2.observes)("hexValue", "brightnessValue", "valid")]))();
    _valid() {
      let color = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.hexValue;
      return /^#?([0-9a-fA-F]{3}|[0-9a-fA-F]{6})$/.test(color);
    }
  }, [(0, _component2.classNames)("color-picker")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ColorInput);
});