define("admin/routes/admin-customize-themes-show-schema", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminCustomizeThemesShowSchemaRoute extends _route.default {
    async model(params) {
      const theme = this.modelFor("adminCustomizeThemesShow");
      const setting = theme.settings.findBy("setting", params.setting_name);
      await setting.loadMetadata(theme.id);
      return {
        theme,
        setting
      };
    }
    setupController() {
      super.setupController(...arguments);
      this.controllerFor("adminCustomizeThemes").set("editingTheme", true);
      this.controllerFor("adminCustomizeThemes.show").set("editingThemeSetting", true);
    }
    deactivate() {
      this.controllerFor("adminCustomizeThemes").set("editingTheme", false);
      this.controllerFor("adminCustomizeThemes.show").set("editingThemeSetting", false);
    }
  }
  _exports.default = AdminCustomizeThemesShowSchemaRoute;
});