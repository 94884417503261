define("admin/components/admin-config-area-cards/about/your-organization", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/components/form", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/helpers/i18n", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _service, _form, _ajax, _ajaxError, _i18n, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminConfigAreasAboutYourOrganization extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "toasts", [_service.service]))();
    #toasts = (() => (dt7948.i(this, "toasts"), void 0))();
    companyName = this.args.yourOrganization.companyName.value;
    governingLaw = this.args.yourOrganization.governingLaw.value;
    cityForDisputes = this.args.yourOrganization.cityForDisputes.value;
    get data() {
      return {
        companyName: this.args.yourOrganization.companyName.value,
        governingLaw: this.args.yourOrganization.governingLaw.value,
        cityForDisputes: this.args.yourOrganization.cityForDisputes.value
      };
    }
    static #_2 = (() => dt7948.n(this.prototype, "data", [_tracking.cached]))();
    async save(data1) {
      this.args.setGlobalSavingStatus(true);
      try {
        await (0, _ajax.ajax)("/admin/config/about.json", {
          type: "PUT",
          data: {
            your_organization: {
              company_name: data1.companyName,
              governing_law: data1.governingLaw,
              city_for_disputes: data1.cityForDisputes
            }
          }
        });
        this.toasts.success({
          duration: 30000,
          data: {
            message: _discourseI18n.default.t("admin.config_areas.about.toasts.your_organization_saved")
          }
        });
      } catch (err1) {
        (0, _ajaxError.popupAjaxError)(err1);
      } finally {
        this.args.setGlobalSavingStatus(false);
      }
    }
    static #_3 = (() => dt7948.n(this.prototype, "save", [_object.action]))();
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <Form @data={{this.data}} @onSubmit={{this.save}} as |form|>
          <form.Field
            @name="companyName"
            @title={{i18n "admin.config_areas.about.company_name"}}
            @subtitle={{i18n "admin.config_areas.about.company_name_help"}}
            @format="large"
            as |field|
          >
            <field.Input
              placeholder={{i18n
                "admin.config_areas.about.company_name_placeholder"
              }}
            />
          </form.Field>
          <form.Alert @type="info">
            {{i18n "admin.config_areas.about.company_name_warning"}}
          </form.Alert>
    
          <form.Field
            @name="governingLaw"
            @title={{i18n "admin.config_areas.about.governing_law"}}
            @subtitle={{i18n "admin.config_areas.about.governing_law_help"}}
            @format="large"
            as |field|
          >
            <field.Input
              placeholder={{i18n
                "admin.config_areas.about.governing_law_placeholder"
              }}
            />
          </form.Field>
    
          <form.Field
            @name="cityForDisputes"
            @title={{i18n "admin.config_areas.about.city_for_disputes"}}
            @subtitle={{i18n "admin.config_areas.about.city_for_disputes_help"}}
            @format="large"
            as |field|
          >
            <field.Input
              placeholder={{i18n
                "admin.config_areas.about.city_for_disputes_placeholder"
              }}
            />
          </form.Field>
    
          <form.Submit
            @label="admin.config_areas.about.update"
            @disabled={{@globalSavingStatus}}
          />
        </Form>
      
    */
    {
      "id": "LNOnfhI1",
      "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@data\",\"@onSubmit\"],[[30,0,[\"data\"]],[30,0,[\"save\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[30,1,[\"Field\"]],null,[[\"@name\",\"@title\",\"@subtitle\",\"@format\"],[\"companyName\",[28,[32,1],[\"admin.config_areas.about.company_name\"],null],[28,[32,1],[\"admin.config_areas.about.company_name_help\"],null],\"large\"]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,2,[\"Input\"]],[[16,\"placeholder\",[28,[32,1],[\"admin.config_areas.about.company_name_placeholder\"],null]]],null,null],[1,\"\\n      \"]],[2]]]]],[1,\"\\n      \"],[8,[30,1,[\"Alert\"]],null,[[\"@type\"],[\"info\"]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[32,1],[\"admin.config_areas.about.company_name_warning\"],null]],[1,\"\\n      \"]],[]]]]],[1,\"\\n\\n      \"],[8,[30,1,[\"Field\"]],null,[[\"@name\",\"@title\",\"@subtitle\",\"@format\"],[\"governingLaw\",[28,[32,1],[\"admin.config_areas.about.governing_law\"],null],[28,[32,1],[\"admin.config_areas.about.governing_law_help\"],null],\"large\"]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,3,[\"Input\"]],[[16,\"placeholder\",[28,[32,1],[\"admin.config_areas.about.governing_law_placeholder\"],null]]],null,null],[1,\"\\n      \"]],[3]]]]],[1,\"\\n\\n      \"],[8,[30,1,[\"Field\"]],null,[[\"@name\",\"@title\",\"@subtitle\",\"@format\"],[\"cityForDisputes\",[28,[32,1],[\"admin.config_areas.about.city_for_disputes\"],null],[28,[32,1],[\"admin.config_areas.about.city_for_disputes_help\"],null],\"large\"]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,4,[\"Input\"]],[[16,\"placeholder\",[28,[32,1],[\"admin.config_areas.about.city_for_disputes_placeholder\"],null]]],null,null],[1,\"\\n      \"]],[4]]]]],[1,\"\\n\\n      \"],[8,[30,1,[\"Submit\"]],null,[[\"@label\",\"@disabled\"],[\"admin.config_areas.about.update\",[30,5]]],null],[1,\"\\n    \"]],[1]]]]],[1,\"\\n  \"]],[\"form\",\"field\",\"field\",\"field\",\"@globalSavingStatus\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/admin/components/admin-config-area-cards/about/your-organization.js",
      "scope": () => [_form.default, _i18n.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AdminConfigAreasAboutYourOrganization;
});