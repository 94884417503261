define("admin/components/site-settings/description", ["exports", "@ember/template", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _template2, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div class="desc">{{htmlSafe @description}}</div>
  
  */
  {
    "id": "djT8wv4z",
    "block": "[[[1,\"\\n  \"],[10,0],[14,0,\"desc\"],[12],[1,[28,[32,0],[[30,1]],null]],[13],[1,\"\\n\"]],[\"@description\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/admin/components/site-settings/description.js",
    "scope": () => [_template2.htmlSafe],
    "isStrictMode": true
  }), (0, _templateOnly.default)());
});