define("admin/templates/customize-themes-index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="themes-intro admin-intro">
    <img src={{this.womanArtistEmojiURL}} alt="" />
    <div class="content-wrapper">
      <h1>{{i18n "admin.customize.theme.themes_intro"}}</h1>
      <div class="create-actions">
        <DButton
          @action={{route-action "installModal"}}
          @icon="upload"
          @label="admin.customize.install"
          class="btn-primary"
        />
      </div>
      <div class="external-resources">
        {{#each this.externalResources as |resource|}}
          <a
            href={{resource.link}}
            class="external-link"
            rel="noopener noreferrer"
            target="_blank"
          >
            {{d-icon resource.icon}}
            {{i18n resource.key}}
          </a>
        {{/each}}
      </div>
    </div>
  </div>
  */
  {
    "id": "8pMOmJ7i",
    "block": "[[[10,0],[14,0,\"themes-intro admin-intro\"],[12],[1,\"\\n  \"],[10,\"img\"],[15,\"src\",[30,0,[\"womanArtistEmojiURL\"]]],[14,\"alt\",\"\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"content-wrapper\"],[12],[1,\"\\n    \"],[10,\"h1\"],[12],[1,[28,[35,0],[\"admin.customize.theme.themes_intro\"],null]],[13],[1,\"\\n    \"],[10,0],[14,0,\"create-actions\"],[12],[1,\"\\n      \"],[8,[39,1],[[24,0,\"btn-primary\"]],[[\"@action\",\"@icon\",\"@label\"],[[28,[37,2],[\"installModal\"],null],\"upload\",\"admin.customize.install\"]],null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"external-resources\"],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"externalResources\"]]],null]],null],null,[[[1,\"        \"],[10,3],[15,6,[30,1,[\"link\"]]],[14,0,\"external-link\"],[14,\"rel\",\"noopener noreferrer\"],[14,\"target\",\"_blank\"],[12],[1,\"\\n          \"],[1,[28,[35,5],[[30,1,[\"icon\"]]],null]],[1,\"\\n          \"],[1,[28,[35,0],[[30,1,[\"key\"]]],null]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"resource\"],false,[\"i18n\",\"d-button\",\"route-action\",\"each\",\"-track-array\",\"d-icon\"]]",
    "moduleName": "admin/templates/customize-themes-index.hbs",
    "isStrictMode": false
  });
});