define("admin/models/admin-plugin", ["exports", "@glimmer/tracking", "@ember/string", "discourse-common/lib/case-converter", "discourse-i18n"], function (_exports, _tracking, _string, _caseConverter, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPlugin {
    static create() {
      let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return new AdminPlugin(args);
    }
    static #_ = (() => dt7948.g(this.prototype, "enabled", [_tracking.tracked]))();
    #enabled = (() => (dt7948.i(this, "enabled"), void 0))();
    constructor() {
      let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      Object.keys(args).forEach(key => {
        this[(0, _caseConverter.snakeCaseToCamelCase)(key)] = args[key];
      });
    }
    get useNewShowRoute() {
      return this.adminRoute?.use_new_show_route;
    }
    get snakeCaseName() {
      return this.name.replaceAll("-", "_");
    }
    get translatedCategoryName() {
      // We do this because the site setting list is grouped by category,
      // with plugins that have their root site setting key defined as `plugins:`
      // being grouped under the generic "plugins" category.
      //
      // If a site setting has defined a proper root key and translated category name,
      // we can use that instead to go directly to the setting category.
      //
      // Over time, no plugins should be missing this data.
      return _discourseI18n.default.lookup(`admin.site_settings.categories.${this.snakeCaseName}`);
    }
    get settingCategoryName() {
      if (this.translatedCategoryName) {
        return this.snakeCaseName;
      }
      return "plugins";
    }
    get nameTitleized() {
      // The category name is better in a lot of cases, as it's a human-inputted
      // translation, and we can handle things like SAML instead of showing them
      // as Saml from discourse-saml. We can fall back to the programmatic version
      // though if needed.
      let name;
      if (this.translatedCategoryName) {
        name = this.translatedCategoryName;
      } else {
        name = this.name.split(/[-_]/).map(word => {
          return (0, _string.capitalize)(word);
        }).join(" ");
      }

      // Cuts down on repetition.
      const discoursePrefix = "Discourse ";
      if (name.startsWith(discoursePrefix)) {
        name = name.slice(discoursePrefix.length);
      }
      return name;
    }
    static #_2 = (() => dt7948.n(this.prototype, "nameTitleized", [_tracking.cached]))();
    get nameTitleizedLower() {
      return this.nameTitleized.toLowerCase();
    }
    static #_3 = (() => dt7948.n(this.prototype, "nameTitleizedLower", [_tracking.cached]))();
    get author() {
      if (this.isOfficial || this.isDiscourseOwned) {
        return _discourseI18n.default.t("admin.plugins.author", {
          author: "Discourse"
        });
      }
      return _discourseI18n.default.t("admin.plugins.author", {
        author: this.authors
      });
    }
    get linkUrl() {
      return this.metaUrl || this.url;
    }
  }
  _exports.default = AdminPlugin;
});