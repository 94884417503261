define("admin/routes/admin-user-fields", ["exports", "discourse/routes/discourse", "admin/models/user-field"], function (_exports, _discourse, _userField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminUserFieldsRoute extends _discourse.default {
    model() {
      return this.store.findAll("user-field");
    }
    setupController(controller, model) {
      controller.setProperties({
        model,
        fieldTypes: _userField.default.fieldTypes()
      });
    }
  }
  _exports.default = AdminUserFieldsRoute;
});