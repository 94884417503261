define("admin/controllers/admin-backups-index", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/computed", "discourse-common/lib/get-url", "discourse-common/utils/decorators", "discourse-i18n"], function (_exports, _controller, _object, _computed, _service, _ajax, _ajaxError, _computed2, _getUrl, _decorators, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminBackupsIndexController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "adminBackups", [_controller.inject]))();
    #adminBackups = (() => (dt7948.i(this, "adminBackups"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "status", [(0, _computed.alias)("adminBackups.model")]))();
    #status = (() => (dt7948.i(this, "status"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "uploadLabel", [(0, _computed2.i18n)("admin.backups.upload.label")]))();
    #uploadLabel = (() => (dt7948.i(this, "uploadLabel"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "backupLocation", [(0, _computed2.setting)("backup_location")]))();
    #backupLocation = (() => (dt7948.i(this, "backupLocation"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "localBackupStorage", [(0, _computed.equal)("backupLocation", "local")]))();
    #localBackupStorage = (() => (dt7948.i(this, "localBackupStorage"), void 0))();
    get restoreSettingsUrl() {
      return (0, _getUrl.default)("/admin/backups/settings?filter=allow_restore");
    }
    restoreTitle(allowRestore, isOperationRunning) {
      if (!allowRestore) {
        return "admin.backups.operations.restore.is_disabled";
      } else if (isOperationRunning) {
        return "admin.backups.operations.is_running";
      } else {
        return "admin.backups.operations.restore.title";
      }
    }
    static #_7 = (() => dt7948.n(this.prototype, "restoreTitle", [(0, _decorators.default)("status.allowRestore", "status.isOperationRunning")]))();
    async download(backup) {
      try {
        await (0, _ajax.ajax)(`/admin/backups/${backup.filename}`, {
          type: "PUT"
        });
        this.dialog.alert(_discourseI18n.default.t("admin.backups.operations.download.alert"));
      } catch (err) {
        (0, _ajaxError.popupAjaxError)(err);
      }
    }
    static #_8 = (() => dt7948.n(this.prototype, "download", [_object.action]))();
    deleteTitle() {
      if (this.status.isOperationRunning) {
        return "admin.backups.operations.is_running";
      }
      return "admin.backups.operations.destroy.title";
    }
    static #_9 = (() => dt7948.n(this.prototype, "deleteTitle", [(0, _decorators.default)("status.isOperationRunning")]))();
  }
  _exports.default = AdminBackupsIndexController;
});