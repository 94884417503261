define("admin/components/chart", ["exports", "@glimmer/component", "ember-modifier", "discourse/lib/load-script", "@ember/component", "@ember/template-factory"], function (_exports, _component, _emberModifier, _loadScript, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // args:
  // chartConfig - object
  class Chart extends _component.default {
    renderChart = (() => (0, _emberModifier.modifier)(element1 => {
      (0, _loadScript.default)("/javascripts/Chart.min.js").then(() => {
        this.chart = new window.Chart(element1.getContext("2d"), this.args.chartConfig);
      });
      return () => this.chart?.destroy();
    }))();
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div ...attributes>
          <div class="chart-canvas-container">
            <canvas {{this.renderChart}} class="chart-canvas"></canvas>
          </div>
        </div>
      
    */
    {
      "id": "3HMrBjbv",
      "block": "[[[1,\"\\n    \"],[11,0],[17,1],[12],[1,\"\\n      \"],[10,0],[14,0,\"chart-canvas-container\"],[12],[1,\"\\n        \"],[11,\"canvas\"],[24,0,\"chart-canvas\"],[4,[30,0,[\"renderChart\"]],null,null],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"&attrs\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/admin/components/chart.js",
      "isStrictMode": true
    }), this))();
  }
  _exports.default = Chart;
});