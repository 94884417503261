define("admin/templates/logs", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <AdminNav>
    <NavItem
      @route="adminLogs.staffActionLogs"
      @label="admin.logs.staff_actions.title"
    />
    <NavItem
      @route="adminLogs.screenedEmails"
      @label="admin.logs.screened_emails.title"
    />
    <NavItem
      @route="adminLogs.screenedIpAddresses"
      @label="admin.logs.screened_ips.title"
    />
    <NavItem
      @route="adminLogs.screenedUrls"
      @label="admin.logs.screened_urls.title"
    />
    <NavItem @route="adminSearchLogs" @label="admin.logs.search_logs.title" />
    {{#if this.currentUser.admin}}
      <NavItem @path="/logs" @label="admin.logs.logster.title" />
    {{/if}}
  </AdminNav>
  
  <div class="admin-container">
    {{outlet}}
  </div>
  */
  {
    "id": "zbzicpAC",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,[[\"@route\",\"@label\"],[\"adminLogs.staffActionLogs\",\"admin.logs.staff_actions.title\"]],null],[1,\"\\n  \"],[8,[39,1],null,[[\"@route\",\"@label\"],[\"adminLogs.screenedEmails\",\"admin.logs.screened_emails.title\"]],null],[1,\"\\n  \"],[8,[39,1],null,[[\"@route\",\"@label\"],[\"adminLogs.screenedIpAddresses\",\"admin.logs.screened_ips.title\"]],null],[1,\"\\n  \"],[8,[39,1],null,[[\"@route\",\"@label\"],[\"adminLogs.screenedUrls\",\"admin.logs.screened_urls.title\"]],null],[1,\"\\n  \"],[8,[39,1],null,[[\"@route\",\"@label\"],[\"adminSearchLogs\",\"admin.logs.search_logs.title\"]],null],[1,\"\\n\"],[41,[30,0,[\"currentUser\",\"admin\"]],[[[1,\"    \"],[8,[39,1],null,[[\"@path\",\"@label\"],[\"/logs\",\"admin.logs.logster.title\"]],null],[1,\"\\n\"]],[]],null]],[]]]]],[1,\"\\n\\n\"],[10,0],[14,0,\"admin-container\"],[12],[1,\"\\n  \"],[46,[28,[37,4],null,null],null,null,null],[1,\"\\n\"],[13]],[],false,[\"admin-nav\",\"nav-item\",\"if\",\"component\",\"-outlet\"]]",
    "moduleName": "admin/templates/logs.hbs",
    "isStrictMode": false
  });
});