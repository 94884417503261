define("admin/routes/admin-config-about", ["exports", "@ember/routing/route", "discourse/lib/ajax"], function (_exports, _route, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminConfigAboutRoute extends _route.default {
    model() {
      return (0, _ajax.ajax)("/admin/config/site_settings.json", {
        data: {
          filter_names: ["title", "site_description", "extended_site_description", "about_banner_image", "community_owner", "contact_email", "contact_url", "site_contact_username", "site_contact_group_name", "company_name", "governing_law", "city_for_disputes"]
        }
      });
    }
  }
  _exports.default = AdminConfigAboutRoute;
});