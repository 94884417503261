define("admin/routes/admin-email-sent", ["exports", "admin/routes/admin-email-logs"], function (_exports, _adminEmailLogs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminEmailSentRoute extends _adminEmailLogs.default {
    status = "sent";
  }
  _exports.default = AdminEmailSentRoute;
});