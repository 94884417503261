define("admin/components/install-theme-item", ["exports", "@ember/component", "@ember-decorators/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <RadioButton
    @name="install-items"
    @id={{this.value}}
    @value={{this.value}}
    @selection={{this.selection}}
  />
  <label class="radio" for={{this.value}}>
    {{#if this.showIcon}}
      {{d-icon "plus"}}
    {{/if}}
    {{i18n this.label}}
  </label>
  {{d-icon "caret-right"}}
  */
  {
    "id": "Uyeo468X",
    "block": "[[[8,[39,0],null,[[\"@name\",\"@id\",\"@value\",\"@selection\"],[\"install-items\",[30,0,[\"value\"]],[30,0,[\"value\"]],[30,0,[\"selection\"]]]],null],[1,\"\\n\"],[10,\"label\"],[14,0,\"radio\"],[15,\"for\",[30,0,[\"value\"]]],[12],[1,\"\\n\"],[41,[30,0,[\"showIcon\"]],[[[1,\"    \"],[1,[28,[35,2],[\"plus\"],null]],[1,\"\\n\"]],[]],null],[1,\"  \"],[1,[28,[35,3],[[30,0,[\"label\"]]],null]],[1,\"\\n\"],[13],[1,\"\\n\"],[1,[28,[35,2],[\"caret-right\"],null]]],[],false,[\"radio-button\",\"if\",\"d-icon\",\"i18n\"]]",
    "moduleName": "admin/components/install-theme-item.hbs",
    "isStrictMode": false
  });
  const InstallThemeItem = dt7948.c(class InstallThemeItem extends _component.default {}, [(0, _component2.classNames)("install-theme-item")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, InstallThemeItem);
});