define("admin/routes/admin-dashboard", ["exports", "discourse/mixins/scroll-top", "discourse/routes/discourse"], function (_exports, _scrollTop, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminDashboardRoute extends _discourse.default {
    activate() {
      this.controllerFor("admin-dashboard").fetchProblems();
      this.controllerFor("admin-dashboard").fetchDashboard();
      (0, _scrollTop.scrollTop)();
    }
  }
  _exports.default = AdminDashboardRoute;
});