define("admin/components/schema-theme-setting/field", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/template", "admin/components/schema-theme-setting/types/boolean", "admin/components/schema-theme-setting/types/categories", "admin/components/schema-theme-setting/types/enum", "admin/components/schema-theme-setting/types/float", "admin/components/schema-theme-setting/types/groups", "admin/components/schema-theme-setting/types/integer", "admin/components/schema-theme-setting/types/string", "admin/components/schema-theme-setting/types/tags", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _template2, _boolean, _categories, _enum, _float, _groups, _integer, _string, _tags, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SchemaThemeSettingField extends _component.default {
    get component() {
      const type1 = this.args.spec.type;
      switch (this.args.spec.type) {
        case "string":
          return _string.default;
        case "integer":
          return _integer.default;
        case "float":
          return _float.default;
        case "boolean":
          return _boolean.default;
        case "enum":
          return _enum.default;
        case "categories":
          return _categories.default;
        case "tags":
          return _tags.default;
        case "groups":
          return _groups.default;
        default:
          throw new Error(`unknown type ${type1}`);
      }
    }
    get description() {
      if (!this.args.description) {
        return;
      }
      return (0, _template2.htmlSafe)(this.args.description.trim().replace(/\n/g, "<br>"));
    }
    static #_ = (() => dt7948.n(this.prototype, "description", [_tracking.cached]))();
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="schema-field" data-name={{@name}} data-type={{@spec.type}}>
          <label class="schema-field__label">{{@label}}{{if
              @spec.required
              "*"
            }}</label>
    
          <div class="schema-field__input">
            <this.component
              @value={{@value}}
              @spec={{@spec}}
              @onChange={{@onValueChange}}
              @description={{this.description}}
              @setting={{@setting}}
            />
          </div>
        </div>
      
    */
    {
      "id": "VywFifcG",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"schema-field\"],[15,\"data-name\",[30,1]],[15,\"data-type\",[30,2,[\"type\"]]],[12],[1,\"\\n      \"],[10,\"label\"],[14,0,\"schema-field__label\"],[12],[1,[30,3]],[1,[52,[30,2,[\"required\"]],\"*\"]],[13],[1,\"\\n\\n      \"],[10,0],[14,0,\"schema-field__input\"],[12],[1,\"\\n        \"],[8,[30,0,[\"component\"]],null,[[\"@value\",\"@spec\",\"@onChange\",\"@description\",\"@setting\"],[[30,4],[30,2],[30,5],[30,0,[\"description\"]],[30,6]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@name\",\"@spec\",\"@label\",\"@value\",\"@onValueChange\",\"@setting\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/admin/components/schema-theme-setting/field.js",
      "isStrictMode": true
    }), this))();
  }
  _exports.default = SchemaThemeSettingField;
});