define("admin/controllers/admin-email-received", ["exports", "@ember/object", "@ember-decorators/object", "discourse-common/config/environment", "discourse-common/lib/debounce", "admin/controllers/admin-email-logs", "admin/models/incoming-email"], function (_exports, _object, _object2, _environment, _debounce, _adminEmailLogs, _incomingEmail) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminEmailReceivedController extends _adminEmailLogs.default {
    filterIncomingEmails() {
      (0, _debounce.default)(this, this.loadLogs, _incomingEmail.default, _environment.INPUT_DELAY);
    }
    static #_ = (() => dt7948.n(this.prototype, "filterIncomingEmails", [(0, _object2.observes)("filter.{status,from,to,subject}")]))();
    loadMore() {
      this.loadLogs(_incomingEmail.default, true);
    }
    static #_2 = (() => dt7948.n(this.prototype, "loadMore", [_object.action]))();
  }
  _exports.default = AdminEmailReceivedController;
});