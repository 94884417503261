define("admin/components/modal/delete-user-posts-progress", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "discourse/lib/ajax-error", "discourse-i18n", "admin/models/admin-user", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _ajaxError, _discourseI18n, _adminUser, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    @title={{i18n "admin.user.delete_posts.progress.title"}}
    @closeModal={{@closeModal}}
    class="admin-delete-user-posts-progress-modal"
    @flash={{this.flash}}
    @dismissable={{false}}
  >
    <:body>
      <p>{{i18n "admin.user.delete_posts.progress.description"}}</p>
      <div class="progress-bar">
        <span style={{html-safe (concat "width: " this.deletedPercentage "%")}} />
      </div>
    </:body>
  </DModal>
  */
  {
    "id": "4qGGXfZr",
    "block": "[[[8,[39,0],[[24,0,\"admin-delete-user-posts-progress-modal\"]],[[\"@title\",\"@closeModal\",\"@flash\",\"@dismissable\"],[[28,[37,1],[\"admin.user.delete_posts.progress.title\"],null],[30,1],[30,0,[\"flash\"]],false]],[[\"body\"],[[[[1,\"\\n    \"],[10,2],[12],[1,[28,[35,1],[\"admin.user.delete_posts.progress.description\"],null]],[13],[1,\"\\n    \"],[10,0],[14,0,\"progress-bar\"],[12],[1,\"\\n      \"],[10,1],[15,5,[28,[37,2],[[28,[37,3],[\"width: \",[30,0,[\"deletedPercentage\"]],\"%\"],null]],null]],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\"i18n\",\"html-safe\",\"concat\"]]",
    "moduleName": "admin/components/modal/delete-user-posts-progress.hbs",
    "isStrictMode": false
  });
  class DeleteUserPostsProgress extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "deletedPosts", [_tracking.tracked], function () {
      return 0;
    }))();
    #deletedPosts = (() => (dt7948.i(this, "deletedPosts"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "flash", [_tracking.tracked]))();
    #flash = (() => (dt7948.i(this, "flash"), void 0))();
    constructor() {
      super(...arguments);
      this.deletePosts();
    }
    get userPostCount() {
      return this.args.model.user.get("post_count");
    }
    get deletedPercentage() {
      return Math.floor(this.deletedPosts * 100 / this.userPostCount);
    }
    async deletePosts() {
      try {
        const progress = await this.args.model.user.deleteAllPosts();
        this.deletedPosts = progress.posts_deleted;
        this.args.model.updateUserPostCount(this.userPostCount - this.deletedPosts);
        // continue deleting posts if more remain, otherwise exit
        this.userPostCount > 0 ? this.deletePosts() : this.args.closeModal();
      } catch (e) {
        _adminUser.default.find(this.args.model.user.id).then(u => this.args.model.user.setProperties(u));
        this.flash = (0, _ajaxError.extractError)(e, _discourseI18n.default.t("admin.user.delete_posts_failed"));
      }
    }
    static #_3 = (() => dt7948.n(this.prototype, "deletePosts", [_object.action]))();
  }
  _exports.default = DeleteUserPostsProgress;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DeleteUserPostsProgress);
});