define("admin/controllers/admin-dashboard-general", ["exports", "@ember/controller", "@ember/object", "@ember/service", "discourse/lib/computed", "discourse-common/lib/get-url", "discourse-common/lib/helpers", "discourse-common/utils/decorators", "discourse-i18n", "admin/models/admin-dashboard", "admin/models/report", "admin/controllers/admin-dashboard-tab"], function (_exports, _controller, _object, _service, _computed, _getUrl, _helpers, _decorators, _discourseI18n, _adminDashboard, _report, _adminDashboardTab) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function staticReport(reportType) {
    return (0, _object.computed)("reports.[]", function () {
      return (0, _helpers.makeArray)(this.reports).find(report => report.type === reportType);
    });
  }
  class AdminDashboardGeneralController extends _adminDashboardTab.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "exceptionController", [(0, _controller.inject)("exception")]))();
    #exceptionController = (() => (dt7948.i(this, "exceptionController"), void 0))();
    isLoading = false;
    dashboardFetchedAt = null;
    static #_4 = (() => dt7948.g(this.prototype, "logSearchQueriesEnabled", [(0, _computed.setting)("log_search_queries")]))();
    #logSearchQueriesEnabled = (() => (dt7948.i(this, "logSearchQueriesEnabled"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "usersByTypeReport", [staticReport("users_by_type")]))();
    #usersByTypeReport = (() => (dt7948.i(this, "usersByTypeReport"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "usersByTrustLevelReport", [staticReport("users_by_trust_level")]))();
    #usersByTrustLevelReport = (() => (dt7948.i(this, "usersByTrustLevelReport"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "storageReport", [staticReport("storage_report")]))();
    #storageReport = (() => (dt7948.i(this, "storageReport"), void 0))();
    activityMetrics(metrics) {
      return (metrics || "").split("|").filter(Boolean);
    }
    static #_8 = (() => dt7948.n(this.prototype, "activityMetrics", [(0, _decorators.default)("siteSettings.dashboard_general_tab_activity_metrics")]))();
    get hiddenReports() {
      return (this.siteSettings.dashboard_hidden_reports || "").split("|").filter(Boolean);
    }
    static #_9 = (() => dt7948.n(this.prototype, "hiddenReports", [(0, _object.computed)("siteSettings.dashboard_hidden_reports")]))();
    get isActivityMetricsVisible() {
      return this.activityMetrics.length && this.activityMetrics.some(x => !this.hiddenReports.includes(x));
    }
    static #_10 = (() => dt7948.n(this.prototype, "isActivityMetricsVisible", [(0, _object.computed)("activityMetrics", "hiddenReports")]))();
    get isSearchReportsVisible() {
      return ["top_referred_topics", "trending_search"].some(x => !this.hiddenReports.includes(x));
    }
    static #_11 = (() => dt7948.n(this.prototype, "isSearchReportsVisible", [(0, _object.computed)("hiddenReports")]))();
    get isCommunityHealthVisible() {
      return ["consolidated_page_views", "site_traffic", "signups", "topics", "posts", "dau_by_mau", "daily_engaged_users", "new_contributors"].some(x => !this.hiddenReports.includes(x));
    }
    static #_12 = (() => dt7948.n(this.prototype, "isCommunityHealthVisible", [(0, _object.computed)("hiddenReports")]))();
    today() {
      return moment().locale("en").utc().endOf("day");
    }
    static #_13 = (() => dt7948.n(this.prototype, "today", [_decorators.default]))();
    get filters() {
      return {
        startDate: this.startDate,
        endDate: this.endDate
      };
    }
    static #_14 = (() => dt7948.n(this.prototype, "filters", [(0, _object.computed)("startDate", "endDate")]))();
    activityMetricsFilters() {
      const lastMonth = moment().locale("en").utc().startOf("day").subtract(1, "month");
      return {
        startDate: lastMonth,
        endDate: this.today
      };
    }
    static #_15 = (() => dt7948.n(this.prototype, "activityMetricsFilters", [_decorators.default]))();
    topReferredTopicsOptions() {
      return {
        table: {
          total: false,
          limit: 8
        }
      };
    }
    static #_16 = (() => dt7948.n(this.prototype, "topReferredTopicsOptions", [_decorators.default]))();
    siteTrafficOptions() {
      return {
        stackedChart: {
          hiddenLabels: ["page_view_other", "page_view_crawler"]
        }
      };
    }
    static #_17 = (() => dt7948.n(this.prototype, "siteTrafficOptions", [_decorators.default]))();
    topReferredTopicsFilters() {
      return {
        startDate: moment().subtract(6, "days").startOf("day"),
        endDate: this.today
      };
    }
    static #_18 = (() => dt7948.n(this.prototype, "topReferredTopicsFilters", [_decorators.default]))();
    trendingSearchFilters() {
      return {
        startDate: moment().subtract(1, "month").startOf("day"),
        endDate: this.today
      };
    }
    static #_19 = (() => dt7948.n(this.prototype, "trendingSearchFilters", [_decorators.default]))();
    trendingSearchOptions() {
      return {
        table: {
          total: false,
          limit: 8
        }
      };
    }
    static #_20 = (() => dt7948.n(this.prototype, "trendingSearchOptions", [_decorators.default]))();
    trendingSearchDisabledLabel() {
      return _discourseI18n.default.t("admin.dashboard.reports.trending_search.disabled", {
        basePath: (0, _getUrl.default)("")
      });
    }
    static #_21 = (() => dt7948.n(this.prototype, "trendingSearchDisabledLabel", [_decorators.default]))();
    fetchDashboard() {
      if (this.isLoading) {
        return;
      }
      if (!this.dashboardFetchedAt || moment().subtract(30, "minutes").toDate() > this.dashboardFetchedAt) {
        this.set("isLoading", true);
        _adminDashboard.default.fetchGeneral().then(adminDashboardModel => {
          this.setProperties({
            dashboardFetchedAt: new Date(),
            model: adminDashboardModel,
            reports: (0, _helpers.makeArray)(adminDashboardModel.reports).map(x => _report.default.create(x))
          });
        }).catch(e => {
          this.exceptionController.set("thrown", e.jqXHR);
          this.router.replaceWith("exception");
        }).finally(() => this.set("isLoading", false));
      }
    }
  }
  _exports.default = AdminDashboardGeneralController;
});