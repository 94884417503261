define("admin/routes/admin-user", ["exports", "@ember/object", "discourse/routes/discourse", "admin/models/admin-user"], function (_exports, _object, _discourse, _adminUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminUserRoute extends _discourse.default {
    serialize(model) {
      return {
        user_id: model.get("id"),
        username: model.get("username").toLowerCase()
      };
    }
    model(params) {
      return _adminUser.default.find((0, _object.get)(params, "user_id"));
    }
    afterModel(adminUser) {
      return adminUser.loadDetails().then(function () {
        adminUser.setOriginalTrustLevel();
        return adminUser;
      });
    }
  }
  _exports.default = AdminUserRoute;
});