define("admin/controllers/admin-logs-screened-urls", ["exports", "@ember/controller", "@ember/object", "discourse/lib/export-csv", "discourse/lib/export-result", "admin/models/screened-url"], function (_exports, _controller, _object, _exportCsv, _exportResult, _screenedUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminLogsScreenedUrlsController extends _controller.default {
    loading = false;
    show() {
      this.set("loading", true);
      _screenedUrl.default.findAll().then(result => {
        this.set("model", result);
        this.set("loading", false);
      });
    }
    exportScreenedUrlList() {
      (0, _exportCsv.exportEntity)("screened_url").then(_exportResult.outputExportResult);
    }
    static #_ = (() => dt7948.n(this.prototype, "exportScreenedUrlList", [_object.action]))();
  }
  _exports.default = AdminLogsScreenedUrlsController;
});