define("admin/routes/admin-watched-words", ["exports", "discourse/routes/discourse", "admin/models/watched-word"], function (_exports, _discourse, _watchedWord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminWatchedWordsRoute extends _discourse.default {
    queryParams = {
      filter: {
        replace: true
      }
    };
    model() {
      return _watchedWord.default.findAll();
    }
    afterModel(model) {
      const controller = this.controllerFor("adminWatchedWords");
      controller.set("allWatchedWords", model);
    }
  }
  _exports.default = AdminWatchedWordsRoute;
});