define("admin/components/permalink-form", ["exports", "@ember/component", "@ember/object", "@ember/runloop", "@ember/service", "@ember-decorators/component", "discourse/lib/computed", "discourse-common/utils/decorators", "discourse-i18n", "admin/models/permalink", "@ember/template-factory"], function (_exports, _component, _object, _runloop, _service, _component2, _computed, _decorators, _discourseI18n, _permalink, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="permalink-form">
    <div class="inline-form">
      <label>{{i18n "admin.permalink.form.label"}}</label>
  
      <TextField
        @value={{this.url}}
        @disabled={{this.formSubmitted}}
        @placeholderKey="admin.permalink.url"
        @autocorrect="off"
        @autocapitalize="off"
        class="permalink-url"
      />
  
      <ComboBox
        @content={{this.permalinkTypes}}
        @value={{this.permalinkType}}
        @onChange={{fn (mut this.permalinkType)}}
        class="permalink-type"
      />
  
      <TextField
        @value={{this.permalinkTypeValue}}
        @disabled={{this.formSubmitted}}
        @placeholderKey={{this.permalinkTypePlaceholder}}
        @autocorrect="off"
        @autocapitalize="off"
        @keyDown={{this.submitFormOnEnter}}
        class="permalink-destination"
      />
  
      <DButton
        @action={{this.onSubmit}}
        @disabled={{this.formSubmitted}}
        @label="admin.permalink.form.add"
        class="permalink-add"
      />
    </div>
  </div>
  */
  {
    "id": "HPkQ+nsC",
    "block": "[[[10,0],[14,0,\"permalink-form\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"inline-form\"],[12],[1,\"\\n    \"],[10,\"label\"],[12],[1,[28,[35,0],[\"admin.permalink.form.label\"],null]],[13],[1,\"\\n\\n    \"],[8,[39,1],[[24,0,\"permalink-url\"]],[[\"@value\",\"@disabled\",\"@placeholderKey\",\"@autocorrect\",\"@autocapitalize\"],[[30,0,[\"url\"]],[30,0,[\"formSubmitted\"]],\"admin.permalink.url\",\"off\",\"off\"]],null],[1,\"\\n\\n    \"],[8,[39,2],[[24,0,\"permalink-type\"]],[[\"@content\",\"@value\",\"@onChange\"],[[30,0,[\"permalinkTypes\"]],[30,0,[\"permalinkType\"]],[28,[37,3],[[28,[37,4],[[30,0,[\"permalinkType\"]]],null]],null]]],null],[1,\"\\n\\n    \"],[8,[39,1],[[24,0,\"permalink-destination\"]],[[\"@value\",\"@disabled\",\"@placeholderKey\",\"@autocorrect\",\"@autocapitalize\",\"@keyDown\"],[[30,0,[\"permalinkTypeValue\"]],[30,0,[\"formSubmitted\"]],[30,0,[\"permalinkTypePlaceholder\"]],\"off\",\"off\",[30,0,[\"submitFormOnEnter\"]]]],null],[1,\"\\n\\n    \"],[8,[39,5],[[24,0,\"permalink-add\"]],[[\"@action\",\"@disabled\",\"@label\"],[[30,0,[\"onSubmit\"]],[30,0,[\"formSubmitted\"]],\"admin.permalink.form.add\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"i18n\",\"text-field\",\"combo-box\",\"fn\",\"mut\",\"d-button\"]]",
    "moduleName": "admin/components/permalink-form.hbs",
    "isStrictMode": false
  });
  const PermalinkForm = dt7948.c(class PermalinkForm extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    formSubmitted = false;
    permalinkType = "topic_id";
    static #_2 = (() => dt7948.g(this.prototype, "permalinkTypePlaceholder", [(0, _computed.fmt)("permalinkType", "admin.permalink.%@")]))();
    #permalinkTypePlaceholder = (() => (dt7948.i(this, "permalinkTypePlaceholder"), void 0))();
    action = null;
    permalinkTypeValue = null;
    permalinkTypes() {
      return [{
        id: "topic_id",
        name: _discourseI18n.default.t("admin.permalink.topic_id")
      }, {
        id: "post_id",
        name: _discourseI18n.default.t("admin.permalink.post_id")
      }, {
        id: "category_id",
        name: _discourseI18n.default.t("admin.permalink.category_id")
      }, {
        id: "tag_name",
        name: _discourseI18n.default.t("admin.permalink.tag_name")
      }, {
        id: "external_url",
        name: _discourseI18n.default.t("admin.permalink.external_url")
      }, {
        id: "user_id",
        name: _discourseI18n.default.t("admin.permalink.user_id")
      }];
    }
    static #_3 = (() => dt7948.n(this.prototype, "permalinkTypes", [_decorators.default]))();
    focusPermalink() {
      (0, _runloop.schedule)("afterRender", () => document.querySelector(".permalink-url")?.focus());
    }
    static #_4 = (() => dt7948.n(this.prototype, "focusPermalink", [_decorators.bind]))();
    submitFormOnEnter(event) {
      if (event.key === "Enter") {
        this.onSubmit();
      }
    }
    static #_5 = (() => dt7948.n(this.prototype, "submitFormOnEnter", [_object.action]))();
    onSubmit() {
      if (!this.formSubmitted) {
        this.set("formSubmitted", true);
        _permalink.default.create({
          url: this.url,
          permalink_type: this.permalinkType,
          permalink_type_value: this.permalinkTypeValue
        }).save().then(result => {
          this.setProperties({
            url: "",
            permalinkTypeValue: "",
            formSubmitted: false
          });
          this.action(_permalink.default.create(result.permalink));
          this.focusPermalink();
        }, e => {
          this.set("formSubmitted", false);
          let error;
          if (e?.jqXHR?.responseJSON?.errors) {
            error = _discourseI18n.default.t("generic_error_with_reason", {
              error: e.jqXHR.responseJSON.errors.join(". ")
            });
          } else {
            error = _discourseI18n.default.t("generic_error");
          }
          this.dialog.alert({
            message: error,
            didConfirm: () => this.focusPermalink(),
            didCancel: () => this.focusPermalink()
          });
        });
      }
    }
    static #_6 = (() => dt7948.n(this.prototype, "onSubmit", [_object.action]))();
  }, [(0, _component2.tagName)("")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PermalinkForm);
});