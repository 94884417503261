define("admin/helpers/post-action-title", ["exports", "@ember/component/helper", "discourse-i18n"], function (_exports, _helper, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function postActionTitle(_ref) {
    let [id, nameKey] = _ref;
    let title = _discourseI18n.default.t(`admin.flags.short_names.${nameKey}`, {
      defaultValue: null
    });

    // TODO: We can remove this once other translations have been updated
    if (!title) {
      return _discourseI18n.default.t(`admin.flags.summary.action_type_${id}`, {
        count: 1
      });
    }
    return title;
  }
  var _default = _exports.default = _helper.default.helper(postActionTitle);
});