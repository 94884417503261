define("admin/components/inline-edit-checkbox", ["exports", "@ember/component", "@ember/object", "@ember-decorators/component", "discourse-common/utils/decorators", "@ember/template-factory"], function (_exports, _component, _object, _component2, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <label class="checkbox-label">
    <Input @type="checkbox" disabled={{this.disabled}} @checked={{this.buffer}} />
    {{i18n this.labelKey}}
  </label>
  {{#if this.changed}}
    <DButton
      @action={{this.apply}}
      @icon="check"
      class="btn-primary btn-small submit-edit"
    />
    <DButton
      @action={{this.cancel}}
      @icon="xmark"
      class="btn-small cancel-edit"
    />
  {{/if}}
  */
  {
    "id": "/PYYkOPy",
    "block": "[[[10,\"label\"],[14,0,\"checkbox-label\"],[12],[1,\"\\n  \"],[8,[39,0],[[16,\"disabled\",[30,0,[\"disabled\"]]]],[[\"@type\",\"@checked\"],[\"checkbox\",[30,0,[\"buffer\"]]]],null],[1,\"\\n  \"],[1,[28,[35,1],[[30,0,[\"labelKey\"]]],null]],[1,\"\\n\"],[13],[1,\"\\n\"],[41,[30,0,[\"changed\"]],[[[1,\"  \"],[8,[39,3],[[24,0,\"btn-primary btn-small submit-edit\"]],[[\"@action\",\"@icon\"],[[30,0,[\"apply\"]],\"check\"]],null],[1,\"\\n  \"],[8,[39,3],[[24,0,\"btn-small cancel-edit\"]],[[\"@action\",\"@icon\"],[[30,0,[\"cancel\"]],\"xmark\"]],null],[1,\"\\n\"]],[]],null]],[],false,[\"input\",\"i18n\",\"if\",\"d-button\"]]",
    "moduleName": "admin/components/inline-edit-checkbox.hbs",
    "isStrictMode": false
  });
  const InlineEditCheckbox = dt7948.c(class InlineEditCheckbox extends _component.default {
    buffer = null;
    bufferModelId = null;
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      if (this.modelId !== this.bufferModelId) {
        // HACK: The condition above ensures this method is called only when its
        // attributes are changed (i.e. only when `checked` changes).
        //
        // Reproduction steps: navigate to theme #1, switch to theme #2 from the
        // left-side panel, then switch back to theme #1 and click on the <input>
        // element wrapped by this component. It will call `didReceiveAttrs` even
        // though none of the attributes have changed (only `buffer` does).
        this.setProperties({
          buffer: this.checked,
          bufferModelId: this.modelId
        });
      }
    }
    changed(checked, buffer) {
      return !!checked !== !!buffer;
    }
    static #_ = (() => dt7948.n(this.prototype, "changed", [(0, _decorators.default)("checked", "buffer")]))();
    apply() {
      this.set("checked", this.buffer);
      this.action();
    }
    static #_2 = (() => dt7948.n(this.prototype, "apply", [_object.action]))();
    cancel() {
      this.set("buffer", this.checked);
    }
    static #_3 = (() => dt7948.n(this.prototype, "cancel", [_object.action]))();
  }, [(0, _component2.classNames)("inline-edit")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, InlineEditCheckbox);
});