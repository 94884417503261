define("admin/templates/email-advanced-test", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <p>{{i18n "admin.email.advanced_test.desc"}}</p>
  
  <div class="email-advanced-test">
    <label for="email">{{i18n "admin.email.advanced_test.email"}}</label>
    <Textarea name="email" @value={{this.email}} class="email-body" />
    <DButton @action={{action "run"}} @label="admin.email.advanced_test.run" />
  </div>
  
  <ConditionalLoadingSpinner @condition={{this.loading}}>
    {{#if this.format}}
      <hr />
      <div class="text">
        <h3>{{i18n "admin.email.advanced_test.text"}}</h3>
        <pre class="full-reason">{{html-safe this.text}}</pre>
      </div>
      <hr />
      <div class="elided">
        <h3>{{i18n "admin.email.advanced_test.elided"}}</h3>
        <pre class="full-reason">{{html-safe this.elided}}</pre>
      </div>
    {{/if}}
  </ConditionalLoadingSpinner>
  */
  {
    "id": "ZsvlKQXY",
    "block": "[[[10,2],[12],[1,[28,[35,0],[\"admin.email.advanced_test.desc\"],null]],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"email-advanced-test\"],[12],[1,\"\\n  \"],[10,\"label\"],[14,\"for\",\"email\"],[12],[1,[28,[35,0],[\"admin.email.advanced_test.email\"],null]],[13],[1,\"\\n  \"],[8,[39,1],[[24,3,\"email\"],[24,0,\"email-body\"]],[[\"@value\"],[[30,0,[\"email\"]]]],null],[1,\"  \"],[8,[39,2],null,[[\"@action\",\"@label\"],[[28,[37,3],[[30,0],\"run\"],null],\"admin.email.advanced_test.run\"]],null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@condition\"],[[30,0,[\"loading\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"format\"]],[[[1,\"    \"],[10,\"hr\"],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"text\"],[12],[1,\"\\n      \"],[10,\"h3\"],[12],[1,[28,[35,0],[\"admin.email.advanced_test.text\"],null]],[13],[1,\"\\n      \"],[10,\"pre\"],[14,0,\"full-reason\"],[12],[1,[28,[35,6],[[30,0,[\"text\"]]],null]],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"hr\"],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"elided\"],[12],[1,\"\\n      \"],[10,\"h3\"],[12],[1,[28,[35,0],[\"admin.email.advanced_test.elided\"],null]],[13],[1,\"\\n      \"],[10,\"pre\"],[14,0,\"full-reason\"],[12],[1,[28,[35,6],[[30,0,[\"elided\"]]],null]],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[]]]]]],[],false,[\"i18n\",\"textarea\",\"d-button\",\"action\",\"conditional-loading-spinner\",\"if\",\"html-safe\"]]",
    "moduleName": "admin/templates/email-advanced-test.hbs",
    "isStrictMode": false
  });
});