define("admin/components/flag-user-lists", ["exports", "@ember/component", "@ember-decorators/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="flagged-by">
    <div class="user-list-title">
      {{i18n "admin.flags.flagged_by"}}
    </div>
    <div class="flag-users">
      {{#each this.flaggedPost.post_actions as |postAction|}}
        <FlagUser @user={{postAction.user}} @date={{postAction.created_at}}>
          <div class="flagger-flag-type">
            {{post-action-title
              postAction.post_action_type_id
              postAction.name_key
            }}
          </div>
          <UserFlagPercentage
            @agreed={{postAction.user.flags_agreed}}
            @disagreed={{postAction.user.flags_disagreed}}
            @ignored={{postAction.user.flags_ignored}}
          />
        </FlagUser>
      {{/each}}
    </div>
  </div>
  
  {{#if this.showResolvedBy}}
    <div class="flagged-post-resolved-by">
      <div class="user-list-title">
        {{i18n "admin.flags.resolved_by"}}
      </div>
      <div class="flag-users">
        {{#each this.flaggedPost.post_actions as |postAction|}}
          <FlagUser
            @user={{postAction.disposed_by}}
            @date={{postAction.disposed_at}}
          >
            {{disposition-icon postAction.disposition}}
            {{#if postAction.staff_took_action}}
              {{d-icon "gavel" title="admin.flags.took_action"}}
            {{/if}}
          </FlagUser>
        {{/each}}
      </div>
    </div>
  {{/if}}
  */
  {
    "id": "KHjZsUtc",
    "block": "[[[10,0],[14,0,\"flagged-by\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"user-list-title\"],[12],[1,\"\\n    \"],[1,[28,[35,0],[\"admin.flags.flagged_by\"],null]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"flag-users\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"flaggedPost\",\"post_actions\"]]],null]],null],null,[[[1,\"      \"],[8,[39,3],null,[[\"@user\",\"@date\"],[[30,1,[\"user\"]],[30,1,[\"created_at\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[10,0],[14,0,\"flagger-flag-type\"],[12],[1,\"\\n          \"],[1,[28,[35,4],[[30,1,[\"post_action_type_id\"]],[30,1,[\"name_key\"]]],null]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[8,[39,5],null,[[\"@agreed\",\"@disagreed\",\"@ignored\"],[[30,1,[\"user\",\"flags_agreed\"]],[30,1,[\"user\",\"flags_disagreed\"]],[30,1,[\"user\",\"flags_ignored\"]]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"showResolvedBy\"]],[[[1,\"  \"],[10,0],[14,0,\"flagged-post-resolved-by\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"user-list-title\"],[12],[1,\"\\n      \"],[1,[28,[35,0],[\"admin.flags.resolved_by\"],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"flag-users\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"flaggedPost\",\"post_actions\"]]],null]],null],null,[[[1,\"        \"],[8,[39,3],null,[[\"@user\",\"@date\"],[[30,2,[\"disposed_by\"]],[30,2,[\"disposed_at\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[1,[28,[35,7],[[30,2,[\"disposition\"]]],null]],[1,\"\\n\"],[41,[30,2,[\"staff_took_action\"]],[[[1,\"            \"],[1,[28,[35,8],[\"gavel\"],[[\"title\"],[\"admin.flags.took_action\"]]]],[1,\"\\n\"]],[]],null],[1,\"        \"]],[]]]]],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"postAction\",\"postAction\"],false,[\"i18n\",\"each\",\"-track-array\",\"flag-user\",\"post-action-title\",\"user-flag-percentage\",\"if\",\"disposition-icon\",\"d-icon\"]]",
    "moduleName": "admin/components/flag-user-lists.hbs",
    "isStrictMode": false
  });
  const FlagUserLists = dt7948.c(class FlagUserLists extends _component.default {}, [(0, _component2.classNames)("flag-user-lists")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FlagUserLists);
});