define("admin/components/form-template/row-item", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-i18n", "@ember/template-factory"], function (_exports, _component, _component2, _object, _service, _ajax, _ajaxError, _discourseI18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <tr class="admin-list-item">
    <td class="col first">{{@template.name}}</td>
    <td class="col categories">
      {{#each this.activeCategories as |category|}}
        {{category-link category}}
      {{/each}}
    </td>
    <td class="col action">
      <DButton
        @title="admin.form_templates.list_table.actions.view"
        @icon="far-eye"
        @action={{fn (mut this.showViewTemplateModal) true}}
        class="btn-view-template"
      />
      <DButton
        @title="admin.form_templates.list_table.actions.edit"
        @icon="pencil"
        @action={{this.editTemplate}}
        class="btn-edit-template"
      />
      <DButton
        @title="admin.form_templates.list_table.actions.delete"
        @icon="trash-can"
        @action={{this.deleteTemplate}}
        class="btn-danger btn-delete-template"
      />
    </td>
  </tr>
  
  {{#if this.showViewTemplateModal}}
    <Modal::CustomizeFormTemplateView
      @closeModal={{fn (mut this.showViewTemplateModal) false}}
      @model={{@template}}
      @refreshModel={{@refreshModel}}
    />
  {{/if}}
  */
  {
    "id": "u5LNjwRP",
    "block": "[[[10,\"tr\"],[14,0,\"admin-list-item\"],[12],[1,\"\\n  \"],[10,\"td\"],[14,0,\"col first\"],[12],[1,[30,1,[\"name\"]]],[13],[1,\"\\n  \"],[10,\"td\"],[14,0,\"col categories\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"activeCategories\"]]],null]],null],null,[[[1,\"      \"],[1,[28,[35,2],[[30,2]],null]],[1,\"\\n\"]],[2]],null],[1,\"  \"],[13],[1,\"\\n  \"],[10,\"td\"],[14,0,\"col action\"],[12],[1,\"\\n    \"],[8,[39,3],[[24,0,\"btn-view-template\"]],[[\"@title\",\"@icon\",\"@action\"],[\"admin.form_templates.list_table.actions.view\",\"far-eye\",[28,[37,4],[[28,[37,5],[[30,0,[\"showViewTemplateModal\"]]],null],true],null]]],null],[1,\"\\n    \"],[8,[39,3],[[24,0,\"btn-edit-template\"]],[[\"@title\",\"@icon\",\"@action\"],[\"admin.form_templates.list_table.actions.edit\",\"pencil\",[30,0,[\"editTemplate\"]]]],null],[1,\"\\n    \"],[8,[39,3],[[24,0,\"btn-danger btn-delete-template\"]],[[\"@title\",\"@icon\",\"@action\"],[\"admin.form_templates.list_table.actions.delete\",\"trash-can\",[30,0,[\"deleteTemplate\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"showViewTemplateModal\"]],[[[1,\"  \"],[8,[39,7],null,[[\"@closeModal\",\"@model\",\"@refreshModel\"],[[28,[37,4],[[28,[37,5],[[30,0,[\"showViewTemplateModal\"]]],null],false],null],[30,1],[30,3]]],null],[1,\"\\n\"]],[]],null]],[\"@template\",\"category\",\"@refreshModel\"],false,[\"each\",\"-track-array\",\"category-link\",\"d-button\",\"fn\",\"mut\",\"if\",\"modal/customize-form-template-view\"]]",
    "moduleName": "admin/components/form-template/row-item.hbs",
    "isStrictMode": false
  });
  class FormTemplateRowItem extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    get activeCategories() {
      return this.site.categories?.filter(c => c["form_template_ids"].includes(this.args.template.id));
    }
    editTemplate() {
      this.router.transitionTo("adminCustomizeFormTemplates.edit", this.args.template);
    }
    static #_4 = (() => dt7948.n(this.prototype, "editTemplate", [_object.action]))();
    deleteTemplate() {
      return this.dialog.yesNoConfirm({
        message: _discourseI18n.default.t("admin.form_templates.delete_confirm", {
          template_name: this.args.template.name
        }),
        didConfirm: () => {
          (0, _ajax.ajax)(`/admin/customize/form-templates/${this.args.template.id}.json`, {
            type: "DELETE"
          }).then(() => {
            this.args.refreshModel();
          }).catch(_ajaxError.popupAjaxError);
        }
      });
    }
    static #_5 = (() => dt7948.n(this.prototype, "deleteTemplate", [_object.action]))();
  }
  _exports.default = FormTemplateRowItem;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FormTemplateRowItem);
});