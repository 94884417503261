define("admin/components/modal/uploaded-image-list", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    class="uploaded-image-list"
    @title={{i18n @model.title}}
    @closeModal={{@closeModal}}
  >
    <:body>
      <div class="selectable-avatars">
        {{#each this.images as |image|}}
          <a href class="selectable-avatar" {{on "click" (fn this.remove image)}}>
            {{bound-avatar-template image "huge"}}
            <span class="selectable-avatar__remove">{{d-icon
                "circle-xmark"
              }}</span>
          </a>
        {{else}}
          <p>{{i18n "admin.site_settings.uploaded_image_list.empty"}}</p>
        {{/each}}
      </div>
    </:body>
    <:footer>
      <DButton @action={{this.close}} @label="close" />
      <ImagesUploader
        @uploading={{this.uploading}}
        @done={{this.uploadDone}}
        class="pull-right"
      />
    </:footer>
  </DModal>
  */
  {
    "id": "N3pkIQk2",
    "block": "[[[8,[39,0],[[24,0,\"uploaded-image-list\"]],[[\"@title\",\"@closeModal\"],[[28,[37,1],[[30,1,[\"title\"]]],null],[30,2]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"selectable-avatars\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"images\"]]],null]],null],null,[[[1,\"        \"],[11,3],[24,6,\"\"],[24,0,\"selectable-avatar\"],[4,[38,4],[\"click\",[28,[37,5],[[30,0,[\"remove\"]],[30,3]],null]],null],[12],[1,\"\\n          \"],[1,[28,[35,6],[[30,3],\"huge\"],null]],[1,\"\\n          \"],[10,1],[14,0,\"selectable-avatar__remove\"],[12],[1,[28,[35,7],[\"circle-xmark\"],null]],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[3]],[[[1,\"        \"],[10,2],[12],[1,[28,[35,1],[\"admin.site_settings.uploaded_image_list.empty\"],null]],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,8],null,[[\"@action\",\"@label\"],[[30,0,[\"close\"]],\"close\"]],null],[1,\"\\n    \"],[8,[39,9],[[24,0,\"pull-right\"]],[[\"@uploading\",\"@done\"],[[30,0,[\"uploading\"]],[30,0,[\"uploadDone\"]]]],null],[1,\"\\n  \"]],[]]]]]],[\"@model\",\"@closeModal\",\"image\"],false,[\"d-modal\",\"i18n\",\"each\",\"-track-array\",\"on\",\"fn\",\"bound-avatar-template\",\"d-icon\",\"d-button\",\"images-uploader\"]]",
    "moduleName": "admin/components/modal/uploaded-image-list.hbs",
    "isStrictMode": false
  });
  class UploadedImageList extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "images", [_tracking.tracked], function () {
      return this.args.model.value?.length ? this.args.model.value.split("|") : [];
    }))();
    #images = (() => (dt7948.i(this, "images"), void 0))();
    remove(url, event) {
      event.preventDefault();
      this.images.removeObject(url);
    }
    static #_2 = (() => dt7948.n(this.prototype, "remove", [_object.action]))();
    uploadDone(_ref) {
      let {
        url
      } = _ref;
      this.images.addObject(url);
    }
    static #_3 = (() => dt7948.n(this.prototype, "uploadDone", [_object.action]))();
    close() {
      this.args.model.changeValue(this.images.join("|"));
      this.args.closeModal();
    }
    static #_4 = (() => dt7948.n(this.prototype, "close", [_object.action]))();
  }
  _exports.default = UploadedImageList;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UploadedImageList);
});