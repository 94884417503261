define("admin/routes/admin-badges/index", ["exports", "@ember/routing/route", "discourse/lib/text"], function (_exports, _route, _text) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminBadgesIndexRoute extends _route.default {
    setupController(controller) {
      controller.badgeIntroEmoji = (0, _text.emojiUrlFor)("woman_student:t4");
    }
  }
  _exports.default = AdminBadgesIndexRoute;
});