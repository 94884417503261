define("admin/components/admin-filtered-site-settings", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/runloop", "@ember/service", "@ember/utils", "discourse/components/conditional-loading-spinner", "discourse/lib/site-setting-filter", "discourse-common/helpers/i18n", "discourse-common/lib/debounce", "admin/components/admin-site-settings-filter-controls", "admin/components/site-setting", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _runloop, _service, _utils, _conditionalLoadingSpinner, _siteSettingFilter, _i18n, _debounce, _adminSiteSettingsFilterControls, _siteSetting, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminFilteredSiteSettings extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "visibleSettings", [_tracking.tracked]))();
    #visibleSettings = (() => (dt7948.i(this, "visibleSettings"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "loading", [_tracking.tracked], function () {
      return true;
    }))();
    #loading = (() => (dt7948.i(this, "loading"), void 0))();
    siteSettingFilter = (() => new _siteSettingFilter.default(this.args.settings))();
    constructor() {
      super(...arguments);
      this.filterChanged({
        filter: "",
        onlyOverridden: false
      });
    }
    filterChanged(filterData1) {
      this._debouncedOnChangeFilter(filterData1);
    }
    static #_4 = (() => dt7948.n(this.prototype, "filterChanged", [_object.action]))();
    get noResults() {
      return (0, _utils.isEmpty)(this.visibleSettings) && !this.loading;
    }
    _debouncedOnChangeFilter(filterData1) {
      (0, _runloop.cancel)(this.onChangeFilterHandler);
      this.onChangeFilterHandler = (0, _debounce.default)(this, this.filterSettings, filterData1, 100);
    }
    filterSettings(filterData1) {
      this.args.onFilterChanged(filterData1);
      this.visibleSettings = this.siteSettingFilter.filterSettings(filterData1.filter, {
        includeAllCategory: false,
        onlyOverridden: filterData1.onlyOverridden
      })[0]?.siteSettings;
      this.loading = false;
    }
    static #_5 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <AdminSiteSettingsFilterControls
          @onChangeFilter={{this.filterChanged}}
          @initialFilter={{@initialFilter}}
        />
    
        <ConditionalLoadingSpinner @condition={{this.loading}}>
          <section class="form-horizontal settings">
            {{#each this.visibleSettings as |setting|}}
              <SiteSetting @setting={{setting}} />
            {{/each}}
    
            {{#if this.noResults}}
              {{i18n "admin.site_settings.no_results"}}
            {{/if}}
          </section>
        </ConditionalLoadingSpinner>
      
    */
    {
      "id": "L0FE4/mD",
      "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@onChangeFilter\",\"@initialFilter\"],[[30,0,[\"filterChanged\"]],[30,1]]],null],[1,\"\\n\\n    \"],[8,[32,1],null,[[\"@condition\"],[[30,0,[\"loading\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[10,\"section\"],[14,0,\"form-horizontal settings\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"visibleSettings\"]]],null]],null],null,[[[1,\"          \"],[8,[32,2],null,[[\"@setting\"],[[30,2]]],null],[1,\"\\n\"]],[2]],null],[1,\"\\n\"],[41,[30,0,[\"noResults\"]],[[[1,\"          \"],[1,[28,[32,3],[\"admin.site_settings.no_results\"],null]],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[\"@initialFilter\",\"setting\"],false,[\"each\",\"-track-array\",\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/admin/components/admin-filtered-site-settings.js",
      "scope": () => [_adminSiteSettingsFilterControls.default, _conditionalLoadingSpinner.default, _siteSetting.default, _i18n.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AdminFilteredSiteSettings;
});