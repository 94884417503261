define("admin/services/admin-sidebar-state-manager", ["exports", "@ember/object/computed", "@ember/service", "discourse/lib/key-value-store", "discourse/lib/sidebar/panels"], function (_exports, _computed, _service, _keyValueStore, _panels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminSidebarStateManager extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "sidebarState", [_service.service]))();
    #sidebarState = (() => (dt7948.i(this, "sidebarState"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "currentUserUsingAdminSidebar", [(0, _computed.alias)("sidebarState.currentUserUsingAdminSidebar")]))();
    #currentUserUsingAdminSidebar = (() => (dt7948.i(this, "currentUserUsingAdminSidebar"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "isForcingAdminSidebar", [(0, _computed.alias)("sidebarState.isForcingAdminSidebar")]))();
    #isForcingAdminSidebar = (() => (dt7948.i(this, "isForcingAdminSidebar"), void 0))();
    keywords = {};
    STORE_NAMESPACE = "discourse_admin_sidebar_experiment_";
    store = (() => new _keyValueStore.default(this.STORE_NAMESPACE))();
    setLinkKeywords(link_name, keywords) {
      if (!this.keywords[link_name]) {
        this.keywords[link_name] = {
          navigation: keywords.map(keyword => keyword.toLowerCase())
        };
        return;
      }
      this.keywords[link_name].navigation = [...new Set(this.keywords[link_name].navigation.concat(keywords.map(keyword => keyword.toLowerCase())))];
    }
    get navConfig() {
      return this.store.getObject("navConfig");
    }
    set navConfig(value) {
      this.store.setObject({
        key: "navConfig",
        value
      });
    }
    maybeForceAdminSidebar() {
      let opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      opts.onlyIfAlreadyActive ??= true;
      const isAdminSidebarActive = this.sidebarState.currentPanel?.key === _panels.ADMIN_PANEL;
      if (!this.currentUserUsingAdminSidebar) {
        this.isForcingAdminSidebar = false;
        return false;
      }
      if (!opts.onlyIfAlreadyActive) {
        return this.#forceAdminSidebar();
      }
      if (isAdminSidebarActive) {
        return this.#forceAdminSidebar();
      } else {
        this.isForcingAdminSidebar = false;
        return false;
      }
    }
    stopForcingAdminSidebar() {
      this.sidebarState.setPanel(_panels.MAIN_PANEL);
      this.isForcingAdminSidebar = false;
    }
    #forceAdminSidebar() {
      this.sidebarState.setPanel(_panels.ADMIN_PANEL);
      this.sidebarState.setSeparatedMode();
      this.sidebarState.hideSwitchPanelButtons();
      this.isForcingAdminSidebar = true;
      return true;
    }
  }
  _exports.default = AdminSidebarStateManager;
});