define("admin/controllers/admin-customize-form-templates-index", ["exports", "@ember/controller", "@ember/object", "@ember/service"], function (_exports, _controller, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminCustomizeFormTemplatesIndex extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    newTemplate() {
      this.router.transitionTo("adminCustomizeFormTemplates.new");
    }
    static #_2 = (() => dt7948.n(this.prototype, "newTemplate", [_object.action]))();
    reload() {
      this.send("reloadModel");
    }
    static #_3 = (() => dt7948.n(this.prototype, "reload", [_object.action]))();
  }
  _exports.default = AdminCustomizeFormTemplatesIndex;
});