define("admin/components/admin-plugin-config-area", ["exports", "@glimmer/component", "@ember/routing", "@ember/service", "discourse/helpers/concat-class", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _routing, _service, _concatClass, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginConfigArea extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "adminPluginNavManager", [_service.service]))();
    #adminPluginNavManager = (() => (dt7948.i(this, "adminPluginNavManager"), void 0))();
    linkText(navLink1) {
      if (navLink1.label) {
        return _discourseI18n.default.t(navLink1.label);
      } else {
        return navLink1.text;
      }
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.adminPluginNavManager.isSidebarMode}}
          <nav class="admin-nav admin-plugin-inner-sidebar-nav pull-left">
            <ul class="nav nav-stacked">
              {{#each
                this.adminPluginNavManager.currentConfigNav.links
                as |navLink|
              }}
                <li
                  class={{concatClass
                    "admin-plugin-inner-sidebar-nav__item"
                    navLink.route
                  }}
                >
                  <LinkTo
                    @route={{navLink.route}}
                    @model={{navLink.model}}
                    title={{this.linkText navLink}}
                  >
                    {{this.linkText navLink}}
                  </LinkTo>
                </li>
              {{/each}}
            </ul>
          </nav>
        {{/if}}
        <section class="admin-plugin-config-area">
          {{yield}}
        </section>
      
    */
    {
      "id": "l6GMinbz",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"adminPluginNavManager\",\"isSidebarMode\"]],[[[1,\"      \"],[10,\"nav\"],[14,0,\"admin-nav admin-plugin-inner-sidebar-nav pull-left\"],[12],[1,\"\\n        \"],[10,\"ul\"],[14,0,\"nav nav-stacked\"],[12],[1,\"\\n\"],[42,[28,[31,2],[[28,[31,2],[[30,0,[\"adminPluginNavManager\",\"currentConfigNav\",\"links\"]]],null]],null],null,[[[1,\"            \"],[10,\"li\"],[15,0,[28,[32,0],[\"admin-plugin-inner-sidebar-nav__item\",[30,1,[\"route\"]]],null]],[12],[1,\"\\n              \"],[8,[32,1],[[16,\"title\",[28,[30,0,[\"linkText\"]],[[30,1]],null]]],[[\"@route\",\"@model\"],[[30,1,[\"route\"]],[30,1,[\"model\"]]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[28,[30,0,[\"linkText\"]],[[30,1]],null]],[1,\"\\n              \"]],[]]]]],[1,\"\\n            \"],[13],[1,\"\\n\"]],[1]],null],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[10,\"section\"],[14,0,\"admin-plugin-config-area\"],[12],[1,\"\\n      \"],[18,2,null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"navLink\",\"&default\"],false,[\"if\",\"each\",\"-track-array\",\"yield\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/admin/components/admin-plugin-config-area.js",
      "scope": () => [_concatClass.default, _routing.LinkTo],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AdminPluginConfigArea;
});