define("admin/components/schema-theme-setting/types/categories", ["exports", "@glimmer/tracking", "@ember/helper", "truth-helpers", "admin/components/schema-theme-setting/field-input-description", "admin/components/schema-theme-setting/types/models", "select-kit/components/category-selector", "@ember/component", "@ember/template-factory"], function (_exports, _tracking, _helper, _truthHelpers, _fieldInputDescription, _models, _categorySelector, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SchemaThemeSettingTypeCategories extends _models.default {
    static #_ = (() => dt7948.g(this.prototype, "value", [_tracking.tracked], function () {
      return this.args.value?.map(categoryId1 => {
        return this.args.setting.metadata.categories[categoryId1];
      }) || [];
    }))();
    #value = (() => (dt7948.i(this, "value"), void 0))();
    type = "categories";
    onChange(categories1) {
      return categories1.map(category1 => {
        this.args.setting.metadata.categories[category1.id] ||= category1;
        return category1.id;
      });
    }
    static #_2 = (() => (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <CategorySelector
          @categories={{this.value}}
          @onChange={{this.onInput}}
          @options={{hash allowUncategorized=false maximum=this.max}}
        />
    
        <div class="schema-field__input-supporting-text">
          {{#if (and @description (not this.validationErrorMessage))}}
            <FieldInputDescription @description={{@description}} />
          {{/if}}
    
          {{#if this.validationErrorMessage}}
            <div class="schema-field__input-error">
              {{this.validationErrorMessage}}
            </div>
          {{/if}}
        </div>
      
    */
    {
      "id": "eNxdV8D3",
      "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@categories\",\"@onChange\",\"@options\"],[[30,0,[\"value\"]],[30,0,[\"onInput\"]],[28,[32,1],null,[[\"allowUncategorized\",\"maximum\"],[false,[30,0,[\"max\"]]]]]]],null],[1,\"\\n\\n    \"],[10,0],[14,0,\"schema-field__input-supporting-text\"],[12],[1,\"\\n\"],[41,[28,[32,2],[[30,1],[28,[32,3],[[30,0,[\"validationErrorMessage\"]]],null]],null],[[[1,\"        \"],[8,[32,4],null,[[\"@description\"],[[30,1]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"validationErrorMessage\"]],[[[1,\"        \"],[10,0],[14,0,\"schema-field__input-error\"],[12],[1,\"\\n          \"],[1,[30,0,[\"validationErrorMessage\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@description\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/admin/components/schema-theme-setting/types/categories.js",
      "scope": () => [_categorySelector.default, _helper.hash, _truthHelpers.and, _truthHelpers.not, _fieldInputDescription.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = SchemaThemeSettingTypeCategories;
});