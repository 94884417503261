define("admin/components/admin-notice", ["exports", "@glimmer/component", "@ember/object", "@ember/template", "discourse/components/d-button", "discourse-common/helpers/d-icon", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _template2, _dButton, _dIcon, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminNotice extends _component.default {
    dismiss() {
      this.args.dismissCallback(this.args.problem);
    }
    static #_ = (() => dt7948.n(this.prototype, "dismiss", [_object.action]))();
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="notice">
          <div class="message">
            {{if @icon (icon @icon)}}
            {{htmlSafe @problem.message}}
          </div>
          <DButton
            @action={{this.dismiss}}
            @label="admin.dashboard.dismiss_notice"
          />
        </div>
      
    */
    {
      "id": "E61HhTzt",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"notice\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"message\"],[12],[1,\"\\n        \"],[1,[52,[30,1],[28,[32,0],[[30,1]],null]]],[1,\"\\n        \"],[1,[28,[32,1],[[30,2,[\"message\"]]],null]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[8,[32,2],null,[[\"@action\",\"@label\"],[[30,0,[\"dismiss\"]],\"admin.dashboard.dismiss_notice\"]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@icon\",\"@problem\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/admin/components/admin-notice.js",
      "scope": () => [_dIcon.default, _template2.htmlSafe, _dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AdminNotice;
});