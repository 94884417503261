define("admin/routes/admin-customize-email-style-edit", ["exports", "@ember/object", "@ember/routing/route", "@ember/service", "discourse-i18n"], function (_exports, _object, _route, _service, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminCustomizeEmailStyleEditRoute extends _route.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    model(params) {
      return {
        model: this.modelFor("adminCustomizeEmailStyle"),
        fieldName: params.field_name
      };
    }
    setupController(controller, model) {
      controller.setProperties({
        fieldName: model.fieldName,
        model: model.model
      });
      this._shouldAlertUnsavedChanges = true;
    }
    willTransition(transition) {
      if (this.get("controller.model.changed") && this._shouldAlertUnsavedChanges && transition.intent.name !== this.routeName) {
        transition.abort();
        this.dialog.confirm({
          message: _discourseI18n.default.t("admin.customize.theme.unsaved_changes_alert"),
          confirmButtonLabel: "admin.customize.theme.discard",
          cancelButtonLabel: "admin.customize.theme.stay",
          didConfirm: () => {
            this._shouldAlertUnsavedChanges = false;
            transition.retry();
          }
        });
      }
    }
    static #_2 = (() => dt7948.n(this.prototype, "willTransition", [_object.action]))();
  }
  _exports.default = AdminCustomizeEmailStyleEditRoute;
});