define("admin/components/staff-actions", ["exports", "@ember/component", "@ember/object", "@ember-decorators/component", "discourse/lib/url", "@ember/template-factory"], function (_exports, _component, _object, _component2, _url, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! template-lint-disable no-invalid-interactive }}
  <div class="table staff-actions" {{on "click" this.openLinks}}>
    {{yield}}
  </div>
  */
  {
    "id": "Nrgk22SI",
    "block": "[[[11,0],[24,0,\"table staff-actions\"],[4,[38,0],[\"click\",[30,0,[\"openLinks\"]]],null],[12],[1,\"\\n  \"],[18,1,null],[1,\"\\n\"],[13]],[\"&default\"],false,[\"on\",\"yield\"]]",
    "moduleName": "admin/components/staff-actions.hbs",
    "isStrictMode": false
  });
  const StaffActions = dt7948.c(class StaffActions extends _component.default {
    openLinks(event) {
      const dataset = event.target.dataset;
      if (dataset.linkPostId) {
        event.preventDefault();
        this.store.find("post", dataset.linkPostId).then(post => {
          _url.default.routeTo(post.url);
        });
      } else if (dataset.linkTopicId) {
        event.preventDefault();
        _url.default.routeTo(`/t/${dataset.linkTopicId}`);
      }
    }
    static #_ = (() => dt7948.n(this.prototype, "openLinks", [_object.action]))();
  }, [(0, _component2.tagName)("")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, StaffActions);
});