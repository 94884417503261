define("admin/components/theme-upload-add", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/utils", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-i18n", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _utils, _ajax, _ajaxError, _discourseI18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    class="add-upload-modal"
    @title={{i18n "admin.customize.theme.add_upload"}}
    @closeModal={{@closeModal}}
    @flash={{this.flash}}
  >
    <:body>
      <div class="inputs">
        <section class="field">
          <input
            {{on "change" this.updateName}}
            type="file"
            id="file-input"
            accept="*"
          />
          <label for="file-input">
            {{i18n "admin.customize.theme.upload_file_tip"}}
          </label>
        </section>
        <section class="field">
          <label for="theme-variable-name">
            {{i18n "admin.customize.theme.variable_name"}}
          </label>
          <Input id="theme-variable-name" @value={{this.name}} />
        </section>
        {{#if this.fileSelected}}
          {{#if this.errorMessage}}
            <span class="alert alert-error">{{this.errorMessage}}</span>
          {{/if}}
        {{/if}}
      </div>
    </:body>
    <:footer>
      <DButton
        @action={{this.upload}}
        @disabled={{this.disabled}}
        @icon="upload"
        @label="admin.customize.theme.upload"
        class="btn-primary"
      />
      <DButton
        @action={{@closeModal}}
        @label="cancel"
        class="btn-transparent d-modal-cancel"
      />
    </:footer>
  </DModal>
  */
  {
    "id": "F7dh5E/f",
    "block": "[[[8,[39,0],[[24,0,\"add-upload-modal\"]],[[\"@title\",\"@closeModal\",\"@flash\"],[[28,[37,1],[\"admin.customize.theme.add_upload\"],null],[30,1],[30,0,[\"flash\"]]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"inputs\"],[12],[1,\"\\n      \"],[10,\"section\"],[14,0,\"field\"],[12],[1,\"\\n        \"],[11,\"input\"],[24,1,\"file-input\"],[24,\"accept\",\"*\"],[24,4,\"file\"],[4,[38,2],[\"change\",[30,0,[\"updateName\"]]],null],[12],[13],[1,\"\\n        \"],[10,\"label\"],[14,\"for\",\"file-input\"],[12],[1,\"\\n          \"],[1,[28,[35,1],[\"admin.customize.theme.upload_file_tip\"],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,\"section\"],[14,0,\"field\"],[12],[1,\"\\n        \"],[10,\"label\"],[14,\"for\",\"theme-variable-name\"],[12],[1,\"\\n          \"],[1,[28,[35,1],[\"admin.customize.theme.variable_name\"],null]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[8,[39,3],[[24,1,\"theme-variable-name\"]],[[\"@value\"],[[30,0,[\"name\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"],[41,[30,0,[\"fileSelected\"]],[[[41,[30,0,[\"errorMessage\"]],[[[1,\"          \"],[10,1],[14,0,\"alert alert-error\"],[12],[1,[30,0,[\"errorMessage\"]]],[13],[1,\"\\n\"]],[]],null]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,5],[[24,0,\"btn-primary\"]],[[\"@action\",\"@disabled\",\"@icon\",\"@label\"],[[30,0,[\"upload\"]],[30,0,[\"disabled\"]],\"upload\",\"admin.customize.theme.upload\"]],null],[1,\"\\n    \"],[8,[39,5],[[24,0,\"btn-transparent d-modal-cancel\"]],[[\"@action\",\"@label\"],[[30,1],\"cancel\"]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\"i18n\",\"on\",\"input\",\"if\",\"d-button\"]]",
    "moduleName": "admin/components/theme-upload-add.hbs",
    "isStrictMode": false
  });
  const THEME_FIELD_VARIABLE_TYPE_IDS = [2, 3, 4];
  const SCSS_VARIABLE_NAMES = [
  // common/foundation/colors.scss
  "primary", "secondary", "tertiary", "quaternary", "header_background", "header_primary", "highlight", "danger", "success", "love",
  // common/foundation/math.scss
  "E", "PI", "LN2", "SQRT2",
  // common/foundation/variables.scss
  "small-width", "medium-width", "large-width", "google", "instagram", "facebook", "cas", "twitter", "github", "base-font-size", "base-line-height", "base-font-family", "primary-low", "primary-medium", "secondary-low", "secondary-medium", "tertiary-low", "quaternary-low", "highlight-low", "highlight-medium", "danger-low", "danger-medium", "success-low", "love-low"];
  class ThemeUploadAdd extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "name", [_tracking.tracked]))();
    #name = (() => (dt7948.i(this, "name"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "fileSelected", [_tracking.tracked], function () {
      return false;
    }))();
    #fileSelected = (() => (dt7948.i(this, "fileSelected"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "flash", [_tracking.tracked]))();
    #flash = (() => (dt7948.i(this, "flash"), void 0))();
    uploadUrl = (() => this.args.model.uploadUrl || "/admin/themes/upload_asset")();
    get disabled() {
      return this.errorMessage && this.fileSelected;
    }
    get errorMessage() {
      if (!this.name) {
        return;
      } else if (!this.name.match(/^[a-z_][a-z0-9_-]*$/i)) {
        return _discourseI18n.default.t("admin.customize.theme.variable_name_error.invalid_syntax");
      } else if (SCSS_VARIABLE_NAMES.includes(name.toLowerCase())) {
        return _discourseI18n.default.t("admin.customize.theme.variable_name_error.no_overwrite");
      } else if (this.args.model.themeFields.some(tf => THEME_FIELD_VARIABLE_TYPE_IDS.includes(tf.type_id) && this.name === tf.name)) {
        return _discourseI18n.default.t("admin.customize.theme.variable_name_error.must_be_unique");
      }
    }
    updateName(e) {
      if ((0, _utils.isEmpty)(this.name)) {
        this.name = e.target.files[0].name.split(".")[0];
      }
      this.fileSelected = e.target.files[0];
    }
    static #_4 = (() => dt7948.n(this.prototype, "updateName", [_object.action]))();
    async upload() {
      const file = document.getElementById("file-input").files[0];
      const options = {
        type: "POST",
        processData: false,
        contentType: false,
        data: new FormData()
      };
      options.data.append("file", file);
      try {
        const result = await (0, _ajax.ajax)(this.uploadUrl, options);
        const upload = {
          upload_id: result.upload_id,
          name: this.name,
          original_filename: file.name
        };
        this.args.model.addUpload(upload);
        this.args.closeModal();
      } catch (e) {
        this.flash = (0, _ajaxError.extractError)(e);
      }
    }
    static #_5 = (() => dt7948.n(this.prototype, "upload", [_object.action]))();
  }
  _exports.default = ThemeUploadAdd;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ThemeUploadAdd);
});