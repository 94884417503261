define("admin/components/embedding-setting", ["exports", "@ember/component", "@ember/object", "@ember/string", "@ember-decorators/component", "discourse-common/utils/decorators", "@ember/template-factory"], function (_exports, _component, _object, _string, _component2, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.isCheckbox}}
    <label for={{this.inputId}}>
      <Input @checked={{this.checked}} id={{this.inputId}} @type="checkbox" />
      {{i18n this.translationKey}}
    </label>
  {{else}}
    <label for={{this.inputId}}>{{i18n this.translationKey}}</label>
    <Input
      @value={{this.value}}
      id={{this.inputId}}
      placeholder={{this.placeholder}}
    />
  {{/if}}
  
  <div class="clearfix"></div>
  */
  {
    "id": "Qm1ifv8B",
    "block": "[[[41,[30,0,[\"isCheckbox\"]],[[[1,\"  \"],[10,\"label\"],[15,\"for\",[30,0,[\"inputId\"]]],[12],[1,\"\\n    \"],[8,[39,1],[[16,1,[30,0,[\"inputId\"]]]],[[\"@checked\",\"@type\"],[[30,0,[\"checked\"]],\"checkbox\"]],null],[1,\"\\n    \"],[1,[28,[35,2],[[30,0,[\"translationKey\"]]],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,\"label\"],[15,\"for\",[30,0,[\"inputId\"]]],[12],[1,[28,[35,2],[[30,0,[\"translationKey\"]]],null]],[13],[1,\"\\n  \"],[8,[39,1],[[16,1,[30,0,[\"inputId\"]]],[16,\"placeholder\",[30,0,[\"placeholder\"]]]],[[\"@value\"],[[30,0,[\"value\"]]]],null],[1,\"\\n\"]],[]]],[1,\"\\n\"],[10,0],[14,0,\"clearfix\"],[12],[13]],[],false,[\"if\",\"input\",\"i18n\"]]",
    "moduleName": "admin/components/embedding-setting.hbs",
    "isStrictMode": false
  });
  const EmbeddingSetting = dt7948.c(class EmbeddingSetting extends _component.default {
    inputId(field) {
      return (0, _string.dasherize)(field);
    }
    static #_ = (() => dt7948.n(this.prototype, "inputId", [(0, _decorators.default)("field")]))();
    translationKey(field) {
      return `admin.embedding.${field}`;
    }
    static #_2 = (() => dt7948.n(this.prototype, "translationKey", [(0, _decorators.default)("field")]))();
    isCheckbox(type) {
      return type === "checkbox";
    }
    static #_3 = (() => dt7948.n(this.prototype, "isCheckbox", [(0, _decorators.default)("type")]))();
    get checked() {
      return !!this.value;
    }
    static #_4 = (() => dt7948.n(this.prototype, "checked", [(0, _object.computed)("value")]))();
    set checked(value) {
      this.set("value", value);
    }
  }, [(0, _component2.classNames)("embed-setting")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, EmbeddingSetting);
});