define("admin/components/site-settings/category", ["exports", "@ember/component", "@ember/template-factory"], function (_exports, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <CategoryChooser
    @value={{this.value}}
    @onChange={{fn (mut this.value)}}
    @options={{hash allowUncategorized=true none=(eq this.setting.default "")}}
  />
  */
  {
    "id": "zhUR0DA+",
    "block": "[[[8,[39,0],null,[[\"@value\",\"@onChange\",\"@options\"],[[30,0,[\"value\"]],[28,[37,1],[[28,[37,2],[[30,0,[\"value\"]]],null]],null],[28,[37,3],null,[[\"allowUncategorized\",\"none\"],[true,[28,[37,4],[[30,0,[\"setting\",\"default\"]],\"\"],null]]]]]],null]],[],false,[\"category-chooser\",\"fn\",\"mut\",\"hash\",\"eq\"]]",
    "moduleName": "admin/components/site-settings/category.hbs",
    "isStrictMode": false
  });
  class Category extends _component.default {}
  _exports.default = Category;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Category);
});