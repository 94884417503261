define("admin/components/schema-theme-setting/types/float", ["exports", "admin/components/schema-theme-setting/number-field"], function (_exports, _numberField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SchemaThemeSettingTypeFloat extends _numberField.default {
    step = 0.1;
    parseValue(value) {
      return parseFloat(value);
    }
  }
  _exports.default = SchemaThemeSettingTypeFloat;
});