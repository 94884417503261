define("admin/models/flag-type", ["exports", "discourse/models/rest", "discourse-common/utils/decorators", "discourse-i18n"], function (_exports, _rest, _decorators, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class FlagType extends _rest.default {
    name(id) {
      return _discourseI18n.default.t(`admin.flags.summary.action_type_${id}`, {
        count: 1
      });
    }
    static #_ = (() => dt7948.n(this.prototype, "name", [(0, _decorators.default)("id")]))();
  }
  _exports.default = FlagType;
});