define("admin/components/admin-report-per-day-counts", ["exports", "@ember/component", "@ember-decorators/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <td class="title"><a
      href={{this.report.reportUrl}}
    >{{this.report.title}}</a></td>
  <td class="value">{{this.report.todayCount}}</td>
  <td class="value">{{this.report.yesterdayCount}}</td>
  <td class="value">{{this.report.sevenDaysAgoCount}}</td>
  <td class="value">{{this.report.thirtyDaysAgoCount}}</td>
  <td class="value"></td>
  */
  {
    "id": "8pKj8yXp",
    "block": "[[[10,\"td\"],[14,0,\"title\"],[12],[10,3],[15,6,[30,0,[\"report\",\"reportUrl\"]]],[12],[1,[30,0,[\"report\",\"title\"]]],[13],[13],[1,\"\\n\"],[10,\"td\"],[14,0,\"value\"],[12],[1,[30,0,[\"report\",\"todayCount\"]]],[13],[1,\"\\n\"],[10,\"td\"],[14,0,\"value\"],[12],[1,[30,0,[\"report\",\"yesterdayCount\"]]],[13],[1,\"\\n\"],[10,\"td\"],[14,0,\"value\"],[12],[1,[30,0,[\"report\",\"sevenDaysAgoCount\"]]],[13],[1,\"\\n\"],[10,\"td\"],[14,0,\"value\"],[12],[1,[30,0,[\"report\",\"thirtyDaysAgoCount\"]]],[13],[1,\"\\n\"],[10,\"td\"],[14,0,\"value\"],[12],[13]],[],false,[]]",
    "moduleName": "admin/components/admin-report-per-day-counts.hbs",
    "isStrictMode": false
  });
  const AdminReportPerDayCounts = dt7948.c(class AdminReportPerDayCounts extends _component.default {}, [(0, _component2.tagName)("tr")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AdminReportPerDayCounts);
});