define("admin/routes/admin-email-received", ["exports", "admin/routes/admin-email-incomings"], function (_exports, _adminEmailIncomings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminEmailReceivedRoute extends _adminEmailIncomings.default {
    status = "received";
  }
  _exports.default = AdminEmailReceivedRoute;
});