define("admin/components/modal/staff-action-log-change", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "discourse/lib/ajax", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _ajax, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    @title={{i18n "admin.logs.staff_actions.modal_title"}}
    @closeModal={{@closeModal}}
    @bodyClass="theme-change-modal-body"
    class="history-modal"
  >
    <:body>
      {{html-safe this.diff}}
    </:body>
    <:footer>
      <DButton class="btn-primary" @action={{@closeModal}} @label="close" />
    </:footer>
  </DModal>
  */
  {
    "id": "JUXYO+wI",
    "block": "[[[8,[39,0],[[24,0,\"history-modal\"]],[[\"@title\",\"@closeModal\",\"@bodyClass\"],[[28,[37,1],[\"admin.logs.staff_actions.modal_title\"],null],[30,1],\"theme-change-modal-body\"]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[1,[28,[35,2],[[30,0,[\"diff\"]]],null]],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,3],[[24,0,\"btn-primary\"]],[[\"@action\",\"@label\"],[[30,1],\"close\"]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\"i18n\",\"html-safe\",\"d-button\"]]",
    "moduleName": "admin/components/modal/staff-action-log-change.hbs",
    "isStrictMode": false
  });
  class AdminStaffActionLogComponent extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "diff", [_tracking.tracked]))();
    #diff = (() => (dt7948.i(this, "diff"), void 0))();
    constructor() {
      super(...arguments);
      this.loadDiff();
    }
    async loadDiff() {
      const diff = await (0, _ajax.ajax)(`/admin/logs/staff_action_logs/${this.args.model.staffActionLog.id}/diff`);
      this.diff = diff.side_by_side;
    }
    static #_2 = (() => dt7948.n(this.prototype, "loadDiff", [_object.action]))();
  }
  _exports.default = AdminStaffActionLogComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AdminStaffActionLogComponent);
});