define("admin/controllers/admin-badges/award", ["exports", "@glimmer/tracking", "@ember/controller", "@ember/object", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-i18n"], function (_exports, _tracking, _controller, _object, _service, _ajax, _ajaxError, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminBadgesAwardController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "saving", [_tracking.tracked], function () {
      return false;
    }))();
    #saving = (() => (dt7948.i(this, "saving"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "replaceBadgeOwners", [_tracking.tracked], function () {
      return false;
    }))();
    #replaceBadgeOwners = (() => (dt7948.i(this, "replaceBadgeOwners"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "grantExistingHolders", [_tracking.tracked], function () {
      return false;
    }))();
    #grantExistingHolders = (() => (dt7948.i(this, "grantExistingHolders"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "fileSelected", [_tracking.tracked], function () {
      return false;
    }))();
    #fileSelected = (() => (dt7948.i(this, "fileSelected"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "unmatchedEntries", [_tracking.tracked], function () {
      return null;
    }))();
    #unmatchedEntries = (() => (dt7948.i(this, "unmatchedEntries"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "resultsMessage", [_tracking.tracked], function () {
      return null;
    }))();
    #resultsMessage = (() => (dt7948.i(this, "resultsMessage"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "success", [_tracking.tracked], function () {
      return false;
    }))();
    #success = (() => (dt7948.i(this, "success"), void 0))();
    static #_9 = (() => dt7948.g(this.prototype, "unmatchedEntriesCount", [_tracking.tracked], function () {
      return 0;
    }))();
    #unmatchedEntriesCount = (() => (dt7948.i(this, "unmatchedEntriesCount"), void 0))();
    resetState() {
      this.saving = false;
      this.unmatchedEntries = null;
      this.resultsMessage = null;
      this.success = false;
      this.unmatchedEntriesCount = 0;
      this.updateFileSelected();
    }
    get massAwardButtonDisabled() {
      return !this.fileSelected || this.saving;
    }
    get unmatchedEntriesTruncated() {
      let count = this.unmatchedEntriesCount;
      let length = this.unmatchedEntries.length;
      return count && length && count > length;
    }
    updateFileSelected() {
      this.fileSelected = !!document.querySelector("#massAwardCSVUpload")?.files?.length;
    }
    static #_10 = (() => dt7948.n(this.prototype, "updateFileSelected", [_object.action]))();
    massAward() {
      const file = document.querySelector("#massAwardCSVUpload").files[0];
      if (this.model && file) {
        const options = {
          type: "POST",
          processData: false,
          contentType: false,
          data: new FormData()
        };
        options.data.append("file", file);
        options.data.append("replace_badge_owners", this.replaceBadgeOwners);
        options.data.append("grant_existing_holders", this.grantExistingHolders);
        this.resetState();
        this.saving = true;
        (0, _ajax.ajax)(`/admin/badges/award/${this.model.id}`, options).then(_ref => {
          let {
            matched_users_count: matchedCount,
            unmatched_entries: unmatchedEntries,
            unmatched_entries_count: unmatchedEntriesCount
          } = _ref;
          this.resultsMessage = _discourseI18n.default.t("admin.badges.mass_award.success", {
            count: matchedCount
          });
          this.success = true;
          if (unmatchedEntries.length) {
            this.unmatchedEntries = unmatchedEntries;
            this.unmatchedEntriesCount = unmatchedEntriesCount;
          }
        }).catch(error => {
          this.resultsMessage = (0, _ajaxError.extractError)(error);
          this.success = false;
        }).finally(() => this.saving = false);
      } else {
        this.dialog.alert(_discourseI18n.default.t("admin.badges.mass_award.aborted"));
      }
    }
    static #_11 = (() => dt7948.n(this.prototype, "massAward", [_object.action]))();
  }
  _exports.default = AdminBadgesAwardController;
});