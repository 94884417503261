define("admin/templates/customize-form-templates-index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="form-templates">
    <FormTemplate::InfoHeader />
  
    {{#if this.model}}
      <table class="form-templates__table grid">
        <thead>
          <th class="col heading">
            {{i18n "admin.form_templates.list_table.headings.name"}}
          </th>
          <th class="col heading">
            {{i18n "admin.form_templates.list_table.headings.active_categories"}}
          </th>
          <th class="col heading sr-only">
            {{i18n "admin.form_templates.list_table.headings.actions"}}
          </th>
        </thead>
        <tbody>
          {{#each this.model as |template|}}
            <FormTemplate::RowItem
              @template={{template}}
              @refreshModel={{this.reload}}
            />
          {{/each}}
        </tbody>
      </table>
    {{/if}}
  
    <DButton
      @label="admin.form_templates.new_template"
      @title="admin.form_templates.new_template"
      @icon="plus"
      @action={{this.newTemplate}}
      class="btn-primary"
    />
  </div>
  */
  {
    "id": "AIkTgv2u",
    "block": "[[[10,0],[14,0,\"form-templates\"],[12],[1,\"\\n  \"],[8,[39,0],null,null,null],[1,\"\\n\\n\"],[41,[30,0,[\"model\"]],[[[1,\"    \"],[10,\"table\"],[14,0,\"form-templates__table grid\"],[12],[1,\"\\n      \"],[10,\"thead\"],[12],[1,\"\\n        \"],[10,\"th\"],[14,0,\"col heading\"],[12],[1,\"\\n          \"],[1,[28,[35,2],[\"admin.form_templates.list_table.headings.name\"],null]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,\"th\"],[14,0,\"col heading\"],[12],[1,\"\\n          \"],[1,[28,[35,2],[\"admin.form_templates.list_table.headings.active_categories\"],null]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,\"th\"],[14,0,\"col heading sr-only\"],[12],[1,\"\\n          \"],[1,[28,[35,2],[\"admin.form_templates.list_table.headings.actions\"],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"model\"]]],null]],null],null,[[[1,\"          \"],[8,[39,5],null,[[\"@template\",\"@refreshModel\"],[[30,1],[30,0,[\"reload\"]]]],null],[1,\"\\n\"]],[1]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[8,[39,6],[[24,0,\"btn-primary\"]],[[\"@label\",\"@title\",\"@icon\",\"@action\"],[\"admin.form_templates.new_template\",\"admin.form_templates.new_template\",\"plus\",[30,0,[\"newTemplate\"]]]],null],[1,\"\\n\"],[13]],[\"template\"],false,[\"form-template/info-header\",\"if\",\"i18n\",\"each\",\"-track-array\",\"form-template/row-item\",\"d-button\"]]",
    "moduleName": "admin/templates/customize-form-templates-index.hbs",
    "isStrictMode": false
  });
});