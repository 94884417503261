define("admin/components/screened-ip-address-form", ["exports", "@ember/component", "@ember/object", "@ember/runloop", "@ember/service", "@ember-decorators/component", "discourse-common/utils/decorators", "discourse-i18n", "admin/models/screened-ip-address", "@ember/template-factory"], function (_exports, _component, _object, _runloop, _service, _component2, _decorators, _discourseI18n, _screenedIpAddress, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <label>{{i18n "admin.logs.screened_ips.form.label"}}</label>
  <TextField
    @value={{this.ip_address}}
    @disabled={{this.formSubmitted}}
    @placeholderKey="admin.logs.screened_ips.form.ip_address"
    @autocorrect="off"
    @autocapitalize="off"
    class="ip-address-input"
  />
  
  <ComboBox
    @content={{this.actionNames}}
    @value={{this.actionName}}
    @onChange={{fn (mut this.actionName)}}
  />
  
  <DButton
    @action={{this.submitForm}}
    @disabled={{this.formSubmitted}}
    @label="admin.logs.screened_ips.form.add"
    type="submit"
    class="btn-default"
  />
  */
  {
    "id": "NHPQ2AhV",
    "block": "[[[10,\"label\"],[12],[1,[28,[35,0],[\"admin.logs.screened_ips.form.label\"],null]],[13],[1,\"\\n\"],[8,[39,1],[[24,0,\"ip-address-input\"]],[[\"@value\",\"@disabled\",\"@placeholderKey\",\"@autocorrect\",\"@autocapitalize\"],[[30,0,[\"ip_address\"]],[30,0,[\"formSubmitted\"]],\"admin.logs.screened_ips.form.ip_address\",\"off\",\"off\"]],null],[1,\"\\n\\n\"],[8,[39,2],null,[[\"@content\",\"@value\",\"@onChange\"],[[30,0,[\"actionNames\"]],[30,0,[\"actionName\"]],[28,[37,3],[[28,[37,4],[[30,0,[\"actionName\"]]],null]],null]]],null],[1,\"\\n\\n\"],[8,[39,5],[[24,0,\"btn-default\"],[24,4,\"submit\"]],[[\"@action\",\"@disabled\",\"@label\"],[[30,0,[\"submitForm\"]],[30,0,[\"formSubmitted\"]],\"admin.logs.screened_ips.form.add\"]],null]],[],false,[\"i18n\",\"text-field\",\"combo-box\",\"fn\",\"mut\",\"d-button\"]]",
    "moduleName": "admin/components/screened-ip-address-form.hbs",
    "isStrictMode": false
  });

  /**
    A form to create an IP address that will be blocked or allowed.
    Example usage:
  
      {{screened-ip-address-form action=(action "recordAdded")}}
  
    where action is a callback on the controller or route that will get called after
    the new record is successfully saved. It is called with the new ScreenedIpAddress record
    as an argument.
  **/
  const ScreenedIpAddressForm = dt7948.c(class ScreenedIpAddressForm extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    formSubmitted = false;
    actionName = "block";
    actionNames(adminAllowlistEnabled) {
      if (adminAllowlistEnabled) {
        return [{
          id: "block",
          name: _discourseI18n.default.t("admin.logs.screened_ips.actions.block")
        }, {
          id: "do_nothing",
          name: _discourseI18n.default.t("admin.logs.screened_ips.actions.do_nothing")
        }, {
          id: "allow_admin",
          name: _discourseI18n.default.t("admin.logs.screened_ips.actions.allow_admin")
        }];
      } else {
        return [{
          id: "block",
          name: _discourseI18n.default.t("admin.logs.screened_ips.actions.block")
        }, {
          id: "do_nothing",
          name: _discourseI18n.default.t("admin.logs.screened_ips.actions.do_nothing")
        }];
      }
    }
    static #_2 = (() => dt7948.n(this.prototype, "actionNames", [(0, _decorators.default)("siteSettings.use_admin_ip_allowlist")]))();
    focusInput() {
      (0, _runloop.schedule)("afterRender", () => {
        this.element.querySelector("input").focus();
      });
    }
    submitForm() {
      if (!this.formSubmitted) {
        this.set("formSubmitted", true);
        const screenedIpAddress = _screenedIpAddress.default.create({
          ip_address: this.ip_address,
          action_name: this.actionName
        });
        screenedIpAddress.save().then(result => {
          this.setProperties({
            ip_address: "",
            formSubmitted: false
          });
          this.action(_screenedIpAddress.default.create(result.screened_ip_address));
          this.focusInput();
        }).catch(e => {
          this.set("formSubmitted", false);
          const message = e.jqXHR.responseJSON?.errors ? _discourseI18n.default.t("generic_error_with_reason", {
            error: e.jqXHR.responseJSON.errors.join(". ")
          }) : _discourseI18n.default.t("generic_error");
          this.dialog.alert({
            message,
            didConfirm: () => this.focusInput(),
            didCancel: () => this.focusInput()
          });
        });
      }
    }
    static #_3 = (() => dt7948.n(this.prototype, "submitForm", [_object.action]))();
  }, [(0, _component2.classNames)("screened-ip-address-form", "inline-form"), (0, _component2.tagName)("form")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ScreenedIpAddressForm);
});