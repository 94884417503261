define("admin/routes/admin-web-hooks-show", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminWebHooksShowRoute extends _discourse.default {
    model(params) {
      return this.store.find("web-hook", params.web_hook_id);
    }
  }
  _exports.default = AdminWebHooksShowRoute;
});