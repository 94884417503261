define("admin/templates/plugins-show", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <AdminPluginConfigPage @plugin={{this.model}}>
    {{outlet}}
  </AdminPluginConfigPage>
  */
  {
    "id": "EeGFcMCU",
    "block": "[[[8,[39,0],null,[[\"@plugin\"],[[30,0,[\"model\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[46,[28,[37,2],null,null],null,null,null],[1,\"\\n\"]],[]]]]]],[],false,[\"admin-plugin-config-page\",\"component\",\"-outlet\"]]",
    "moduleName": "admin/templates/plugins-show.hbs",
    "isStrictMode": false
  });
});