define("admin/models/theme", ["exports", "@ember/object", "@ember/object/computed", "@ember/utils", "discourse/lib/ajax-error", "discourse/models/rest", "discourse-common/utils/decorators", "discourse-i18n", "admin/models/theme-settings"], function (_exports, _object, _computed, _utils, _ajaxError, _rest, _decorators, _discourseI18n, _themeSettings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.THEMES = _exports.COMPONENTS = void 0;
  const THEME_UPLOAD_VAR = 2;
  const FIELDS_IDS = [0, 1, 5];
  const THEMES = _exports.THEMES = "themes";
  const COMPONENTS = _exports.COMPONENTS = "components";
  const SETTINGS_TYPE_ID = 5;
  class Theme extends _rest.default {
    static munge(json) {
      if (json.settings) {
        json.settings = json.settings.map(setting => _themeSettings.default.create(setting));
      }
      return json;
    }
    static #_ = (() => dt7948.g(this.prototype, "isActive", [(0, _computed.or)("default", "user_selectable")]))();
    #isActive = (() => (dt7948.i(this, "isActive"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "isPendingUpdates", [(0, _computed.gt)("remote_theme.commits_behind", 0)]))();
    #isPendingUpdates = (() => (dt7948.i(this, "isPendingUpdates"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "hasEditedFields", [(0, _computed.gt)("editedFields.length", 0)]))();
    #hasEditedFields = (() => (dt7948.i(this, "hasEditedFields"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "hasParents", [(0, _computed.gt)("parent_themes.length", 0)]))();
    #hasParents = (() => (dt7948.i(this, "hasParents"), void 0))();
    changed = false;
    targets() {
      return [{
        id: 0,
        name: "common"
      }, {
        id: 1,
        name: "desktop",
        icon: "desktop"
      }, {
        id: 2,
        name: "mobile",
        icon: "mobile-screen-button"
      }, {
        id: 3,
        name: "settings",
        icon: "gear",
        advanced: true
      }, {
        id: 4,
        name: "translations",
        icon: "globe",
        advanced: true,
        customNames: true
      }, {
        id: 5,
        name: "extra_scss",
        icon: "paintbrush",
        advanced: true,
        customNames: true
      }].map(target => {
        target["edited"] = this.hasEdited(target.name);
        target["error"] = this.hasError(target.name);
        return target;
      });
    }
    static #_5 = (() => dt7948.n(this.prototype, "targets", [(0, _decorators.default)("theme_fields.[]")]))();
    fieldNames() {
      const common = ["scss", "head_tag", "header", "after_header", "body_tag", "footer"];
      const scss_fields = (this.theme_fields || []).filter(f => f.target === "extra_scss" && f.name !== "").map(f => f.name);
      if (scss_fields.length < 1) {
        scss_fields.push("importable_scss");
      }
      return {
        common: [...common, "color_definitions", "embedded_scss", "embedded_header"],
        desktop: common,
        mobile: common,
        settings: ["yaml"],
        translations: ["en", ...(this.theme_fields || []).filter(f => f.target === "translations" && f.name !== "en").map(f => f.name)],
        extra_scss: scss_fields
      };
    }
    static #_6 = (() => dt7948.n(this.prototype, "fieldNames", [(0, _decorators.default)("theme_fields.[]")]))();
    fields(fieldNames) {
      const hash = {};
      Object.keys(fieldNames).forEach(target => {
        hash[target] = fieldNames[target].map(fieldName => {
          const field = {
            name: fieldName,
            edited: this.hasEdited(target, fieldName),
            error: this.hasError(target, fieldName)
          };
          if (target === "translations" || target === "extra_scss") {
            field.translatedName = fieldName;
          } else {
            field.translatedName = _discourseI18n.default.t(`admin.customize.theme.${fieldName}.text`);
            field.title = _discourseI18n.default.t(`admin.customize.theme.${fieldName}.title`);
          }
          if (fieldName.indexOf("_tag") > 0) {
            field.icon = "far-file-lines";
          }
          return field;
        });
      });
      return hash;
    }
    static #_7 = (() => dt7948.n(this.prototype, "fields", [(0, _decorators.default)("fieldNames", "theme_fields.[]", "theme_fields.@each.error")]))();
    themeFields(fields) {
      if (!fields) {
        this.set("theme_fields", []);
        return {};
      }
      let hash = {};
      fields.forEach(field => {
        if (!field.type_id || FIELDS_IDS.includes(field.type_id)) {
          hash[this.getKey(field)] = field;
        }
      });
      return hash;
    }
    static #_8 = (() => dt7948.n(this.prototype, "themeFields", [(0, _decorators.default)("theme_fields")]))();
    uploads(fields) {
      if (!fields) {
        return [];
      }
      return fields.filter(f => f.target === "common" && f.type_id === THEME_UPLOAD_VAR);
    }
    static #_9 = (() => dt7948.n(this.prototype, "uploads", [(0, _decorators.default)("theme_fields", "theme_fields.[]")]))();
    isBroken(fields) {
      return fields && fields.any(field => field.error && field.error.length > 0);
    }
    static #_10 = (() => dt7948.n(this.prototype, "isBroken", [(0, _decorators.default)("theme_fields", "theme_fields.@each.error")]))();
    editedFields(fields) {
      return fields.filter(field => !(0, _utils.isBlank)(field.value) && field.type_id !== SETTINGS_TYPE_ID);
    }
    static #_11 = (() => dt7948.n(this.prototype, "editedFields", [(0, _decorators.default)("theme_fields.[]")]))();
    remoteError(errorText) {
      if (errorText && errorText.length > 0) {
        return errorText;
      }
    }
    static #_12 = (() => dt7948.n(this.prototype, "remoteError", [(0, _decorators.default)("remote_theme.last_error_text")]))();
    getKey(field) {
      return `${field.target} ${field.name}`;
    }
    hasEdited(target, name) {
      if (name) {
        return !(0, _utils.isEmpty)(this.getField(target, name));
      } else {
        let fields = this.theme_fields || [];
        return fields.any(field => field.target === target && !(0, _utils.isEmpty)(field.value));
      }
    }
    hasError(target, name) {
      return this.theme_fields.filter(f => f.target === target && (!name || name === f.name)).any(f => f.error);
    }
    getError(target, name) {
      let themeFields = this.themeFields;
      let key = this.getKey({
        target,
        name
      });
      let field = themeFields[key];
      return field ? field.error : "";
    }
    getField(target, name) {
      let themeFields = this.themeFields;
      let key = this.getKey({
        target,
        name
      });
      let field = themeFields[key];
      return field ? field.value : "";
    }
    removeField(field) {
      this.set("changed", true);
      field.upload_id = null;
      field.value = null;
      return this.saveChanges("theme_fields");
    }
    setField(target, name, value, upload_id, type_id) {
      this.set("changed", true);
      let themeFields = this.themeFields;
      let field = {
        name,
        target,
        value,
        upload_id,
        type_id
      };

      // slow path for uploads and so on
      if (type_id && type_id > 1) {
        let fields = this.theme_fields;
        let existing = fields.find(f => f.target === target && f.name === name && f.type_id === type_id);
        if (existing) {
          existing.value = value;
          existing.upload_id = upload_id;
        } else {
          fields.pushObject(field);
        }
        return;
      }

      // fast path
      let key = this.getKey({
        target,
        name
      });
      let existingField = themeFields[key];
      if (!existingField) {
        this.theme_fields.pushObject(field);
        themeFields[key] = field;
      } else {
        const changed = (0, _utils.isEmpty)(existingField.value) && !(0, _utils.isEmpty)(value) || (0, _utils.isEmpty)(value) && !(0, _utils.isEmpty)(existingField.value);
        existingField.value = value;
        if (changed) {
          // Observing theme_fields.@each.value is too slow, so manually notify
          // if the value goes to/from blank
          this.notifyPropertyChange("theme_fields.[]");
        }
      }
    }
    child_theme_ids(childThemes) {
      if (childThemes) {
        return childThemes.map(theme => (0, _object.get)(theme, "id"));
      }
    }
    static #_13 = (() => dt7948.n(this.prototype, "child_theme_ids", [(0, _decorators.default)("childThemes.[]")]))();
    warnUnassignedComponent(recent, component, hasParents) {
      return recent && component && !hasParents;
    }
    static #_14 = (() => dt7948.n(this.prototype, "warnUnassignedComponent", [(0, _decorators.default)("recentlyInstalled", "component", "hasParents")]))();
    removeChildTheme(theme) {
      const childThemes = this.childThemes;
      childThemes.removeObject(theme);
      return this.saveChanges("child_theme_ids");
    }
    addChildTheme(theme) {
      let childThemes = this.childThemes;
      if (!childThemes) {
        childThemes = [];
        this.set("childThemes", childThemes);
      }
      childThemes.removeObject(theme);
      childThemes.pushObject(theme);
      return this.saveChanges("child_theme_ids");
    }
    addParentTheme(theme) {
      let parentThemes = this.parentThemes;
      if (!parentThemes) {
        parentThemes = [];
        this.set("parentThemes", parentThemes);
      }
      parentThemes.addObject(theme);
    }
    checkForUpdates() {
      return this.save({
        remote_check: true
      }).then(() => this.set("changed", false));
    }
    updateToLatest() {
      return this.save({
        remote_update: true
      }).then(() => this.set("changed", false));
    }
    saveChanges() {
      const hash = this.getProperties.apply(this, arguments);
      return this.save(hash).finally(() => this.set("changed", false)).catch(_ajaxError.popupAjaxError);
    }
  }
  var _default = _exports.default = Theme;
});