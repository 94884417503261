define("admin/components/report-filters/filter", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Filter extends _component.default {
    onChange(value) {
      this.applyFilter(this.filter.id, value);
    }
    static #_ = (() => dt7948.n(this.prototype, "onChange", [_object.action]))();
  }
  _exports.default = Filter;
});