define("admin/models/web-hook", ["exports", "@glimmer/tracking", "@ember/object", "@ember/utils", "@ember-decorators/object", "discourse/models/group", "discourse/models/rest", "discourse/models/site", "discourse-common/utils/decorators"], function (_exports, _tracking, _object, _utils, _object2, _group, _rest, _site, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class WebHookExtras {
    static #_ = (() => dt7948.g(this.prototype, "categories", [_tracking.tracked]))();
    #categories = (() => (dt7948.i(this, "categories"), void 0))();
    constructor(args) {
      this.categories = args.categories || [];
      this.content_types = args.content_types || [];
      this.default_event_types = args.default_event_types || [];
      this.delivery_statuses = args.delivery_statuses || [];
      this.grouped_event_types = args.grouped_event_types || [];
    }
    addCategories(categories) {
      this.categories = this.categories.concat(categories).uniqBy(c => c.id);
    }
    get categoriesById() {
      if (this.categories) {
        return new Map(this.categories.map(c => [c.id, c]));
      }
    }
    findCategoryById(id) {
      return this.categoriesById?.get(id);
    }
  }
  class WebHook extends _rest.default {
    static ExtrasClass = (() => WebHookExtras)();
    content_type = 1; // json
    last_delivery_status = 1; // inactive
    wildcard_web_hook = false;
    verify_certificate = true;
    active = false;
    web_hook_event_types = null;
    groupsFilterInName = null;
    get wildcard() {
      return this.wildcard_web_hook ? "wildcard" : "individual";
    }
    static #_ = (() => dt7948.n(this.prototype, "wildcard", [(0, _object.computed)("wildcard_web_hook")]))();
    set wildcard(value) {
      this.set("wildcard_web_hook", value === "wildcard");
    }
    get categories() {
      return (this.category_ids || []).map(id => this.extras.findCategoryById(id));
    }
    static #_2 = (() => dt7948.n(this.prototype, "categories", [(0, _object.computed)("category_ids")]))();
    set categories(value) {
      this.extras ||= new WebHookExtras({});
      this.extras.addCategories(value);
      this.set("category_ids", value.map(c => c.id));
    }
    updateGroupsFilter() {
      const groupIds = this.group_ids;
      this.set("groupsFilterInName", _site.default.currentProp("groups").reduce((groupNames, g) => {
        if (groupIds.includes(g.id)) {
          groupNames.push(g.name);
        }
        return groupNames;
      }, []));
    }
    static #_3 = (() => dt7948.n(this.prototype, "updateGroupsFilter", [(0, _object2.observes)("group_ids")]))();
    groupFinder(term) {
      return _group.default.findAll({
        term,
        ignore_automatic: false
      });
    }
    description(isWildcardWebHook, types) {
      let desc = "";
      types.forEach(type => {
        const name = `${type.name.toLowerCase()}_event`;
        desc += desc !== "" ? `, ${name}` : name;
      });
      return isWildcardWebHook ? "*" : desc;
    }
    static #_4 = (() => dt7948.n(this.prototype, "description", [(0, _decorators.default)("wildcard_web_hook", "web_hook_event_types.[]")]))();
    createProperties() {
      const types = this.web_hook_event_types;
      const categoryIds = this.categories.map(c => c.id);
      const tagNames = this.tag_names;

      // Hack as {{group-selector}} accepts a comma-separated string as data source, but
      // we use an array to populate the datasource above.
      const groupsFilter = this.groupsFilterInName;
      const groupNames = typeof groupsFilter === "string" ? groupsFilter.split(",") : groupsFilter;
      return {
        payload_url: this.payload_url,
        content_type: this.content_type,
        secret: this.secret,
        wildcard_web_hook: this.wildcard_web_hook,
        verify_certificate: this.verify_certificate,
        active: this.active,
        web_hook_event_type_ids: (0, _utils.isEmpty)(types) ? [null] : types.map(type => type.id),
        category_ids: (0, _utils.isEmpty)(categoryIds) ? [null] : categoryIds,
        tag_names: (0, _utils.isEmpty)(tagNames) ? [null] : tagNames,
        group_ids: (0, _utils.isEmpty)(groupNames) || (0, _utils.isEmpty)(groupNames[0]) ? [null] : _site.default.currentProp("groups").reduce((groupIds, g) => {
          if (groupNames.includes(g.name)) {
            groupIds.push(g.id);
          }
          return groupIds;
        }, [])
      };
    }
    updateProperties() {
      return this.createProperties();
    }
  }
  _exports.default = WebHook;
});