define("admin/components/dashboard-period-selector", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "select-kit/components/period-chooser", "admin/components/modal/custom-date-range", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _dButton, _periodChooser, _customDateRange, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DashboardPeriodSelector extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    availablePeriods = ["yearly", "quarterly", "monthly", "weekly"];
    openCustomDateRangeModal() {
      this.modal.show(_customDateRange.default, {
        model: {
          startDate: this.args.startDate,
          endDate: this.args.endDate,
          setCustomDateRange: this.args.setCustomDateRange
        }
      });
    }
    static #_2 = (() => dt7948.n(this.prototype, "openCustomDateRangeModal", [_object.action]))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div>
          <PeriodChooser
            @period={{@period}}
            @action={{@setPeriod}}
            @content={{this.availablePeriods}}
            @fullDay={{false}}
          />
          <DButton
            @icon="gear"
            @action={{this.openCustomDateRangeModal}}
            @title="admin.dashboard.custom_date_range"
            class="custom-date-range-button"
          />
        </div>
      
    */
    {
      "id": "VV48/o0F",
      "block": "[[[1,\"\\n    \"],[10,0],[12],[1,\"\\n      \"],[8,[32,0],null,[[\"@period\",\"@action\",\"@content\",\"@fullDay\"],[[30,1],[30,2],[30,0,[\"availablePeriods\"]],false]],null],[1,\"\\n      \"],[8,[32,1],[[24,0,\"custom-date-range-button\"]],[[\"@icon\",\"@action\",\"@title\"],[\"gear\",[30,0,[\"openCustomDateRangeModal\"]],\"admin.dashboard.custom_date_range\"]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@period\",\"@setPeriod\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/admin/components/dashboard-period-selector.js",
      "scope": () => [_periodChooser.default, _dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = DashboardPeriodSelector;
});