define("admin/controllers/admin-email-bounced", ["exports", "@ember/object", "@ember-decorators/object", "discourse-common/config/environment", "discourse-common/lib/debounce", "admin/controllers/admin-email-logs"], function (_exports, _object, _object2, _environment, _debounce, _adminEmailLogs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminEmailBouncedController extends _adminEmailLogs.default {
    handleShowIncomingEmail(id, event) {
      event?.preventDefault();
      this.send("showIncomingEmail", id);
    }
    static #_ = (() => dt7948.n(this.prototype, "handleShowIncomingEmail", [_object.action]))();
    filterEmailLogs() {
      (0, _debounce.default)(this, this.loadLogs, _environment.INPUT_DELAY);
    }
    static #_2 = (() => dt7948.n(this.prototype, "filterEmailLogs", [(0, _object2.observes)("filter.{status,user,address,type}")]))();
  }
  _exports.default = AdminEmailBouncedController;
});