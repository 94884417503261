define("admin/templates/customize-robots-txt", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="robots-txt-edit">
    <h3>{{i18n "admin.customize.robots.title"}}</h3>
    <p>{{i18n "admin.customize.robots.warning"}}</p>
    {{#if this.model.overridden}}
      <div class="overridden">
        {{i18n "admin.customize.robots.overridden"}}
      </div>
    {{/if}}
    <Textarea @value={{this.buffered.robots_txt}} class="robots-txt-input" />
    <SaveControls
      @model={{this}}
      @action={{action "save"}}
      @saved={{this.saved}}
      @saveDisabled={{this.saveDisabled}}
    >
      <DButton
        @disabled={{this.resetDisabled}}
        @icon="arrow-rotate-left"
        @action={{this.reset}}
        @label="admin.settings.reset"
        class="btn-default"
      />
    </SaveControls>
  </div>
  */
  {
    "id": "qjoCDBns",
    "block": "[[[10,0],[14,0,\"robots-txt-edit\"],[12],[1,\"\\n  \"],[10,\"h3\"],[12],[1,[28,[35,0],[\"admin.customize.robots.title\"],null]],[13],[1,\"\\n  \"],[10,2],[12],[1,[28,[35,0],[\"admin.customize.robots.warning\"],null]],[13],[1,\"\\n\"],[41,[30,0,[\"model\",\"overridden\"]],[[[1,\"    \"],[10,0],[14,0,\"overridden\"],[12],[1,\"\\n      \"],[1,[28,[35,0],[\"admin.customize.robots.overridden\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[8,[39,2],[[24,0,\"robots-txt-input\"]],[[\"@value\"],[[30,0,[\"buffered\",\"robots_txt\"]]]],null],[1,\"  \"],[8,[39,3],null,[[\"@model\",\"@action\",\"@saved\",\"@saveDisabled\"],[[30,0],[28,[37,4],[[30,0],\"save\"],null],[30,0,[\"saved\"]],[30,0,[\"saveDisabled\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,5],[[24,0,\"btn-default\"]],[[\"@disabled\",\"@icon\",\"@action\",\"@label\"],[[30,0,[\"resetDisabled\"]],\"arrow-rotate-left\",[30,0,[\"reset\"]],\"admin.settings.reset\"]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[],false,[\"i18n\",\"if\",\"textarea\",\"save-controls\",\"action\",\"d-button\"]]",
    "moduleName": "admin/templates/customize-robots-txt.hbs",
    "isStrictMode": false
  });
});