define("admin/components/admin-backups-logs", ["exports", "@ember/component", "@ember/runloop", "@ember-decorators/component", "@ember-decorators/object", "discourse-common/lib/debounce", "discourse-i18n", "@ember/template-factory"], function (_exports, _component, _runloop, _component2, _object, _debounce, _discourseI18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.hasFormattedLogs}}
    <pre>{{this.formattedLogs}}</pre>
  {{else}}
    <p>{{this.noLogsMessage}}</p>
  {{/if}}
  {{#if this.showLoadingSpinner}}
    <div class="spinner small"></div>
  {{/if}}
  */
  {
    "id": "FifPKb4F",
    "block": "[[[41,[30,0,[\"hasFormattedLogs\"]],[[[1,\"  \"],[10,\"pre\"],[12],[1,[30,0,[\"formattedLogs\"]]],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,2],[12],[1,[30,0,[\"noLogsMessage\"]]],[13],[1,\"\\n\"]],[]]],[41,[30,0,[\"showLoadingSpinner\"]],[[[1,\"  \"],[10,0],[14,0,\"spinner small\"],[12],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\"]]",
    "moduleName": "admin/components/admin-backups-logs.hbs",
    "isStrictMode": false
  });
  const AdminBackupsLogs = dt7948.c(class AdminBackupsLogs extends _component.default {
    showLoadingSpinner = false;
    hasFormattedLogs = false;
    noLogsMessage = (() => _discourseI18n.default.t("admin.backups.logs.none"))();
    formattedLogs = "";
    index = 0;
    _reset() {
      this.setProperties({
        formattedLogs: "",
        index: 0
      });
    }
    _scrollDown() {
      const div = this.element;
      div.scrollTop = div.scrollHeight;
    }
    _resetFormattedLogs() {
      if (this.logs.length === 0) {
        this._reset(); // reset the cached logs whenever the model is reset
        this.renderLogs();
      }
    }
    static #_ = (() => dt7948.n(this.prototype, "_resetFormattedLogs", [(0, _object.observes)("logs.[]"), (0, _object.on)("init")]))();
    _updateFormattedLogsFunc() {
      const logs = this.logs;
      if (logs.length === 0) {
        return;
      }

      // do the log formatting only once for HELLish performance
      let formattedLogs = this.formattedLogs;
      for (let i = this.index, length = logs.length; i < length; i++) {
        const date = logs[i].get("timestamp"),
          message = logs[i].get("message");
        formattedLogs += "[" + date + "] " + message + "\n";
      }
      // update the formatted logs & cache index
      this.setProperties({
        formattedLogs,
        index: logs.length
      });
      // force rerender
      this.renderLogs();
      (0, _runloop.scheduleOnce)("afterRender", this, this._scrollDown);
    }
    _updateFormattedLogs() {
      (0, _debounce.default)(this, this._updateFormattedLogsFunc, 150);
    }
    static #_2 = (() => dt7948.n(this.prototype, "_updateFormattedLogs", [(0, _object.observes)("logs.[]"), (0, _object.on)("init")]))();
    renderLogs() {
      const formattedLogs = this.formattedLogs;
      if (formattedLogs && formattedLogs.length > 0) {
        this.set("hasFormattedLogs", true);
      } else {
        this.set("hasFormattedLogs", false);
      }
      // add a loading indicator
      if (this.get("status.isOperationRunning")) {
        this.set("showLoadingSpinner", true);
      } else {
        this.set("showLoadingSpinner", false);
      }
    }
  }, [(0, _component2.classNames)("admin-backups-logs")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AdminBackupsLogs);
});