define("admin/routes/admin-customize-colors", ["exports", "@ember/routing/route", "admin/models/color-scheme"], function (_exports, _route, _colorScheme) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminCustomizeColorsRoute extends _route.default {
    model() {
      return _colorScheme.default.findAll();
    }
    setupController(controller, model) {
      controller.set("model", model);
    }
  }
  _exports.default = AdminCustomizeColorsRoute;
});