define("admin/components/schema-theme-setting/field-input-description", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div class="schema-field__input-description">
      {{@description}}
    </div>
  
  */
  {
    "id": "iEc30I9T",
    "block": "[[[1,\"\\n  \"],[10,0],[14,0,\"schema-field__input-description\"],[12],[1,\"\\n    \"],[1,[30,1]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@description\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/admin/components/schema-theme-setting/field-input-description.js",
    "isStrictMode": true
  }), (0, _templateOnly.default)());
});