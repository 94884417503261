define("admin/components/admin-report-inline-table", ["exports", "@ember/component", "@ember-decorators/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="table-container">
    {{#each this.model.data as |data|}}
      <a class="table-cell user-{{data.key}}" href={{data.url}}>
        <span class="label">
          {{#if data.icon}}
            {{d-icon data.icon}}
          {{/if}}
          {{data.x}}
        </span>
        <span class="value">
          {{number data.y}}
        </span>
      </a>
    {{/each}}
  </div>
  */
  {
    "id": "EcTtNo6q",
    "block": "[[[10,0],[14,0,\"table-container\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"model\",\"data\"]]],null]],null],null,[[[1,\"    \"],[10,3],[15,0,[29,[\"table-cell user-\",[30,1,[\"key\"]]]]],[15,6,[30,1,[\"url\"]]],[12],[1,\"\\n      \"],[10,1],[14,0,\"label\"],[12],[1,\"\\n\"],[41,[30,1,[\"icon\"]],[[[1,\"          \"],[1,[28,[35,3],[[30,1,[\"icon\"]]],null]],[1,\"\\n\"]],[]],null],[1,\"        \"],[1,[30,1,[\"x\"]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,1],[14,0,\"value\"],[12],[1,\"\\n        \"],[1,[28,[35,4],[[30,1,[\"y\"]]],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[1]],null],[13]],[\"data\"],false,[\"each\",\"-track-array\",\"if\",\"d-icon\",\"number\"]]",
    "moduleName": "admin/components/admin-report-inline-table.hbs",
    "isStrictMode": false
  });
  const AdminReportInlineTable = dt7948.c(class AdminReportInlineTable extends _component.default {}, [(0, _component2.classNames)("admin-report-inline-table")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AdminReportInlineTable);
});