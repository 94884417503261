define("admin/components/dashboard-new-feature-item", ["exports", "truth-helpers", "discourse/components/cook-text", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _truthHelpers, _cookText, _i18n, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DashboardNewFeatureItem = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div class="admin-new-feature-item">
      <div class="admin-new-feature-item__content">
        <div class="admin-new-feature-item__header">
          {{#if (and @item.emoji (not @item.screenshot_url))}}
            <div class="admin-new-feature-item__new-feature-emoji">
              {{@item.emoji}}
            </div>
          {{/if}}
          <h3>
            {{@item.title}}
          </h3>
          {{#if @item.discourse_version}}
            <div class="admin-new-feature-item__new-feature-version">
              {{@item.discourse_version}}
            </div>
          {{/if}}
        </div>
  
        {{#if @item.screenshot_url}}
          <img
            src={{@item.screenshot_url}}
            class="admin-new-feature-item__screenshot"
            alt={{@item.title}}
          />
        {{/if}}
  
        <div class="admin-new-feature-item__feature-description">
          <CookText @rawText={{@item.description}} />
  
          {{#if @item.link}}
            <a
              href={{@item.link}}
              target="_blank"
              rel="noopener noreferrer"
              class="admin-new-feature-item__learn-more"
            >
              {{i18n "admin.dashboard.new_features.learn_more"}}
            </a>
          {{/if}}
        </div>
      </div>
    </div>
  
  */
  {
    "id": "Y6wfRfYr",
    "block": "[[[1,\"\\n  \"],[10,0],[14,0,\"admin-new-feature-item\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"admin-new-feature-item__content\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"admin-new-feature-item__header\"],[12],[1,\"\\n\"],[41,[28,[32,0],[[30,1,[\"emoji\"]],[28,[32,1],[[30,1,[\"screenshot_url\"]]],null]],null],[[[1,\"          \"],[10,0],[14,0,\"admin-new-feature-item__new-feature-emoji\"],[12],[1,\"\\n            \"],[1,[30,1,[\"emoji\"]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"        \"],[10,\"h3\"],[12],[1,\"\\n          \"],[1,[30,1,[\"title\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"],[41,[30,1,[\"discourse_version\"]],[[[1,\"          \"],[10,0],[14,0,\"admin-new-feature-item__new-feature-version\"],[12],[1,\"\\n            \"],[1,[30,1,[\"discourse_version\"]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n\\n\"],[41,[30,1,[\"screenshot_url\"]],[[[1,\"        \"],[10,\"img\"],[15,\"src\",[30,1,[\"screenshot_url\"]]],[14,0,\"admin-new-feature-item__screenshot\"],[15,\"alt\",[30,1,[\"title\"]]],[12],[13],[1,\"\\n\"]],[]],null],[1,\"\\n      \"],[10,0],[14,0,\"admin-new-feature-item__feature-description\"],[12],[1,\"\\n        \"],[8,[32,2],null,[[\"@rawText\"],[[30,1,[\"description\"]]]],null],[1,\"\\n\\n\"],[41,[30,1,[\"link\"]],[[[1,\"          \"],[10,3],[15,6,[30,1,[\"link\"]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[14,0,\"admin-new-feature-item__learn-more\"],[12],[1,\"\\n            \"],[1,[28,[32,3],[\"admin.dashboard.new_features.learn_more\"],null]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@item\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/admin/components/dashboard-new-feature-item.js",
    "scope": () => [_truthHelpers.and, _truthHelpers.not, _cookText.default, _i18n.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)());
  var _default = _exports.default = DashboardNewFeatureItem;
});