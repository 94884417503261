define("admin/components/site-settings/compact-list", ["exports", "@ember/component", "@ember/object", "discourse-common/lib/helpers", "@ember/template-factory"], function (_exports, _component, _object, _helpers, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <ListSetting
    @value={{this.settingValue}}
    @settingName={{this.setting.setting}}
    @choices={{this.settingChoices}}
    @onChange={{this.onChangeListSetting}}
    @onChangeChoices={{this.onChangeChoices}}
    @options={{hash allowAny=this.allowAny}}
  />
  */
  {
    "id": "n7FpvbBw",
    "block": "[[[8,[39,0],null,[[\"@value\",\"@settingName\",\"@choices\",\"@onChange\",\"@onChangeChoices\",\"@options\"],[[30,0,[\"settingValue\"]],[30,0,[\"setting\",\"setting\"]],[30,0,[\"settingChoices\"]],[30,0,[\"onChangeListSetting\"]],[30,0,[\"onChangeChoices\"]],[28,[37,1],null,[[\"allowAny\"],[[30,0,[\"allowAny\"]]]]]]],null]],[],false,[\"list-setting\",\"hash\"]]",
    "moduleName": "admin/components/site-settings/compact-list.hbs",
    "isStrictMode": false
  });
  class CompactList extends _component.default {
    tokenSeparator = "|";
    createdChoices = null;
    get settingValue() {
      return this.value.toString().split(this.tokenSeparator).filter(Boolean);
    }
    static #_ = (() => dt7948.n(this.prototype, "settingValue", [(0, _object.computed)("value")]))();
    get settingChoices() {
      return [...new Set([...(0, _helpers.makeArray)(this.settingValue), ...(0, _helpers.makeArray)(this.setting.choices), ...(0, _helpers.makeArray)(this.createdChoices)])];
    }
    static #_2 = (() => dt7948.n(this.prototype, "settingChoices", [(0, _object.computed)("settingValue", "setting.choices.[]", "createdChoices.[]")]))();
    onChangeListSetting(value) {
      this.set("value", value.join(this.tokenSeparator));
    }
    static #_3 = (() => dt7948.n(this.prototype, "onChangeListSetting", [_object.action]))();
    onChangeChoices(choices) {
      this.set("createdChoices", [...new Set([...(0, _helpers.makeArray)(this.createdChoices), ...(0, _helpers.makeArray)(choices)])]);
    }
    static #_4 = (() => dt7948.n(this.prototype, "onChangeChoices", [_object.action]))();
  }
  _exports.default = CompactList;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, CompactList);
});