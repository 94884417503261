define("admin/models/version-check", ["exports", "@ember/object", "discourse/lib/ajax", "discourse-common/utils/decorators"], function (_exports, _object, _ajax, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class VersionCheck extends _object.default {
    static find() {
      return (0, _ajax.ajax)("/admin/version_check").then(json => VersionCheck.create(json));
    }
    noCheckPerformed(updatedAt) {
      return updatedAt === null;
    }
    static #_ = (() => dt7948.n(this.prototype, "noCheckPerformed", [(0, _decorators.default)("updated_at")]))();
    upToDate(missingVersionsCount) {
      return missingVersionsCount === 0 || missingVersionsCount === null;
    }
    static #_2 = (() => dt7948.n(this.prototype, "upToDate", [(0, _decorators.default)("missing_versions_count")]))();
    behindByOneVersion(missingVersionsCount) {
      return missingVersionsCount === 1;
    }
    static #_3 = (() => dt7948.n(this.prototype, "behindByOneVersion", [(0, _decorators.default)("missing_versions_count")]))();
    gitLink(installedSHA) {
      if (installedSHA) {
        return `https://github.com/discourse/discourse/commits/${installedSHA}`;
      }
    }
    static #_4 = (() => dt7948.n(this.prototype, "gitLink", [(0, _decorators.default)("installed_sha")]))();
    shortSha(installedSHA) {
      if (installedSHA) {
        return installedSHA.slice(0, 10);
      }
    }
    static #_5 = (() => dt7948.n(this.prototype, "shortSha", [(0, _decorators.default)("installed_sha")]))();
  }
  _exports.default = VersionCheck;
});