define("admin/templates/customize-email-style-edit", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <EmailStylesEditor
    @styles={{this.model}}
    @fieldName={{this.fieldName}}
    @save={{action "save"}}
  />
  
  <div class="admin-footer">
    <div class="buttons">
      <DButton
        @action={{this.save}}
        @disabled={{this.saveDisabled}}
        @translatedLabel={{this.saveButtonText}}
        class="btn-primary"
      />
    </div>
  </div>
  */
  {
    "id": "B/Uru/q2",
    "block": "[[[8,[39,0],null,[[\"@styles\",\"@fieldName\",\"@save\"],[[30,0,[\"model\"]],[30,0,[\"fieldName\"]],[28,[37,1],[[30,0],\"save\"],null]]],null],[1,\"\\n\\n\"],[10,0],[14,0,\"admin-footer\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"buttons\"],[12],[1,\"\\n    \"],[8,[39,2],[[24,0,\"btn-primary\"]],[[\"@action\",\"@disabled\",\"@translatedLabel\"],[[30,0,[\"save\"]],[30,0,[\"saveDisabled\"]],[30,0,[\"saveButtonText\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"email-styles-editor\",\"action\",\"d-button\"]]",
    "moduleName": "admin/templates/customize-email-style-edit.hbs",
    "isStrictMode": false
  });
});