define("admin/models/email-style", ["exports", "discourse/models/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class EmailStyle extends _rest.default {
    changed = false;
    setField(fieldName, value) {
      this.set(`${fieldName}`, value);
      this.set("changed", true);
    }
  }
  _exports.default = EmailStyle;
});